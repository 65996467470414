import React, { useCallback, useMemo, useState } from 'react';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldSwitch, InputTable, InputTableCell, InputTableRow, Seperator, Space, useApp, useForm } from '@dex/bubl-dash';
import TeamsApi from '../TeamsApi';
import { get, loop } from '@dex/bubl-helpers';
import TeamsSingleAddAthlete from './TeamsSingleAddAthlete';

const TeamsSingleAthletes: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const [athletes, setAthletes] = useState(data.activeAthletes || []);

    const activeIds = useMemo(() => {

        const ids: any = {};

        data.activeAthletesIds.forEach((id: any) => {

            ids[id] = true;

        });

        return ids;

    }, []);

    const form = useForm({ activeIds: activeIds });

    const update = TeamsApi.update(data.id, "updateAthletes");

    const handleSubmit = useCallback((values: any) => {

        const athletesIds: any = [];
        const activeAthletesIds: any = [];

        loop(athletes, (athlete: any) => {

            athletesIds.push(athlete.id);

            if (values.activeIds && values.activeIds[athlete.id]) activeAthletesIds.push(athlete.id);

        });

        update.run({
            data: { activeAthletesIds: activeAthletesIds, athletesIds: athletesIds },
            onComplete: () => {

                app.alert("Team Athletes Saved", "success");

            }
        });

    }, [athletes]);

    const handleRemove = useCallback((athlete: any) => {

        setAthletes((list) => {

            list = list.filter((item: any) => item.id !== athlete.id);

            return list;

        });

    }, [athletes]);

    const columns = [
        {
            key: "#",
            label: "#",
            style: { width: 44, paddingLeft: 12 },
        },
        {
            key: "this",
            label: "Athlete Name",
            format: "ModelLink",
            style: { paddingLeft: 12 },
        },
        {
            key: "shortName",
            label: "Short Name",
            style: { paddingLeft: 12 },
        },
        {
            key: "position",
            label: "Position",
            style: { paddingLeft: 12 },
        },
        {
            key: "active",
            label: "Active",
            style: { paddingLeft: 12 },
            render: () => {

            }
        },
        {
            key: "actions",
            label: "Actions",
            style: { paddingLeft: 12, width: 100 },
            alignEnd: true,
        },
    ];

    return useMemo(() => (

        <>

            <Seperator
                heading={"Athletes"}
                top={"auto"}
                bottom={"auto"}
            />

            <TeamsSingleAddAthlete
                data={data}
                form={form}
                athletes={athletes}
                setAthletes={setAthletes}
            />

            <Space />

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={update.loading}
            >

                <InputTable
                    columns={columns}
                >

                    {athletes.map((athlete: any, index: number) => (
                        <InputTableRow key={athlete.id + index}>

                            <InputTableCell {...columns[0]}>
                                {index + 1}
                            </InputTableCell>

                            <InputTableCell {...columns[1]}>
                                {app.columnValue(columns[1], athlete)}
                            </InputTableCell>

                            <InputTableCell {...columns[2]}>
                                {app.columnValue(columns[2], athlete)}
                            </InputTableCell>

                            <InputTableCell {...columns[3]}>
                                {app.columnValue(columns[3], athlete)}
                            </InputTableCell>

                            <InputTableCell {...columns[4]}>
                                <FieldSwitch
                                    form={form}
                                    name={`activeIds.${athlete.id}`}
                                    defaultValue={get(form.defaults, `activeIds.${athlete.id}`)}
                                />
                            </InputTableCell>

                            <InputTableCell {...columns[5]}>
                                <Button
                                    type={"faded"}
                                    size={"small"}
                                    label={"Remove"}
                                    onClick={handleRemove.bind(null, athlete)}
                                />
                            </InputTableCell>

                        </InputTableRow>
                    ))}

                </InputTable>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={update.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </>

    ), [form.hash, athletes, update.loading]);

}

export default TeamsSingleAthletes;
