import { Button, ButtonRow, Col, FieldNumber, FieldTextTime, Form, Line, Panel, Row, Seperator, useForm } from '@dex/bubl-dash';
import React from 'react';
import { getPaceFromKph, getKphFromPace, getSwimPaceNew, getSwimSpeed, getSpeed, getPace, getSwimPaceFromDistanceTime, getDecimalTime, getClockTime } from '../../functions/sharedTests';
import BigNumber from 'bignumber.js';

const RibbonPaceCalculator: React.FC<any> = (props: any) => {

    const { handleClose } = props;

    const form = useForm();

    const handleReset = () => {
        form.reset()
    };

    const getCalculatorPace = (distance, time) => {
        if (distance == null || time == null) return;

        const timeParts = time.split(':');

        const hours = parseInt(timeParts[0]) || 0;
        const minutes = parseInt(timeParts[1]) || 0;
        let seconds = parseInt(timeParts[2]) || 0;

        seconds = seconds / 60;

        const totalMinutes = ((hours * 60) + minutes) + seconds;

        const pace = new BigNumber(totalMinutes)
            .dividedBy(distance)
            .toFixed(2);

        return getClockTime(pace, "mm:ss");
    }

    return (

        <Panel
            heading={"Pace/Speed Calculator"}
        >

            <Form form={form}>

                <Row edge gutter={8}>

                    <Col col={{ xs: 24 }}>
                        <Seperator heading={"Running"} top={"small"} bottom={"small"} />
                    </Col>

                    <Col col={{ xs: 6 }}>

                        <FieldNumber
                            form={form}
                            name='manualSpeed'
                            defaultValue={form.defaults.manualSpeed}
                            suffix=" kph"
                            label='Speed (kph)'
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 6 }}>

                        <FieldTextTime
                            dynamicKey
                            form={form}
                            name='calculatedPace'
                            defaultValue={getPaceFromKph(form.values.manualSpeed)}
                            placeholder="mm:ss/km"
                            label='Pace'
                            wrapClass={""}
                            disabled
                        />

                    </Col>

                    <Col col={{ xs: 6 }}>

                        <FieldTextTime
                            form={form}
                            name='manualPace'
                            defaultValue={form.values.manualPace}
                            placeholder="mm:ss"
                            label='Pace (mm:ss/km)'
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 6 }}>

                        <FieldNumber
                            dynamicKey
                            form={form}
                            name='calculatedSpeed'
                            defaultValue={getKphFromPace(form.values.manualPace)}
                            suffix=" kph"
                            label='Speed (kph)'
                            wrapClass={""}
                            disabled
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>
                        <Seperator heading={"Swim"} top={"small"} bottom={"small"} />
                    </Col>

                    <Col col={{ xs: 6 }}>

                        <FieldNumber
                            form={form}
                            name='manualSwimSpeed'
                            defaultValue={form.defaults.manualSwimSpeed}
                            suffix=" m/sec"
                            label='Swim Speed (m/sec)'
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 6 }}>

                        <FieldTextTime
                            dynamicKey
                            form={form}
                            name='calculatedSwimPace'
                            defaultValue={getSwimPaceNew(form.values.manualSwimSpeed)}
                            placeholder="mm:ss"
                            label='Swim Pace (mm:ss/100m)'
                            wrapClass={""}
                            disabled
                        />

                    </Col>

                    <Col col={{ xs: 6 }}>

                        <FieldTextTime
                            form={form}
                            name='manualSwimPace'
                            defaultValue={form.values.manualSwimPace}
                            placeholder="mm:ss"
                            label='Swim Pace (mm:ss/100m)'
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 6 }}>

                        <FieldNumber
                            dynamicKey
                            form={form}
                            name='calculatedSpeed'
                            defaultValue={getSwimSpeed(form.values.manualSwimPace)}
                            suffix=" m/sec"
                            label='Swim Speed'
                            wrapClass={""}
                            disabled
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>
                        <Seperator heading={"Other"} top={"small"} bottom={"small"} />
                    </Col>

                    <Col col={{ xs: 6 }}>

                        <FieldNumber
                            form={form}
                            name='manualDistance'
                            defaultValue={form.defaults.manualDistance}
                            suffix=" km"
                            label='Distance (km)'
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 6 }}>

                        <FieldTextTime
                            form={form}
                            name='manualTime'
                            defaultValue={form.defaults.manualTime}
                            placeholder="hh:mm:ss"
                            label='Time (hh:mm:ss)'
                            wrapClass={"manual-input"}
                        />

                    </Col>

                    <Col col={{ xs: 12 }} />

                    <Col col={{ xs: 6 }}>

                        <FieldNumber
                            dynamicKey
                            form={form}
                            name='otherSpeed'
                            defaultValue={getSpeed(form.values.manualDistance, form.values.manualTime)}
                            suffix=" kph"
                            label='Speed (kph)'
                            wrapClass={""}
                            disabled
                        />

                    </Col>

                    <Col col={{ xs: 6 }}>

                        <FieldTextTime
                            dynamicKey
                            form={form}
                            name='otherPace'
                            defaultValue={getCalculatorPace(form.values.manualDistance, form.values.manualTime)}
                            placeholder="mm:ss"
                            label='Pace (mm:ss/km)'
                            wrapClass={""}
                            disabled
                        />

                    </Col>

                    <Col col={{ xs: 6 }}>
                        <FieldTextTime
                            dynamicKey
                            form={form}
                            name='otherSwimPace'
                            defaultValue={getSwimPaceFromDistanceTime(form.values.manualTime, form.values.manualDistance)}
                            placeholder="mm:ss"
                            label='Swim Pace (mm:ss/100m)'
                            wrapClass={""}
                            disabled
                        />

                    </Col>

                </Row>

            </Form>

            <Line />

            <ButtonRow>

                <Button
                    label="Reset"
                    type='faded'
                    onClick={handleReset.bind(null)}
                />
                <Button
                    label="Close"
                    // type='faded'
                    onClick={handleClose.bind(null)}
                />

            </ButtonRow>

        </Panel>

    );

}

export default RibbonPaceCalculator;
