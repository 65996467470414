import { Col, Line, Row, Space } from '@dex/bubl-dash';
import React, { useMemo } from 'react';
import ReportMetaTable from '../../../elements/ReportMetaTable/ReportMetaTable';
import styles from "./GaitReportSummary.module.scss";
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import { ColoredHeader } from './GaitReportPage';
import GaitReportComment from '../../../elements/GaitReportComment/GaitReportComment';
import ReportCheck from '../../../elements/ReportCheck/ReportCheck';

const GaitReportSummary: React.FC<any> = (props: any) => {

    const { data, form } = props;

    let gender = data.athlete?.gender;

    return useMemo(() => (

        <Row gutter={20} edge>

            <Col col={{ xs: 24 }}>

                <ColoredHeader
                    heading="Summary"
                />

            </Col>

            <Col col={{ xs: 12 }}>

                <GaitReportComment
                    label="Major Issues"
                    className="summary"
                    value={data.summary.majorIssues}
                />

            </Col>

            <Col col={{ xs: 12 }}>

                <GaitReportComment
                    label="Minor Issues"
                    className="summary"
                    value={data.summary.minorIssues}
                />

            </Col>

            <Col col={{ xs: 16 }}>

                <GaitReportComment
                    label="Recomended Shoe Types"
                    className="summary"
                    value={<RecomendedShoe data={data.summary} />}
                />

            </Col>

            <Col col={{ xs: 8 }}>

                <GaitReportComment
                    label="Shoe Brands Of This Type"
                    className="summary"
                    value={<RecomendedBrands data={data.summary} />}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <GaitReportComment
                    label="Comments"
                    className="summary"
                    value={data.summary.comments}
                />

            </Col>

        </Row>

    ), [data]);

}

const RecomendedShoe: React.FC<any> = (props: any) => {

    const { data } = props;

    return (
        <Row edge gutter={8}>

            <Col col={{ xs: 10 }}>

                <div className={styles.list}>
                    <ReportCheck checked={data?.shoeType?.includes("Neutral/Balance")} />
                    <strong>Neutral/Balance</strong>
                </div>

                <div className={styles.list}>
                    <ReportCheck checked={data?.shoeType?.includes("Stability")} />
                    <strong>Stability</strong>
                    <small> (Mild Pronators/Supinators)</small>
                </div>

                <div className={styles.list}>
                    <ReportCheck checked={data?.shoeType?.includes("Motion Control")} />
                    <strong>Motion Control</strong>
                    <small> (Overpronators)</small>
                </div>

                <div className={styles.list}>
                    <ReportCheck checked={data?.shoeType?.includes("Trail")} />
                    <strong>Trail</strong>
                </div>

            </Col>

            <Col col={{ xs: 14 }}>

                <div className={styles.list}>
                    <ReportCheck checked={data?.shoeType?.includes("Cushioning")} />
                    <strong>Cushioning</strong>
                    <small> (Heel Strikers, Long-Distance/Heavy Runner)</small>
                </div>

                <div className={styles.list}>
                    <ReportCheck checked={data?.shoeType?.includes("Light/Carbon Support")} />
                    <strong>Light/Carbon Support</strong>
                    <small> (Racing, Forefoot Runners)</small>
                </div>

                <div className={styles.list}>
                    <ReportCheck checked={data?.shoeType?.includes("Rigid/Supportive")} />
                    <strong>Rigid/Strong Midsole</strong>
                    <small> (High-Arc, Heavy Supinators)</small>
                </div>

            </Col>

        </Row>
    )

}

const RecomendedBrands: React.FC<any> = (props: any) => {

    const { data } = props;

    return (
        <Row edge gutter={8}>

            <Col col={{ xs: 24 }}>

                <Row edge gutter={1}>

                    {(data.shoeBrand || []).map((brand, index) => (
                        <Col col={{ xs: 24 }} key={index} className={styles.brand}>
                            ● {brand}
                        </Col>
                    ))}

                </Row>

            </Col>

        </Row>
    )

}

const TickBox: React.FC<any> = (props: any) => {

    const { tick } = props;

    return (
        <div className={tick ? styles.tick : styles.untick} />
    )
}

export default GaitReportSummary;