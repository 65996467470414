import { Col, Heading, Panel, Row, Space, useMountEffect } from "@dex/bubl-dash";
import React, { useEffect, useState } from "react";
import ReportMetaTable from "../../../elements/ReportMetaTable/ReportMetaTable";
import { changeDemographic } from "../../../functions/sharedTests";
import { clone, get, titleCase } from "@dex/bubl-helpers";
import ReportHeading from "../../../elements/ReportHeading/ReportHeading";
import RaceReadyLegend from "../../../elements/RaceReadyLegend/RaceReadyLegend";
import ChartProps from "../../../elements/Chart/Chart.Props";
import ChartRaceReady from "../../../elements/ChartLabTests/ChartRaceReady";
import PanelChart from "../../../elements/PanelChart/PanelChart";
import { mergeEffort, withinRange, withinRangeCurrent } from "../../../functions/raceReadyTest";
import styles from "./TestSessionsReportRaceReadyResult.module.scss";
import EventTargetsApi from "../../EventTargets/EventTargetsApi";
import RaceReadySideTable from "../../../elements/RaceReadySideTable/RaceReadySideTable";
import ReportHeadingNameDate from "../../../elements/ReportHeadingNameDate/ReportHeadingNameDate";

const TestSessionsReportRaceReadyResult: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const activity = data.test.activity;

    const avgData = clone(data?.test?.avgData || []);

    const { effortData, efforts } = mergeEffort(avgData)

    const options = [
        activity === "Cycling" ? { label: "Power", value: "Power" } : null,
        activity === "Running" ? { label: "Speed", value: "Speed" } : null,
        { label: "HR", value: "HR" },
        { label: "VO2", value: "VO2" },
        { label: "SMO2", value: "SMO2" },
        { label: "Lactate", value: "Lactate" },
        { label: "RPE", value: "RPE" },
        { label: "Breathing", value: "Breathing" },
        { label: "Energy", value: "Carb Burn Rate" },
        activity === "Running" ? { label: "Stride", value: "Stride Rate" } : null,
    ].filter(Boolean);

    let types: any = [];

    const getType = (key) => {

        if (key === "Power") {

            return types = [
                {
                    name: "w",
                    label: "W",
                    data: effortData,
                },
                {
                    name: "w/kg",
                    label: "W/kg",
                    data: effortData,
                }
            ];

        } else if (key === "Speed") {

            return types = [
                {
                    name: "speed",
                    label: "speed",
                    data: effortData,
                },
                {
                    name: "pace",
                    label: "pace",
                    data: effortData,
                }
            ];

        } else if (key === "SMO2") {

            return types = [
                {
                    name: "smo2_1",
                    label: "SMO2 1",
                    data: effortData,
                },
                {
                    name: "smo2_2",
                    label: "SMO2 2",
                    data: effortData,
                }
            ];

        } else if (key === "VO2") {

            return types = [
                {
                    name: "vo2relative",
                    label: "ml/kg*min",
                    data: effortData,
                },
                {
                    name: "vo2absolute",
                    label: "L/min",
                    data: effortData,
                }
            ];

        } else if (key === "Energy") {

            return types = [
                {
                    name: "g/hr",
                    label: "g/hr",
                    data: effortData,
                },
                {
                    name: "kcal/hr",
                    label: "kcal/hr",
                    data: effortData,
                }
            ];

        } else if (key === "Breathing") {

            return types = [
                {
                    name: "brpm",
                    label: "BRPM",
                    data: effortData,
                },
                {
                    name: "tvl",
                    label: "TVL",
                    data: effortData,
                }
            ];

        } else if (key === "Lactate") {

            return types = [
                {
                    name: "mmol/L",
                    label: "mmol/L",
                    data: effortData,
                },
                {
                    name: "mmol/L/min",
                    label: "mmol/L/min",
                    data: effortData,
                }
            ];

        } else if (key === "Stride Rate" || key === "Stride") {

            return types = [
                {
                    name: "per min",
                    label: "per min",
                    data: effortData,
                },
                {
                    name: "length [m]",
                    label: "length [m]",
                    data: effortData,
                }
            ];

        } else if (key === "RPE" || key === "HR") {

            return types = [
            ];

        };
    }

    const targets = EventTargetsApi.getTargetsByEvent();

    useMountEffect(() => {

        targets.run({
            params: { eventType: form.values.manual?.type, activity: activity },
        });

    });

    return (

        <>

            <ReportHeadingNameDate
                name={data.athlete.fullName}
                date={data.date}
            />

            <Row edge gutter={"auto"} >

                <Col col={{ xs: 8 }} >

                    <ReportHeading
                        text={data.athlete.fullName}
                    />

                    <ReportMetaTable
                        data={data}
                        columnSize={{ xs: 12 }}
                        columnA={[
                            {
                                key: 'bio.age',
                                label: 'Age',
                                format: "number",
                                suffix: " yrs"
                            },
                            {
                                key: 'athlete.gender',
                                label: 'Gender',
                                format: "titleCase"
                            }
                        ]}
                        columnB={[
                            {
                                key: 'bio.weight',
                                label: 'Weight',
                                format: "number",
                                suffix: " kg"
                            },
                            {
                                key: 'bio.height',
                                label: 'Height',
                                format: "number",
                                suffix: " cm"
                            },
                        ]}
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <ReportHeading
                        text="Current Stat"
                    />

                    <ReportMetaTable
                        data={data}
                        columnSize={{ xs: 12 }}
                        columnA={[
                            {
                                key: 'test.result.vo2maxHr',
                                label: 'HRmax',
                                format: "number",
                                suffix: " bpm"
                            },
                            {
                                key: 'test.result.vo2maxVo2',
                                label: 'VO2max',
                                format: "number",
                                suffix: " ml/kg*min"
                            }
                        ]}
                        columnB={[
                            {
                                key: 'test.result.ftp',
                                label: 'FTP',
                                format: "number",
                                suffix: activity === "Cycling" ? " W" : " kph"
                            },
                        ]}
                    />
                </Col>

                <Col col={{ xs: 8 }} >

                    <ReportHeading
                        text="Targeted Event"
                    />

                    <ReportMetaTable
                        data={data}
                        columnSize={{ xs: 12 }}
                        columnA={[
                            {
                                key: 'test.manual.name',
                                label: 'Event',
                                format: "titleCase",
                                render: (value) => {
                                    return <>{titleCase(data.events[value]?.name)}</>
                                }
                            },
                            {
                                key: 'test.manual.level',
                                label: 'Level',
                                format: "titleCase",
                                render: (value) => {
                                    return <>{titleCase(changeDemographic(value))}</>
                                }
                            }
                        ]}
                        columnB={[
                            {
                                key: 'test.manual.type',
                                label: 'Type',
                                format: "titleCase"
                            },
                            {
                                key: 'test.manual.time',
                                label: activity === "Cycling" ? 'Bike Duration' : "Run Duration",
                                render: (value) => {
                                    return <>{`${value} hrs`}</>
                                }
                            },
                        ]}
                    />
                </Col>

                <Col col={{ xs: 24 }}>
                    <ReportHeading
                        text="Results"
                    />
                </Col>

                <Col col={{ xs: 24 }}>
                    <RaceReadyLegend
                        justify='center'
                    />
                </Col>

                {options.map((option, index) => {

                    const type: any = getType(option?.label);

                    const [activeTab, setActiveTab] = useState(type[0]?.name);

                    return (

                        <React.Fragment key={index}>

                            <Col col={{ xs: 18 }}>

                                <PanelChart
                                    key={option?.label}
                                    heading={option?.value}
                                    addZero={false}
                                    addIntervalEnd={true}
                                    types={type}
                                    defaultType={types[0]?.name}
                                    data={effortData}
                                    renderChart={(chartType, chartData) => {

                                        useEffect(() => {
                                            setActiveTab(chartType)
                                        }, [chartType]);

                                        return (
                                            <ChartRaceReady
                                                type={option?.label}
                                                data={chartData}
                                                efforts={efforts}
                                                activity={activity}
                                                intensity={data.intensity}
                                                unit={chartType}
                                                xAxis={{
                                                    interval: ChartProps.interval("time", null),
                                                }}
                                            />
                                        )
                                    }}
                                />

                            </Col>

                            <Col col={{ xs: 6 }} justify="center">

                                <RaceReadySideTable
                                    avgData={avgData}
                                    option={option}
                                    data={data}
                                    activity={activity}
                                    targets={targets}
                                    activeTab={activeTab}
                                />

                            </Col>

                        </React.Fragment>

                    )
                })}

                <Col col={{ xs: 24, md: 12 }}>
                    <SustainabilityPanel
                        data={data.test?.result?.recommendations}
                    />
                </Col>

            </Row>

        </>

    )
}

const SustainabilityPanel: React.FC<any> = (props: any) => {

    const { data } = props;

    return (
        <Panel heading={false} className={styles.panel} >

            <Col align="center">
                <Heading space="0" size="1">Pace Sustainability</Heading>
            </Col>

            {(data.sustainable || []).map((item, index) => (

                <Row edge gutter={"8"} key={index} align="end" justify="center">

                    <Col >
                        <p className={styles.key}>
                            EFFORT {index + 1}:
                        </p>
                    </Col>

                    <Col >
                        <p className={[styles.value, styles[item]].join(" ")}>
                            {item?.split("-").join(" ")}
                        </p>
                    </Col>

                </Row>
            ))}

        </Panel>
    )
}

export default TestSessionsReportRaceReadyResult;
