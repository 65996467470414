import React, { useEffect, useMemo, useState } from 'react';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import { Col, FieldCheckbox, FieldHidden, FieldNumber, FieldSelect, Row, useUpdateEffect } from '@dex/bubl-dash';
import CoachingReportHeading from '../../../elements/CoachingReportHeading/CoachingReportHeading';
import { fixedNum, get, loop, lowerCase } from '@dex/bubl-helpers';
import { airDensityCalculation, airPressureCalculation, gravityCalculation } from '../../../functions/sharedAerodynamic';
import styles from "./SessionsReport.module.scss";
import { bikeTypeOptions, calculateAirSpeed, calculateRollingResistanceCoefficient, calculateRollingResistanceDrag, clothingOptions, gravititionalForce, helmetOptions, pressureOptions, qualityOptions, roadSurfaceOptions, roadTypeOptions, widthOptions } from '../../../functions/sharedAerodynamicBike';

const SessionsReportAerodynamic: React.FC<any> = (props: any) => {

    const { data, form, bike, index } = props;

    const bikeForm = get(form.values, `bikes[${index}]`);
    const prefix = `bikes[${index}]`;

    useUpdateEffect(() => {

        form.handleChange({ reset: true, name: `${prefix}.parameter.roadTypeCrr`, value: roadTypeOptions.find(x => x.label === bikeForm.parameter?.roadType)?.value });

    }, [bikeForm.parameter?.roadType]);

    useUpdateEffect(() => {

        form.handleChange({ reset: true, name: `${prefix}.parameter.roadSurfaceCrr`, value: roadSurfaceOptions.find(x => x.label === bikeForm.parameter?.roadSurface)?.value });

    }, [bikeForm.parameter?.roadSurface]);

    const [airDensity, setAirDensity] = useState<number>();

    useEffect(() => {

        const calcAirDensity = airDensityCalculation(bikeForm.parameter?.relativeHumidity, bikeForm.parameter?.airTemperature, bikeForm.parameter?.altitude)
        setAirDensity(calcAirDensity);

    }, [bikeForm.parameter?.relativeHumidity, bikeForm.parameter?.airTemperature, bikeForm.parameter?.altitude]);

    useEffect(() => {

        const airSpeed = calculateAirSpeed(bikeForm.parameter?.bikeSpeed, bikeForm.parameter?.headwind);

        form.handleChange({ reset: true, name: `${prefix}.parameter.airSpeed`, value: fixedNum(airSpeed, 2) });

    }, [bikeForm.parameter?.bikeSpeed, bikeForm.parameter?.headwind]);

    const [roadSurfaceRating, setRoadSurfaceRating] = useState("");

    useEffect(() => {

        let output;
        output = roadSurfaceOptions.find(x => x.label === bikeForm.parameter?.roadSurface)?.rating;
        output = lowerCase(output);
        setRoadSurfaceRating(output);

    }, [bikeForm.parameter?.roadSurface]);

    const [gravity, setGravity] = useState<number>();
    const [rollingResistanceCoefficient, setRollingResistanceCoefficient] = useState<number>();
    const [rollingResistanceDrag, setRollingResistanceDrag] = useState<number>();
    useEffect(() => {

        const calcGravity = gravityCalculation(bikeForm.parameter?.percentGrade, data.bio?.weight, bikeForm.parameter?.bikeWeight)
        setGravity(calcGravity)

        const calcRollingResistanceCoefficient = calculateRollingResistanceCoefficient(roadSurfaceRating, bikeForm.parameter?.roadTypeCrr, bikeForm.parameter?.roadSurfaceCrr, bikeForm.parameter?.pressureCrr, bikeForm.parameter?.qualityCrr, bikeForm.parameter?.widthCrr)
        setRollingResistanceCoefficient(calcRollingResistanceCoefficient)

        const calcRollingResistanceDrag = calculateRollingResistanceDrag(bikeForm.parameter?.percentGrade, data.bio?.weight, bikeForm.parameter?.bikeWeight, calcRollingResistanceCoefficient)
        setRollingResistanceDrag(calcRollingResistanceDrag)

    }, [form.hash]);

    useUpdateEffect(() => {
        form.handleChange({ reset: true, name: `${prefix}.parameter.bikeType`, value: bikeForm.type });
        form.handleChange({ reset: true, name: `${prefix}.parameter.bikeTypeCd`, value: bikeTypeOptions.find(x => x.label === bikeForm.type)?.value });
    }, [bikeForm.type]);

    useUpdateEffect(() => {
        form.handleChange({ reset: true, name: `${prefix}.parameter.bikeWeight`, value: fixedNum(bikeForm.parameter?.bikeAndRiderWeight - form.values.bio?.weight, 2) });
    }, [bikeForm.parameter?.bikeAndRiderWeight]);

    useUpdateEffect(() => {
        form.handleChange({ reset: true, name: `${prefix}.parameter.helmetCd`, value: helmetOptions.find(x => x.label === bikeForm.parameter?.helmet)?.value });
    }, [bikeForm.parameter?.helmet]);

    useUpdateEffect(() => {
        form.handleChange({ reset: true, name: `${prefix}.parameter.clothingCd`, value: clothingOptions.find(x => x.label === bikeForm.parameter?.clothing)?.value });
    }, [bikeForm.parameter?.clothing]);

    useUpdateEffect(() => {

        const pressureValue = roadSurfaceRating ? pressureOptions.find(x => x.label === bikeForm.parameter?.pressure)?.[roadSurfaceRating] : "";

        form.handleChange({ reset: true, name: `${prefix}.parameter.pressureCrr`, value: pressureValue });

    }, [bikeForm.parameter?.pressure, bikeForm.parameter?.roadSurface]);

    useUpdateEffect(() => {
        form.handleChange({ reset: true, name: `${prefix}.parameter.qualityCrr`, value: qualityOptions.find(x => x.label === bikeForm.parameter?.quality)?.value });
    }, [bikeForm.parameter?.quality]);

    useUpdateEffect(() => {

        const widthValue = roadSurfaceRating ? widthOptions.find(x => x.label === bikeForm.parameter?.width)?.[roadSurfaceRating] : "";

        form.handleChange({ reset: true, name: `${prefix}.parameter.widthCrr`, value: widthValue });

    }, [bikeForm.parameter?.width, bikeForm.parameter?.roadSurface]);

    return useMemo(() => (

        <Row gutter={8} edge>

            <Col col={{ xs: 12 }}>

                <CoachingReportHeading
                    text="Enter a Value"
                />

                <Row gutter={8} edge>

                    <Col col={{ xs: 11 }}>

                        <FieldNumber
                            form={form}
                            name={`${prefix}.parameter.bikeSpeed`}
                            defaultValue={bikeForm.parameter?.bikeSpeed}
                            label='Speed'
                            suffix=" kph"
                            wrapClass="manual-input inline-label"
                        />

                        <FieldCheckbox
                            form={form}
                            label="use"
                            name={`${prefix}.parameter.useTarget`}
                            defaultValue={bikeForm.parameter?.useTarget}
                            wrapClass={"inline-label"}
                            singular
                            options={[
                                { label: "", value: "speed" }
                            ]}
                            key={bikeForm.parameter?.useTarget}
                        />

                    </Col>


                    <Col col={{ xs: 2 }} align='center' justify='center'>
                        or
                    </Col>

                    <Col col={{ xs: 11 }}>

                        <FieldNumber
                            form={form}
                            name={`${prefix}.parameter.power`}
                            defaultValue={bikeForm.parameter?.power}
                            label='Power'
                            suffix=" W"
                            wrapClass="manual-input inline-label"
                        />

                        <FieldCheckbox
                            form={form}
                            label="use"
                            name={`${prefix}.parameter.useTarget`}
                            defaultValue={bikeForm.parameter?.useTarget}
                            wrapClass={"inline-label"}
                            singular
                            options={[
                                { label: "", value: "power" }
                            ]}
                            key={bikeForm.parameter?.useTarget}
                        />

                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 12 }}>

                <ReportDesc
                    // className="no-box"
                    label=""
                    value={bikeForm?.summary?.aerodynamicParameter || ""}
                />
            </Col>

            <Col col={{ xs: 12 }}>

                <CoachingReportHeading
                    text={"Environment"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldNumber
                            form={form}
                            label="Air Temperature"
                            name={`${prefix}.parameter.airTemperature`}
                            defaultValue={bikeForm.parameter?.airTemperature}
                            wrapClass="manual-input inline-label "
                            suffix=" C"
                            dynamicValue
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldNumber
                            form={form}
                            label="Relative Humidity"
                            name={`${prefix}.parameter.relativeHumidity`}
                            defaultValue={bikeForm.parameter?.relativeHumidity}
                            wrapClass="manual-input inline-label "
                            suffix=" %"
                            dynamicValue
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldNumber
                            form={form}
                            label="Altitude"
                            name={`${prefix}.parameter.altitude`}
                            defaultValue={bikeForm.parameter?.altitude}
                            wrapClass="manual-input inline-label "
                            suffix=" m"
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldNumber
                            form={form}
                            label="% Grade"
                            name={`${prefix}.parameter.percentGrade`}
                            defaultValue={bikeForm.parameter?.percentGrade}
                            wrapClass="manual-input inline-label "
                            suffix=" %"
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldNumber
                            form={form}
                            label="Headwind"
                            name={`${prefix}.parameter.headwind`}
                            defaultValue={bikeForm.parameter?.headwind}
                            wrapClass="manual-input inline-label "
                            suffix=" kph"
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldNumber
                            form={form}
                            label="Wind Angle"
                            name={`${prefix}.parameter.yawAngle`}
                            defaultValue={bikeForm.parameter?.yawAngle}
                            wrapClass="manual-input inline-label "
                            suffix=" deg"
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldSelect
                            form={form}
                            label="Road Type"
                            name={`${prefix}.parameter.roadType`}
                            defaultValue={bikeForm.parameter?.roadType}
                            wrapClass="manual-input inline-label "
                            options={[
                                { label: "Track", value: "Track" },
                                { label: "Road", value: "Road" },
                                { label: "Off-Road", value: "Off-Road" },
                            ]}
                        />


                        <FieldHidden
                            form={form}
                            //@ts-ignore
                            label={<span>C<sub>rr</sub> Adjustment</span>}
                            name={`${prefix}.parameter.roadTypeCrr`}
                            defaultValue={bikeForm.parameter?.roadTypeCrr}
                            wrapClass={"green-input"}
                            dynamicValue
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldSelect
                            form={form}
                            label="Road Surface"
                            name={`${prefix}.parameter.roadSurface`}
                            defaultValue={bikeForm.parameter?.roadSurface}
                            wrapClass="manual-input inline-label "
                            options={[
                                bikeForm.parameter?.roadType == "Track" ? { label: "Wood", value: "Wood" } : null,
                                bikeForm.parameter?.roadType == "Track" || bikeForm.parameter?.roadType == "Road" ? { label: "Smooth Concreate", value: "Smooth Concreate" } : null,
                                bikeForm.parameter?.roadType == "Track" ? { label: "Smooth Asphalt", value: "Smooth Asphalt" } : null,
                                bikeForm.parameter?.roadType == "Off-Road" || bikeForm.parameter?.roadType == "Road" ? { label: "Rough Asphalt", value: "Rough Asphalt" } : null,
                                bikeForm.parameter?.roadType == "Off-Road" || bikeForm.parameter?.roadType == "Road" ? { label: "Mixed", value: "Mixed" } : null,
                                bikeForm.parameter?.roadType == "Off-Road" || bikeForm.parameter?.roadType == "Road" ? { label: "Gravel", value: "Gravel" } : null,
                                bikeForm.parameter?.roadType == "Off-Road" || bikeForm.parameter?.roadType == "Road" ? { label: "Cobblestones/Brick", value: "Cobblestones/Brick" } : null,
                                bikeForm.parameter?.roadType == "Off-Road" || bikeForm.parameter?.roadType == "Road" ? { label: "Uneven Dirt", value: "Uneven Dirt" } : null,
                            ].filter((option): option is { label: string; value: string } => option !== null)}
                        />

                        <FieldHidden
                            form={form}
                            label={""}
                            name={`${prefix}.parameter.roadSurfaceCrr`}
                            defaultValue={bikeForm.parameter?.roadSurfaceCrr}
                            wrapClass={"green-input"}
                            dynamicValue
                        />

                    </Col>

                    <Col col={{ xs: 8 }} align='end'>
                        <p className={styles['text-label']}>
                            Gravity :
                        </p>
                    </Col>

                    <Col col={{ xs: 16 }}>
                        {bikeForm.parameter?.gravitationalAcceleration} m/sec²
                    </Col>

                    <Col col={{ xs: 8 }} align='end'>
                        <p className={styles['text-label']}>
                            Air Pressure :
                        </p>
                    </Col>

                    <Col col={{ xs: 16 }}>
                        {airPressureCalculation(bikeForm.parameter?.altitude, bikeForm.parameter?.airTemperature)}
                    </Col>

                    <Col col={{ xs: 8 }} align='end'>
                        <p className={styles['text-label']}>
                            Air Density :
                        </p>
                    </Col>

                    <Col col={{ xs: 16 }}>
                        {fixedNum(airDensityCalculation(bikeForm.parameter?.relativeHumidity, bikeForm.parameter?.airTemperature, bikeForm.parameter?.altitude), 2)} kg/m3
                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 12 }}>

                <CoachingReportHeading
                    text={"Bike"}
                />

                <Row edge gutter={8}>

                    <Col col={{ xs: 6 }} align='end'>
                        <p className={styles['text-label']}>
                            Bike Type :
                        </p>
                    </Col>

                    <Col col={{ xs: 6 }}>
                        {bikeForm.type}
                    </Col>

                    <Col col={{ xs: 6 }} align='end'>
                        <p className={styles['text-label']}>
                            Bike Size :
                        </p>
                    </Col>

                    <Col col={{ xs: 6 }}>
                        {bikeForm.setupBikeSize}
                    </Col>

                    <Col col={{ xs: 6 }} align='end'>
                        <p className={styles['text-label']}>
                            Bike Model :
                        </p>
                    </Col>

                    <Col col={{ xs: 6 }}>
                        {bikeForm.model}
                    </Col>

                    <Col col={{ xs: 6 }} align='end'>
                        <p className={styles['text-label']}>
                            Bike Weight :
                        </p>
                    </Col>

                    <Col col={{ xs: 6 }}>
                        {bikeForm.parameter?.bikeWeight}
                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldNumber
                            form={form}
                            label="You & Bike Weight"
                            name={`${prefix}.parameter.bikeAndRiderWeight`}
                            defaultValue={bikeForm.parameter?.bikeAndRiderWeight}
                            wrapClass="inline-label  manual-input"
                            suffix=" kg"
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldSelect
                            form={form}
                            label="Helmet"
                            name={`${prefix}.parameter.helmet`}
                            defaultValue={bikeForm.parameter?.helmet}
                            wrapClass="manual-input inline-label "
                            dynamicValue
                            options={[
                                { label: "Regular with Vents", value: "Regular with Vents" },
                                { label: "Regular few vents", value: "Regular few vents" },
                                { label: "Aero Some Vents", value: "Aero Some Vents" },
                                { label: "Aero No Vents", value: "Aero No Vents" },
                                { label: "Pro Aero", value: "Pro Aero" },
                                { label: "No Selection", value: "No Selection" },
                            ]}
                        />

                        <FieldHidden
                            form={form}
                            label={""}
                            name={`${prefix}.parameter.helmetCd`}
                            defaultValue={bikeForm.parameter?.helmetCd}
                            wrapClass={"green-input"}
                            dynamicValue
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldSelect
                            form={form}
                            label="Clothing"
                            name={`${prefix}.parameter.clothing`}
                            defaultValue={bikeForm.parameter?.clothing}
                            wrapClass="manual-input inline-label "
                            dynamicValue
                            options={[
                                { label: "Street Clothing", value: "Street Clothing" },
                                { label: "Loose Cycling Kit", value: "Loose Cycling Kit" },
                                { label: "Snug Cycling Bib", value: "Snug Cycling Bib" },
                                { label: "TT Bib", value: "TT Bib" },
                                { label: "Skin Suit", value: "Skin Suit" },
                                { label: "No Selection", value: "No Selection" },
                            ]}
                        />

                        <FieldHidden
                            form={form}
                            label={""}
                            name={`${prefix}.parameter.clothingCd`}
                            defaultValue={bikeForm.parameter?.clothingCd}
                            wrapClass={"green-input"}
                            dynamicValue
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldNumber
                            form={form}
                            label={"Drive Change Power Loss"}
                            name={`${prefix}.parameter.driveChangePowerLoss`}
                            defaultValue={bikeForm.parameter?.driveChangePowerLoss}
                            wrapClass={"manual-input inline-label "}
                            dynamicValue
                            suffix=" %"
                        />

                    </Col>

                </Row>

                <CoachingReportHeading
                    text={"Tires"}
                />

                <Row edge gutter={8}>

                    <Col col={{ xs: 24 }}>

                        <FieldSelect
                            form={form}
                            label="Pressure"
                            name={`${prefix}.parameter.pressure`}
                            defaultValue={bikeForm.parameter?.pressure}
                            wrapClass="manual-input inline-label "
                            dynamicValue
                            options={[
                                { label: "Very High (>120psi)", value: "Very High (>120psi)", },
                                { label: "High (101-120psi)", value: "High (101-120psi)", },
                                { label: "Moderate (81-100psi)", value: "Moderate (81-100psi)", },
                                { label: "Low (40-80psi)", value: "Low (40-80psi)", },
                                { label: "Very Low (<40psi)", value: "Very Low (<40psi)", },
                            ]}
                        />

                        <FieldHidden
                            form={form}
                            //@ts-ignore
                            label={<span>C<sub>rr</sub> Adjustment</span>}
                            name={`${prefix}.parameter.pressureCrr`}
                            defaultValue={bikeForm.parameter?.pressureCrr}
                            wrapClass={"green-input"}
                            dynamicValue
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldSelect
                            form={form}
                            label="Quality"
                            name={`${prefix}.parameter.quality`}
                            defaultValue={bikeForm.parameter?.quality}
                            wrapClass="manual-input inline-label "
                            dynamicValue
                            options={[
                                { label: "Very High", value: "Very High" },
                                { label: "High", value: "High" },
                                { label: "Moderate", value: "Moderate" },
                                { label: "Low", value: "Low" },
                                { label: "Very Low", value: "Very Low" },
                            ]}
                        />

                        <FieldHidden
                            form={form}
                            label={""}
                            name={`${prefix}.parameter.qualityCrr`}
                            defaultValue={bikeForm.parameter?.qualityCrr}
                            wrapClass={"green-input"}
                            dynamicValue
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldSelect
                            form={form}
                            label="Width"
                            name={`${prefix}.parameter.width`}
                            defaultValue={bikeForm.parameter?.width}
                            wrapClass="manual-input inline-label "
                            dynamicValue
                            options={[
                                { label: "<23mm", value: "<23mm" },
                                { label: "23-24mm", value: "23-24mm" },
                                { label: "25-27mm", value: "25-27mm" },
                                { label: "28-30mm", value: "28-30mm" },
                                { label: "31-34mm", value: "31-34mm" },
                                { label: ">34mm", value: ">34mm" },
                            ]}
                        />

                        <FieldHidden
                            form={form}
                            label={""}
                            name={`${prefix}.parameter.widthCrr`}
                            defaultValue={bikeForm.parameter?.widthCrr}
                            wrapClass={"green-input"}
                            dynamicValue
                        />

                    </Col>

                    <Col col={{ xs: 8 }} align='end'>
                        <p className={styles['text-label']}>
                            Rolling Resistance :
                        </p>
                    </Col>

                    <Col col={{ xs: 16 }}>
                        {rollingResistanceCoefficient}
                    </Col>

                </Row>

            </Col>

        </Row>
    ), [props, form.hash, bikeForm, rollingResistanceCoefficient, rollingResistanceDrag])
}

export default SessionsReportAerodynamic;