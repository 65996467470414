import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';

const QuestionnairesCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Questionnaire Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12, lg: 8 }}>

                        <FieldRelationship
                            form={form}
                            label='Athlete'
                            name='athleteId'
                            required={true}
                            preload={true}
                            endpoint={'Athletes'}
                            defaultValue={form.defaults.athleteId}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12, lg: 8 }}>

                        <FieldSelect
                            form={form}
                            label={"Type of Service"}
                            name='typeOfService'
                            required={true}
                            defaultValue={form.defaults.typeOfService}
                            options={[
                                { value: "Basic Bike Fit", label: "Basic Bike Fit" },
                                { value: "Comprehensive Bike Fit", label: "Comprehensive Bike Fit" },
                                { value: "Bike Sizing", label: "Bike Sizing (Buying a new bike)" },
                                { value: "Fitting and Sizing", label: "Fitting and Sizing" },
                                { value: "ReFit on Same Bike", label: "ReFit on Same Bike" },
                                { value: "ReFit on New Bike", label: "ReFit on New Bike" },
                                { value: "Remote Bike Sizing", label: "Remote Bike Sizing" },
                                { value: "Lab Test", label: "Lab Test" },
                                { value: "Gait Analysis", label: "Gait Analysis" },
                            ]}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />

                </ButtonRow>

            </Form>

        </Panel>

    ), [props]);

}

export default QuestionnairesCreateEditForm;
