import { Col, Heading, Line, MediaImage, Row, Space, useMountEffect } from '@dex/bubl-dash';
import React, { useMemo } from 'react';
import ReportMetaTable from '../../../elements/ReportMetaTable/ReportMetaTable';
import styles from "./GaitReportShoe.module.scss";
import { ColoredHeader } from './GaitReportPage';
import GaitReportComment from '../../../elements/GaitReportComment/GaitReportComment';
import CoachingReportHeading from '../../../elements/CoachingReportHeading/CoachingReportHeading';
import InsoleWearPattern from '../../../elements/InsoleWearPattern/InsoleWearPattern';
import ShoeWearPattern from '../../../elements/ShoeWearPattern/ShoeWearPattern';
import { WearPatternLegend } from '../Components/GaitsEditTabsShoes';
import GaitRatingCard from '../../../elements/GaitRatingCard/GaitRatingCard';

const GaitReportShoe: React.FC<any> = (props: any) => {

    const { data, form, shoe, index } = props;

    const shoeData = shoe;

    const gender = data.athlete.gender;

    const prefix = `shoes[${index}]]`;

    return useMemo(() => (

        <Row gutter={20} edge>

            <Col col={{ xs: 24 }}>
                <ColoredHeader
                    heading="running shoe details"
                    desc={`${shoeData.shoeBrand} size ${shoeData.shoeSize}`}
                />
            </Col>

            <Col col={{ xs: 4 }}>

                {/* <Space height={25} /> */}

                <ReportMetaTable
                    data={shoeData}
                    columnSize={{ xs: 24 }}
                    columnA={[
                        {
                            label: "Shoe Brand",
                            key: "shoeBrand"
                        },
                        {
                            label: "Shoe Size",
                            key: "shoeSize",
                            format: "number",
                            suffix: " EUR"
                        },
                        {
                            label: "Foot Size (left,Right)",
                            key: "shoeSize",
                            render: () => (
                                <>{data.foot.footLengthCombined}</>
                            )
                        },
                        {
                            label: "Insoles",
                            key: "insoles"
                        },
                        {
                            label: "Shoe Weight",
                            key: "shoeWeight",
                            format: "number",
                            suffix: " g"
                        },
                        {
                            label: "Shoe Age",
                            key: "shoeAge",
                            // format: "number",
                        }
                    ]}
                />
            </Col>

            <Col col={{ xs: 20 }}>

                {/* <CoachingReportHeading
                    text="PHOTOS"
                /> */}

                <Row edge gutter={8} >

                    {shoeData?.pictures && shoeData.pictures.map((picture, index) => (

                        <Col col={{ xs: 8 }} justify='center' align='center' key={index} >
                            {picture &&
                                <>

                                    <MediaImage
                                        id={picture.image?.fileId}
                                        filters={{ width: 250, height: 250, resizeMode: "contain", fallback: { background: "#313b45", text: picture.caption } }}
                                        imgProps={{
                                            crossOrigin: "anonymous"
                                        }}
                                    />

                                    <Space />

                                    <p className={styles.caption}>{picture.caption} </p>

                                </>}
                        </Col>

                    ))}

                </Row>

            </Col>



            <Col col={{ xs: 24 }}>
                <CoachingReportHeading
                    text="WEAR PATTERN"
                />
            </Col>

            <Col col={{ xs: 8 }} align='center' className='text-center'>

                <Heading>Shoe Wear Pattern</Heading>

                <Space />

                <ShoeWearPattern
                    prefix={prefix}
                    shoe={shoeData}
                    form={form}
                />

            </Col>

            <Col col={{ xs: 8 }} align='center' className='text-center'>

                <Heading>Insole Wear Pattern</Heading>

                <Space />

                <InsoleWearPattern
                    prefix={prefix}
                    shoe={shoeData}
                    form={form}
                />

            </Col>

            <Col col={{ xs: 8 }} >

                <Heading>Foot Load Pattern</Heading>

                <GaitRatingCard
                    title={"Left"}
                    metric={"Foot Load"}
                    value={shoeData.leftFootwearRating}
                    gender={gender}
                    split={{
                        metric: "Foot Load",
                        value: shoeData.rightFootwearRating,
                        title: "Right"
                    }}
                />

                <Space height={8} />

                <GaitReportComment
                    label="Comment"
                    value={shoeData?.comments}
                />

            </Col>

            <Col col={{ xs: 4 }} />

            <Col col={{ xs: 8 }}>
                <Space />

                <WearPatternLegend />

            </Col>

            <Col col={{ xs: 4 }} />

        </Row>

    ), [data]);

}

export default GaitReportShoe;