import { parseNum } from '@dex/bubl-helpers';
import React from 'react';
import * as _ from 'lodash';

import { getDecimalTime, getDuration } from '../../functions/sharedTests';

import styles from "./ReportRunTimes.module.scss";

const ReportRunTimes: React.FC<ReportRunTimesProps> = (props) => {

    let { items, className, ...rest } = props;

    let classes = [styles.runtimes];

    if (className && Array.isArray(className)) classes = [...classes, ...className];
    else if (typeof className === "string") classes.push(className);

    const getPace = (distance, zone) => {

        if (!zone) return "-";

        return zone.pace_adj_lower;

    };

    const getTime = (distance, zone) => {

        if (!zone.pace_adj_lower) return "-";

        const speed = getDecimalTime((zone.pace_adj_lower));

        return getDuration(distance, speed, true);

    };

    const getColumnHeight = (distance) => {

        const distances = _.map(items, "distance");
        const max = _.max(distances);

        if (distance >= max / 2) distance = distance * .80;

        return distance / max * 100 + "%";

    }

    return (

        <div
            className={classes.join(" ")}
            {...rest}
        >

            <div className={styles.labels}>
                <div className={styles.pace}>
                    Pace
                </div>
                <div className={styles.time}>
                    Time
                </div>
            </div>

            <div className={styles.items}>

                {items.map((item, index) => (
                    <div key={index} className={styles.item}>

                        <div className={styles.pace}>
                            {getPace(item.distance, item.zone)}
                        </div>

                        <div className={styles.time}>
                            {getTime(item.distance, item.zone)}
                        </div>

                        <div className={styles.column} style={{ height: getColumnHeight(item.distance) }}>

                            <div className={styles.graphic}>
                            </div>

                        </div>

                        <div className={styles.distance}>
                            {item.distance} km
                        </div>

                    </div>
                ))}

            </div>

        </div>

    )

}

interface ReportRunTimesProps {
    [key: string]: any,
}

export default ReportRunTimes;