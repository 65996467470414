import React, { useCallback, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import SessionsEditTabsPanel from './SessionsEditTabsPanel';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import { Button, Space } from '@dex/bubl-dash';
import { getSummaryText } from '../../../functions/sharedBikeFit';
import { get, set } from '@dex/bubl-helpers';

const SessionsEditTabsSummary: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, settings } = props;

    const useDefaultText = useCallback((defaultName, name: string) => {

        let text = get(settings.fit, defaultName);

        const values = form.values;

        set(values, name, text);

        form.reset(values);

    }, [props, form.hash]);

    return useMemo(() => (

        <SessionsEditTabsPanel
            heading={"Fitter's Summary"}
            {...props}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, lg: 16 }}>

                    <FieldTextArea
                        form={form}
                        label={"Session Summary"}
                        name={'summary.comments'}
                        required={false}
                        defaultValue={form.defaults.summary.comments}
                        rows={20}
                        maxLength={5000}
                    />

                </Col>

                <Col col={{ xs: 24, lg: 8 }}>

                    <Space />

                    <Row edge={true}>

                        <Button
                            label={'Use Default Text'}
                            onClick={useDefaultText.bind(null, `defaultText`, "summary.comments")}
                            type={'faded'}
                            confirm={form.values.summary.comments ? { title: 'Are you sure?', message: 'Are you sure you want to overwrite the current summary' } : undefined}
                        />

                    </Row>

                    <Space />

                    <Row edge={true}>

                        <Button
                            label={'Sitting Too High'}
                            onClick={useDefaultText.bind(null, `sittingTooHigh`, "summary.comments")}
                            type={'faded'}
                            confirm={form.values.summary.comments ? { title: 'Are you sure?', message: 'Are you sure you want to overwrite the current summary' } : undefined}
                        />

                    </Row>

                    <Space />

                    <Row edge={true}>

                        <Button
                            label={'Sitting Too Low'}
                            onClick={useDefaultText.bind(null, `sittingTooLow`, "summary.comments")}
                            type={'faded'}
                            confirm={form.values.summary.comments ? { title: 'Are you sure?', message: 'Are you sure you want to overwrite the current summary' } : undefined}
                        />

                    </Row>

                    <Space />

                    <Row edge={true}>

                        <Button
                            label={'Bike Sizing'}
                            onClick={useDefaultText.bind(null, `bikeSizing`, "summary.comments")}
                            type={'faded'}
                            confirm={form.values.summary.comments ? { title: 'Are you sure?', message: 'Are you sure you want to overwrite the current summary' } : undefined}
                        />

                    </Row>

                </Col>

            </Row>

        </SessionsEditTabsPanel>

    ), [props]);

}

export default SessionsEditTabsSummary;
