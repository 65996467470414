import React, { useEffect, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import SessionsEditTabsPanel from './SessionsEditTabsPanel';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import { fixedNum, hash } from '@dex/bubl-helpers';
import { FieldSelect, FieldText, FieldTextTime, useUpdateEffect } from '@dex/bubl-dash';
import { glycogenEventList } from '../../../functions/glycogenTest';
import { get } from 'http';
import { getSpeed } from '../../../functions/sharedTests';

const SessionsEditTabsBioAerodynamic: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    let data = props.data || {};

    const gender = data.athlete.gender || "male";

    const FSACalculation = (weight: number, height: number) => {

        if (!weight || !height) return;

        const fsa = (0.00215 * weight) + (0.18964 * (height / 100) - 0.07961);

        return fixedNum(fsa, 3);

    };

    useUpdateEffect(() => {
        form.handleChange({ reset: true, name: "bio.bodyFsa", value: FSACalculation(form.values.bio.weight, form.values.bio.height) });

        calculateSpeed(form.values.bio.targetDistance, form.values.bio.targetTime);
    }, [form.values.bio.weight, form.values.bio.height, form.values.bio.targetDistance, form.values.bio.targetTime]);

    const calculateSpeed = (distance: number, time: number) => {

        if (!distance || !time) return;

        let speed = getSpeed(distance, time);
        speed = fixedNum(speed, 1);

        form.handleChange({ reset: true, name: "bio.targetSpeed", value: speed });
    };

    return useMemo(() => (

        <SessionsEditTabsPanel
            heading={"Bio Data"}
            {...props}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, sm: 12, md: 6 }}>

                    <FieldNumber
                        form={form}
                        name="bio.weight"
                        label="Weight (kg)"
                        suffix={" kg"}
                        defaultValue={form.defaults.bio.weight}
                        wrapClass={"manual-input"}
                    />

                </Col>

                <Col col={{ xs: 24, sm: 12, md: 6 }}>

                    <FieldNumber
                        form={form}
                        name="bio.height"
                        label="Height (cm)"
                        suffix={" cm"}
                        defaultValue={form.defaults.bio.height}
                        wrapClass={"manual-input"}
                    />

                </Col>

                <Col col={{ xs: 24, sm: 12, md: 6 }}>

                    <FieldNumber
                        form={form}
                        name="bio.bodyFsa"
                        label="Body FSA (estimated)"
                        suffix={" m"}
                        defaultValue={form.defaults.bio.bodyFsa}
                        dynamicValue
                        disabled
                    />

                </Col>

                <Col col={{ xs: 24, sm: 12, md: 6 }}>

                    <FieldNumber
                        form={form}
                        name="bio.ftpW"
                        label="Current FTP (W)"
                        suffix={" W"}
                        defaultValue={form.defaults.bio.ftpW}
                        wrapClass={"manual-input"}
                    />

                </Col>

                <Col col={{ xs: 24, sm: 12, md: 6 }}>

                    <FieldSelect
                        form={form}
                        name="bio.eventType"
                        label="Event Type"
                        defaultValue={form.defaults.bio.eventType}
                        options={glycogenEventList}
                        wrapClass={"manual-input"}
                    />

                </Col>

                <Col col={{ xs: 24, sm: 12, md: 6 }}>

                    <FieldText
                        form={form}
                        name="bio.eventName"
                        label="Event Name"
                        defaultValue={form.defaults.bio.eventName}
                        wrapClass={"manual-input"}
                    />

                </Col>

            </Row>

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, sm: 12, md: 6 }}>

                    <FieldNumber
                        form={form}
                        name="bio.targetDistance"
                        label="Target Distance"
                        suffix={" km"}
                        defaultValue={form.defaults.bio.targetDistance}
                        wrapClass={"manual-input"}
                    />

                </Col>

                <Col col={{ xs: 24, sm: 12, md: 6 }}>

                    <FieldNumber
                        form={form}
                        name="bio.targetPower"
                        label="Target Power"
                        suffix={" W"}
                        defaultValue={form.defaults.bio.targetPower}
                        wrapClass={"manual-input"}
                    />

                </Col>


                <Col col={{ xs: 24, sm: 12, md: 6 }} className={"full-width"}>

                    <FieldTextTime
                        form={form}
                        name="bio.targetTime"
                        label="Target Bike Time"
                        defaultValue={form.defaults.bio.targetTime}
                        wrapClass={"manual-input"}
                        placeholder={"hh:mm:ss"}
                    />

                </Col>

                <Col col={{ xs: 24, sm: 12, md: 6 }}>

                    <FieldNumber
                        form={form}
                        name="bio.targetSpeed"
                        label="Target Speed"
                        suffix={" kph"}
                        defaultValue={form.defaults.bio.targetSpeed}
                        disabled
                        dynamicValue
                    />

                </Col>

            </Row>


        </SessionsEditTabsPanel>

    ), [props, form.hash]);

}

export default SessionsEditTabsBioAerodynamic;
