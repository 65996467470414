import React from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Box, Panel, Space, useForm } from '@dex/bubl-dash';
import AthletesSingleTrackingForm from './AthletesSingleTrackingForm';
import AthletesSingleTrackingTable from './AthletesSingleTrackingTable';

const AthletesSingleTracking: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm({ sessions: [], dataType: "fitSize", dataSet: "bio" });

    return (

        <Row
            gutter={'auto'}
        >

            <Col col={{ xs: 24 }}>

                <Panel
                    heading={"Athlete Tracking"}
                    full={true}
                >

                    <Box gutter={"auto"}>

                        <AthletesSingleTrackingForm
                            form={form}
                            data={data}
                        />

                    </Box>

                    {form.values.sessions && form.values.dataType && form.values.dataSet &&
                        <AthletesSingleTrackingTable
                            form={form}
                            data={data}
                        />
                    }

                    <Space />

                </Panel>

            </Col>

        </Row>

    );

}

export default AthletesSingleTracking;
