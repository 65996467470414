import { Col, Line, MediaImage, Row, Space } from '@dex/bubl-dash';
import React, { useMemo } from 'react';
import ReportMetaTable from '../../../elements/ReportMetaTable/ReportMetaTable';
import styles from "./SessionsReport.module.scss";
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import CoachingReportHeading from '../../../elements/CoachingReportHeading/CoachingReportHeading';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import { titleCase } from '@dex/bubl-helpers';

const SessionsReportRiderInformation: React.FC<any> = (props: any) => {

    const { data, form, bike } = props;

    let mileageCss;

    switch (data.cyclingMileagePerWeek) {
        case "Elite (>501KM)":
            mileageCss = "elite"
            break;
        case "Very-High (401-500KM)":
            mileageCss = "very-high"
            break;
        case "High (301-400KM)":
            mileageCss = "high"
            break;
        case "Moderately-High (201-300KM)":
            mileageCss = "moderately-high"
            break;
        case "Moderate (101-200KM)":
            mileageCss = "moderate"
            break;
        case "Low (50-100KM)":
            mileageCss = "low"
            break;
        case "Very-Low (<50KM)":
            mileageCss = "very-low"
            break;

        default:
            mileageCss = "none"
            break;
    }

    return useMemo(() => (

        <Row gutter={20} edge>

            <Col col={{ xs: 16 }}>

                <CoachingReportHeading
                    text="General"
                />

                <ReportMetaTable
                    data={data}
                    columnSize={{ xs: 8 }}
                    columnA={[
                        { label: "Athlete", key: "athlete.fullName" },
                        { label: "Height", key: "bio.height", suffix: " cm", format: "number" },
                        { label: "Main Sport", key: "athlete.mainSport" },
                    ]}
                    columnB={[
                        { label: "Gender", key: "athlete.gender", format: "titleCase" },
                        { label: "Weight", key: "bio.weight", suffix: " kg", format: "number" },
                        { label: "Riding Years", key: "yearsRiding", format: "number", suffix: " years" },
                    ]}
                    columnC={[
                        { label: "Age", key: "athlete.age" },
                        { label: "FTP (Current)", key: "bio.ftpW", suffix: " W", format: "number" },
                        { label: "Riding Level", key: "cyclistLevel" },
                    ]}
                />

                <ReportMetaTable
                    data={data}
                    columnSize={{ xs: 24 }}
                    columnA={[
                        { label: "Compete?", key: "competes" },
                    ]}
                />

                <Space />

            </Col>

            <Col col={{ xs: 8 }}>

                <CoachingReportHeading
                    text={"Weekly Cycling Mileage"}
                />

                <div className={styles.chartContainer}>


                    <div className={styles.barChart}>
                        <table>
                            <tr>
                                <td>Elite</td>

                                <td>{'>'}501 km</td>
                            </tr>
                            <tr>
                                <td>Very High</td>

                                <td>201-500 km</td>
                            </tr>
                            <tr>
                                <td>High</td>

                                <td>301-400 km</td>
                            </tr>
                            <tr>
                                <td>Moderately High</td>

                                <td>201-300 km</td>
                            </tr>
                            <tr>
                                <td>Moderate</td>

                                <td>101-200 km</td>
                            </tr>
                            <tr>
                                <td>Low</td>

                                <td>50-100 km</td>
                            </tr>
                            <tr>
                                <td>Very Low</td>

                                <td> &lt;50 km </td>
                            </tr>
                        </table>

                        <label
                            className={[styles.mileageBar, styles[`${mileageCss}`]].join("")}
                        />

                    </div>

                    <div className={styles.label}>
                        You
                    </div>

                </div>

            </Col>

            <Col col={{ xs: 16 }}>

                <CoachingReportHeading
                    text="Total Frontal Surface (FSA)"
                />


                <ReportMetaTable
                    data={bike}
                    columnSize={{ xs: 24 }}
                    columnA={[
                        { label: "Total FSA", key: "FSA", suffix: " m²", format: "number" }
                    ]}
                />

                <Space />

                <ReportDesc
                    className="no-box"
                    value={`This is your total frontal surface area when standing over your bike.`}
                />

            </Col>

            <Col col={{ xs: 8 }}>
                <div className={styles.imageContainer}>
                    <MediaImage
                        id={bike.photo?.fileId}
                        filters={{ width: 200, height: 400, resizeMode: "contain", fallback: { background: "#FFF", text: bike.name } }}
                        imgProps={{
                            crossOrigin: "anonymous"
                        }}
                        className={styles.image}
                    />
                </div>
            </Col>

            <Col col={{ xs: 24 }} />

            <Col col={{ xs: 16 }}>
                <CoachingReportHeading
                    text={`Target Event - ${titleCase(data.bio.eventType)}`}
                />

                <Row gutter={8} edge>

                    <Col col={{ xs: 8 }}>
                        <ReportMetaTable
                            data={data}
                            columnSize={{ xs: 24 }}
                            columnA={[
                                { label: "Event", key: "bio.eventName" },
                            ]}

                        />
                    </Col>

                    <Col col={{ xs: 16 }}>
                        <ReportMetaTable
                            data={data}
                            columnSize={{ xs: 6 }}
                            columnA={[
                                { label: "Distance", key: "bio.targetDistance", suffix: " km", format: "number" },
                            ]}
                            columnB={[
                                { label: "Time", key: "bio.targetTime" },
                            ]}
                            columnC={[
                                { label: "Speed", key: "bio.targetSpeed", suffix: " kph", format: "number" },
                            ]}
                            columnD={[
                                { label: "Power", key: "bio.targetPower", suffix: " W", format: "number" },
                            ]}
                        />
                    </Col>
                </Row>

            </Col>

            <Col col={{ xs: 8 }} align='center'>
                <img
                    src={require("../../../assets/images/Coaching/preferredEvents.png")}
                    alt="preferredEvents"
                    className={styles.icon}
                />
            </Col>

        </Row>

    ), [data]);

};

export default SessionsReportRiderInformation;