import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import AthletesApi from './AthletesApi';

let settings;

export default settings = {
    key: 'athletes',
    zone: 'people',
    path: '/athletes',
    title: "Athletes",
    endpoint: AthletesApi.endpoint,
    modelName: 'Athletes',
    idKey: 'id',
    primaryKey: 'fullName',
    actions: ['trash', 'clone'],
    indexCaps: ["viewAthletes"],
    viewCaps: ["viewAthletes"],
    createCaps: ["createAthletes"],
    updateCaps: ["updateAthletes"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
            {
                label: "Import Multiple",
                path: settings.path + "/import",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: AthletesApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = {
            where: {},
            include: [{ relation: 'teams', preset: "link" }],
            order: ['created.date DESC'],
            fields: [
                "id", "modelName",
                "fullName", "firstName", "shortName", "callName",
                "phone", "email", "location",
                "mainSport", "discipline", "level",
                "teamsIds", "teams",
                "created", "updated",
            ],
            limit: 10,
            skip: 0
        };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['firstName', 'lastName'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "First Name",
                key: "firstName",
                type: "text",
            },
            {
                label: "Last Name",
                key: "lastName",
                type: "text",
            },
            {
                label: "Email",
                key: "email",
                type: "text",
            },
            {
                label: "Phone",
                key: "phone",
                type: "text",
            },
            {
                label: "Gender",
                key: "gender",
                type: "choice",
                options: [
                    { value: "male", label: "Male" },
                    { value: "female", label: "Female" }
                ],
            },
            {
                label: "Main Sport",
                key: "mainSport",
                type: "text",
            },
            {
                label: "Location",
                key: "location",
                type: "text",
            },
            {
                label: "Team",
                key: "teamId",
                type: "relationship",
                model: "Teams"
            }
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "Full Name",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Phone",
                key: "phone",
            },
            {
                label: "Email",
                key: "email",
            },
            {
                label: "Main Sport",
                key: "mainSport",
            },
            {
                label: "Discipline",
                key: "discipline",
                format: 'titleCase',
            },
            {
                label: "Level",
                key: "level",
                format: 'titleCase',
            },
            {
                label: "Location",
                key: "location",
            },
            {
                label: "Teams",
                key: "teams",
                format: 'modelLink',
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: AthletesApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: AthletesApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: ["teams"] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleButtons: (routeName, params, users, fetch) => {

        return [];

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Tracking",
                path: path + "/tracking"
            },
            {
                label: "History",
                path: path + "/history"
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.updateCaps
            },
        ]

    },
};