import { apiGet, baseApi, baseApiReturn, options, response } from "@dex/bubl-fetch";

const endpoint = 'Questionnaires';

const QuestionnairesApi: baseApiReturn = baseApi(endpoint);

QuestionnairesApi.getOnePublic = (id: string, options: options = {}): response => {

    return apiGet({
        url: endpoint + "/public/" + id,
        params: {},
        ...options
    });

}

export default QuestionnairesApi;