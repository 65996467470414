import React, { useCallback, useEffect, useMemo } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import SessionsApi from '../SessionsApi';
import { ButtonRow, Col, FieldCheckbox, Form, useApp, useForm } from '@dex/bubl-dash';
import { Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import moment from 'moment';
import { bikeLabel } from '../../../functions/sharedBikeFit';

const SessionsCopyData: React.FC<any> = (props: any) => {

    const app = useApp();

    const { data } = props;

    // find previous session
    const pastSessions = SessionsApi.get({
        params: {
            where: { athleteId: data.athleteId, id: { neq: data.id } },
            order: ['sessionDate desc']
        }
    });

    useEffect(() => {
        pastSessions.run();
    }, [])

    const form = useForm(data);

    const [nav] = app.store("nav");

    const update = SessionsApi.update(data.id, "copyData", {
        onComplete: (data) => {

            app.alert('Sessions Saved', 'success');

            app.navigate(nav, 'update', {});
        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    const getBikes = (sessionId) => {

        let bikes = [];

        pastSessions.data.data.map(session => {

            if (session.id !== sessionId) return;

            bikes = session.bikes.map((bike, index) => {

                return {
                    value: index.toString(),
                    label: bikeLabel(bike)
                }
            })

        })

        return bikes;

    }

    const getDataToCopyOptions = (sessionId) => {

        let options: any = [
            { value: "session", label: "Session Data" },
            { value: "bio", label: "Bio Data" },
        ];

        pastSessions.data.data.map(session => {

            if (session.id !== sessionId) return;

            if (session.fitType === 'Bike Sizing' || session.fitType === 'Remote Sizing') {
                options = [
                    ...options,
                    { value: "injury", label: "Injury" },
                    { value: "toeTouch", label: "Toe Touch" },
                    { value: "bikes", label: "Bike Sizing" },
                    { value: "summary", label: "Summary" }
                ]
            } else if (session.fitType === 'Aerodynamic') {
                options = [
                    ...options,
                    { value: "bikes", label: "Bike Sizing" },
                ]
            }
            else {
                options = [
                    ...options,
                    { value: "injury", label: "Injury" },
                    { value: "posture", label: "Posture" },
                    { value: "mobility", label: "Mobility" },
                    { value: "symmetry", label: "Symmetry" },
                    { value: "stability", label: "Stability" },
                    { value: "foot", label: "Foot" },
                    { value: "cleats", label: "Cleats" },
                    { value: "bikes", label: "Bikes" },
                    { value: "exercises", label: "Exercises" },
                    { value: "summary", label: "Summary" }
                ];
            }

        })

        return options;

    }

    return useMemo(() => (
        <>
            {pastSessions.data && pastSessions.data.count &&

                <Panel
                    heading={"Copy Fit/Size Data From Past Session"}
                >

                    <Form
                        form={form}
                        onSubmit={handleSubmit}
                        loading={update.loading}
                    >


                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, lg: 12 }}>

                                <FieldRadio
                                    form={form}
                                    label={"Session"}
                                    name='sessionId'
                                    required={true}
                                    list={true}
                                    defaultValue={form.defaults.sessionId}
                                    options={pastSessions.data.data.map(item => {
                                        return {
                                            value: item.id,
                                            label: item.id + " - " + item.fitType + " - " + moment(item.sessionDate).format('D MMM YYYY'),
                                        }
                                    })}
                                />
                            </Col>

                            {form.values.sessionId &&

                                <Col col={{ xs: 24 }}>

                                    <FieldCheckbox
                                        list={false}
                                        form={form}
                                        label={"Data To Copy"}
                                        name='toCopy'
                                        defaultValue={form.defaults.toCopy}
                                        required={true}
                                        options={getDataToCopyOptions(form.values.sessionId)}
                                    />
                                </Col>
                            }

                            {form.values.toCopy && form.values.toCopy.includes('bikes') &&

                                <Col col={{ xs: 24 }}>

                                    <FieldCheckbox
                                        list={true}
                                        form={form}
                                        label={"Bikes To Copy"}
                                        name='bikes'
                                        defaultValue={form.defaults.bikes}
                                        required={true}
                                        options={getBikes(form.values.sessionId)}
                                    />
                                </Col>
                            }

                        </Row>

                        <Line />

                        <ButtonRow>
                            <Button
                                label={"Copy Data"}
                                loading={update.loading}
                            />

                        </ButtonRow>

                    </Form>

                </Panel>
            }

        </>

    ), [props, update, form.hash, pastSessions.loading]);

}

export default SessionsCopyData;
