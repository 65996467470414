import { date, fixedNum, formatNum, get, loop, parseNum } from "@dex/bubl-helpers"

export const dataFormatRow = (row, index, settings) => {

    row._is = {
        active: false,
        rest: false,
        high: false,
        avg: false,
        low: false,
    };

    row._index = index + 1;

    if (settings.mode === "manual") {

        row._is.active = row.stages ? true : false;

        row._is.avg = row.averages ? true : false;

        row._is.rest = !row._is.active;

        row._stage = row.stages;

    }

    return row;

};

export const dataFormatAutoAvgRows = (rows, settings) => {

    const activePeriod = parseNum(settings.activePeriod);
    const restPeriod = parseNum(settings.restPeriod);

    const totalPeriod = activePeriod + restPeriod;
    const singlePeriod = parseNum(rows[0]?.time || 15);
    const periodsInStage = totalPeriod / singlePeriod;

    const averageBy = get(settings, "averageBy", "0-end").split('-');
    const averageByTime = parseNum(averageBy[0]);
    const averageByFrom = averageBy[1].toLowerCase();

    loop(rows, (row, index) => {

        row._stage = Math.ceil((index + 1) / periodsInStage);

        let currentTime = row.time;

        while (currentTime > totalPeriod) {
            currentTime = currentTime - totalPeriod;
        }

        if (currentTime <= activePeriod) {

            row._is.active = true;

            const averageGroup = Math.ceil(currentTime / averageByTime) % 2;

            if (averageByFrom === 'block') {

                if (averageGroup) row._is.avg = true;

            } else {

                if ((activePeriod - currentTime) < averageByTime) row._is.avg = true;

            }

        } else {

            row._is.rest = true;

        }

    });

    return rows;

};

export const dataRowClassName = (row, index) => {

    let classNames: any = [];

    if (!row._is) return;

    if (row._is.active) classNames.push("row-active");
    if (row._is.rest) classNames.push("row-rest");
    if (row._is.high) classNames.push("row-high");
    if (row._is.avg) classNames.push("row-avg");
    if (row._is.low) classNames.push("row-low");
    if (row._is.empty) classNames.push("row-empty");
    if (row._is.sum) classNames.push("row-sum");

    return classNames.join(" ");

};


export const dataPeriodOptions = (zero, maxSeconds, interval, maxMinutes = 0) => {

    maxSeconds = parseNum(maxSeconds) || 100;

    interval = interval || 15;

    let size = fixedNum(maxSeconds / interval, 0);

    let options: any = [];

    let i = zero ? -1 : 0;

    while (i < size) {

        i++;

        const x = i * interval;

        options.push({
            value: x + "",
            label: formatNum(x) + " Seconds"
        });

    }

    i = 0;

    while (i < maxMinutes) {

        i++;

        const x = i * 60;

        if (x <= maxSeconds) continue;

        options.push({
            value: x + "",
            label: formatNum(x) + " Seconds" + " / " + formatNum(x / 60) + " Minutes"
        });

    }

    return options;

}

export const dataAverageOptions = (zero, maxSeconds, interval) => {

    maxSeconds = parseNum(maxSeconds) || 100;

    interval = interval || 15;

    let size = fixedNum(maxSeconds / interval, 0);

    let options: any = [];

    let i = zero ? -1 : 0;

    while (i < size) {

        i++;

        const x = (i) * interval;

        // options.push({
        //     value: x + "-block",
        //     label: formatNum(x) + " Second Blocks"
        // });

        options.push({
            value: x + "-end",
            label: formatNum(x) + " Seconds (From End)"
        });

    }

    return options;

}

export const workoutLabel = (workout) => {

    let label = [date(workout.date), workout.activity, workout.location];

    return label.filter(Boolean).join(" ");

}