import React, { useCallback, useMemo, useState } from 'react';
import { Button, ButtonRow, useApp } from '@dex/bubl-dash';
import { clone, get } from '@dex/bubl-helpers';

const CopyPaste: React.FC<any> = (props: any) => {

    const { form, name } = props;

    const app = useApp();

    const [state, setState] = app.store(`copyPasate.${name}`, null);

    const handleCopy = useCallback((values) => {

        let value = get(values, name);

        if (!value) return;

        value = clone(value);

        setState(value);

    }, []);

    const handlePaste = useCallback(() => {

        if (!state) return;

        form.handleChange({ name: name, value: state, reset: true });

        form.setKey();

        setState(null);

    }, [state]);

    return (

        <ButtonRow>

            <Button
                label={props.labelCopy ? props.labelCopy : "Copy"}
                type={"faded"}
                size={"small"}
                onClick={handleCopy.bind(null, form.values)}
            />

            {state &&
                <Button
                    label={props.labelPaste ? props.labelPaste : "Paste"}
                    type={"secondary"}
                    size={"small"}
                    onClick={handlePaste}
                />
            }

        </ButtonRow>

    );

}


export default CopyPaste;
