import React from 'react';
import FancyValue from '../FancyValue/FancyValue';

import styles from "./ReportTestSummary.module.scss";

const ReportTestSummary: React.FC<ReportTestSummaryProps> = (props) => {

    let { rows, className, ...rest } = props;

    let classes = [styles.summary];

    if (className && Array.isArray(className)) classes = [...classes, ...className];
    else if (typeof className === "string") classes.push(className);

    return (

        <div
            className={classes.join(" ")}
            {...rest}
        >

            {rows.map((row, index) => (
                <div key={index} className={styles.row}>

                    <div className={styles.heading}>{row.heading}:</div>

                    <div className={styles.values}>
                        {row.values.map((item, index) => (
                            <div key={index} className={styles.item}>
                                <FancyValue
                                    {...item}
                                />
                                <div className={styles.label}>{item.label}</div>
                            </div>
                        ))}
                    </div>

                </div>
            ))}

        </div>

    )

}

interface ReportTestSummaryProps {
    [key: string]: any,
}

export default ReportTestSummary;