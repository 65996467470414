import React from 'react';
import styles from "./CoachingReportHeading.module.scss";
import { Line } from '@dex/bubl-dash';

const CoachingReportHeading: React.FC<CoachingReportHeadingProps> = (props) => {

    let { text, desc, icons, altText, classes } = props;

    return (

        <>

            <div className={[styles.heading, styles[classes]].join(" ")}>

                <p>
                    {text}
                    {desc && <span> {desc}</span>}
                </p>

                {icons &&
                    <>
                        {Array.isArray(icons) ?
                            <>
                                {icons.map((icon) => (
                                    <img
                                        src={icon}
                                        alt={text}
                                    />

                                ))}
                            </>
                            :

                            <img
                                src={icons}
                                alt={text}
                            />
                        }
                    </>
                }

                {altText &&
                    <p className={styles.altText}>{altText}</p>
                }

                {props.legend && <div> {props.legend} </div>}

            </div>

            {classes !== "seperator" &&
                <Line top={4} />
            }

        </>
    )

}

interface CoachingReportHeadingProps {
    text?: string,
    desc?: string,
    [key: string]: any,
}

export default CoachingReportHeading;