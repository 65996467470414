
import React, { useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { DataTable, FieldNumber, Heading, InputTableCell, InputTableRow, Line, Space } from '@dex/bubl-dash';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import { fixedNum, get, parseNum } from '@dex/bubl-helpers';
import { labAnalysisRegresionCharts } from '../../../functions/chartRegression';
import ChartAnalysis from '../../../elements/ChartLabTests/ChartAnalysis';
import FancyValue from '../../../elements/FancyValue/FancyValue';

const LabTestsVo2Analysis: React.FC<any> = (props: any) => {

    const { form, data, session } = props;

    const activity = form.values.activity;

    const { carb, carbKcal, fat, fatKcal, hr, mergedAvg, vo2, vo2hr, lactatePost, lactate, x } = useMemo(() => {

        return labAnalysisRegresionCharts(form.values.avgData, activity);

    }, []);

    const [vo2Unit, setVo2Unit] = useState("relative");
    const [carbUnit, setCarbUnit] = useState("g");
    const [fatUnit, setFatUnit] = useState("g");

    return useMemo(() => (

        <>

            <Analysis
                {...props}
                type={"hr"}
                mergedAvg={mergedAvg}
                activity={activity}
                reg={hr}
                formula={"linear"}
                chart={{
                    title: "HR Over Workload",
                    element: ChartAnalysis
                }}
                compute={{
                    element: ComputeHr,
                }}
                table={{
                    cols: ["load", "hr"]
                }}
            />

            <Line
                top={"small"}
            />

            {vo2Unit === "relative" &&
                <>
                    <Analysis
                        {...props}
                        type={"vo2"}
                        mergedAvg={mergedAvg}
                        activity={activity}
                        reg={vo2}
                        formula={"linear"}
                        chart={{
                            title: "VO2 Over Workload",
                            element: ChartAnalysis,
                            types: [
                                { name: "relative", label: "ml/kg*min", data: mergedAvg },
                                { name: "absolute", label: "L/min", data: mergedAvg },
                            ],
                            defaultType: "relative",
                            onTypeChange: setVo2Unit,
                        }}
                        compute={{
                            element: ComputeVo2,
                        }}
                        table={{
                            cols: ["load", "vo2"]
                        }}
                    />
                </>
            }

            {vo2Unit === "absolute" &&
                <>
                    <Analysis
                        {...props}
                        type={"vo2hr"}
                        mergedAvg={mergedAvg}
                        activity={activity}
                        reg={vo2hr}
                        formula={"linear"}
                        chart={{
                            title: "VO2-HR Relationship",
                            element: ChartAnalysis,
                            types: [
                                { name: "relative", label: "ml/kg*min", data: mergedAvg },
                                { name: "absolute", label: "L/min", data: mergedAvg },
                            ],
                            defaultType: "absolute",
                            onTypeChange: setVo2Unit,
                        }}
                        compute={{
                            element: ComputeVo2Hr,
                        }}
                        table={{
                            cols: ["vo2absolute", "hr"]
                        }}
                    />
                </>
            }

            <Line
                top={"small"}
            />

            <Analysis
                {...props}
                type={"carb" + carbUnit}
                mergedAvg={mergedAvg}
                activity={activity}
                reg={carbUnit === "g" ? carb : carbKcal}
                formula={"poly"}
                chart={{
                    title: "Carbohydrate Burn Rate",
                    element: ChartAnalysis,
                    types: [
                        { name: "g", label: "g/hr", data: mergedAvg },
                        { name: "kcal", label: "kcal/hr", data: mergedAvg },
                    ],
                    defaultType: "g",
                    onTypeChange: setCarbUnit,
                }}
                compute={{
                    element: ComputeCarb,
                }}
                table={{
                    cols: ["load", "carb" + carbUnit]
                }}
            />

            <Line
                top={"small"}
            />

            <Analysis
                {...props}
                type={"fat" + fatUnit}
                mergedAvg={mergedAvg}
                activity={activity}
                reg={fatUnit === "g" ? fat : fatKcal}
                formula={"poly"}
                chart={{
                    title: "Fat Burn Rate",
                    element: ChartAnalysis,
                    types: [
                        { name: "g", label: "g/hr", data: mergedAvg },
                        { name: "kcal", label: "kcal/hr", data: mergedAvg },
                    ],
                    defaultType: "g",
                    onTypeChange: setFatUnit,
                }}
                compute={{
                    element: ComputeFat,
                }}
                table={{
                    cols: ["load", "fat" + fatUnit]
                }}
            />

        </>

    ), [mergedAvg, vo2Unit, carbUnit, fatUnit, props]);

}

export const Analysis: React.FC<any> = (props: any) => {

    const { form, type, mergedAvg, reg, formula, activity, chart, table, compute, data } = props;

    const ChartElement = chart?.element;
    const ComputeElement = compute?.element;

    return (

        <Row gutter={8} edge={true}>

            <Col col={{ xs: 24, sm: 24, lg: 12 }}>

                {chart &&
                    <PanelChart
                        heading={chart.title}
                        data={mergedAvg}
                        // formula={reg}
                        types={chart.types}
                        defaultType={chart.defaultType}
                        onTypeChange={chart.onTypeChange}
                        renderChart={(chartType, chartData) => (

                            <>
                                {ChartElement &&
                                    <ChartElement
                                        data={chartData}
                                        activity={activity}
                                        intensity={data.intensity}
                                        type={type}
                                        xAxis={{
                                            interval: 0
                                        }}
                                    />
                                }
                            </>

                        )}
                    />
                }

            </Col>

            <Col col={{ xs: 24, sm: 12, lg: 6 }}>

                {reg && table &&
                    <SideTable
                        data={mergedAvg}
                        activity={activity}
                        cols={table.cols}
                    />
                }

            </Col>

            <Col col={{ xs: 24, sm: 12, lg: 6 }}>

                {reg &&
                    <>

                        {formula === "poly" &&
                            <FormulaTablePoly
                                reg={reg}
                            />
                        }

                        {formula === "linear" &&
                            <FormulaTable
                                reg={reg}
                            />
                        }

                        <Space height={"small"} />

                        <Heading space={false}>
                            Conversions
                        </Heading>

                        <Space height={"small"} />

                        {compute && ComputeElement &&
                            <ComputeElement
                                reg={reg}
                                type={type}
                                form={form}
                                activity={activity}
                            />
                        }

                    </>
                }

            </Col>

        </Row>

    )

}

export const FormulaTable: React.FC<any> = (props: any) => {

    const { title, reg } = props;

    const type = reg.regression.constructor.name;

    let columns: any = [
        {
            key: "slope",
            label: "B Slope",
            className: "table-bg-blue",
        },
        {
            key: "intercept",
            label: "Y-Intercept",
            className: "table-bg-blue",
        },
        {
            key: "r",
            label: <><span>R<sup>2</sup></span></>,
            className: "table-bg-blue",
        },
    ];

    return (

        <>

            <Heading space={false}>
                Formula
            </Heading>

            <Space height={"small"} />

            <DataTable data={null} className={"mini-table"} columns={columns}>

                <InputTableRow>

                    <InputTableCell style={{ width: 80 }}>
                        {fixedNum(reg.regression.slope, 4)}
                    </InputTableCell>

                    <InputTableCell style={{ width: 80 }}>
                        {fixedNum(reg.regression.intercept, 4)}
                    </InputTableCell>

                    <InputTableCell style={{ width: 80 }}>
                        <strong>{fixedNum(reg.score.r2, 4)}</strong>
                    </InputTableCell>

                </InputTableRow>

            </DataTable>

        </>

    )

};

export const FormulaTablePoly: React.FC<any> = (props: any) => {

    const { title, reg } = props;

    let columns: any = [
        {
            key: "X",
            label: <><span>X</span></>,
            className: "table-bg-blue",
        },
        {
            key: "X2",
            label: <><span>X<sup>2</sup></span></>,
            className: "table-bg-blue",
        },
        {
            key: "X3",
            label: <><span>X<sup>3</sup></span></>,
            className: "table-bg-blue",
        },
    ];

    let secondColumns: any = [
        {
            key: "intercept",
            label: "Y-intercept",
            className: "table-bg-blue",
        },
        {
            key: "r",
            label: <><span>R<sup>2</sup></span></>,
            className: "table-bg-blue",
            colSpan: 2
        }
    ];

    const x1 = fixedNum(reg?.regression?.coefficients[3], 4);
    const x2 = fixedNum(reg?.regression?.coefficients[2], 4);
    const x3 = fixedNum(reg?.regression?.coefficients[1], 4);
    const i = fixedNum(reg?.regression?.coefficients[0], 4)
    const r = fixedNum(reg?.score?.r2, 4);

    return (

        <>

            <Heading space={false}>
                Formula
            </Heading>

            <Space height={"small"} />

            <DataTable data={null} className={"mini-table"} columns={columns}>

                <InputTableRow>

                    <InputTableCell style={{ width: 80 }}>
                        {x3}
                    </InputTableCell>

                    <InputTableCell style={{ width: 80 }}>
                        {x2}
                    </InputTableCell>

                    <InputTableCell style={{ width: 80 }}>
                        {x1}
                    </InputTableCell>

                </InputTableRow>

            </DataTable>

            <DataTable data={null} className={"mini-table"} columns={secondColumns}>

                <InputTableRow>

                    <InputTableCell style={{ width: 80 }}>
                        {i}
                    </InputTableCell>

                    <InputTableCell style={{ width: 80 }}>
                        <strong>{r}</strong>
                    </InputTableCell>

                    <InputTableCell></InputTableCell>

                </InputTableRow>

            </DataTable>

        </>

    )

};

export const SideTable: React.FC<any> = (props: any) => {

    const { data, activity, cols, title } = props;

    let columns: any = [
        cols.includes("load") && activity === "Cycling" ? {
            key: "power_raw",
            label: "Power",
            format: "number",
            decimals: 0
        } : null,
        cols.includes("load") && activity === "Running" ? {
            key: "speed",
            label: "Speed",
        } : null,
        cols.includes("vo2") ? {
            key: "vo2relative",
            label: "VO2",
        } : null,
        cols.includes("vo2absolute") ? {
            key: "vo2absolute",
            label: "Vo2",
        } : null,
        cols.includes("hr") ? {
            key: "hr",
            label: "HR",
        } : null,
        cols.includes("vo2hr") ? {
            key: "calc_vo2hr",
            label: "Vo2-HR",
        } : null,
        cols.includes("reg_hr") ? {
            key: "reg_hr",
            label: "HR Linear",
        } : null,
        cols.includes("carbg") ? {
            key: "cho",
            label: "Carb Burn Rate [g/hr]",
        } : null,
        cols.includes("carbkcal") ? {
            key: "eecho",
            label: "Carb Burn Rate [kcal/hr]",
        } : null,
        cols.includes("fatg") ? {
            key: "fat",
            label: "Fat Burn Rate [g/hr]",
        } : null,
        cols.includes("fatkcal") ? {
            key: "eefat",
            label: "Fat Burn Rate [kcal/hr]",
        } : null,
        cols.includes("lactate") ? {
            key: "lactate",
            label: "Lactate",
        } : null,
        cols.includes("lactatePost") ? {
            key: "lactatePost",
            label: "Lactate",
        } : null,
        cols.includes("vla") ? {
            key: "vla",
            label: "Vla",
        } : null,
    ].filter(Boolean);

    return useMemo(() => (

        <>

            <Heading space={false}>
                Data
            </Heading>

            <Space height={"small"} />

            <DataTable
                className={"mini-table"}
                data={data || []}
                columns={columns}
            />

        </>

    ), [data, columns]);

}

const ComputeHr: React.FC<any> = (props: any) => {

    const { activity } = props;

    const label = activity == "Running" ? "Speed" : "Power";
    const suffix = activity == "Running" ? " kph" : " watts";

    return (

        <>

            <Compute
                {...props}
                label={[label, "HR"]}
                suffix={[suffix, " bpm"]}
            />

        </>

    )

}

const ComputeVo2: React.FC<any> = (props: any) => {

    const { activity } = props;

    const label = activity == "Running" ? "Speed" : "Power";
    const suffix = activity == "Running" ? " kph" : " watts";

    return (

        <>

            <Compute
                {...props}
                label={[label, "Vo2"]}
                suffix={[suffix, " ml/kg*min"]}
            />

        </>

    )

}

export const ComputeVla: React.FC<any> = (props: any) => {

    const { activity, type } = props;

    const label = activity == "Running" ? "Speed" : "Power";
    const suffix = activity == "Running" ? " kph" : " watts";

    return (

        <>

            <ComputePoly
                {...props}
                label={[label]}
                suffix={[suffix, "mmol/L/min"]}
            />

        </>

    )

}

export const ComputLactate: React.FC<any> = (props: any) => {

    const { activity, type } = props;

    const label = activity == "Running" ? "Speed" : "Power";
    const suffix = activity == "Running" ? " kph" : " watts";

    return (

        <>

            <ComputePoly
                {...props}
                label={[label]}
                suffix={[suffix, "mmol/L"]}
            />

        </>

    )

}

const ComputeCarb: React.FC<any> = (props: any) => {

    const { activity, type } = props;

    const label = activity == "Running" ? "Speed" : "Power";
    const suffix = activity == "Running" ? " kph" : " watts";

    return (

        <>

            <ComputePoly
                {...props}
                label={[label]}
                suffix={[suffix, type === "carbg" ? " g/hr" : "kcal/hr"]}
            />

        </>

    )

}

const ComputeFat: React.FC<any> = (props: any) => {

    const { activity, type } = props;

    const label = activity == "Running" ? "Speed" : "Power";
    const suffix = activity == "Running" ? " kph" : " watts";

    return (

        <>

            <ComputePoly
                {...props}
                label={[label]}
                suffix={[suffix, type === "carbg" ? " g/hr" : "kcal/hr"]}
            />

        </>

    )

}

const ComputeVo2Hr: React.FC<any> = (props: any) => {

    const { activity } = props;

    const label = activity == "Running" ? "Speed" : "Power";
    const suffix = activity == "Running" ? " kph" : " watts";

    return (

        <>

            <Compute
                {...props}
                label={["HR", "VO2"]}
                suffix={[" bpm", " L/min"]}
            />

        </>

    )

}

const Compute: React.FC<any> = (props: any) => {

    const { form, reg, activity, type, label, suffix } = props;

    const inputA = get(form.values, `analysis.${type}.inputA`) || 0;
    const computedA = !inputA ? "" : fixedNum(reg.regression.slope * inputA + reg.regression.intercept, 0);

    const inputB = get(form.values, `analysis.${type}.inputB`) || 0;
    const computedB = !inputB ? "" : fixedNum((inputB - reg.regression.intercept) / reg.regression.slope, 1);

    return (

        <Row gutter={8} edge={true}>

            <Col col={{ xs: 12 }}>

                <FieldNumber
                    form={form}
                    name={`analysis.${type}.inputA`}
                    defaultValue={get(form.defaults, `analysis.${type}.inputA`)}
                    suffix={suffix[0]}
                    label={"Enter " + label[0]}
                    wrapClass={"manual-input"}
                />

            </Col>

            <Col col={{ xs: 12 }} justify="end">

                {computedA &&
                    <FancyValue
                        value={computedA}
                        suffix={suffix[1]}
                    />
                }

            </Col>

            <Col col={{ xs: 12 }}>

                <FieldNumber
                    form={form}
                    name={`analysis.${type}.inputB`}
                    defaultValue={get(form.defaults, `analysis.${type}.inputB`)}
                    suffix={suffix[1]}
                    label={"Enter " + label[1]}
                    wrapClass={"manual-input"}
                />


            </Col>

            <Col col={{ xs: 12 }} justify="end">

                {computedB &&
                    <FancyValue
                        value={computedB}
                        suffix={suffix[0]}
                    />
                }

            </Col>

        </Row>

    )

}

const ComputePoly: React.FC<any> = (props: any) => {

    const { form, reg, activity, type, label, suffix, decimal } = props;

    const inputA = get(form.values, `analysis.${type}.inputA`) || 0;

    const computedA = !inputA ? "" : fixedNum(reg.regression.predict(parseNum(inputA)), decimal || 1);

    return (

        <Row gutter={8} edge={true}>

            <Col col={{ xs: 12 }}>

                <FieldNumber
                    form={form}
                    name={`analysis.${type}.inputA`}
                    defaultValue={get(form.defaults, `analysis.${type}.inputA`)}
                    suffix={suffix[0]}
                    label={"Enter " + label[0]}
                    wrapClass={"manual-input"}
                />

            </Col>

            <Col col={{ xs: 12 }} justify="end">

                {computedA &&
                    <FancyValue
                        value={computedA}
                        suffix={suffix[1]}
                    />
                }

            </Col>

        </Row>

    )

}

export default LabTestsVo2Analysis;
