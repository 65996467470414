import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import TeamsSingleAthletes from './TeamsSingleAthletes';

const TeamsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                col={{ xs: 24 }}
            >

                <Panel
                    heading={"Team Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Team Name",
                                key: "name",
                            },
                            {
                                label: "Gender",
                                key: "gender",
                                format: "titleCase",
                            },
                            {
                                label: "Year",
                                key: "year",
                            },
                            {
                                label: "Photo",
                                key: "photo",
                                format: "image",
                                link: true,
                                hideEmpty: true,
                                filters: {
                                    width: 100,
                                    height: 100
                                }
                            },
                        ]}
                        columnB={[
                            {
                                label: "Training Venue",
                                key: "trainingVenue",
                            },
                            {
                                label: "Sport",
                                key: "mainSport",
                            },
                            {
                                label: "Discipline",
                                key: "discipline",
                            },
                            {
                                label: "Level",
                                key: "level",
                                format: "titleCase",
                            },
                        ]}
                        columnC={[
                            {
                                label: "Coach Name",
                                key: "coachName",
                                format: "titleCase",
                            },
                            {
                                label: "Coach Email",
                                key: "coachEmail",
                            },
                            {
                                label: "Coach Phone",
                                key: "coachPhone",
                            },
                        ]}
                        columnD={[
                            {
                                label: "Manager Name",
                                key: "managerName",
                                format: "titleCase",
                            },
                            {
                                label: "Manager Email",
                                key: "managerEmail",
                            },
                            {
                                label: "Manager Phone",
                                key: "managerPhone",
                            },
                        ]}
                    />

                    <TeamsSingleAthletes
                        data={data}
                    />

                </Panel>

            </Col>

            <Col className={'side'}>


            </Col>

        </Row>

    ), [data]);

}

export default TeamsSingleView;
