import React from 'react';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';

const SessionsReportComment: React.FC<any> = (props: any) => {

    const { data, form, bike } = props;

    return (
        <>

            {bike?.summary?.summary &&
                <ReportDesc
                    label=""
                    value={bike?.summary.summary || ""}
                />
            }

        </>
    )
}

export default SessionsReportComment;