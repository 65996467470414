import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import TestSessionsApi from './TestSessionsApi';

let settings;

export default settings = {
    key: 'lab/test-sessions',
    zone: 'lab',
    path: '/lab/test-sessions',
    title: "Lab Test Sessions",
    endpoint: TestSessionsApi.endpoint,
    modelName: 'TestSessions',
    idKey: 'id',
    primaryKey: 'id',
    actions: ['trash', 'clone'],
    indexCaps: ["viewTestSessions"],
    viewCaps: ["viewTestSessions"],
    createCaps: ["createTestSessions"],
    updateCaps: ["updateTestSessions"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: TestSessionsApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = {
            where: {},
            include: [{ relation: 'athlete', preset: "link" }, { relation: 'fitters', preset: "link" }],
            order: ['date DESC'],
            fields: [
                "id", "modelName",
                "athlete", "athleteId", "fullName", "labTestNames",
                "fitters", "fitterIds", "name", "type", "date",
                "created", "updated",
            ],
            limit: 10,
            skip: 0
        };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['athleteName', 'id', 'labTestNames'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "ID",
                key: "id",
                type: "text",
            },
            {
                label: "Athlete",
                key: "athleteId",
                type: "relationship",
                model: "Athletes",
            },
            {
                label: "Session Date",
                key: "date",
                type: "date",
            },
            {
                label: "Appraisers",
                key: "fitterIds",
                type: "relationship",
                model: "Fitters",
            },
            // {
            //     label: "Test Type",
            //     key: "type",
            //     type: "choice",
            //     options: [
            //         { value: "Individual", label: "Individual" },
            //         { value: "Team", label: "Team" },
            //     ],
            // },
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "ID",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Athlete",
                key: "athlete",
                format: "ModelLink",
            },
            {
                label: "Session Date",
                key: "date",
                format: 'date',
                sort: "date",
            },
            {
                label: "Appraisers",
                key: "fitters",
                format: "ModelLink",
            },
            {
                label: "Lab Tests",
                key: "labTestNames",
                style: { maxWidth: 160, whiteSpace: "wrap", fontSize: 13 },
                render: (names) => {
                    return <>{names.map(name => (
                        <div>{name.replace(", -", "")}</div>
                    ))}</>
                }
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: TestSessionsApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: TestSessionsApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const include = [
            'athlete',
            'fitters',
            {
                relation: 'labTests',
                scope: {
                    fields: ["id", "type", "activity", "date", "intensity"]
                }
            }
        ];

        const filters = { include: include };

        return filters;

    },
    reportFetch: TestSessionsApi.getReport,
    reportFetchParams: (routeName, params, users, fetch) => {

        const filters = { key: params?.reportKey };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch, app) => {

        let title: string | any = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) {

            title = [data.athlete.fullName, app.formatValue("dateDay", {}, data.date)].join(" - "); //, data.id

        }
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleButtons: (routeName, params, users, fetch) => {

        const data = get(fetch, 'data');

        if (!data) return [];

        return [
            {
                label: "Athlete Profile",
                path: `/athletes/${data.athleteId}/view`,
                caps: settings.createCaps
            },
        ]

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "General",
                path: path + "/view"
            },
            // {
            //     label: "General",
            //     path: path + "/edit/general",
            //     caps: settings.updateCaps
            // },
            {
                label: "Health",
                path: path + "/edit/health",
                caps: settings.updateCaps
            },
            {
                label: "Bio Data",
                path: path + "/edit/bio",
                caps: settings.updateCaps
            },
            {
                label: "Events",
                path: path + "/edit/events",
                caps: settings.updateCaps
            },
            {
                label: "Lab Tests",
                path: path + "/edit/tests",
                caps: settings.updateCaps
            },
            {
                label: "Summary",
                path: path + "/edit/summary",
                caps: settings.updateCaps
            },
            {
                label: "Reports",
                path: path + "/edit/reports",
                caps: settings.updateCaps
            },
        ]

    },
};