import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import React, { useMemo } from 'react';
import { FieldTextArea, Seperator } from '@dex/bubl-dash';

const SettingsCoaching: React.FC<any> = (props: any) => {

    const { form } = props;

    return useMemo(() => (

        <Row gutter={8} edge={true}>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Comments"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Past Training Summary"}
                            name={"pastWeek"}
                            defaultValue={form.defaults.pastWeek}
                            rows={5}
                        />

                    </Col>

                    {/* <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Highlight"}
                            name={"highlightPassWeek"}
                            defaultValue={form.defaults.highlightPassWeek}
                            rows={5}
                        />

                    </Col> */}

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Upcoming Training Summary"}
                            name={"upcomingWeek"}
                            defaultValue={form.defaults.upcomingWeek}
                            rows={5}
                        />

                    </Col>

                </Row>

            </Col>

        </Row>

    ), [form.hash]);

}

export default SettingsCoaching;
