import { FieldSelect, Panel, Space } from '@dex/bubl-dash';
import { get, hash, titleCase } from '@dex/bubl-helpers';
import React, { useEffect, useState } from 'react';
import { getRandomInt } from '../../functions/sharedGeneral';
import RatingScalesApi from '../../views/RatingScales/RatingScalesApi';
import { stepsMinMax, stepLabels, ratingLabel, arrowPosition, MetricRanking, MetricRankingAlt, Scale, Steps, DisplayAs, arrowPositionLevels } from './RatingScaleElements';
import { changeDemographic } from '../../functions/sharedTests';
import styles from "./RatingScaleCard.module.scss";

const RatingScaleCard: React.FC<RatingScaleCardProps> = (props) => {

    let { metric, value, form, name, demographic, source, gender, altUnit, split, renderValue, prefix, renderAfter, renderHeading } = props;

    const group = RatingScalesApi.getData();

    const [data, setData]: any = useState({ metric: metric, demographic: "Scale Not Found" });

    if (form && name) demographic = get(form.values, name) || demographic;

    if (!gender && form) gender = get(form.values, "athlete.gender");

    if (!gender) gender = "male";

    if (!demographic) demographic = "all";

    useEffect(() => {

        if (props.data) setData(props.data);

    }, [hash(props.data)])

    useEffect(() => {

        if (!metric) return;

        group.run({
            params: {
                metric: metric,
                split: split ? split.metric : null,
            },
            onComplete: (response) => {

                if (response) setData(response);

            }
        });

    }, [metric]);

    const { steps, min, max }: any = stepsMinMax(data, demographic, gender);

    value = (props.value === "rand") ? getRandomInt(min, max) : parseFloat(value);

    return (

        <>

            <Panel heading={false} className={styles.panel}>

                {data &&

                    <RatingScaleCardInner
                        data={data}
                        value={value}
                        demographic={props.demographicLabel || props.demographic}
                        showDemographic={!form || !name}
                        source={source}
                        gender={gender}
                        altUnit={altUnit}
                        split={split}
                        renderValue={renderValue}
                        renderHeading={renderHeading}
                        steps={steps}
                        min={min}
                        max={max}
                        prefix={prefix}
                        renderAfter={renderAfter}
                    />

                }

                {form && name &&

                    <FieldSelect
                        form={form}
                        name={name}
                        wrapClass={styles.fieldWrap}
                        defaultValue={get(form.defaults, name, demographic)}
                        options={[
                            { label: "Recreational", value: "untrained" },
                            { label: "Competitive", value: "trained" },
                            { label: "Pro", value: "elite" },
                            { label: "All", value: "all" },
                        ]}
                    />

                }

            </Panel>

        </>

    )

}

export const RatingScaleCardInner: React.FC<RatingScaleCardProps> = (props) => {

    let { value, demographic, showDemographic, source, gender, altUnit, split, renderValue, renderHeading, data, steps, min, max, field, prefix, renderAfter } = props;

    const labels = stepLabels(steps);

    const rating = ratingLabel(value, steps, min, max);

    const left = data.displayAs === "levels" ? arrowPositionLevels(value, steps) : arrowPosition(value, min, max);

    return (

        <div className={styles.inner}>

            <MetricRanking
                data={data}
                value={value}
                rating={rating}
                className={styles.firstRanking}
                demographic={demographic}
                gender={gender}
                renderValue={renderValue}
                renderHeading={renderHeading}
                altUnit={altUnit}
                field={field}
                prefix={prefix}
            />

            <DisplayAs
                left={left}
                labels={labels}
                steps={steps}
                data={data}
                split={split}
                min={min}
                max={max}
                demographic={demographic}
                gender={gender}
            />

            {data.split &&
                <>

                    <MetricRankingAlt
                        data={data.split}
                        value={props.split?.value}
                        demographic={demographic}
                        gender={gender}
                        renderValue={renderValue}
                        altUnit={props.split?.altUnit}
                    />

                </>
            }

            {renderAfter &&
                <>
                    <Space />
                    {renderAfter()}
                </>
            }

            {(props.demographic && showDemographic) &&
                <div className={styles.demographic}>
                    Demographic: {titleCase(changeDemographic(demographic))}
                </div>
            }

            {source &&
                <div className={styles.demographic}>
                    Source: {source}
                </div>
            }

        </div>

    )

}

interface RatingScaleCardProps {
    [key: string]: any,
}

export default RatingScaleCard;