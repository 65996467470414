import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import React, { useMemo } from 'react';
import { FieldTextArea, Seperator } from '@dex/bubl-dash';

const SettingsPerformance: React.FC<any> = (props: any) => {

    const { form } = props;

    return useMemo(() => (

        <Row gutter={8} edge={true}>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Maximum Aerobic Capacity Descriptions"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Maximum Aerobic Capacity"}
                            name={"aerobicDescription"}
                            defaultValue={form.defaults.aerobicDescription}
                        />

                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Threshold Descriptions"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Threshold"}
                            name={"thresholdDescription"}
                            defaultValue={form.defaults.thresholdDescription}
                        />

                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Sprint Descriptions"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Sprint"}
                            name={"sprintDescription"}
                            defaultValue={form.defaults.sprintDescription}
                        />

                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Metabolic Efficiency Descriptions"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Fatmax"}
                            name={"metabolicEfficiencyDescription"}
                            defaultValue={form.defaults.metabolicEfficiencyDescription}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Met"}
                            name={"metDescription"}
                            defaultValue={form.defaults.metDescription}
                        />

                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Economy Descriptions"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Economy"}
                            name={"economyDescription"}
                            defaultValue={form.defaults.economyDescription}
                        />

                    </Col>

                </Row>

            </Col>

        </Row>

    ), [form.hash]);

}

export default SettingsPerformance;
