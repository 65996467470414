import React, { useMemo, useState } from 'react';
import IndexTable from '@dex/bubl-dash/src/lib/components/IndexTable/IndexTable';
import SessionsSettings from '../Sessions/SessionsSettings';
import moment from 'moment';

const DashboardSessions: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tableState, setTableState] = useState({ search: undefined, where: undefined, order: undefined, skip: undefined });

    const indexFetchParams = (routeName, params, users) => {

        const filters = SessionsSettings.indexFetchParams(routeName, params, users);

        const todaysDate = moment().format('YYYY-MM-DD');

        filters.where.sessionDate = todaysDate;

        return filters;

    }


    return useMemo(() => (

        <>

            <IndexTable
                title={"Today's Fit / Size Sessions"}
                getFetch={SessionsSettings.indexFetch}
                getColumns={SessionsSettings.indexColumns}
                preFetchFilters={indexFetchParams}
                getQuickSearch={SessionsSettings.indexQuickSearch}
                getSearchFields={SessionsSettings.indexSearchFields}
                routeParams={tableState}
                manageState={setTableState}
            />

        </>

    ), [data]);

}

export default DashboardSessions;
