import { apiGet, baseApi, baseApiReturn, options, response } from "@dex/bubl-fetch";

const endpoint = 'Gaits';

const GaitsApi: baseApiReturn = baseApi(endpoint);

GaitsApi.getReport = (id: string, options?: options): response => {

    return apiGet({
        url: endpoint + "/public/" + id + "/getReport",
        params: {},
        ...options
    });

}

export default GaitsApi;