import React, { useMemo } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';

import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';


const QuestionnairesFillView: React.FC<any> = (props: any) => {

    const { data } = props;

    let heading = "Pre-Bike Fit/Bike Size Questionnaire";

    let show = {
        cyclist: false,
        bike: false,
        bikeSizing: false,
        refitSame: false,
        refitNew: false,
    };

    const typeOfService = data.typeOfService;

    switch (typeOfService) {

        case "Basic Bike Fit":
        case "Comprehensive Bike Fit":

            heading = "Pre-Bike Fit Questionnaire";
            show.cyclist = true;
            show.bike = true;

            break;

        case "Bike Sizing":

            heading = "Pre-Bike Sizing Questionnaire";
            show.cyclist = true;
            show.bikeSizing = true;

            break;

        case "Fitting and Sizing":

            heading = "Pre-Bike Sizing Questionnaire";
            show.cyclist = true;
            show.bikeSizing = true;
            show.bike = true;

            break;

        case "ReFit on Same Bike":

            heading = "Pre-Bike Fit Questionnaire";
            show.cyclist = false;
            show.refitSame = true;

            break;

        case "ReFit on New Bike":

            heading = "Pre-Bike Fit Questionnaire";
            show.cyclist = false;
            show.bike = true;
            show.refitNew = true;

            break;

        case "Remote Bike Sizing":

            heading = "Pre-Bike Sizing Questionnaire";
            show.bikeSizing = true;

            break;

        case "Lab Test":

            heading = "Pre-Test Questionnaire";

            break;
    }

    return useMemo(() => (

        <>

            {show.cyclist &&

                <>

                    <Seperator
                        top={"auto"}
                        bottom={"auto"}
                        heading={"Cyclist Info"}
                    />

                    <MetaTable
                        data={data.cyclistInfo}
                        columnSize={{ xs: 24, sm: 12 }}
                        columnA={[
                            {
                                label: "Gender",
                                key: "gender",
                                format: "titleCase",
                            },
                            {
                                label: "Height",
                                key: "height",
                            },
                            {
                                label: "Location",
                                key: "location",
                            },
                            {
                                label: "Cycling Level",
                                key: "cyclingLevel",
                            },
                            {
                                label: "Years of Riding",
                                key: "yearsOfRiding",
                            },
                            {
                                label: "Performance or Fitness Goals",
                                key: "performanceOrFitnessGoals",
                            },
                            {
                                label: "Do you have any current Injuries that affect your cycling ability?",
                                key: "currentInjuries",
                            }
                        ]}
                        columnB={[
                            {
                                label: "Date of Birth",
                                key: "dob",
                                format: "dateDay",
                            },
                            {
                                label: "Weight",
                                key: "weight",
                            },
                            {
                                label: "Main Sport",
                                key: "mainSport",
                            },
                            {
                                label: "If a person referred you to us, please let us know who",
                                key: "referredBy",
                            },
                            {
                                label: "Reason For Cycling",
                                key: "reasonForCycling",
                            },
                            {
                                label: "Reason For Fit",
                                key: "reasonForFit",
                            },
                            {
                                label: "Weekly Riding Volume",
                                key: "weeklyRidingVolume",
                            },
                            {
                                label: "Do you currently have any discomfort or pain when riding?",
                                key: "discomfortOrPain"
                            },
                            {
                                label: "If you would like to share anything else about you or your bike",
                                key: "bikeDescriptionMore"
                            },
                        ]}
                    />

                </>

            }

            {show.bike &&
                <>

                    <Seperator
                        top={"auto"}
                        bottom={"auto"}
                        heading={"Bike Details"}
                    />

                    <MetaTable
                        data={data.bikeDetails}
                        columnSize={{ xs: 24, sm: 12 }}
                        columnA={[
                            {
                                label: "Type of Bike",
                                key: "typeOfBike",
                                format: "titleCase",
                            },
                            {
                                label: "Bike Model",
                                key: "bikeModel",
                                format: "titleCase",
                            },
                            {
                                label: "Size",
                                key: "size",
                                format: "titleCase",
                            },
                            {
                                label: "Type of Brakes",
                                key: "typeOfBrakes",
                                format: "titleCase",
                            }
                        ]}
                        columnB={[
                            {
                                label: "Bike Brand",
                                key: "bikeBrand",
                                format: "titleCase",
                            },
                            {
                                label: "Year",
                                key: "year",
                                format: "titleCase",
                            },
                            {
                                label: "Type Of Pedals",
                                key: "typeOfPedals",
                                format: "titleCase",
                            }
                        ]}
                    />

                </>
            }

            {show.bikeSizing &&
                <>

                    <Seperator
                        top={"auto"}
                        bottom={"auto"}
                        heading={"Bike Sizing"}
                    />

                    <MetaTable
                        data={data.bikeSizing}
                        columnSize={{ xs: 24, sm: 12 }}
                        columnA={[
                            {
                                label: "What type of Bike manufacturing are you considering?",
                                key: "bikeManufacturing",
                                format: "titleCase",
                            },
                            {
                                label: "List the Bike(s) you are thinking of getting?",
                                key: "listOfBikes",
                                format: "titleCase",
                            },
                            {
                                label: "Do you have any specific preferences for your new bike?",
                                key: "preferencesForNewBike",
                                format: "titleCase",
                            },

                        ]}
                    />

                </>
            }

            {show.refitSame &&
                <>

                    <Seperator
                        top={"auto"}
                        bottom={"auto"}
                        heading={"ReFit Same Bike"}
                    />

                    <MetaTable
                        data={data.refitSame}
                        columnSize={{ xs: 24, sm: 12 }}
                        columnA={[
                            {
                                label: "Why do you wish to be re-fitted again on your bike? ",
                                key: "reasonForReFitted",
                                format: "titleCase",
                            },
                            {
                                label: "Do you have any new Performance or Fitness Goals? ",
                                key: "newFitnessOrPerformanceGoal",
                                format: "titleCase",
                            },
                            {
                                label: "Please indicate your current cycling volume",
                                key: "currentCyclingVolume",
                                format: "titleCase",
                            }
                        ]}
                        columnB={[
                            {
                                label: "Have you made any changes to your bike since we last saw you?",
                                key: "madeNewBikeAdjustments",
                                format: "titleCase",
                            },
                            {
                                label: "Do you have any new or ongoing comfort issues when riding?",
                                key: "newOrOngoingDiscomfortWhenRiding",
                                format: "titleCase",
                            },
                            {
                                label: "Share any other information about your bike or yourself since your last session with us?",
                                key: "newInformation",
                                format: "titleCase",
                            }
                        ]}
                    />

                </>
            }

            {show.refitNew &&
                <>
                    <Seperator
                        top={"auto"}
                        bottom={"auto"}
                        heading={"ReFit New Bike"}
                    />

                    <MetaTable
                        data={data.refitNew}
                        columnSize={{ xs: 24, sm: 12 }}
                        columnA={[
                            {
                                label: "Do you have any new Performance or Fitness Goals you are pursing since last session?",
                                key: "newFitnessOrPerformanceGoal",
                                format: "titleCase",
                            },
                            {
                                label: "Please indicate your current cycling volume",
                                key: "currentCyclingVolume",
                                format: "titleCase",
                            }
                        ]}
                        columnB={[
                            {
                                label: "Do you have any new or ongoing comfort issues when riding?",
                                key: "newOrOngoingDiscomfortWhenRiding",
                                format: "titleCase",
                            },
                            {
                                label: "Share any other information about your bike or yourself since your last session with us?",
                                key: "newInformation",
                                format: "titleCase",
                            }
                        ]}
                    />

                </>
            }

        </>


    ), [props, show]);

}

export default QuestionnairesFillView;
