import React, { useEffect, useState } from 'react';

import styles from "./RaceReadyRecommendation.module.scss";
import { Col, DataTableCell, FieldHidden, FieldNumber, FieldSelect, FieldTextArea, FieldTextTime, InputTable, InputTableCell, InputTableRow, Row, Seperator, useMountEffect, useUpdateEffect } from '@dex/bubl-dash';
import { clone, get, loop, parseNum } from '@dex/bubl-helpers';
import { getClockTime, percentageOf } from '../../functions/sharedTests';

const RaceReadyRecommendation: React.FC<RaceReadyRecommendationProps> = (props) => {

    const { form, data, activity, session, targets } = props;

    const effortData = clone(form.values.avgData || []);

    const calculateTime = (power) => {

        const distance = get(form.values, "manual.distance", 0);

        if (!power && !distance) return;

        const decimalTime = parseNum(distance) / parseNum(power)

        return getClockTime(decimalTime)

    };

    const unit = form.values.result.unit;

    useMountEffect(() => {

        const lowerBurnRateG = get(form.values, `result.recommendations.lowerBurnRateG`);
        const upperBurnRateG = get(form.values, `result.recommendations.upperBurnRateG`);

        if (!lowerBurnRateG) form.handleChange({ silent: true, reset: true, name: `result.recommendations.lowerBurnRateG`, value: get(form.values, `result.sum.carbBurnG[0]`) });
        if (!upperBurnRateG) form.handleChange({ silent: true, reset: true, name: `result.recommendations.upperBurnRateG`, value: get(form.values, `result.sum.carbBurnG[1]`) });

    });

    useUpdateEffect(() => {

        form.handleChange({ silent: true, reset: true, name: `result.recommendations.lowerPercentBurnRate`, value: percentageOf(get(form.values, `result.recommendations.lowerLimit`), get(form.values, `result.recommendations.lowerBurnRateG`)) });
        form.handleChange({ silent: true, reset: true, name: `result.recommendations.upperPercentBurnRate`, value: percentageOf(get(form.values, `result.recommendations.upperLimit`), get(form.values, `result.recommendations.upperBurnRateG`)) });

    }, [form.hash]);

    return (

        <Row edge gutter={"8"}>

            <Col col={{ xs: 24 }}>
                <Seperator heading={"Recommendations"} top={"auto"} bottom={"auto"} />
            </Col>

            <Col col={{ xs: 24 }}>

                <InputTable
                    columns={[
                        { label: "Pace Sustainable ?" },
                        effortData.length
                    ]}
                >

                    <InputTableRow>

                        {effortData.map((effort, index) => (

                            <InputTableCell>
                                <FieldSelect
                                    form={form}
                                    name={`result.recommendations.sustainable[${index}]`}
                                    label={`Effort ${index + 1}`}
                                    options={[
                                        { value: "Very-Likely", label: "Very Likely" },
                                        { value: "Likely", label: "Likely" },
                                        { value: "Possible", label: "Possible" },
                                        { value: "Unlikely", label: "Unlikely" },
                                        { value: "Very-Unlikely", label: "Very Unlikely" },
                                        { value: "Uncertain", label: "Uncertain" },
                                    ]}
                                    wrapClass={"manual-input inline-label w-400"}
                                    defaultValue={get(form.values, `result.recommendations.sustainable[${index}]`)}
                                />
                            </InputTableCell>

                        ))}

                    </InputTableRow>

                </InputTable>

            </Col>

            <Col col={{ xs: 24 }}>

                <InputTable
                    columns={[
                        {},
                        { colSpan: 2, label: "Range", desc: "From - To", alignMiddle: true },
                        {},
                        { colSpan: 2, label: "Range", desc: "From - To", alignMiddle: true },
                        { label: "Fueling Rate", colSpan: 2, alignMiddle: true },
                        { label: "Burn Rate", alignMiddle: true },
                        { label: "%Burn Rate", alignMiddle: true }
                    ]}
                >

                    <InputTableRow>

                        {activity == "Cycling" ?

                            <>

                                <DataTableCell>
                                    <strong>{"Avg Power"}</strong>
                                </DataTableCell>

                                <InputTableCell>
                                    <FieldNumber
                                        form={form}
                                        name={`result.recommendations.avgPowerMin`}
                                        defaultValue={get(form.defaults, "result.recommendations.avgPowerMin")}
                                        label=""
                                        wrapClass={"manual-input"}
                                        suffix={" W"}
                                    />
                                    <FieldHidden
                                        form={form}
                                        name={`result.recommendations.avgPowerMinFtp`}
                                        value={percentageOf(get(form.values, "result.recommendations.avgPowerMin"), get(form.values, "result.ftp"), 0)}
                                    />
                                </InputTableCell>

                                <InputTableCell>
                                    <FieldNumber
                                        form={form}
                                        name={`result.recommendations.avgPowerMax`}
                                        defaultValue={get(form.defaults, "result.recommendations.avgPowerMax")}
                                        label=""
                                        wrapClass={"manual-input"}
                                        suffix={" W"}
                                    />
                                    <FieldHidden
                                        form={form}
                                        name={`result.recommendations.avgPowerMaxFtp`}
                                        value={percentageOf(get(form.values, "result.recommendations.avgPowerMax"), get(form.values, "result.ftp"), 0)}
                                    />
                                </InputTableCell>
                            </>
                            :
                            <>
                                <DataTableCell>
                                    <strong>{"Avg Pace"}</strong>
                                </DataTableCell>

                                <InputTableCell>
                                    <FieldTextTime
                                        form={form}
                                        name={`result.recommendations.avgPaceMin`}
                                        defaultValue={get(form.defaults, "result.recommendations.avgPaceMin")}
                                        label=""
                                        wrapClass={"manual-input"}
                                        placeholder="mm:ss"
                                    />
                                </InputTableCell>

                                <InputTableCell>
                                    <FieldTextTime
                                        form={form}
                                        name={`result.recommendations.avgPaceMax`}
                                        defaultValue={get(form.defaults, "result.recommendations.avgPaceMax")}
                                        label=""
                                        wrapClass={"manual-input"}
                                        placeholder="mm:ss"
                                    />
                                </InputTableCell>

                            </>

                        }

                        <DataTableCell>
                            <strong>HR</strong>
                        </DataTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                name={`result.recommendations.hrMin`}
                                label=""
                                wrapClass={"manual-input"}
                                defaultValue={get(form.values, "result.recommendations.hrMin")}
                                suffix=" bpm"
                            />
                            <FieldHidden
                                form={form}
                                name={`result.recommendations.vo2HrMin`}
                                value={percentageOf(get(form.values, "result.recommendations.hrMin"), get(form.values, "result.vo2maxHr"), 0)}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                name={`result.recommendations.hrMax`}
                                label=""
                                wrapClass={"manual-input"}
                                defaultValue={get(form.values, "result.recommendations.hrMax")}
                                suffix=" bpm"
                            />
                            <FieldHidden
                                form={form}
                                name={`result.recommendations.vo2HrMax`}
                                value={percentageOf(get(form.values, "result.recommendations.hrMax"), get(form.values, "result.vo2maxHr"), 0)}
                            />
                        </InputTableCell>

                        <DataTableCell>
                            <strong>Lower Limit</strong>
                        </DataTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                name={`result.recommendations.lowerLimit`}
                                label=""
                                wrapClass={"manual-input"}
                                defaultValue={get(form.values, "result.recommendations.lowerLimit")}
                                suffix=" g/hr"
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                name={`result.recommendations.lowerBurnRateG`}
                                label=""
                                wrapClass={"manual-input"}
                                defaultValue={get(form.values, "result.recommendations.lowerBurnRateG")}
                                suffix=" g/hr"
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                name={`result.recommendations.lowerPercentBurnRate`}
                                label=""
                                wrapClass={""}
                                defaultValue={get(form.values, "result.recommendations.lowerPercentBurnRate")}
                                suffix=" %"
                                disabled
                                hidden
                                key={get(form.values, "result.recommendations.lowerPercentBurnRate")}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <DataTableCell>
                            <strong>{"Avg Speed"}</strong>
                        </DataTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                name={`result.recommendations.avgSpeedMin`}
                                defaultValue={get(form.values, "result.recommendations.avgSpeedMin")}
                                label=""
                                wrapClass={"manual-input"}
                                suffix={" kph"}
                            />
                            <FieldHidden
                                form={form}
                                name={`result.recommendations.avgSpeedMinFtp`}
                                value={percentageOf(get(form.values, "result.recommendations.avgSpeedMin"), get(form.values, "result.ftp"), 0)}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                name={`result.recommendations.avgSpeedMax`}
                                defaultValue={get(form.values, "result.recommendations.avgSpeedMax")}
                                label=""
                                wrapClass={"manual-input"}
                                suffix={" kph"}
                            />
                            <FieldHidden
                                form={form}
                                name={`result.recommendations.avgSpeedMaxFtp`}
                                value={percentageOf(get(form.values, "result.recommendations.avgSpeedMax"), get(form.values, "result.ftp"), 0)}
                            />
                        </InputTableCell>

                        <DataTableCell>
                            <strong>Time</strong>
                        </DataTableCell>

                        <InputTableCell>
                            <FieldTextTime
                                form={form}
                                name={`result.recommendations.timeMin`}
                                label=""
                                disabled
                                hidden
                                defaultValue={calculateTime(get(form.values, "result.recommendations.avgSpeedMin"))}
                                key={get(form.values, "result.recommendations.avgSpeedMin")}
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldTextTime
                                form={form}
                                name={`result.recommendations.timeMax`}
                                label=""
                                disabled
                                hidden
                                defaultValue={calculateTime(get(form.values, "result.recommendations.avgSpeedMax"))}
                                key={get(form.values, "result.recommendations.avgSpeedMax")}
                            />
                        </InputTableCell>

                        <DataTableCell>
                            <strong>Upper Limit</strong>
                        </DataTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                name={`result.recommendations.upperLimit`}
                                label=""
                                wrapClass={"manual-input"}
                                defaultValue={get(form.values, "result.recommendations.upperLimit")}
                                suffix=" g/hr"
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                name={`result.recommendations.upperBurnRateG`}
                                label=""
                                wrapClass={"manual-input"}
                                defaultValue={get(form.values, "result.recommendations.upperBurnRateG")}
                                suffix=" g/hr   "
                            />
                        </InputTableCell>

                        <InputTableCell>
                            <FieldNumber
                                form={form}
                                name={`result.recommendations.upperPercentBurnRate`}
                                label=""
                                wrapClass={"manual-input"}
                                defaultValue={get(form.values, "result.recommendations.upperPercentBurnRate")}
                                key={get(form.values, "result.recommendations.upperPercentBurnRate")}
                                suffix=" %"
                                disabled
                            />
                        </InputTableCell>

                    </InputTableRow>

                </InputTable>

            </Col>

            <Col col={{ xs: 24 }}>
                <FieldTextArea
                    form={form}
                    name='result.summary'
                    defaultValue={get(form.values, "result.summary")}
                    label='Summary'
                    rows={4}
                />
            </Col>

        </Row>

    )

}

interface RaceReadyRecommendationProps {
    [key: string]: any,
}

export default RaceReadyRecommendation;