import { Col, Heading, Line, MediaImage, Row, Space, useMountEffect } from '@dex/bubl-dash';
import React, { useMemo } from 'react';
import { ColoredHeader } from './GaitReportPage';
import styles from "./GaitReportPhotos.module.scss";

const GaitReportPhotos: React.FC<any> = (props: any) => {

    const { data, form, run, index } = props;

    const runData = run;

    return useMemo(() => (

        <Row edge gutter={20}>

            <Col col={{ xs: 24 }}>
                <ColoredHeader
                    heading="Analysis Photos"
                    desc={`${runData.date}`}
                />
            </Col>

            {runData?.pictures && runData.pictures.map((picture, index) => (

                <Col col={{ xs: 12 }} key={index}>

                    {picture &&

                        <>
                            <div className={styles.imageContainer}>
                                <MediaImage
                                    id={picture.image?.fileId}
                                    filters={{ fallback: { background: "#313b45", text: picture.caption } }}
                                    imgProps={{
                                        crossOrigin: "anonymous"
                                    }}
                                    className={styles.image}
                                />
                            </div>

                            <Space />

                            <p>{picture.caption}</p>
                        </>

                    }

                </Col>

            ))}

        </Row>

    ), [data]);

}

export default GaitReportPhotos;