import React, { useCallback, useMemo } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldCheckbox from '@dex/bubl-dash/src/lib/components/Fields/FieldCheckbox';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import { bikeLabel } from '../../../functions/sharedBikeFit';

const SessionsSingleReport: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const bikeValues: any = [];
    const pageValues: any = [];

    const bikeOptions = data.bikes.map((bike, i) => {

        bikeValues.push(i + "");

        return { value: i + "", label: "Bike " + (i + 1) + " - " + bikeLabel(bike) };

    });

    let pageOptions = [
        { value: "0.cover", label: "Cover" },
        { value: "1.rider", label: "Rider Information" },
        { value: "2.posture", label: "Posture" },
        { value: "3.stability", label: "Stability, Mobility & Symmetry" },
        { value: "4.cleat", label: "Shoe & Cleat Alignment" },
        { value: "5.bikes", label: "Bikes" },
        { value: "6.summary", label: "Summary" },
        { value: "7.exercises", label: "Exercises" },
    ];


    if (data.fitType === 'Bike Sizing' || data.fitType === 'Remote Sizing') {

        pageOptions = [
            { value: "0.cover", label: "Cover" },
            { value: "1.rider", label: "Rider Information" },
            { value: "2.posture", label: "Posture" },
            { value: "5.bike-sizing", label: "Bike Sizing" },
            { value: "6.summary", label: "Summary" },
        ];

    }

    if (data.fitType === 'Basic Bike Fit') {

        pageOptions = [
            { value: "0.cover", label: "Cover" },
            { value: "1.rider", label: "Rider Information" },
            { value: "2.mobility", label: "Mobility & Foot" },
            { value: "4.cleat", label: "Shoe & Cleat Alignment" },
            { value: "5.bikes", label: "Bikes" },
            { value: "6.summary", label: "Summary" },
            { value: "7.exercises", label: "Exercises" },
        ];

    }

    if (data.fitType === 'Aerodynamic') {

        pageOptions = [
            { value: "0.cover", label: "Cover" },
            { value: "1.rider", label: "Rider Information" },
            { value: "5.bikes", label: "Bikes" },
        ];

    }

    pageOptions.map((option, i) => {

        pageValues.push(option.value);

        return option;

    });

    const form = useForm({
        action: "preview",
        pages: pageValues,
        bikes: bikeValues,
    });

    const path = window.location.origin;
    const handleSubmit = useCallback((values) => {

        const query: any = [];

        values.pages?.map(page => {
            query.push("pages[]=" + page);
        });

        values.bikes?.map(bike => {
            query.push("bikes[]=" + bike);
        });

        window.open(app.options.endpoint.slice(0, -4) + "/report/" + (values.action === 'download' ? 'download/' : '') + data.id + "?" + query.join("&"))

    }, [props]);


    return useMemo(() => (

        <Panel
            heading={"Report"}
            actions={
                <Button
                    label={"Generate"}
                    onClick={form.triggerSubmit}
                />
            }
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldRadio
                            form={form}
                            label={"Actions"}
                            name='action'
                            defaultValue={form.defaults.action}
                            options={[
                                { value: "preview", label: "Preview" },
                                { value: "download", label: "Download" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 24 }}>

                        <FieldCheckbox
                            list={true}
                            form={form}
                            label={"Pages"}
                            name='pages'
                            defaultValue={form.defaults.pages}
                            required={true}
                            options={pageOptions}
                        />

                    </Col>

                    {(form.values.pages?.includes("5.bikes") || form.values.pages?.includes("5.bike-sizing")) &&
                        <Col col={{ xs: 24, sm: 24 }}>

                            <FieldCheckbox
                                list={true}
                                form={form}
                                label={"Bikes"}
                                name='bikes'
                                defaultValue={form.defaults.bikes}
                                required={true}
                                options={bikeOptions}
                            />

                        </Col>
                    }

                </Row>

            </Form>

        </Panel>

    ), [data, form.hash]);

}

export default SessionsSingleReport;
