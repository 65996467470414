import { apiGet, baseApi, baseApiReturn, options, response } from "@dex/bubl-fetch";

const endpoint = 'TestSessions';

const TestSessionsApi: baseApiReturn = baseApi(endpoint);

TestSessionsApi.getReport = (id: string, options?: options): response => {

    return apiGet({
        url: endpoint + "/public/" + id + "/getReport",
        params: {},
        ...options
    });

}

export default TestSessionsApi;