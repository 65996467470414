import React, { useMemo, useState } from 'react';
import IndexTable from '@dex/bubl-dash/src/lib/components/IndexTable/IndexTable';
import GaitsSettings from '../GaitsSettings';

const GaitsSingleSessions: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tableState, setTableState] = useState({ search: undefined, where: undefined, order: undefined, skip: undefined });

    const indexFetchParams = (routeName, params, users) => {

        let filters = GaitsSettings.indexFetchParams(routeName, params, users);

        filters.where.athleteId = data.id;

        return filters;

    }

    const getColumns = (routeName, params, users) => {

        let columns = GaitsSettings.indexColumns(routeName, params, users);

        columns.map((column, index) => {

            if (column.key === "athlete") delete (columns[index]);

            return column;

        });

        return columns;

    }

    return useMemo(() => (

        <>

            <IndexTable
                title={"Gait Analysis Sessions"}
                getFetch={GaitsSettings.indexFetch}
                getColumns={getColumns}
                preFetchFilters={indexFetchParams}
                getQuickSearch={GaitsSettings.indexQuickSearch}
                getSearchFields={GaitsSettings.indexSearchFields}
                routeParams={tableState}
                manageState={setTableState}
            />

        </>

    ), [data]);

}

export default GaitsSingleSessions;
