import React, { useMemo, useState } from 'react';
import IndexTable from '@dex/bubl-dash/src/lib/components/IndexTable/IndexTable';
import TestSessionsSettings from '../../TestSessions/TestSessionsSettings';

const AthletesSingleTestSessions: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tableState, setTableState] = useState({ search: undefined, where: undefined, order: undefined, skip: undefined });

    const indexFetchParams = (routeName, params, users) => {

        let filters = TestSessionsSettings.indexFetchParams(routeName, params, users);

        filters.where.athleteId = data.id;

        return filters;

    }

    const getColumns = (routeName, params, users) => {

        let columns = TestSessionsSettings.indexColumns(routeName, params, users);

        columns.map((column, index) => {

            if (column.key === "athlete") delete (columns[index]);

            return column;

        });

        return columns;

    }

    return useMemo(() => (

        <>

            <IndexTable
                title={"Lab Test Sessions"}
                getFetch={TestSessionsSettings.indexFetch}
                getColumns={getColumns}
                preFetchFilters={indexFetchParams}
                getQuickSearch={TestSessionsSettings.indexQuickSearch}
                getSearchFields={TestSessionsSettings.indexSearchFields}
                routeParams={tableState}
                manageState={setTableState}
            />

        </>

    ), [data]);

}

export default AthletesSingleTestSessions;
