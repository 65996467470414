import React, { useMemo } from 'react';
import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import { FieldTextArea } from '@dex/bubl-dash';

const SettingsLabTests: React.FC<any> = (props: any) => {

    const { form } = props;

    return useMemo(() => (

        <>

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24 }}>

                    <Seperator
                        heading={"VO2 Descriptions"}
                        bottom={"auto"}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"VO2 Description"}
                                name={`vo2Description`}
                                defaultValue={form.defaults.vo2Description}
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24 }}>

                    <Seperator
                        heading={"HR Descriptions"}
                        bottom={"auto"}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"HR Description"}
                                name={`hrDescription`}
                                defaultValue={form.defaults.hrDescription}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"VO2/HR Description"}
                                name={`vo2HrDescription`}
                                defaultValue={form.defaults.vo2HrDescription}
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24 }}>

                    <Seperator
                        heading={"Breathing Descriptions"}
                        bottom={"auto"}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Breathing Rate Description"}
                                name={"breathingRateDescription"}
                                defaultValue={form.defaults.breathingRateDescription}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Tidal Volume Description"}
                                name={"tidalVolumeDescription"}
                                defaultValue={form.defaults.tidalVolumeDescription}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Ventilation Rate (Ve) Description"}
                                name={"ventilationRateDescription"}
                                defaultValue={form.defaults.ventilationRateDescription}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Breathing Efficiency (BE) Description"}
                                name={"breathingEfficiencyDescription"}
                                defaultValue={form.defaults.breathingEfficiencyDescription}
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24 }}>

                    <Seperator
                        heading={"Muscle Descriptions"}
                        bottom={"auto"}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Muscle Oxygen (SMO2) Description"}
                                name={"smo2Description"}
                                defaultValue={form.defaults.smo2Description}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Total Hemoglobin (THb) Description"}
                                name={"tbhDescription"}
                                defaultValue={form.defaults.tbhDescription}
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24 }}>

                    <Seperator
                        heading={"Lactate Descriptions"}
                        bottom={"auto"}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Lactate (Lac) Description"}
                                name={"lactateDescription"}
                                defaultValue={form.defaults.lactateDescription}
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24 }}>

                    <Seperator
                        heading={"Energy Descriptions"}
                        bottom={"auto"}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Fatmax Description"}
                                name={"fatmaxDescription"}
                                defaultValue={form.defaults.fatmaxDescription}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"MEP Description"}
                                name={"mepDescription"}
                                defaultValue={form.defaults.mepDescription}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Carbohydrate Description"}
                                name={"carbDescription"}
                                defaultValue={form.defaults.carbDescription}
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24 }}>

                    <Seperator
                        heading={"Running Performance Descriptions"}
                        bottom={"auto"}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Running Speed Description"}
                                name={"RunningDescription"}
                                defaultValue={form.defaults.RunningDescription}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Running Economy Description"}
                                name={"economyRunningDescription"}
                                defaultValue={form.defaults.economyRunningDescription}
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24 }}>

                    <Seperator
                        heading={"Cycling Performance Descriptions"}
                        bottom={"auto"}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Cycling Power Description"}
                                name={"CyclingDescription"}
                                defaultValue={form.defaults.CyclingDescription}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Cycling Economy Description"}
                                name={"economyCyclingDescription"}
                                defaultValue={form.defaults.economyCyclingDescription}
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24 }}>

                    <Seperator
                        heading={"RMR Descriptions"}
                        bottom={"auto"}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Metabollic Rate Description"}
                                name={"metabollicDescription"}
                                defaultValue={form.defaults.metabollicDescription}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Fuel Burner Description"}
                                name={"fuelDescription"}
                                defaultValue={form.defaults.fuelDescription}
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24 }}>

                    <Seperator
                        heading={"Fluid/Salt Rate Descriptions"}
                        bottom={"auto"}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Fluid Loss Description"}
                                name={"fluidDescription"}
                                defaultValue={form.defaults.fluidDescription}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Sodium Loss Description"}
                                name={"sodiumDescription"}
                                defaultValue={form.defaults.sodiumDescription}
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24 }}>

                    <Seperator
                        heading={"Rehydration Descriptions"}
                        bottom={"auto"}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"loss Rate Description"}
                                name={"lossRateDescription"}
                                defaultValue={form.defaults.lossRateDescription}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Fluid Intake Description"}
                                name={"fluidIntakeDescription"}
                                defaultValue={form.defaults.fluidIntakeDescription}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Sodium Intake Description"}
                                name={"sodiumIntakeDescription"}
                                defaultValue={form.defaults.sodiumIntakeDescription}
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24 }}>

                    <Seperator
                        heading={"Dehydration Descriptions"}
                        bottom={"auto"}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Critical Dehydration Description"}
                                name={"criticalDescription"}
                                defaultValue={form.defaults.criticalDescription}
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24 }}>

                    <Seperator
                        heading={"Result Descriptions"}
                        bottom={"auto"}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Recommendation Summary"}
                                name={"recommendationDescription"}
                                defaultValue={form.defaults.recommendationDescription}
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24 }}>

                    <Seperator
                        heading={"Rating Descriptions"}
                        bottom={"auto"}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Performance Rating Description"}
                                name={"performanceRatingDescription"}
                                defaultValue={form.defaults.performanceRatingDescription}
                            />

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24 }}>

                    <Seperator
                        heading={"Sprint Descriptions"}
                        bottom={"auto"}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"HR Description"}
                                name={`sprintHrDescription`}
                                defaultValue={form.defaults.sprintHrDescription}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Muscle Oxygen (SMO2) Description"}
                                name={"sprintSmo2Description"}
                                defaultValue={form.defaults.sprintSmo2Description}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Total Hemoglobin (THb) Description"}
                                name={"sprintTbhDescription"}
                                defaultValue={form.defaults.sprintTbhDescription}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Peak Lactate Description"}
                                name={`sprintLactateDescription`}
                                defaultValue={form.defaults.sprintLactateDescription}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Performance Cycling Description"}
                                name={`sprintPerformanceCyclingDescription`}
                                defaultValue={form.defaults.sprintPerformanceCyclingDescription}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldTextArea
                                form={form}
                                label={"Performance Running Description"}
                                name={`sprintPerformanceRunningDescription`}
                                defaultValue={form.defaults.sprintPerformanceRunningDescription}
                            />

                        </Col>

                    </Row>

                </Col>

            </Row>

        </>

    ), [form.hash]);

}

export default SettingsLabTests;
