import React, { useMemo, useCallback } from 'react';
import { get } from '@dex/bubl-helpers';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import SessionsApi from '../SessionsApi';
import SessionsEditTabsInfo from './SessionsEditTabsInfo';
import SessionsEditTabsBio from './SessionsEditTabsBio';
import SessionsEditTabsInjury from './SessionsEditTabsInjury';
import SessionsEditTabsPosture from './SessionsEditTabsPosture';
import SessionsEditTabsMobility from './SessionsEditTabsMobility';
import SessionsEditTabsSymmetry from './SessionsEditTabsSymmetry';
import SessionsEditTabsStability from './SessionsEditTabsStability';
import SessionsEditTabsFoot from './SessionsEditTabsFoot';
import SessionsEditTabsCleat from './SessionsEditTabsCleat';
import SessionsEditTabsBike from './SessionsEditTabsBike';
import SessionsEditTabsExercises from './SessionsEditTabsExercises';
import SessionsEditTabsSummary from './SessionsEditSummary';
import SessionsEditTabsSizing from './SessionsEditTabsSizing';
import SessionsEditTabsToeTouch from './SessionsEditTabsToeTouch';
import { Space } from '@dex/bubl-dash';
import SessionsCopyData from './SessionsCopyData';
import EditableContext from '../../../context/EditableContext';
import SessionsEditTabsBioAerodynamic from './SessionsEditTabsBioAerodynamic';
import SessionsEditTabsBikeAerodynamic from './SessionsEditTabsBikeAerodynamic';
import SessionsEditTabsReport from './SessionsEditTabsReport';

const SessionsSingleEdit: React.FC<any> = (props: any) => {

    const app = useApp();

    const { data } = props;

    const tab = get(props, 'routeParams.tab');

    const form = useForm(data);

    const [nav] = app.store("nav");

    let action = tab;

    if (tab === "info" || !tab) action = "update"

    const update = SessionsApi.update(data.id, action, {
        onComplete: (data) => {

            app.alert('Session Saved', 'success');

            app.navigate(nav, 'update', {});
        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    const fitType = get(data, "fitType");

    let TabElement: any = React.Fragment;

    if (tab === "info" || tab === "general" || !tab) TabElement = SessionsEditTabsInfo;
    else if (tab === "bio" && fitType != "Aerodynamic") TabElement = SessionsEditTabsBio;
    else if (tab === "bio" && fitType == "Aerodynamic") TabElement = SessionsEditTabsBioAerodynamic;
    else if (tab === "injury") TabElement = SessionsEditTabsInjury;
    else if (tab === "posture") TabElement = SessionsEditTabsPosture;
    else if (tab === "mobility") TabElement = SessionsEditTabsMobility;
    else if (tab === "symmetry") TabElement = SessionsEditTabsSymmetry;
    else if (tab === "stability") TabElement = SessionsEditTabsStability;
    else if (tab === "foot") TabElement = SessionsEditTabsFoot;
    else if (tab === "cleat") TabElement = SessionsEditTabsCleat;
    else if (tab === "bike" && fitType != "Aerodynamic") TabElement = SessionsEditTabsBike;
    else if (tab === "bike" && fitType == "Aerodynamic") TabElement = SessionsEditTabsBikeAerodynamic;
    else if (tab === "sizing") TabElement = SessionsEditTabsSizing;
    else if (tab === "exercises") TabElement = SessionsEditTabsExercises;
    else if (tab === "summary") TabElement = SessionsEditTabsSummary;
    else if (tab === "toeTouch") TabElement = SessionsEditTabsToeTouch;
    else if (tab === "report" && fitType == "Aerodynamic") TabElement = SessionsEditTabsReport;

    const [settings] = app.store("appSettings");

    return useMemo(() => (

        <>
            {data && TabElement &&

                <EditableContext.Provider value={true}>

                    <TabElement
                        form={form}
                        data={data}
                        handleSubmit={handleSubmit}
                        process={update}
                        settings={settings}
                    />

                </EditableContext.Provider>

            }

        </>

    ), [data, form.hash, update]);

}

export default SessionsSingleEdit;
