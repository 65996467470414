import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldPhone from '@dex/bubl-dash/src/lib/components/Fields/FieldPhone';
import { loop, slug } from '@dex/bubl-helpers';
import { FieldAutocomplete, FieldParseFile, FieldSelect, FieldTextDate, InputTableRow, Space } from '@dex/bubl-dash';
import DataTable from '@dex/bubl-dash/src/lib/components/DataTable/DataTable';
import { InputTableCell } from '@dex/bubl-dash/src/lib/components/InputTable/InputTable';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import { utils, writeFile } from 'xlsx';
import TeamsApi from '../../Teams/TeamsApi';

const AthletesImportForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const [fileInfo, setFileInfo] = React.useState(null);

    const parseData = async (data, file, buffer) => {

        const rows: any = [];

        loop(data, (row, index) => {

            rows.push({
                firstName: row["first-name"],
                lastName: row["last-name"],
                shortName: row["short-name"],
                gender: row["gender"],
                type: row["athlete-type"],
                email: row["email"],
                phone: row["phone"],
                dob: row["dob"],
                mainSport: row["sport"],
                discipline: row["discipline"],
                position: row["position"],
                level: row["level"],
                teamId: row["team"],
                location: row["location"],
            });

        });

        setFileInfo(file);

        form.reset({ athletes: rows });
        form.setKey();

    };

    const columns = [
        {
            label: "First Name",
            key: "firstName",
        },
        {
            label: "Last Name",
            key: "lastName",
        },
        {
            label: "Short Name",
            key: "shortName",
        },
        {
            label: "Gender",
            key: "gender",
            style: { minWidth: 150 }
        },
        {
            label: "Athlete Type",
            key: "type",
            style: { minWidth: 180 }
        },
        {
            label: "Email",
            key: "email",
            style: { minWidth: 160 }
        },
        {
            label: "Phone",
            key: "phone",
            style: { minWidth: 200 }
        },
        {
            label: "DOB",
            key: "dob",
            style: { minWidth: 100 }
        },
        {
            label: "Sport",
            key: "mainSport",
            style: { minWidth: 100 }
        },
        {
            label: "Discipline",
            key: "discipline",
            style: { minWidth: 160 }
        },
        {
            label: "Position",
            key: "position",
            style: { minWidth: 160 }
        },
        {
            label: "Level",
            key: "level",
            style: { minWidth: 160 }
        },
        {
            label: "Location",
            key: "location",
            style: { minWidth: 100 }
        },

    ];

    const exportCsv = () => {

        const filteredColumns = columns.filter(item => item.key !== "teamId");

        const worksheet = utils.json_to_sheet([{}], { header: filteredColumns.map(item => item.label) });

        const workbook = utils.book_new();

        utils.book_append_sheet(workbook, worksheet, 'Sheet');

        writeFile(workbook, 'Athletes-Import.xlsx');

    };

    const team = TeamsApi.getTeam();

    const [teamId, setTeamId] = useState("");

    const handleChange = (e) => {

        setTeamId(e.value)

    }

    useEffect(() => {

        team.reset();

        if (!form.values.teamId) return;

        team.run({
            params: {
                id: teamId
            }
        })

    }, [form.values.teamId]);

    return useMemo(() => (

        <Panel
            heading={"Import Athletes"}
            actions={(
                <Button
                    label={"Create Blank File"}
                    size={"small"}
                    onClick={exportCsv}
                />
            )}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, lg: 12 }}>

                        <FieldParseFile
                            label={"Import File"}
                            required={true}
                            accept={".xlsx,.xls,.csv"}
                            onFileLoaded={parseData}
                            optsCSV={{
                                header: true,
                                dynamicTyping: false,
                                skipEmptyLines: true,
                                transformHeader: (text, index) => slug(text),
                            }}
                            optsXLS={{
                                toCSV: true,
                                singleSheet: true,
                            }}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRelationship
                            form={form}
                            name="teamId"
                            label="Team"
                            required={false}
                            defaultValue={form.defaults.teamId}
                            preload={true}
                            endpoint={'Teams'}
                            onChange={handleChange}
                        />

                    </Col>

                </Row>

                <Space />

                {form.values.athletes && form.values.athletes.length > 0 &&

                    <div style={{ overflow: "auto" }}>

                        <DataTable
                            data={null}
                            columns={columns}
                            className={"input-table"}
                            style={{ overflowY: "scroll" }}
                        >

                            {form.values.athletes.map((athlete, index) => (

                                <InputTableRow key={index}>

                                    <InputTableCell style={{ minWidth: "130px" }}>

                                        <FieldText
                                            form={form}
                                            name={`athletes[${index}].firstName`}
                                            defaultValue={athlete.firstName}
                                            required={true}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ minWidth: "130px" }}>

                                        <FieldText
                                            form={form}
                                            name={`athletes[${index}].lastName`}
                                            defaultValue={athlete.lastName}
                                            required={true}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ minWidth: "130px" }}>

                                        <FieldText
                                            form={form}
                                            name={`athletes[${index}].shortName`}
                                            defaultValue={athlete.shortName}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ minWidth: "130px" }}>

                                        <FieldSelect
                                            form={form}
                                            name={`athletes[${index}].gender`}
                                            defaultValue={athlete.gender}
                                            options={[
                                                { value: "male", label: "Male" },
                                                { value: "female", label: "Female" }
                                            ]}
                                        />
                                    </InputTableCell>

                                    <InputTableCell style={{ minWidth: "130px" }}>

                                        <FieldSelect
                                            form={form}
                                            name={`athletes[${index}].type`}
                                            defaultValue={athlete.type}
                                            options={[
                                                { value: "individual", label: "Individual" },
                                                { value: "team", label: "Team" },
                                                { value: "both", label: "Both" }
                                            ]}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ minWidth: "130px" }}>

                                        <FieldText
                                            form={form}
                                            name={`athletes[${index}].email`}
                                            defaultValue={athlete.email}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ minWidth: "130px" }}>

                                        <FieldPhone
                                            form={form}
                                            name={`athletes[${index}].phone`}
                                            defaultValue={athlete.phone}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ minWidth: "130px" }}>

                                        <FieldTextDate
                                            form={form}
                                            name={`athletes[${index}].dob`}
                                            defaultValue={athlete.dob}
                                            isDayBeforeMonth={true}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ minWidth: "130px" }}>

                                        <FieldAutocomplete
                                            form={form}
                                            name={`athletes[${index}].mainSport`}
                                            defaultValue={athlete.mainSport}
                                            group={"mainSport"}
                                            options={[
                                                { value: "Cycling", label: "Cycling" },
                                                { value: "Triathlon", label: "Triathlon" },
                                                { value: "Running", label: "Running" },
                                                { value: "Swimming", label: "Swimming" },
                                                { value: "Football", label: "Football" },
                                                { value: "Rugby", label: "Rugby" },
                                                { value: "Basketball", label: "Basketball" },
                                                { value: "Cricket", label: "Cricket" },
                                                { value: "Gymnastics", label: "Gymnastics" },
                                            ]}
                                            sort={false}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ minWidth: "130px" }}>

                                        <FieldAutocomplete
                                            key={athlete.mainSport}
                                            form={form}
                                            name={`athletes[${index}].discipline`}
                                            defaultValue={athlete.discipline}
                                            group={`discipline.${athlete.mainSport}`}
                                            preload={true}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ minWidth: "130px" }}>

                                        <FieldAutocomplete
                                            key={athlete.mainSport}
                                            form={form}
                                            name={`athletes[${index}].position`}
                                            defaultValue={athlete.position}
                                            group={`position.${athlete.mainSport}`}
                                            preload={true}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ minWidth: "130px" }}>

                                        <FieldSelect
                                            form={form}
                                            name={`athletes[${index}].level`}
                                            defaultValue={athlete.level}
                                            options={[
                                                { value: "national", label: "National" },
                                                { value: "state", label: "State" },
                                                { value: "junior", label: "Junior" },
                                                { value: "amateur", label: "Amateur" },
                                                { value: "club", label: "Club" },
                                                { value: "novice", label: "Novice" },
                                                { value: "age-grouper", label: "Age-Grouper" },
                                                { value: "recreational", label: "Recreational" },
                                                { value: "other", label: "Other" }
                                            ]}
                                        />

                                    </InputTableCell>

                                    <InputTableCell style={{ minWidth: "130px" }}>

                                        <FieldText
                                            form={form}
                                            name={`athletes[${index}].location`}
                                            defaultValue={athlete.location}
                                        />

                                    </InputTableCell>

                                </InputTableRow>

                            ))}

                            <Space height={300} />

                        </DataTable>

                    </div>

                }

                <Line />

                <ButtonRow>

                    {form.values.athletes && form.values.athletes.length > 0 &&
                        <Button
                            label={"Save"}
                            loading={process.loading}
                        />
                    }

                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />

                </ButtonRow>

            </Form>

        </Panel >


    ), [props]);

}

export default AthletesImportForm;
