import React from 'react';
import { ResponsiveContainer } from 'recharts';
import styles from './Chart.module.scss';

const Chart: React.FC<ChartProps> = (props) => {

    let { children, style } = props;

    return (

        <>
            <div className={styles.wrap} style={{ width: '100%', height: 300, paddingBottom: 30, ...style }}>

                <ResponsiveContainer debounce={1000}>

                    {/* @ts-ignore */}
                    {children}

                </ResponsiveContainer>

            </div>
        </>


    )

}

interface ChartProps {
    [key: string]: any,
}

export default Chart;