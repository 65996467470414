import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import React, { useMemo } from 'react';
import { FieldTextArea, Line, Seperator } from '@dex/bubl-dash';

const SettingsGeneral: React.FC<any> = (props: any) => {

    const { form } = props;

    return useMemo(() => (

        <Row gutter={8} edge={true}>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Bio Descriptions"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Body Composition"}
                            name={"bioDescription"}
                            defaultValue={form.defaults.bioDescription}
                        />

                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Summary Descriptions"}
                    bottom={"auto"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"VO2max Cycling Session Summary"}
                            name={"VO2maxCyclingSessionSummary"}
                            defaultValue={form.defaults.VO2maxCyclingSessionSummary}
                            rows={5}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"VO2max Cycling Going Forward"}
                            name={"VO2maxCyclingGoingForward"}
                            defaultValue={form.defaults.VO2maxCyclingGoingForward}
                            rows={5}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>
                        <Line />
                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"VO2max Running Session Summary"}
                            name={"VO2maxRunningSessionSummary"}
                            defaultValue={form.defaults.VO2maxRunningSessionSummary}
                            rows={5}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"VO2max Running Going Forward"}
                            name={"VO2maxRunningGoingForward"}
                            defaultValue={form.defaults.VO2maxRunningGoingForward}
                            rows={5}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>
                        <Line />
                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Met Cycling Session Summary"}
                            name={"MetCyclingSessionSummary"}
                            defaultValue={form.defaults.MetCyclingSessionSummary}
                            rows={5}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Met Cycling Going Forward"}
                            name={"MetCyclingGoingForward"}
                            defaultValue={form.defaults.MetCyclingGoingForward}
                            rows={5}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>
                        <Line />
                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Met Running Session Summary"}
                            name={"MetRunningSessionSummary"}
                            defaultValue={form.defaults.MetRunningSessionSummary}
                            rows={5}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Met Running Going Forward"}
                            name={"MetRunningGoingForward"}
                            defaultValue={form.defaults.MetRunningGoingForward}
                            rows={5}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>
                        <Line />
                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Sprint Cycling Session Summary"}
                            name={"sprintCyclingSessionSummary"}
                            defaultValue={form.defaults.sprintCyclingSessionSummary}
                            rows={5}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Sprint Cycling Going Forward"}
                            name={"sprintCyclingGoingForward"}
                            defaultValue={form.defaults.sprintCyclingGoingForward}
                            rows={5}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>
                        <Line />
                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Sprint Running Session Summary"}
                            name={"sprintRunningSessionSummary"}
                            defaultValue={form.defaults.sprintRunningSessionSummary}
                            rows={5}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Sprint Running Going Forward"}
                            name={"sprintRunningGoingForward"}
                            defaultValue={form.defaults.sprintRunningGoingForward}
                            rows={5}
                        />

                    </Col>

                </Row>

            </Col>

        </Row>

    ), [form.hash]);

}

export default SettingsGeneral;
