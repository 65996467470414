import React, { useMemo, useState } from 'react';
import IndexTable from '@dex/bubl-dash/src/lib/components/IndexTable/IndexTable';
import QuestionnairesSettings from '../Questionnaires/QuestionnairesSettings';

const DashboardQuestionnaires: React.FC<any> = (props: any) => {

    const { data } = props;

    const [tableState, setTableState] = useState({ search: undefined, where: undefined, order: undefined, skip: undefined });

    const indexFetchParams = (routeName, params, users) => {

        const filters = QuestionnairesSettings.indexFetchParams(routeName, params, users);

        filters.where.status = 'filled';

        return filters;

    }

    return useMemo(() => (

        <>

            <IndexTable
                title={"Filled Questionnaires"}
                getFetch={QuestionnairesSettings.indexFetch}
                getColumns={QuestionnairesSettings.indexColumns}
                preFetchFilters={indexFetchParams}
                getQuickSearch={QuestionnairesSettings.indexQuickSearch}
                getSearchFields={QuestionnairesSettings.indexSearchFields}
                routeParams={tableState}
                manageState={setTableState}
            />

        </>

    ), [data]);

}

export default DashboardQuestionnaires;
