import React, { useEffect, useMemo, useState } from 'react';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import { Col, FieldRadio, FieldSelect, Heading, InputTable, InputTableCell, InputTableRow, MediaImage, Row, Space } from '@dex/bubl-dash';
import { fixedNum, get, loop, lowerCase } from '@dex/bubl-helpers';
import styles from "./SessionsReport.module.scss";
import ChartProps from '../../../elements/Chart/Chart.Props';
import ChartPerformanceMetric from '../../../elements/ChartBikeAerodynamic/ChartPerformanceMetric';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import ChartPerformanceTimeTrial from '../../../elements/ChartBikeAerodynamic/ChartPerformanceTimeTrial';
import { calculateBikeSpeed, calculateCoefficientDrag, calculateDragForce, calculatePowerWithDrive, calculateRollingResistanceCoefficient, calculateRollingResistanceDrag, calculateTotalResistance, roadSurfaceOptions } from '../../../functions/sharedAerodynamicBike';

const SessionsReportPositions: React.FC<any> = (props: any) => {

    const { data, form, bike, index } = props;

    const bikeForm = get(form.values, `bikes[${index}]`);
    const prefix = `bikes[${index}]`;

    const [timeTrialDistance, setTimeTrialDistance] = useState("10");
    let timeTrialText: string = "";

    switch (timeTrialDistance) {
        case "10":
            timeTrialText = `• 10k TT’s are commonly ridden at 95%-105%% of FTP for Novice and between 105-115% for very fit Cyclists.`
            break;
        case "40":
            timeTrialText = `• 40k TT’s are commonly ridden at 80%-85% of FTP for Novice and between 87% to 102% for very fit Cyclists.`
            break;
        case "90":
            timeTrialText = `• 90k TT’s are commonly ridden at 75%-80% of FTP for Novice and between 79% to 84% for very fit Cyclists.`
            break;
        case "180":
            timeTrialText = `• 180k TT’s are commonly ridden at 65%-70% of FTP for Novice and between 75% to 80% for very fit Cyclists. `
            break;
        default:
            timeTrialText = `• 10k TT’s are commonly ridden at 95%-105%% of FTP for Novice and between 105-115% for very fit Cyclists.`
            break;
    }

    const [dragCoefficient, setDragCoefficient] = useState<number[]>([]);
    const [dragForce, setDragForce] = useState<number[]>([]);
    const [totalDragResistance, setTotalDragResistance] = useState<number[]>([]);
    const [roadSurfaceRating, setRoadSurfaceRating] = useState("");
    const [powerDrive, setPowerDrive] = useState<number[]>([]);
    const [bikeSpeed, setBikeSpeed] = useState<number[]>([]);

    useEffect(() => {

        let output;
        output = roadSurfaceOptions.find(x => x.label === bikeForm.parameter?.roadSurface)?.rating;
        output = lowerCase(output);
        setRoadSurfaceRating(output);

    }, [bikeForm.parameter?.roadSurface]);

    useEffect(() => {

        const newDragCoefficients: number[] = [];
        const newDragForces: number[] = [];
        const newTotalDragResistances: number[] = [];
        const newPowerDrive: number[] = [];
        const newBikeSpeed: number[] = [];

        const gravity = bikeForm.parameter?.forceGravity;
        const airDensity = bikeForm.parameter?.airDensity;

        loop(bikeForm.positions, (position, index) => {
            const rollingResistanceCoefficient = calculateRollingResistanceCoefficient(roadSurfaceRating, bikeForm.parameter?.roadTypeCrr, bikeForm.parameter?.roadSurfaceCrr, bikeForm.parameter?.pressureCrr, bikeForm.parameter?.qualityCrr, bikeForm.parameter?.widthCrr)

            const rollingResistanceDrag = calculateRollingResistanceDrag(bikeForm.parameter?.percentGrade, data.bio?.weight, bikeForm.parameter?.bikeWeight, rollingResistanceCoefficient)

            const coefficientDrag = calculateCoefficientDrag(position.ridingPositionCd, bikeForm.parameter?.helmetCd, bikeForm.parameter?.clothingCd, bikeForm.parameter?.bikeTypeCd);
            const forceDrag = calculateDragForce(bikeForm.parameter?.bikeSpeed, bikeForm.parameter?.headwind, airDensity, position.FSA, coefficientDrag);
            const totalResistance = calculateTotalResistance([gravity, rollingResistanceDrag, forceDrag]);

            newDragCoefficients.push(coefficientDrag);
            newDragForces.push(forceDrag);
            newTotalDragResistances.push(totalResistance);

            const powerDrive = calculatePowerWithDrive(bikeForm.parameter?.bikeSpeed, bikeForm.parameter?.headwind, totalDragResistance[index], bikeForm.parameter?.driveChangePowerLoss);
            const bikeSpeed = calculateBikeSpeed(bikeForm.parameter?.power, (position?.FSA * dragCoefficient[index]), airDensity, bikeForm.parameter?.headwind, bikeForm.parameter?.bikeAndRiderWeight, bikeForm.parameter?.driveChangePowerLoss, bikeForm.parameter?.percentGrade, rollingResistanceCoefficient);

            const isPowerTarget = bikeForm.parameter?.useTarget === "power";

            const updatedPower = isPowerTarget ? bikeForm.parameter?.power : powerDrive;
            const updatedSpeed = isPowerTarget ? bikeSpeed : bikeForm.parameter?.bikeSpeed;

            newPowerDrive.push(updatedPower);
            newBikeSpeed.push(updatedSpeed);

            form.handleChange({ reset: true, name: `${prefix}.positions[${index}].power`, value: updatedPower });
            form.handleChange({ reset: true, name: `${prefix}.positions[${index}].speed`, value: updatedSpeed });

        })

        setDragCoefficient(newDragCoefficients);
        setDragForce(newDragForces);
        setTotalDragResistance(newTotalDragResistances);

        setPowerDrive(newPowerDrive);
        setBikeSpeed(newBikeSpeed);

    }, [form.hash]);

    useEffect(() => {
        loop(bikeForm.positions, (position, index) => {
            const output = position.FSA * dragCoefficient[index];
            form.handleChange({ reset: true, name: `${prefix}.positions[${index}].cdA`, value: fixedNum(output, 3) });
        })
    }, [dragCoefficient]);

    const chartOptions = [
        {
            name: "10",
            label: "10km",
        },
        {
            name: "40",
            label: "40km",
        },
        {
            name: "90",
            label: "90km",
        },
        {
            name: "180",
            label: "180km",
        },
    ];

    return useMemo(() => (

        <Row gutter={8} edge>

            <InputTable>

                <InputTableRow>

                    {bikeForm.positions?.length >= 0 && bikeForm.positions?.map((position: any, index: number) => {

                        return (
                            <React.Fragment key={index}>

                                <InputTableCell align="center" className={`${index >= 1 ? "table-bdr-left-gray" : ""} table-bdr-top-none table-bdr-btm-none`}>

                                    <Heading space={null}>Position {index + 1}</Heading>
                                    <Heading space={"small"} className={styles["caption-label"]}>{position.photoCaption ? position?.photoCaption : <>&nbsp;</>}</Heading>

                                    <MediaImage
                                        id={position.photo?.fileId}
                                        filters={{ width: 150, height: 225, resizeMode: "contain", fallback: { background: "#272D60", text: position.photoCaption } }}
                                        className={styles.positionImage}
                                        imgProps={{
                                            crossOrigin: "anonymous"
                                        }}
                                    />

                                    <Space />

                                    <Row gutter={8} edge>

                                        <PositionTable
                                            leftText="Cycling FSA"
                                            rightText={`${position.FSA} m²`}
                                        />

                                        <PositionTable
                                            leftText="% Standing FSA"
                                            rightText={`${position.percentFSA} %`}
                                        />

                                        <PositionTable
                                            leftText="Aero Drag"
                                            rightText={`${position.cdA}`}
                                            className={styles.aero}
                                        />

                                        <PositionTable
                                            leftText="Power"
                                            rightText={`${position.power} W`}
                                            className={styles.power}
                                        />

                                        <PositionTable
                                            leftText="Speed"
                                            rightText={`${position.speed} kph`}
                                            className={styles.speed}
                                        />

                                    </Row>

                                </InputTableCell>

                            </React.Fragment>
                        )

                    })}

                </InputTableRow>

            </InputTable >

            <Col col={{ xs: 19 }}>

                <Space />

                <PanelChart
                    key={form.hash}
                    heading={"Performance Metrics By Position"}
                    data={bikeForm.positions}
                    renderChart={(chartType, chartData) => {

                        return (
                            <ChartPerformanceMetric
                                data={chartData}
                                type={bikeForm.performance?.metricChartType}
                                metricCompareTo={bikeForm.performance?.metricCompareTo}
                                FTPPower={data.bio.ftpW}
                            />
                        )
                    }
                    }
                />

            </Col>

            <Col col={{ xs: 5 }}>

                <FieldRadio
                    form={form}
                    name={`${prefix}.performance.metricChartType`}
                    defaultValue={bikeForm.performance?.metricChartType}
                    list
                    label='Metric'
                    key={bikeForm.performance?.metricChartType}
                    options={[
                        {
                            label: "Power",
                            value: "Power",
                            color: ChartProps.performanceMetricColor("Power")
                        },
                        {
                            label: "Speed",
                            value: "Speed",
                            color: ChartProps.performanceMetricColor("Speed")
                        },
                        {
                            label: "CdA",
                            value: "CdA",
                            color: ChartProps.performanceMetricColor("CdA")
                        },
                        {
                            label: "W Diff",
                            value: "W Diff",
                            color: ChartProps.performanceMetricColor("W Diff")
                        },
                        {
                            label: "% FTP",
                            value: "% FTP",
                            color: ChartProps.performanceMetricColor("% FTP")
                        },
                    ]}
                />

                {bikeForm.performance?.metricChartType == "W Diff" &&
                    <FieldSelect
                        form={form}
                        name={`${prefix}.performance.metricCompareTo`}
                        defaultValue={bikeForm.performance?.metricCompareTo}
                        label='Compare To'
                        options={bike.positions.map((item, index) => ({ label: "Position " + (index + 1), value: (index + 1) })) || []}
                        wrapClass="manual-input"
                        key={bikeForm.performance?.metricCompareTo}
                    />
                }

                <ReportDesc
                    value={bike.summary?.performanceChart}
                />

            </Col>

            <Col col={{ xs: 19 }}>

                <PanelChart
                    key={form.hash}
                    heading={"Time Trial Performance By Position"}
                    data={bikeForm.positions}
                    types={chartOptions}
                    renderChart={(chartType, chartData) => {
                        return (
                            <ChartPerformanceTimeTrial
                                type={chartType}
                                compareTo={bikeForm.performance?.timeTrialCompareTo}
                                parameter={bikeForm.performance?.parameter}
                                data={bikeForm.positions}
                                FTPPower={data.bio.ftpW}
                                setTimeTrialDistance={setTimeTrialDistance}
                            />
                        )
                    }
                    }
                />

                <p>Differences in Time and % are given at bottom of columns</p>

            </Col>

            <Col col={{ xs: 5 }}>

                <FieldRadio
                    form={form}
                    name={`${prefix}.performance.parameter`}
                    defaultValue={bikeForm.performance?.parameter}
                    list
                    key={bikeForm.performance?.parameter}
                    options={[
                        {
                            label: "Total Time",
                            value: "Total Time",
                            color: ChartProps.indexColor(4)
                        },
                        {
                            label: "Avg Speed",
                            value: "Avg Speed",
                            color: ChartProps.indexColor(1)
                        },
                        {
                            label: "Avg Power",
                            value: "Avg Power",
                            color: ChartProps.indexColor(0)
                        }
                    ]}
                />

                <FieldSelect
                    form={form}
                    name={`${prefix}.performance.timeTrialCompareTo`}
                    defaultValue={bikeForm.performance?.timeTrialCompareTo}
                    label='Compare To'
                    options={bikeForm?.positions?.map((item, index) => ({ label: "Position " + (index + 1), value: (index + 1) })) || []}
                    wrapClass="manual-input"
                />

                <p>{timeTrialText}</p>

                <Space />

                <ReportDesc
                    value={bike.summary?.timeTrialChart}
                />


            </Col>

        </Row>
    ), [props, form.hash, bikeForm, timeTrialText])
}

const PositionTable: React.FC<any> = (props: any) => {

    const { leftText, rightText, className } = props;

    return (
        <>
            <Col col={{ xs: 11 }} align='end'>
                <p className={[styles["text-label"], className].join(" ")}>
                    {leftText} :
                </p>
            </Col>
            <Col col={{ xs: 2 }} />
            <Col col={{ xs: 11 }} align='start'>
                <p className={[className].join(" ")}>
                    {rightText}
                </p>
            </Col>
        </>
    )
}
export default SessionsReportPositions;