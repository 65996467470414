import React, { useEffect, useMemo } from 'react';
import { DataTable, FieldSelect, InputTableCell, InputTableRow, useApp } from '@dex/bubl-dash';
import AthletesApi from '../AthletesApi';
import moment from 'moment';
import { get, hash, titleCase } from '@dex/bubl-helpers';
import AthletesTrackingRows from '../AthletesTrackingRows';
import { bikeLabel } from '../../../functions/sharedBikeFit';

const AthletesSingleTrackingTable: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const app = useApp();

    const sessions = AthletesApi.getTrackingSessionsData(data.id);

    const key = form.values.sessions.join("");

    useEffect(() => {

        sessions.run({
            params: {
                sessionIds: form.values.sessions
            }
        });

    }, [key]);

    const columns = useMemo(() => {

        let columns: any = [
            {
                label: titleCase(form.values.dataSet),
                key: "dataPoint"
            }
        ];

        (sessions.data || []).map((session: any) => {

            columns.push({
                label: moment(session.date).format("D MMM YYYY"),
                desc: session.title,
                key: session.id,
            })

        });

        return columns;

    }, [form.values.dataSet, sessions.data]);

    const rows = useMemo(() => {

        let rows = AthletesTrackingRows(form.values.dataType, form.values.dataSet, form);

        return rows;


    }, [form.values.dataType, form.values.dataSet]);

    return useMemo(() => (

        <>

            {sessions.data && sessions.data.length >= 1 &&
                <>

                    <DataTable
                        key={key}
                        data={null}
                        columns={columns}
                    >

                        {form.values.dataSet === "cleat" &&
                            <AthletesSingleTrackingTableSelectRow
                                form={form}
                                name={"cleat"}
                                sessions={sessions.data}
                                label={"Cleat"}
                                generateOptions={(session) => {

                                    return (session.cleats || []).map((cleat: any, index) => {
                                        return {
                                            label: "Cleat " + (index + 1) + (cleat.shoeBrand ? ` - ${cleat.shoeBrand}` : "") + (cleat.shoeSize ? ` Size ${cleat.shoeSize}` : ""),
                                            value: index
                                        }
                                    })

                                }}
                            />
                        }

                        {(form.values.dataSet === "bike" || form.values.dataSet === "bikeSizing") &&
                            <AthletesSingleTrackingTableSelectRow
                                form={form}
                                name={"bike"}
                                sessions={sessions.data}
                                label={"Bike"}
                                generateOptions={(session) => {

                                    return (session.bikes || []).map((bike: any, index) => {
                                        return {
                                            label: "Bike " + (index + 1) + " - " + bikeLabel(bike),
                                            value: index
                                        }
                                    })

                                }}
                            />
                        }

                        {rows.map((row, index) => (

                            <InputTableRow key={index}>

                                <InputTableCell style={{ width: 300 }}>
                                    {row.label}
                                </InputTableCell>

                                {(sessions.data || []).map((session: any, index) => {

                                    const cleatIndex = get(form.values, `cleat[${session.id}]`, 0);
                                    session.cleat = get(session, `cleats[${cleatIndex}]`, {});

                                    const bikeIndex = get(form.values, `bike[${session.id}]`, 0);
                                    session.bike = get(session, `bikes[${bikeIndex}]`, {});

                                    const value = app.columnValue(row, session);

                                    return (
                                        <InputTableCell key={session.id + index} style={{ width: 150, maxWidth: 200 }}>
                                            {value || row.default || "-"}
                                        </InputTableCell>
                                    )

                                })}

                            </InputTableRow>

                        ))}

                    </DataTable>

                </>
            }

        </>

    ), [data, form.hash, rows, columns]);

}

const AthletesSingleTrackingTableSelectRow: React.FC<any> = (props: any) => {

    const { sessions, form, name, label, generateOptions } = props;

    return (
        <InputTableRow>

            <InputTableCell style={{ width: 300 }}>
                {label}
            </InputTableCell>

            {(sessions || []).map((session: any, index) => (
                <InputTableCell key={session.id + index} style={{ overflow: "visible" }}>
                    <FieldSelect
                        key={label}
                        form={form}
                        name={`${name}[${session.id}]`}
                        defaultValue={"0"}
                        options={generateOptions(session)}
                    />
                </InputTableCell>
            ))}

        </InputTableRow>
    )

};

export default AthletesSingleTrackingTable;
