import { apiGet, baseApi, baseApiReturn, options, response } from "@dex/bubl-fetch";

const endpoint = 'Sessions';

const SessionsApi: baseApiReturn = baseApi(endpoint);

SessionsApi.getReport = (id: string, options?: options): response => {

    return apiGet({
        url: endpoint + "/public/" + id + "/getReport",
        params: {},
        ...options
    });

}

export default SessionsApi;