import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import React, { useMemo } from 'react';
import { FieldTextArea, Seperator } from '@dex/bubl-dash';

const SettingsGait: React.FC<any> = (props: any) => {

    const { form } = props;

    return useMemo(() => (

        <Row gutter={8} edge={true}>


            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Injury"}
                    bottom={"auto"}
                />
            </Col>


            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Injury Comment"}
                    name={"injuryComment"}
                    defaultValue={form.defaults.injuryComment}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Posture"}
                    bottom={"auto"}
                />
            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Posture Comment"}
                    name={"postureComment"}
                    defaultValue={form.defaults.postureComment}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Mobility"}
                    bottom={"auto"}
                />
            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Mobility Comment"}
                    name={"mobilityComment"}
                    defaultValue={form.defaults.mobilityComment}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Symmetry"}
                    bottom={"auto"}
                />
            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Symmetry Comment"}
                    name={"symmetryComment"}
                    defaultValue={form.defaults.symmetryComment}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Stability"}
                    bottom={"auto"}
                />
            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Stability Comment"}
                    name={"stabilityComment"}
                    defaultValue={form.defaults.stabilityComment}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Foot"}
                    bottom={"auto"}
                />
            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Foot Comment"}
                    name={"footComment"}
                    defaultValue={form.defaults.footComment}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Shoe"}
                    bottom={"auto"}
                />
            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Shoe Comment"}
                    name={"shoeComment"}
                    defaultValue={form.defaults.shoeComment}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Run"}
                    bottom={"auto"}
                />
            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Metric Comment"}
                    name={"runMetricComment"}
                    defaultValue={form.defaults.runMetricComment}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Foot Inclination Comment"}
                    name={"footInclination"}
                    defaultValue={form.defaults.footInclination}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Leg Inclination Comment"}
                    name={"legInclination"}
                    defaultValue={form.defaults.legInclination}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Foot Strike Audio Comment"}
                    name={"footStrikeAudio"}
                    defaultValue={form.defaults.footStrikeAudio}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Knee Angle Comment"}
                    name={"kneeAngle"}
                    defaultValue={form.defaults.kneeAngle}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Arm Swing Style Comment"}
                    name={"armSwingStyle"}
                    defaultValue={form.defaults.armSwingStyle}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Torso Lean Comment"}
                    name={"torsoLean"}
                    defaultValue={form.defaults.torsoLean}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Pelvic Tilt Comment"}
                    name={"pelvicTilt"}
                    defaultValue={form.defaults.pelvicTilt}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Heel Strike Orientation Comment"}
                    name={"heelStrikeOrientation"}
                    defaultValue={form.defaults.heelStrikeOrientation}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Foot Pronation Rate Comment"}
                    name={"footPronationRate"}
                    defaultValue={form.defaults.footPronationRate}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Foot Direction Comment"}
                    name={"footDirection"}
                    defaultValue={form.defaults.footDirection}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Leg Angle Comment"}
                    name={"legAngle"}
                    defaultValue={form.defaults.legAngle}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Knee Shifting Comment"}
                    name={"kneeShifting"}
                    defaultValue={form.defaults.kneeShifting}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Pelvic Drop Comment"}
                    name={"pelvicDrop"}
                    defaultValue={form.defaults.pelvicDrop}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Torso Rotation Comment"}
                    name={"torsoRotation"}
                    defaultValue={form.defaults.torsoRotation}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Elbow Position Comment"}
                    name={"elbowPosition"}
                    defaultValue={form.defaults.elbowPosition}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Toe Off Area Comment"}
                    name={"toeOffArea"}
                    defaultValue={form.defaults.toeOffArea}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Heel Whip ( Swing Mid- Stance) Comment"}
                    name={"heelWhip"}
                    defaultValue={form.defaults.heelWhip}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Leg Cross Comment"}
                    name={"legCross"}
                    defaultValue={form.defaults.legCross}
                    rows={5}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <Seperator
                    heading={"Summary"}
                    bottom={"auto"}
                />
            </Col>

            <Col col={{ xs: 24 }}>

                <FieldTextArea
                    form={form}
                    label={"Summary Comment"}
                    name={"summary"}
                    defaultValue={form.defaults.summary}
                    rows={5}
                />

            </Col>

        </Row>

    ), [form.hash]);

}

export default SettingsGait;
