import React from 'react';
import { LineChart } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import * as _ from 'lodash';
import ChartAxis from '../Chart/ChartAxis';
import { slug } from '@dex/bubl-helpers';

const ChartPerfEconomy: React.FC<ChartLactateProps> = (props) => {

    let { data, activity, type, date, index, intensity } = props;

    const Axis = new ChartAxis();

    Axis.setXByActivity(activity, intensity);

    const historyData = Axis.prepareHistoryData(data, slug(date), slug(index));

    Axis.x.props = {
        ...Axis.x.props,
        ...props.xAxis,
    };

    Axis.y.push({
        axis: true,
        line: true,
        pos: "left",
        index: 0,
        key: "calc_economy_" + slug(date),
        axisLabel: activity === "Running" ? "Economy - (ml/kg/min)/(min/s)" : "Economy - %",
        label: "Economy - " + date,
        props: {
            domain: activity === "Running" ? [8, 16] : [15, 25],
            ticks: activity === "Running" ? null : [15, 17.5, 20, 22.5, 25],
            connectNulls: true,
            tickFormatter: (val) => val + "%",
            suffix: "%",
        }
    });

    if (date !== index) {

        Axis.y.push({
            line: true,
            pos: "left",
            index: 1,
            key: "calc_economy_" + slug(index),
            label: "Economy - " + index,
            props: {
                strokeDasharray: "6 4",
                connectNulls: true,
                suffix: "%",
                labelPosition: "bottom"
            }
        });

    }

    return (

        <Chart>

            <LineChart
                {...ChartProps.LineChart()}
                data={historyData}
            >

                {Axis.render()}

            </LineChart>

        </Chart>


    )

}

interface ChartLactateProps {
    [key: string]: any,
}

export default ChartPerfEconomy;