import React, { useMemo } from 'react';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import moment from 'moment';

const AthletesSingleViewGeneral: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <MetaTable
            data={data}
            columnA={[
                {
                    label: "First Name",
                    key: "firstName",
                    format: "titleCase",
                },
                {
                    label: "Last Name",
                    key: "lastName",
                    format: "titleCase",
                },
                {
                    label: "Short Name",
                    key: "shortName",
                    format: "titleCase",
                },
                {
                    label: "Gender",
                    key: "gender",
                    format: "titleCase",
                },
                {
                    label: "Location",
                    key: "location",
                    format: "titleCase",
                },
                {
                    label: "Photo",
                    key: "photo",
                    format: "image",
                    link: true,
                    hideEmpty: true,
                    filters: {
                        width: 100,
                        height: 100
                    }
                },
            ]}
            columnB={[
                {
                    label: "Phone",
                    key: "phone",
                },
                {
                    label: "Email",
                    key: "email",
                },
                {
                    label: "Date of Birth",
                    key: "dob",
                    format: 'dateDay',
                },
                {
                    label: "Age",
                    key: "age",
                },
                {
                    label: "Referred By",
                    key: "referredBy",
                    format: "titleCase",
                },
            ]}
            columnC={[
                {
                    label: "Main Sport",
                    key: "mainSport",
                    format: "titleCase",
                },
                {
                    label: "Discipline",
                    key: "discipline",
                    format: "titleCase",
                },
                {
                    label: "Level",
                    key: "level",
                    format: "titleCase",
                },
                {
                    label: "Position",
                    key: "position",
                    format: "titleCase",
                },
                {
                    label: "Other Sport",
                    key: "otherSports",
                    format: "titleCase",
                },
            ]}
        />


    ), [props]);

}

export default AthletesSingleViewGeneral;
