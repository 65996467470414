import { Box, Col, DataTable, DataTableCell, InputTable, InputTableHeadCell, InputTableHeadRow, InputTableRow, Line, Row, Space, WhiteSpace, index } from '@dex/bubl-dash';
import React from 'react';
import ReportMetaTable from '../../../elements/ReportMetaTable/ReportMetaTable';
import { date, loop } from '@dex/bubl-helpers';
import ReportHeadingNameDate from '../../../elements/ReportHeadingNameDate/ReportHeadingNameDate';
import { coachingGetLastWeek, getStars } from '../../../functions/sharedCoaching';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import * as _ from 'lodash';

import styles from "./CoachingReportLongTermPlan.module.scss";
import ReportCheck from '../../../elements/ReportCheck/ReportCheck';

const CoachingReportLongTermPlan: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const { targetWeek } = coachingGetLastWeek(data, true);

    const plan = _.isEmpty(data?.week) ? data?.plans : data?.week?.plan;

    const selectedPlans = data?.plans?.filter(plan => data.reportPlans.includes(plan.id));

    return (

        <Row edge gutter={"auto"}>


            <Col col={{ xs: 24 }} key={plan?.id}>

                <ReportDesc
                    className={"no-box"}
                    value={"This is an outline of your overall training plan designed to help you achieve your goals. Your events, test sessions and training load levels are shown as well as where you are currently in your long-term plan."}
                />

                {(selectedPlans || []).map((plan: any, i: number) => (

                    <>

                        <Space />

                        <Row gutter={"auto"} edge>

                            <Col col={{ xs: 20 }} className="table-bg-blue">

                                <ReportMetaTable
                                    data={plan}
                                    columnSize={8}
                                    columnA={[
                                        {
                                            label: `Plan Date`,
                                            key: "plan",
                                            render: (value, row) => {
                                                return (
                                                    <>{date(row?.startDate, "YYYY MMM")} to {date(row?.endDate, "YYYY MMM")}</>
                                                )
                                            }
                                        }
                                    ]}
                                    columnB={[
                                        { label: "Total Weeks", key: "totalWeek" },
                                    ]}
                                    columnC={[
                                        { label: "Planned Weeks", key: "weekPlanned" },
                                    ]}
                                />
                            </Col>

                            <Col col={{ xs: 4 }} align='end' justify='end' className="table-bg-blue">

                                <div className={styles.legend}>
                                    <span className={styles.currentWeekDot}></span>
                                    <span className={styles.label}>Current Week</span>
                                </div>

                            </Col>

                        </Row>

                        {plan?.blocks?.map((block: any, b: number) => (
                            <>

                                <Space />

                                <Box className={styles.block} gutter={"auto"} >

                                    <ReportHeadingNameDate
                                        name={`Block ${b + 1}`}
                                        desc={`(${block.totalWeeks} Weeks)`}
                                    />

                                    <ReportMetaTable
                                        data={block}
                                        columnSize={8}
                                        columnA={[
                                            { label: "Training Phase", key: "phase" },
                                        ]}
                                        columnB={[
                                            { label: "Training Block", key: "block" },
                                        ]}
                                        columnC={[
                                            { label: "Focus", key: "focus" },
                                        ]}
                                    />

                                    <Space />

                                    <Box edge={{ left: true, right: true }} corner={"auto"}>

                                        <DataTable
                                            className="mini-table"
                                            head={(
                                                <>
                                                    <InputTableHeadRow className="table-bg-gray">

                                                        <InputTableHeadCell colSpan={8} className={"table-bdr-btm-none"} />

                                                        <InputTableHeadCell colSpan={4} alignMiddle>
                                                            Training Load
                                                        </InputTableHeadCell>
                                                    </InputTableHeadRow>
                                                    <InputTableHeadRow className="table-bg-gray">

                                                        <InputTableHeadCell alignMiddle desc={"Calendar"} />
                                                        <InputTableHeadCell alignMiddle desc={"Week"} />

                                                        <InputTableHeadCell desc={"From"} />
                                                        <InputTableHeadCell desc={"To"} />
                                                        <InputTableHeadCell desc={"Event"} />
                                                        <InputTableHeadCell desc={"Rating"} />

                                                        <InputTableHeadCell alignMiddle desc={"Race"} />
                                                        <InputTableHeadCell alignMiddle desc={"Test"} />
                                                        <InputTableHeadCell alignMiddle desc={"Up"} />
                                                        <InputTableHeadCell alignMiddle desc={"Down"} />
                                                        <InputTableHeadCell alignMiddle desc={"Hold"} />
                                                        <InputTableHeadCell alignMiddle desc={"Recovery"} />

                                                    </InputTableHeadRow>
                                                </>
                                            )}
                                        >

                                            {block && block.weeks &&
                                                block.weeks.map((week: any, w: number) => (

                                                    <InputTableRow
                                                        key={form.key + "-" + index}
                                                        className={week.startDate == targetWeek.startDate ? styles.currentWeek : ""}
                                                    >

                                                        <DataTableCell style={{ width: 10 }} alignMiddle>

                                                            {week.startDate == targetWeek.startDate ? <span className={styles.currentWeekDot}></span> : ""}

                                                            {week.calendar}

                                                        </DataTableCell>

                                                        <DataTableCell style={{ width: 10 }} alignMiddle>

                                                            {week.week}

                                                        </DataTableCell>

                                                        <DataTableCell>

                                                            {date(week.startDate)}

                                                        </DataTableCell>

                                                        <DataTableCell>

                                                            {date(week.endDate)}

                                                        </DataTableCell>

                                                        <DataTableCell>

                                                            {week.event}

                                                        </DataTableCell>

                                                        <DataTableCell>

                                                            {getStars(week.rating)}

                                                        </DataTableCell>

                                                        <DataTableCell alignMiddle>
                                                            <ReportCheck checked={week.race} />
                                                        </DataTableCell>

                                                        <DataTableCell alignMiddle>
                                                            <ReportCheck checked={week.test} />
                                                        </DataTableCell>

                                                        <DataTableCell alignMiddle>
                                                            <ReportCheck checked={week.trainingStimulus === "up"} />
                                                        </DataTableCell>

                                                        <DataTableCell alignMiddle>
                                                            <ReportCheck checked={week.trainingStimulus === "down"} />
                                                        </DataTableCell>

                                                        <DataTableCell alignMiddle>
                                                            <ReportCheck checked={week.trainingStimulus === "hold"} />
                                                        </DataTableCell>

                                                        <DataTableCell alignMiddle>
                                                            <ReportCheck checked={week.trainingStimulus === "recovery"} />
                                                        </DataTableCell>

                                                    </InputTableRow>

                                                ))}

                                        </DataTable>

                                    </Box>

                                </Box>

                            </>

                        ))}

                    </>

                ))}

            </Col>


        </Row>

    );

}

export default CoachingReportLongTermPlan;