import { Row, Col, Line, FieldTextArea, Heading, Space } from "@dex/bubl-dash";
import React from "react";
import EventTargetCard from "../../../elements/EventTargetCard/EventTargetCard";
import ReportDesc from "../../../elements/ReportDesc/ReportDesc";
import ReportHeading from "../../../elements/ReportHeading/ReportHeading";
import FancyValue from "../../../elements/FancyValue/FancyValue";
import styles from "./TestSessionsReportRaceReadyResult.module.scss";
import { titleCase, upperCase } from "@dex/bubl-helpers";
import ReportHeadingNameDate from "../../../elements/ReportHeadingNameDate/ReportHeadingNameDate";

const TestSessionsReportRaceReadyPerformance: React.FC<any> = (props: any) => {

    const { form, data } = props;

    const activity = data.test.activity;

    return (

        <>

            <ReportHeadingNameDate
                name={data.athlete.fullName}
                date={data.date}
            />

            <Row gutter={"auto"} edge>

                <Col col={{ xs: 24 }}>

                    <Row edge gutter={"auto"} justify="center">

                        <Col col={{ xs: 24 }}>
                            <ReportHeading
                                text="Performance Rating"
                            />
                        </Col>

                        {data.test.rating.description &&
                            <Col col={{ xs: 24, md: 12 }} align="center">

                                <ReportDesc
                                    value={data.test.rating.description}
                                />

                            </Col>
                        }

                        <Col col={{ xs: 24, md: 12 }} align="center">

                            <Heading space="0" >Your Rating Category</Heading>

                            <p className={styles.level}>{titleCase(data.test?.manual?.level)}</p>

                        </Col>

                    </Row>

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <EventTargetCard
                        metric={"%FTP"}
                        gender={data.athlete.gender}
                        level={form.values?.manual?.level}
                        activity={activity}
                        eventType={form.values?.manual?.type}
                        values={form.values?.result?.ftpCurrent || []}
                        altUnits={{
                            values: form.values?.result?.sum?.ftp || [],
                            suffix: activity == "Running" ? "kph" : "w",
                            sep: "/",
                            position: "before",
                        }}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <EventTargetCard
                        metric={"%HRmax"}
                        gender={data.athlete.gender}
                        level={form.values?.manual?.level}
                        activity={activity}
                        eventType={form.values?.manual?.type}
                        values={form.values?.result?.vo2maxHrCurrent || []}
                        altUnits={{
                            values: form.values?.result?.sum?.hr || [],
                            suffix: "bpm",
                            sep: "/",
                            position: "before",
                        }}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <EventTargetCard
                        metric={"%VO2max"}
                        gender={data.athlete.gender}
                        level={form.values?.manual?.level}
                        activity={activity}
                        eventType={form.values?.manual?.type}
                        values={form.values?.result?.vo2maxVo2Current || []}
                        altUnits={{
                            values: form.values?.result?.sum?.vo2relative || [],
                            suffix: "ml/kg*min",
                            sep: "/",
                            position: "before",
                        }}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <EventTargetCard
                        metric={"Lactate"}
                        gender={data.athlete.gender}
                        level={form.values?.manual?.level}
                        activity={activity}
                        eventType={form.values?.manual?.type}
                        values={form.values?.result?.sum?.lactate || []}
                    />

                </Col>

                <Col col={{ xs: 24 }}>

                    <ReportHeading
                        text="Recommendations"
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <RecommendationTable
                        data={data?.test?.result?.recommendations}
                        activity={activity}
                    />

                </Col>

                <Col col={{ xs: 24, md: 12 }}>

                    <ReportDesc
                        label="Based on today's test results,"
                        value={data.test.result.summary}
                    />

                </Col>

            </Row>

        </>

    )
}

const RecommendationTable: React.FC<any> = (props: any) => {

    const { data, activity } = props;

    const styles = { display: "flex", justifyContent: "center", color: "gray" };

    return (
        <Row edge gutter={8}>

            <Col col={{ xs: 12 }} align="center" justify="center">

                <div style={styles}>

                    <img
                        src={activity == "Running" ? require("../../../assets/images/LabReport/icon-running.png") : require("../../../assets/images/LabReport/icon-cycling.png")}
                        style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                        alt={`${activity}.png`}
                    />

                </div>

                {activity == "Cycling" ?
                    <>
                        <strong style={styles}>Avg Power</strong>

                        <FancyValue
                            value={`${data?.avgPowerMin || 0} - ${data?.avgPowerMax || 0}`}
                            suffix={'W'}
                        />

                        <strong style={styles}>
                            <strong style={styles}>{data?.avgPowerMinFtp}% - {data?.avgPowerMaxFtp}% FTP</strong>
                        </strong>

                    </>
                    :
                    <>

                        <strong style={styles}>Avg Pace</strong>

                        <FancyValue
                            value={`${data?.avgPaceMin || 0} - ${data?.avgPaceMax || 0}`}
                        // suffix={'kph'}
                        />

                        <strong style={styles}>
                            <strong style={styles}>{data?.avgSpeedMinFtp}% - {data?.avgSpeedMaxFtp}% FTP</strong>
                        </strong>

                    </>
                }

            </Col>

            <Col col={{ xs: 12 }} justify="center">

                <div style={styles}>

                    <img
                        src={require("../../../assets/images/LabReport/icon-fitness.png")}
                        style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                        alt={`${activity}.png`}
                    />

                </div>

                <strong style={styles}>Avg Speed</strong>

                <FancyValue
                    value={`${data?.avgSpeedMin || 0} - ${data?.avgSpeedMax || 0}`}
                    suffix={"kph"}
                />

                {(data.timeMin || data.timeMax) &&
                    <strong style={styles}>{data?.timeMin} - {data?.timeMax} hrs</strong>
                }

            </Col>

            <Col col={{ xs: 24 }}><Space /></Col>

            <Col col={{ xs: 12 }} justify="center">

                <div style={styles}>

                    <img
                        src={require("../../../assets/images/LabReport/icon-circulation.png")}
                        style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                        alt={`heart Rate.png`}
                    />

                </div>

                <strong style={styles}>Avg HR</strong>

                <FancyValue
                    value={`${data?.hrMin || 0} - ${data?.hrMax || 0}`}
                    suffix={" bpm"}
                />

                <strong style={styles}>{data?.vo2HrMin}% - {data?.vo2HrMax}% HRmax</strong>

            </Col>

            <Col col={{ xs: 12 }} justify="center">

                <div style={styles}>

                    <img
                        src={require("../../../assets/images/GlycogenTest/gels-100.png")}
                        style={{ width: "50px", height: "50px", objectFit: "scale-down" }}
                        alt={`carb Burn.png`}
                    />

                </div>

                <strong style={styles}>Fueling Rate</strong>

                <FancyValue
                    value={`${data?.lowerLimit || 0} - ${data?.upperLimit || 0}`}
                    suffix={" g/hr"}
                />

                <strong style={styles}>{data?.lowerPercentBurnRate}% - {data?.upperPercentBurnRate}% Carb Burn Rate</strong>

            </Col>

        </Row>
    )

}

export default TestSessionsReportRaceReadyPerformance;
