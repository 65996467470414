import { Col, Line } from '@dex/bubl-dash';
import React from 'react';
import styles from "./Questionnaires.module.scss";

export const QuestionnairesText = (data) => {
    let headingDescription = "In order to better prepare for your upcoming test session, we need to know a little bit about yourself and your training history. Please answer the following questions:"
    let preparationHeading = "Preparing For your Test Session";
    let preparationDescription = "Please follow the recommended guidelines below to ensure you are properly prepared for testing and have followed a standard set of controls to minimize influences on your test performance.";
    let consentHeading = "About the Test Session and Your Consent";
    let consentDescription = "The following section provides information about your test session. Please take the to read thru it so you are aware of what to expect when you come in for testing.";
    let consentText = () => {

        return (
            <Col col={{ xs: 24 }}>

                <p>Anthropometric  Measurements</p>

                <br />

                <p>
                    Your body composition will be assessed by taking your height, weight, girth and measurements.
                    Ideally this is done with minimal clothing and may require the appraiser to pinch your skin at several key
                    locations on the body. To respect privacy and cultural/religious differences, measurements can be performed
                    fully clothed if need be. The measurements are used to estimate your lean and adipose (fat) mass as well as
                    to account for body size in fitness markers.
                </p>

                <br />

                <p>Testing in our V02max Studio</p>

                <br />

                <p>
                    Most exercise related tests we perform on athletes at our studio require the athlete to push themselves to
                    near-maximal or maximal levels. The objective of the tests may differ depending on which one you choose and
                    can include measures Of your aerobic capacity (V02max), metabolic efficiency and/or your race pace
                    sustainability. The tests can take anywhere from 20 to 60 min depending on the one you chose and will require
                    you to either cycle on a bike trainer or run on a treadmill or do both. Most tests are progressive and done in
                    Steps Of 3-5min where each step gets progressively harder (i.e. more resistance, faster speed and/or higher
                    grade).
                </p>

                <br />

                <p>
                    During the test,measures of heart rate, oxygen consumption, ventilation, blood lactate (finger prick) and sweat
                    collection may be taken and you will be required to wear a HR monitor around the chest and breathe thru a
                    mask that covers both your nose and mouth. During the test, you will also be required to provide feedback on
                    your perceived exertion at each stage along the way. For maximal tests, you'll need to try your best in order to
                    get the most accurate results for determining your fitness level and other performance indicators. Your
                    appraiser will fill you in on any more details once you arrive for testing.
                </p>

                <br />

                <p>Test Results</p>

                <br />

                <p>
                    Following completion of testing, your appraiser will go over your test results with you. In addition, a report that
                    summarizes your test results will be emailed to you following the test session. All information and test results
                    collected from you will be kept confidential and will not be revealed to any other party without your verbal or
                    written consent.
                </p>

                <br />

                <p>Your Consent</p>

                <br />

                <p>
                    By participating in any of V02max Sdn Bhd's test services, you acknowledge you have read the information
                    provided above, and understand the nature of testing, the need to push yourself physically, and the inherent
                    risks involved with exercise and your participation in the program. In addition, you agree to accept full
                    responsibility for any adverse reaction(s) or incident(s) that may arise from your participation in any test
                    session administered at our studio and waive your rights to hold V02Max Sdn Bhd, its employees and directors
                    responsible should a health or injury-related incident occur that is not the result of negligence on our part.
                </p>

                <br />

                <p>
                    Please also be aware that during your test session, you are free to stop at any time if you feel too
                    uncomfortable or no longer wish to continue.
                </p>

                <br />

                <p>
                    Thanks for the information. See you at our studio soon!
                </p>

                <br />

                <p>
                    All the best till then,<br />
                    V02max Team
                </p>

            </Col>
        )
    }

    switch (data.typeOfService) {
        case "Lab Test":
            break;
        case "Gait Analysis":
            headingDescription = "In order to better prepare for your upcoming gait analysis session, we need to know a little bit about yourself and your training history. Please answer the following questions:"

            preparationHeading = "Preparing For your Gait Analysis Session";
            preparationDescription = "Please follow the recommended guidelines below to ensure you are properly prepared for testing and have followed a standard set of controls to minimize influences on your test performance.";

            consentHeading = "About the Session and Your Consent";
            consentDescription = "The following section provides information about your gait analysis session. Please take the time to read through it so you have a better understanding of what to expect when you come in for the session.";
            consentText = () => {

                return (
                    <Col col={{ xs: 24 }}>

                        <p className={styles.boldText}>1. Anthropometric Measurements</p>

                        <br />

                        <p>
                            Several body measurements will be taken including your height, weight, shoulder width, inseam, arm length, and arm span. These measurements are used to determine your body proportions.
                        </p>

                        <br />

                        <p className={styles.boldText}>2. Body/Muscle Functional Mobility Assessment</p>

                        <br />

                        <p>
                            A functional assessment will be conducted to evaluate your posture, symmetry, stability, and flexibility. During this time, you will be required to perform movements related to running and there may be light physical contact by your appraiser to ensure accuracy of the measures.
                        </p>

                        <br />

                        <p className={styles.boldText}>3. Gait Analysis</p>

                        <br />

                        <p>
                            Your appraiser will provide additional details upon your arrival. During the session:
                            <ul>
                                <li>•	A running pod will be attached to your back, and markers will be placed on key joint areas.</li>
                                <li>•	You will start with a short warm-up on the treadmill (~10min).</li>
                                <li>•	In most cases, you will run at two different speeds for 2 to 5 min each while your appraiser evaluates your running pattern and records video from various angles.</li>
                            </ul>

                        </p>

                        <br />

                        <p className={styles.boldText}>4. Findings</p>

                        <br />

                        <p>
                            After completing the recordings, your appraiser will review the videos and findings with you. If deemed necessary, you may be asked to run again following any suggested corrections and/or with the addition of special insoles or aids in your shoes.
                        </p>

                        <br />

                        <p className={styles.boldText}>5. Prescription Exercises</p>

                        <br />

                        <p>
                            At the end of the session and if needed, your appraiser will provide prescribed exercises designed to improve your running mobility, stability, and/or strength. Based on the findings from your gait analysis, these exercises will be tailored to help enhance your running mechanics and overall performance.
                        </p>

                        <br />

                        <p className={styles.boldText}>6. Report</p>

                        <br />

                        <p>
                            A comprehensive report summarizing your test results will be emailed to you within 24 hrs following the session. All data and test results will be kept confidential and will not be shared without your consent.
                        </p>

                        <Line />

                        <p className={styles.boldText}>Your Consent</p>

                        <br />

                        <p>
                            By participating in VO2max Sdn Bhd's test services, you acknowledge that you:
                            <ul>
                                <li>•	Have read and understood the information provided above.</li>
                                <li>•	Are aware of the testing process and the risks associated with exercise.</li>
                                <li>•	Accept full responsibility for any adverse reactions or incidents arising from your participation, except those caused by negligence on our part.</li>
                                <li> •	Waive your right to hold VO2max Sdn Bhd, its employees, or directors liable for any non-negligent health or injury-related incidents.</li>
                            </ul>
                            You may stop the session at any time if you feel uncomfortable or no longer wish to continue.
                        </p>

                        <Line />

                        <p>
                            Thank you for trusting us to be your running gait appraisers and we look forward to seeing you at our studio soon!
                        </p>

                        <br />

                        <p>
                            Warm regards,
                        </p>

                        <p className={styles.boldText}>
                            V02max Team
                        </p>

                    </Col>
                )
            }

    }
    return {
        headingDescription,
        preparationHeading,
        preparationDescription,
        consentHeading,
        consentDescription,
        consentText
    };
};