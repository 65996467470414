import { IndexTable, Modal, ModalPage, Panel } from '@dex/bubl-dash';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import React, { useCallback, useMemo, useState } from 'react';
import AthletesSettings from '../../Athletes/AthletesSettings';

const TeamsSingleAddAthlete: React.FC<any> = (props: any) => {

    const { data, form, athletes, setAthletes } = props;

    const [addNew, setAddNew] = useState(false);
    const [addExisting, setAddExisting] = useState(false);

    const [tableState, setTableState] = useState({ search: undefined, where: undefined, order: undefined, skip: undefined });

    const handleClose = useCallback((data: any) => {

        setAddNew(false);
        setAddExisting(false);

        if (!data) return;


        setAthletes(list => {

            list.push(data);

            return list;

        });

        form.handleChange({ name: `activeIds.${data.id}`, value: true, reset: true });
        form.setKey();

    }, [form.hash, athletes]);

    const indexFetchParams = (routeName, params, users) => {

        let filters = AthletesSettings.indexFetchParams(routeName, params, users);

        filters.where.id = { nin: athletes.map((athlete: any) => athlete.id) };
        filters.fields = undefined;

        return filters;

    }

    const indexColumns = (routeName, params, users) => {

        let columns: any = AthletesSettings.indexColumns(routeName, params, users);

        columns.map((column, index) => {

            if (column.key === "actions") {

                column.render = (value, row: any) => (
                    <Button
                        label={`Add To Team`}
                        size={"small"}
                        onClick={handleClose.bind(null, row)}
                    />
                );

            }

            return column;

        });

        return columns;

    }

    return useMemo(() => (

        <>

            <ButtonRow>

                <Button
                    label={"Attach Existing Athlete"}
                    type={"light"}
                    size={"small"}
                    onClick={setAddExisting.bind(null, true)}
                />

                <Button
                    label={"Add New Athlete"}
                    type={"light"}
                    size={"small"}
                    onClick={setAddNew.bind(null, true)}
                />

            </ButtonRow>

            <ModalPage
                key={form.key}
                show={addNew}
                onClose={setAddNew.bind(null, false)}
                routePath={"athletes/create"}
                routeParams={{
                    create: {
                        mode: "create",
                        defaults: {
                            teamsIds: [data.id],
                            type: "team",
                        },
                        rowProps: {
                            gutter: null
                        },
                        formProps: {
                            mode: "create",
                            title: "Add New Player",
                            onAttach: (data) => {
                                handleClose(data);
                            },
                        },
                        successRouteName: null,
                        successCallback: (data) => {
                            handleClose(data);
                        },
                    }
                }}
            />

            {addExisting &&
                <Modal
                    show={addExisting}
                    onClose={setAddExisting.bind(null, false)}
                    title={""}
                    width={1220}
                >

                    <IndexTable
                        title={"Athletes"}
                        getFetch={AthletesSettings.indexFetch}
                        getColumns={indexColumns}
                        preFetchFilters={indexFetchParams}
                        getQuickSearch={AthletesSettings.indexQuickSearch}
                        getSearchFields={AthletesSettings.indexSearchFields}
                        routeParams={tableState}
                        manageState={setTableState}
                        hideTrashSwitch={true}
                    />

                </Modal>
            }

        </>

    ), [addExisting, addNew, tableState, handleClose]);

}

export default TeamsSingleAddAthlete;
