import { apiGet, baseApi, baseApiReturn, options, response } from "@dex/bubl-fetch";

const endpoint = 'Athletes';

const AthletesApi: baseApiReturn = baseApi(endpoint);

AthletesApi.import = (options?: options): response => {

    return AthletesApi.create(options, "import");

}

AthletesApi.getTrackingSessionsList = (id, options?: options): response => {

    return apiGet({
        url: endpoint + `/${id}/getTrackingSessionsList`,
        params: {},
        ...options
    });

}

AthletesApi.getTrackingSessionsData = (id, options?: options): response => {

    return apiGet({
        url: endpoint + `/${id}/getTrackingSessionsData`,
        params: {},
        ...options
    });

}

AthletesApi.getSessions = (id, options?: options): response => {

    return apiGet({
        url: endpoint + `/${id}/getSessions`,
        params: {},
        ...options
    });

}

AthletesApi.getSession = (id, sessionId, options?: options): response => {

    return apiGet({
        url: endpoint + `/${id}/getSession`,
        params: { sessionId: sessionId },
        ...options
    });

}

export default AthletesApi;