import { fixedNum, loop, parseNum, titleCase } from "@dex/bubl-helpers"
import moment from "moment";

export const getAthleteAge = (dob) => {

    if (!moment(dob).isValid()) return null;

    return moment().diff(dob, 'years', false).toString();

}

export const getSum = (values) => {

    let sum = 0;

    loop(values, (value) => {
        if (value) sum += parseFloat(value);
    })

    return sum;

}

export const getAverage = (values, decimal = 2) => {

    if (typeof values === 'object') values = Object.values(values);
    if (!values) return 0;

    let sum = getSum(values);
    let len = 0;

    loop(values, (value) => {

        if (value !== null && value !== undefined && value !== "" && !Number.isNaN(value)) len++;

    });

    return fixedNum(sum / len, decimal);

}

export const mobilityValuesToClasses = (values) => {

    let fair: any = []
    let restricted: any = []

    loop(values, (value, key) => {

        if (typeof (value) !== 'string') return;

        if (value !== 'Fair' && value !== 'Restricted') return;

        if (key === 'lowerBack' && value === 'Fair') fair.push('LowerBack')
        if (key === 'lowerBack' && value === 'Restricted') restricted.push('LowerBack')

        const details = values[key + "Details"];
        if (!details) return;

        details.map(detail => {

            detail = detail.replace("Tight", "");

            if (value === 'Fair') fair.push(detail)
            else if (value === 'Restricted') restricted.push(detail)

        })

    });

    return { fair, restricted };

}

export const fillImage = (svg, classes, fillClass): any => {

    loop(classes, (cls) => {

        cls = cls.replace(/ /g, "");

        const find = new RegExp(`style="fill: #e6e7e8" id="${cls}`, `g`)

        svg = svg.replace(find, `style="fill: ${fillClass}"`)

    })

    return svg;

}



export const HHMMSS = (seconds) => {

    let date = new Date(0);

    date.setSeconds(seconds);

    let timeString = date.toISOString().substring(11, 19);

    return timeString;

}

export const MM = (seconds) => {

    return seconds / 60;

}











export const sampleData = [
    {
        name: '50', vo2: 24, predicted: null
    },
    {
        name: '75', vo2: 25, predicted: null
    },
    {
        name: '100', vo2: 27, predicted: 27
    },
    {
        name: '125', vo2: 32, predicted: 32,
    },
    {
        name: '150', vo2: 34, predicted: 35.6,
    },
    {
        name: '175', vo2: 38, predicted: 39,
    },
    {
        name: '200', vo2: 43, predicted: 43,
    },
    {
        name: '225', vo2: 48, predicted: 47,
    },
    {
        name: '250', vo2: 51, predicted: 51,
    },
    {
        name: '275', vo2: 52, predicted: null,
    },
    {
        name: '300', vo2: 54, predicted: null
    },
]

export const sampleData1 = {
    title: 'VO2max',
    ratingTitle: 'Fitness',
    ratingValue: 'High',
    measurementVal: '52.0',
    measurementUnit: 'ml/kg²min',
    percentile: '82.64',
    rankingType: 'World',
    measurementRanges: [10, 20, 30, 40, 50, 60, 70],
    lowestLabel: 'Unhealthy',
    highestLabel: 'Elite',
}

export const sampleData2 = {
    title: 'Engine Size',
    ratingTitle: 'Size',
    ratingValue: 'Small-Medium',
    measurementVal: '3.50',
    measurementUnit: 'L/min',
    percentile: '79.56',
    rankingType: 'World',
    measurementRanges: [10, 20, 30, 40, 50, 60, 70],
    lowestLabel: 'Very Small',
    highestLabel: 'Very Large',
}

export const sampleData3 = {
    title: 'Body Fat',
    ratingValue: 'High',
    measurementVal: 13,
    measurementUnit: '%',
    percentile: '82.64',
    rankingType: 'World',
    measurementRanges: [5, 10, 15, 20, 25, 30],
    lowestLabel: 'Unhealthy',
    highestLabel: 'Elite',
    labels: ['Very Lean', 'Lean', 'Average', 'Fat', 'Very Fat', 'Obese']
}

export const sampleDataVo2Hr = [
    {
        time: 15,
        speed: 8.4,
        vo2absolute: 15.7,
        vo2absolutehr: 20.5
    },
    {
        time: 15,
        speed: 8.4,
        vo2absolute: 15.7,
        vo2absolutehr: 20.5
    },
    {
        time: 15,
        speed: 8.4,
        vo2absolute: 15.7,
        vo2absolutehr: 20.5
    },

]

export const generateDate = (length = 30) => {
    let arr: any = [];
    for (let i = 0; i < length; i++) {
        let rand1 = Math.random() * 15 + 5;
        let rand2 = Math.random() * 15 + 5;
        let date = moment()
            .subtract(i, "day")
            .toDate();

        arr.unshift({
            date: moment(date).format("MMMM D, YYYY"),
            index: i,
            spent: Math.ceil(rand1),
            items: Math.ceil(rand2)
        });
    }

    return arr;
};

export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const removeWhitespace = (text) => {
    return text.replace(/\s+/g, "");
};