import { Button, ButtonRow, Form, Panel, Space, useForm } from '@dex/bubl-dash';
import { fixedNum, loop } from '@dex/bubl-helpers';
import React, { useCallback, useMemo, useState } from 'react';
import ChartProps from '../Chart/Chart.Props';

import styles from "./PanelChart.module.scss";
import { labTestFormatChartValues } from '../../functions/labTestData';

const PanelChart: React.FC<PanelChartProps> = (props) => {

    let { heading, types, actions, formula, renderChart, renderForm, addZero, addZeroEnd, addIntervalEnd, maxCols, children, data, onTypeChange, defaultType } = props;

    const [chartType, setChartType] = useState(defaultType ? defaultType : (types ? types[0]?.name : null));

    const form = useForm({ ...props.formDefaults });

    const chartData = useMemo(() => {

        const dataSet: any = [];

        if (addZero) dataSet.push({ time: 0, date: "" });

        let rows = data;

        if (types) {

            loop(types, (type) => {
                if (type.name === chartType) rows = type.data;
            })

        }

        loop(rows || [], (row, index) => {

            if (maxCols && index >= maxCols) return;

            dataSet.push(row);

        });

        if (addZeroEnd) dataSet.push({ time: 0, date: "" });

        if (addIntervalEnd) {

            const last = dataSet[dataSet.length - 1];

            dataSet.push({ time: last.time + last._time_diff, date: "" });

        }

        return labTestFormatChartValues(dataSet);

    }, [types, chartType]);

    const handleChangeType = useCallback((type) => {

        setChartType(type);

        if (onTypeChange) onTypeChange(type);

    }, [])

    return (

        <>

            <Panel
                key={props.chartKey}
                className={styles.panel}
                heading={`${heading}`}
                search={!renderForm ? undefined : (
                    <Form
                        form={form}
                        allowDiscardChanges={true}
                    >

                        {renderForm(form, heading)}

                    </Form>
                )}
                actions={(
                    <>

                        {formula &&
                            <span className='chart-formula'>
                                {ChartProps.formula(formula)}
                                <br />
                                <span>R<sup>2</sup></span> = {fixedNum(formula.score.r2, 4)}
                            </span>
                        }

                        {actions}

                        {types &&
                            <ButtonRow>

                                {(types || []).map((type, index) => (

                                    <Button
                                        key={index}
                                        label={type.label}
                                        size={"small"}
                                        type={chartType === type.name ? "light" : "dark"}
                                        onClick={handleChangeType.bind(null, type.name)}
                                    />

                                ))}

                            </ButtonRow>
                        }
                    </>
                )}
            >

                {children}

                {renderChart && renderChart(chartType, chartData, form.values)}

            </Panel>

            <Space />

        </>

    )

}

interface PanelChartProps {
    [key: string]: any,
}

export default PanelChart;