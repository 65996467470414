import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import QuestionnairesApi from './QuestionnairesApi';
import ModelLink from '@dex/bubl-dash/src/lib/components/ModelLink/ModelLink';

let settings;

export default settings = {
    key: 'questionnaires',
    zone: 'people',
    path: '/questionnaires',
    title: "Questionnaires",
    endpoint: QuestionnairesApi.endpoint,
    modelName: 'Questionnaires',
    idKey: 'id',
    primaryKey: 'title',
    actions: ['trash', 'clone'],
    indexCaps: ["viewQuestionnaires"],
    viewCaps: ["viewQuestionnaires"],
    createCaps: ["createQuestionnaires"],
    updateCaps: ["updateQuestionnaires"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: QuestionnairesApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = {
            where: {},
            include: [{ relation: "athlete", preset: "link" }],
            order: ['created.date DESC'],
            fields: [
                "this", "id",
                "athleteId", "fullName",
                "status", "typeOfService",
                "created", "updated",
            ],
            limit: 10,
            skip: 0
        };

        if (params && params.status) filters.where.status = { inq: [params.status] };
        if (params && params.type) filters.where.typeOfService = { inq: [params.type] };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['id', 'firstName', 'lastName', 'fitType', 'status'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "Athlete",
                key: "athleteId",
                type: "relationship",
                model: "Athletes",
            },
            {
                label: "Status",
                key: "status",
                type: "choice",
                options: [
                    { value: "pending", label: "Pending" },
                    { value: "filled", label: "Filled" },
                    { value: "reviewed", label: "Reviewed" },
                ],
            },
            {
                label: "Type Of Service",
                key: "typeOfService",
                type: "choice",
                options: [
                    { value: "Basic Bike Fit", label: "Basic Bike Fit" },
                    { value: "Comprehensive Bike Fit", label: "Comprehensive Bike Fit" },
                    { value: "Bike Sizing", label: "Bike Sizing (Buying a new bike)" },
                    { value: "Fitting and Sizing", label: "Fitting and Sizing" },
                    { value: "ReFit on Same Bike", label: "ReFit on Same Bike" },
                    { value: "ReFit on New Bike", label: "ReFit on New Bike" },
                    { value: "Remote Bike Sizing", label: "Remote Bike Sizing" },
                ],
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "",
                key: "this",
                format: "ModelLink",
                render: (value, data) => {
                    return <ModelLink modelName="Questionnaires" data={{ id: data.id, title: "View Details" }} />
                }
            },
            {
                label: "Athlete",
                key: "athlete",
                format: "ModelLink",
            },
            {
                label: "Type of Service",
                key: "typeOfService",
            },
            {
                label: "Status",
                key: "status",
                format: "titleCase",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: QuestionnairesApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: QuestionnairesApi.getOne,
    singleFetchFill: QuestionnairesApi.getOnePublic,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: ["athlete"] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        let heading = "Pre-Bike Fit/Bike Size Questionnaire";

        if (data) {
            switch (data.typeOfService) {

                case "Basic Bike Fit":
                case "Comprehensive Bike Fit":

                    heading = "Pre-Bike Fit Questionnaire";

                    break;

                case "Bike Sizing":
                case "Fitting and Sizing":

                    heading = "Pre-Bike Sizing Questionnaire";

                    break;

                case "ReFit on Same Bike":

                    heading = "Pre-Bike Fit Questionnaire";

                    break;

                case "ReFit on New Bike":

                    heading = "Pre-Bike Fit Questionnaire";

                    break;

                case "Remote Bike Sizing":

                    heading = "Pre-Bike Sizing Questionnaire";

                    break;

                case "Lab Test":

                    heading = "Pre-Test Questionnaire";

                    break;

            }
        }

        if (error) title = "Error";
        else if (data) title = heading;
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.updateCaps
            },
            {
                label: "Fill",
                path: path + "/fill",
                caps: settings.updateCaps
            },
        ]

    },
};