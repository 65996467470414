import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import TeamsApi from './TeamsApi';

let settings;

export default settings = {
    key: 'teams',
    zone: 'people',
    path: '/teams',
    title: "Teams",
    endpoint: TeamsApi.endpoint,
    modelName: 'Teams',
    idKey: 'id',
    primaryKey: 'name',
    actions: ['trash', 'clone'],
    indexCaps: ["viewTeams"],
    viewCaps: ["viewTeams"],
    createCaps: ["createTeams"],
    updateCaps: ["updateTeams"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: TeamsApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = {
            where: {},
            include: [],
            order: ['created.date DESC'],
            fields: [
                "id", "name", "modelName",
                "mainSport", "trainingVenue",
                "coachName", "managerName",
                "created", "updated",
            ],
            limit: 10,
            skip: 0
        };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['name'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "Name",
                key: "name",
                type: "text",
            },
            {
                label: "Training Venue",
                key: "trainingVenue",
                type: "text",
            },
            {
                label: "Sport",
                key: "mainSport",
                type: "text",
            },
            {
                label: "Coach Name",
                key: "coachName",
                type: "text",
            },
            {
                label: "Manager Name",
                key: "managerName",
                type: "text",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "Team Name",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Sport",
                key: "mainSport",
            },
            {
                label: "Training Venue",
                key: "trainingVenue",
            },
            {
                label: "Coach Name",
                key: "coachName",
            },
            {
                label: "Manager Name",
                key: "managerName",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: TeamsApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: TeamsApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: [{ relation: "activeAthletes" }] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.updateCaps
            },
        ]

    },
};