import { fixedNum } from "@dex/bubl-helpers";
import { getSum } from "./sharedGeneral";

export const gravititionalForce = 9.8067;
export const roadTypeOptions = [
    { value: "0.0050", label: "Track" },
    { value: "0.0050", label: "Road" },
    { value: "0.0050", label: "Off-Road" }
];

export const roadSurfaceOptions = [
    { label: "Wood", value: "0.0020", rating: "Smooth" },
    { label: "Smooth Concreate", value: "0.0022", rating: "Smooth" },
    { label: "Smooth Asphalt", value: "0.0025", rating: "Smooth" },
    { label: "Rough Asphalt", value: "0.0055", rating: "Rough" },
    { label: "Mixed", value: "0.0075", rating: "Rough" },
    { label: "Gravel", value: "0.0150", rating: "Rough" },
    { label: "Cobblestones/Brick", value: "0.0250", rating: "Rough" },
    { label: "Uneven Dirt", value: "0.0400", rating: "Rough" }
];

export const calculateRollingResistanceCoefficient = (roadSurfaceRating, roadTypeCrr, roadSurfaceCrr, pressureCrr, qualityCrr, widthCrr) => {

    if (roadSurfaceRating == null || roadTypeCrr == null || roadSurfaceCrr == null || pressureCrr == null || qualityCrr == null || widthCrr == null) return;

    let output;

    const roadTypeCrrNum = parseFloat(roadTypeCrr);
    const roadSurfaceCrrNum = parseFloat(roadSurfaceCrr);
    const pressureCrrNum = parseFloat(pressureCrr);
    const qualityCrrNum = parseFloat(qualityCrr);
    const widthCrrNum = parseFloat(widthCrr);

    if (roadSurfaceRating === "smooth") {
        output = (roadTypeCrrNum + roadSurfaceCrrNum + pressureCrrNum + qualityCrrNum + widthCrrNum) / 5;
    } else if (roadSurfaceRating === "rough") {
        output = (roadTypeCrrNum + roadSurfaceCrrNum + pressureCrrNum + qualityCrrNum + widthCrrNum) / 5;
    } else {
        output = "";
    }

    return fixedNum(output, 4);
};

export const calculateRollingResistanceDrag = (percentGrade, riderWeight, BikeWeight, rollingResistanceCoefficient) => {

    if (percentGrade == null || riderWeight == null || BikeWeight == null || rollingResistanceCoefficient == null) return;

    let output;
    const GravitationalForce = 9.807;
    const BikeAndRiderWeight = parseFloat(BikeWeight) + parseFloat(riderWeight);

    const ArcTan = calculateArcTan(percentGrade);
    const ArcCos = calculateArcCos(ArcTan);

    output = GravitationalForce * ArcCos * BikeAndRiderWeight * rollingResistanceCoefficient;

    return fixedNum(output, 2);
};

export const calculateAirSpeed = (bikeSpeed, headwind) => {

    if (bikeSpeed == null || headwind == null) return 0;

    let output = getSum([bikeSpeed, headwind]);

    return output

};

export const bikeTypeOptions = [
    { label: "Road", value: "0.00" },
    { label: "Road-Aero", value: "-0.01" },
    { label: "Road-Aerobars", value: "-0.02" },
    { label: "Time Trial", value: "-0.03" },
    { label: "Gravel", value: "0.02" },
    { label: "MTB", value: "0.04" }
];

export const helmetOptions = [
    { label: "Regular with Vents", value: "0.02" },
    { label: "Regular few vents", value: "0.01" },
    { label: "Aero Some Vents", value: "0.00" },
    { label: "Aero No Vents", value: "-0.01" },
    { label: "Pro Aero", value: "-0.02" },
    { label: "No Selection", value: "0.00" }
];

export const clothingOptions = [
    { label: "Street Clothing", value: "0.10" },
    { label: "Loose Cycling Kit", value: "0.05" },
    { label: "Snug Cycling Bib", value: "0.00" },
    { label: "TT Bib", value: "-0.01" },
    { label: "Skin Suit", value: "-0.02" },
    { label: "No Selection", value: "0.00" },
];

export const pressureOptions = [
    { label: "Very High (>120psi)", smooth: "0.0020", rough: "0.0100" },
    { label: "High (101-120psi)", smooth: "0.0030", rough: "0.0070" },
    { label: "Moderate (81-100psi)", smooth: "0.0050", rough: "0.0050" },
    { label: "Low (40-80psi)", smooth: "0.0100", rough: "0.0040" },
    { label: "Very Low (<40psi)", smooth: "0.0200", rough: "0.0030" },
];

export const qualityOptions = [
    { label: "Very High", value: "0.0020" },
    { label: "High", value: "0.0030" },
    { label: "Moderate", value: "0.0050" },
    { label: "Low", value: "0.0070" },
    { label: "Very Low", value: "0.0100" },
];

export const widthOptions = [
    { label: "<23mm", smooth: "0.0020", rough: "0.0100" },
    { label: "23-24mm", smooth: "0.0030", rough: "0.0070" },
    { label: "25-27mm", smooth: "0.0050", rough: "0.0050" },
    { label: "28-30mm", smooth: "0.0070", rough: "0.0040" },
    { label: "31-34mm", smooth: "0.0100", rough: "0.0035" },
    { label: ">34mm", smooth: "0.0200", rough: "0.0050" },
];

export const ridingPositionOptions = [
    { label: "Standing, Hands on Hoods", value: "1.20" },
    { label: "Standing, Hands on  Drops", value: "1.10" },
    { label: "Hands on Hoods straight arms", value: "1.00" },
    { label: "Hands on Hoods Arms slightly bent", value: "0.88" },
    { label: "Hands on Hoods Aero", value: "0.70" },
    { label: "Hands on Drops,  Straight Arms", value: "0.80" },
    { label: "Hands on Drops, Arms Slightly Bent", value: "0.75" },
    { label: "Hands on Drops Aero", value: "0.70" },
    { label: "With Aerobars Relaxed", value: "0.72" },
    { label: "With Aerobars Moderate", value: "0.68" },
    { label: "With Aerobars Aggressive", value: "0.65" },
    { label: "Sprint Tuck on Top Tube", value: "0.59" },
    { label: "TT Aero - Hands on Basebar", value: "0.80" },
    { label: "TT Aero - Relaxed", value: "0.68" },
    { label: "TT Aero - Moderate", value: "0.64" },
    { label: "TT Aero - Aggressive", value: "0.62" },
    { label: "TT Aero - Pro*", value: "0.60" },
];

export const calculateCoefficientDrag = (ridingPositionCd, helmetCd, clothingCd, bikeTypeCd) => {

    if (ridingPositionCd == null || helmetCd == null || clothingCd == null || bikeTypeCd == null) return 0;

    const ridingPositionCdNum = parseFloat(ridingPositionCd);
    const helmetCdNum = parseFloat(helmetCd);
    const clothingCdNum = parseFloat(clothingCd);
    const bikeTypeCdNum = parseFloat(bikeTypeCd);

    const coefficientDrag = ridingPositionCdNum + helmetCdNum + clothingCdNum + bikeTypeCdNum;

    return fixedNum(coefficientDrag, 2);
};

export const calculateDragForce = (bikeSpeed, headwind, airDensity, FSA, coefficientDrag) => {

    if (bikeSpeed == null || headwind == null || airDensity == null || FSA == null || coefficientDrag == null) return 0;

    // if (airDensity) {
    //     airDensity = airDensity.replace(" kg/m3", '');
    //     airDensity = parseFloat(airDensity);
    // }

    let output;

    const totalAirSpeed = calculateAirSpeed(bikeSpeed, headwind);

    const CdTimeA = FSA * coefficientDrag;

    const speedConversion = totalAirSpeed / 3.6;

    output = 0.5 * CdTimeA * airDensity * speedConversion * speedConversion;

    return fixedNum(output, 2);

};

export const calculateTotalResistance = (val) => {

    if (!val) return 0;

    const sum = getSum(val);

    return fixedNum(sum, 2);

};

export const calculatePowerWithDrive = (bikeSpeed, headwind, totalResistance, percentDrive) => {

    const airSpeed = calculateAirSpeed(bikeSpeed, headwind);

    const totalAirSPeed = airSpeed / 3.6;

    const powerToWheel = totalResistance * totalAirSPeed;

    let output = powerToWheel + (powerToWheel * (percentDrive / 100));

    return fixedNum(output, 0);
};

export const calculateBikeSpeed = (powerTarget, cdA, airDensity, headwind, totalWeight, driveTrainLoss, percentGrade, rollingCoefficient) => {

    let output = 0;

    // if (airDensity) {
    //     airDensity = airDensity.replace(" kg/m3", '');
    //     airDensity = parseFloat(airDensity);
    // }

    const arcTan = calculateArcTan(percentGrade);
    const arcCos = calculateArcCos(arcTan);
    const arcSin = calculateArcSin(arcTan);

    const headWindSquared = Math.pow(headwind, 2);
    const accellTimeWeight = gravititionalForce * totalWeight;

    const a = 0.5 * cdA * airDensity;
    const b = headwind * cdA * airDensity;
    const c = accellTimeWeight * (arcSin + (rollingCoefficient * arcCos)) + (a * headWindSquared);
    const d = (-(1 - (driveTrainLoss / 100))) * powerTarget;

    const Q1 = 3 * a * c - (b * b);
    const Q2 = 9 * a * a;
    const Q = Q1 / Q2;

    const R1 = (9 * a * b * c) - (27 * Math.pow(a, 2) * d) - (2 * Math.pow(b, 3));
    const R2 = 54 * Math.pow(a, 3);
    const R = R1 / R2;

    const S = Math.pow(R + Math.sqrt(Math.pow(Q, 3) + Math.pow(R, 2)), 1 / 3);

    const T1 = Math.pow(Q, 3);
    const T2 = Math.pow(R, 2);
    const T3 = R - Math.sqrt(T1 + T2);
    const T = Math.cbrt(T3);

    const temOutput = S + T - (b / (3 * a));
    output = temOutput * 3.66;

    return fixedNum(output, 1)

};

export const calculateArcTan = (percentGrade) => { return Math.atan(percentGrade / 100) };

export const calculateArcCos = (arcTan) => { return Math.cos(arcTan) };

export const calculateArcSin = (arcTan) => { return Math.sin(arcTan) };