import React from 'react';
import { ComposedChart, ReferenceLine } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { fixedNum, loop } from '@dex/bubl-helpers';
import { nearestMaxHundered } from '../../functions/sharedChart';

const ChartCoachingHydrationFueling: React.FC<ChartCoachingHydrationFuelingProps> = (props) => {

    let { filters, data } = props;

    const hydration = filters?.trackingHydration || [];
    const metric = filters?.trackingHydrationType?.toLowerCase();
    const variation = filters?.trackingHydrationVariation;
    const filteredData = data && data[metric] ? data[metric] : (data?.all || []);

    const Axis = new ChartAxis();

    Axis.x.props = { ...Axis.x.props, ...props.xAxis };

    Axis.x.label = "Week";
    Axis.x.format = undefined;
    Axis.x.key = "date";

    Axis.x.props = {
        interval: 0,
        angle: -45,
        textAnchor: "end",
    }
    if (hydration.includes("Fluid Loss")) {

        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 2,
            key: variation == "Total" ? "fluidLoss" : "fluidLossRate",
            axisLabel: variation == "Total" ? "Fluid Loss - ml" : "Fluid Loss - ml/hr",
            label: "Fluid Loss",
            props: {
                yAxisId: "Fluid Loss",
                domain: [0, 1000],
                fill: ChartProps.trackingColor("Fluid Loss"),
            },
        });

    }
    if (hydration.includes("Fluid Intake")) {

        Axis.y.push({
            axis: true,
            bar: true,
            pos: "left",
            index: 0,
            key: variation == "Total" ? "fluidIntake" : "fluidIntakeRate",
            axisLabel: variation == "Total" ? "Fluid Intake - ml" : "Fluid Intake - ml/hr",
            label: "Fluid Intake",
            props: {
                yAxisId: "Fluid Intake",
                domain: [0, 1000],
                fill: ChartProps.trackingColor("Fluid Intake"),
            },
        });

    }
    if (hydration.includes("Carb Intake")) {

        Axis.y.push({
            axis: true,
            bar: true,
            pos: "right",
            index: 4,
            key: variation == "Total" ? "carbIntake" : "carbIntakeRate",
            axisLabel: variation == "Total" ? "Carb Intake - g" : "Carb Intake - g/hr",
            label: "Carb Intake",
            props: {
                yAxisId: "Carb Intake",
                domain: [0, 600],
                fill: ChartProps.trackingColor("Carb Intake"),
            },
        });

    }

    let carbIntakeSum = 0;
    let fluidIntakeSum = 0;
    let fluidLossSum = 0;
    let carbIntakeCount = 0;
    let fluidIntakeCount = 0;
    let fluidLossCount = 0;

    loop(filteredData, (item, index) => {
        if (variation == "Total" ? item.carbIntake : item.carbIntakeRate) {
            carbIntakeSum += variation == "Total" ? item.carbIntake : item.carbIntakeRate;
            carbIntakeCount++;
        }
        if (variation == "Total" ? item.fluidIntake : item.fluidIntakeRate) {
            fluidIntakeSum += variation == "Total" ? item.fluidIntake : item.fluidIntakeRate;
            fluidIntakeCount++;
        }
        if (variation == "Total" ? item.fluidLoss : item.fluidLossRate) {
            fluidLossSum += variation == "Total" ? item.fluidLoss : item.fluidLossRate;
            fluidLossCount++;
        }
    });

    const carbIntakeAverage = carbIntakeCount > 0 ? fixedNum(carbIntakeSum / carbIntakeCount, 0) : 0;
    const fluidIntakeAverage = fluidIntakeCount > 0 ? fixedNum(fluidIntakeSum / fluidIntakeCount, 0) : 0;
    const fluidLossAverage = fluidLossCount > 0 ? fixedNum(fluidLossSum / fluidLossCount, 0) : 0;

    Axis.legend = false;

    return (

        <Chart>

            <ComposedChart
                {...ChartProps.BarChart()}
                data={filteredData}
            >

                {hydration.includes("Fluid Loss") &&
                    <ReferenceLine
                        y={fluidLossAverage}
                        stroke={ChartProps.indexColor(2)}
                        yAxisId={"Fluid Loss"}
                        strokeDasharray="3 3"
                        label={{
                            value: `Avg = ${fluidLossAverage} ${variation == "Total" ? "ml" : "ml/hr"}`,
                            position: 'insideTopLeft',
                            fill: ChartProps.indexColor(2),
                            stroke: ChartProps.indexColor(2),
                        }}
                    />
                }

                {hydration.includes("Fluid Intake") &&
                    <ReferenceLine
                        y={fluidIntakeAverage}
                        stroke={ChartProps.indexColor(0)}
                        yAxisId={"Fluid Intake"}
                        strokeDasharray="3 3"
                        label={{
                            value: `Avg = ${fluidIntakeAverage} ${variation == "Total" ? "ml" : "ml/hr"}`,
                            position: 'top',
                            fill: ChartProps.indexColor(0),
                            stroke: ChartProps.indexColor(0),
                        }}
                    />
                }

                {hydration.includes("Carb Intake") &&
                    <ReferenceLine
                        y={carbIntakeAverage}
                        stroke={ChartProps.indexColor(4)}
                        yAxisId={"Carb Intake"}
                        strokeDasharray="3 3"

                        label={{
                            value: `Avg = ${carbIntakeAverage} ${variation == "Total" ? "g" : "g/hr"}`,
                            position: 'insideTopRight',
                            fill: ChartProps.indexColor(4),
                            stroke: ChartProps.indexColor(4),
                        }}
                    />
                }

                {Axis.render()}

            </ComposedChart>

        </Chart>

    )


}

interface ChartCoachingHydrationFuelingProps {
    [key: string]: any,
}

export default ChartCoachingHydrationFueling;