import { get, isEmpty, loop, startCase, titleCase } from "@dex/bubl-helpers";
import { GoDash } from "react-icons/go";
import { HiArrowDown, HiArrowUp } from "react-icons/hi";
import React, { useEffect, useState } from 'react';
import { getMinutesTime } from "./sharedTests";
import styles from "./shareGait.module.scss";

export const getIssueType = (type, rating) => {

    let issue;

    if (isEmpty(rating)) return null;

    switch (type) {
        case "a":
        case "heelWhip"://
        case "footDirection"://
        case "legCross"://
            if (rating <= -4 || rating >= 4) {
                issue = "major";
            } else if ((rating >= 1.5 && rating <= 3.5) || (rating >= -3.5 && rating <= -1.5)) {
                issue = "mild";
            } else if (rating >= -1 && rating <= 1) {
                issue = "normal";
            }
            break;
        case "legAngle"://not available
            if (rating >= 3.5 || rating <= -3.5) {
                issue = "major";
            } else if ((rating > 1.5 && rating <= 3.5) || (rating >= -3.5 && rating < -1.5)) {
                issue = "mild";
            } else if (rating >= -1.5 && rating <= 1.5) {
                issue = "normal";
            }
            break;
        case "heelStrikeOrientation"://
            if (rating <= -4) {
                issue = "major";
            } else if (rating >= 4 || (rating >= -3.5 && rating <= -1.5)) {
                issue = "mild";
            } else if (rating >= -1 && rating <= 3.5) {
                issue = "normal";
            }
            break;
        case "b":
        case "pelvicDrop"://
        case "legInclination"://
            if (rating >= 4.5) {
                issue = "major";
            } else if ((rating >= 2 && rating <= 4)) {
                issue = "mild";
            } else if (rating <= 1.5) {
                issue = "normal";
            }
            break;
        case "footInclination"://
            if (rating >= 4) {
                issue = "major";
            } else if ((rating <= -4) || (rating >= 1.5 && rating <= 3.5)) {
                issue = "mild";
            } else if (rating > -3.5 && rating <= 1) {
                issue = "normal";
            }
            break;
        case "footStrikeAudio"://
            if (rating >= 4.5) {
                issue = "major";
            } else if ((rating <= 0.5) || (rating >= 3.5 && rating <= 4)) {
                issue = "mild";
            } else if (rating >= 1 && rating <= 3) {
                issue = "normal";
            }
            break;
        case "kneeShifting"://
            if (rating <= -4 || rating >= 4) {
                issue = "major";
            } else if ((rating >= 1.5 && rating <= 3.5) || (rating >= -3.5 && rating <= -1.5)) {
                issue = "mild";
            } else if (rating >= -1 && rating <= 1) {
                issue = "normal";
            }
            break;
        case "c":
        case "torsoRotation"://
            if (rating >= 4.5) {
                issue = "major";
            } else if ((rating >= 0 && rating <= 0.5) || (rating >= 3.5 && rating <= 4)) {
                issue = "mild";
            } else if (rating >= 1.0 && rating <= 3) {
                issue = "normal";
            }
            break;
        case "armSwingStyle"://
            if (rating <= 0.5 || rating >= 4.5) {
                issue = "major";
            } else if ((rating >= 1 && rating <= 1.5) || (rating >= 3.5 && rating <= 4)) {
                issue = "mild";
            } else if (rating >= 2 && rating <= 3) {
                issue = "normal";
            }
            break;
        case "d":
        case "toeOffArea"://
            if (rating >= 4.5) {
                issue = "major";
            } else if ((rating >= 3.5 && rating <= 4) || rating <= 0.5) {
                issue = "mild";
            } else if (rating >= 1 && rating <= 3) {
                issue = "normal";
            }
            break;
        case "e":
        case "pelvicTilt"://
        case "torsoLean"://
            if (rating <= -4 || rating >= 4) {
                issue = "major";
            } else if ((rating >= -3.5 && rating <= -1.5) || (rating >= -1 && rating <= 1)) {
                issue = "mild";
            } else if (rating >= 1.5 && rating <= 3.5) {
                issue = "normal";
            }
            break;
        case "f":
        case "footPronationRate"://
            if (rating >= 4.5) {
                issue = "major";
            } else if (rating <= 0.5 || (rating >= 3.5 && rating <= 4)) {
                issue = "mild";
            } else if (rating >= 1 && rating <= 3) {
                issue = "normal";
            }
            break;
        case "elbowPosition"://
            if (rating >= 4 || rating <= -4) {
                issue = "major";
            } else if (rating >= -3.5 && rating <= -1.5) {
                issue = "mild";
            } else if (rating >= -1 && rating <= 3.5) {
                issue = "normal";
            }
            break;
        case "kneeAngle"://
            if (rating <= -4 || rating >= 4) {
                issue = "major";
            } else if ((rating >= 1.5 && rating <= 3.5) || (rating >= -3.5 && rating <= -1.5)) {
                issue = "mild";
            } else if (rating >= -1 && rating <= 1) {
                issue = "normal";
            }
            break;
        default:
            break;
    }

    return issue;

};

export const getIssueTypeLabel = (type) => {

    if (!type) return null;

    const labels = {
        "normal": "Normal/Optimal",
        "mild": "Mild Issue",
        "major": "Major Issue",
    };

    return labels[type] || type;

}

export const gaitIssueOptions = [
    { label: "Normal/Optimal", value: "normal-optimal" },
    { label: "Mild Issue", value: "mild-issue" },
    { label: "Major Issue", value: "major-issue" },
];

export const getIssueOption = (rating) => {

    let filteredOptions = gaitIssueOptions.filter(item => item.value == rating)

    return filteredOptions;

};

export const getPatternColor = (pattern) => {

    let color;

    if (pattern == "none") {
        color = "#359eff"
    } else if (pattern == "mild") {
        color = "#ffc107"
    } else if (pattern == "severe") {
        color = "#f5222d"
    }

    return color;

};

const iconUp = <HiArrowUp className={styles.up} />;
const iconDown = <HiArrowDown className={styles.down} />;
const iconDash = <GoDash className={styles.dash} />;

const getDifferences = (current, base, difference) => {

    const count = current - base;

    if (Math.abs(count) <= difference) {
        return iconDash;
    } else if (difference < count) {
        return iconDown;
    } else {
        return iconUp;
    }

};

export const getRunDifference = (data, current, type) => {

    if (!data[current][type] || !data[0][type]) return iconDash;

    switch (type) {
        case "trialDuration":
            return getDifferences(getMinutesTime(data[0][type]), getMinutesTime(data[current][type]), 2);
        case "trialDistance":
            return getDifferences(data[0][type], data[current][type], 5);
        case "podPace":
            return getDifferences(getMinutesTime(data[0][type]), getMinutesTime(data[current][type]), 2);
        case "treadmillSpeed":
        case "podSpeed":
            return getDifferences(data[0][type], data[current][type], 0.2);
        case "cadence":
            return getDifferences(data[0][type], data[current][type], 2);
        case "strideLength":
            return getDifferences(data[0][type], data[current][type], 0.05);
        case "verticalOscillation":
            return getDifferences(data[0][type], data[current][type], 0.1);
        case "verticalRatio":
            return getDifferences(data[0][type], data[current][type], 0.1);
        case "groundContactTime":
            return getDifferences(data[0][type], data[current][type], 2);
        case "gtcLeft":
        case "gtcRight":
            return getDifferences(data[0][type], data[current][type], 0.1);
        case "hrMax":
        case "hrAvg":
        case "cadenceMax":
            return getDifferences(data[0][type], data[current][type], 1);
        case "elevation":
            return getDifferences(data[0][type], data[current][type], 2);
        default:
            return iconDash;
    }

};

export const calculateLegScore = (stability, parts) => {

    let output = 0;
    let partsLength = 5

    if (!stability) {
        return output;
    }

    switch (stability) {
        case "Stable":
            output += 0.5
            break;

        default:
            break;
    };

    const length = parts ? parts.length : 0;

    partsLength = 5 - length;
    output += partsLength * 0.5;

    return output;
};