import { apiGet, baseApi, baseApiReturn, options, response } from "@dex/bubl-fetch";

const endpoint = 'TrainingZones';

const TrainingZonesApi: baseApiReturn = baseApi(endpoint);

TrainingZonesApi.getWorkouts = (options?: options): response => {

    return apiGet({
        url: endpoint + "/getWorkouts",
        params: {},
        ...options
    });
}

export default TrainingZonesApi;