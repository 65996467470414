import React, { useCallback, useEffect, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldCheckbox from '@dex/bubl-dash/src/lib/components/Fields/FieldCheckbox';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import FieldTextDate from '@dex/bubl-dash/src/lib/components/Fields/FieldTextDate';
import { FieldAutocomplete, FieldTextTime, Space } from '@dex/bubl-dash';
import styles from "./Questionnaires.module.scss";
import Questionnaires from '../Questionnaires';
import { QuestionnairesText } from './QuestionnairesText';

const QuestionnairesFillForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, data } = props;

    const app = useApp();

    const [user] = app.store('currentUser');
    const [nav] = app.store('nav');

    let heading = "Pre-Bike Fit/Bike Size Questionnaire";

    let show = {
        labTest: false,
        preparation: false,
        cyclist: false,
        bike: false,
        bikeSizing: false,
        refitSame: false,
        refitNew: false,
        runningTime: false,
    };

    const typeOfService = data.typeOfService;

    switch (typeOfService) {

        case "Basic Bike Fit":
        case "Comprehensive Bike Fit":

            heading = "Pre-Bike Fit Questionnaire";
            show.cyclist = true;
            show.bike = true;

            break;

        case "Bike Sizing":

            heading = "Pre-Bike Sizing Questionnaire";
            show.cyclist = true;
            show.bikeSizing = true;

            break;
        case "Fitting and Sizing":

            heading = "Pre-Bike Sizing Questionnaire";
            show.cyclist = true;
            show.bikeSizing = true;
            show.bike = true;

            break;

        case "ReFit on Same Bike":

            heading = "Pre-Bike Fit Questionnaire";
            show.cyclist = false;
            show.refitSame = true;

            break;

        case "ReFit on New Bike":

            heading = "Pre-Bike Fit Questionnaire";
            show.cyclist = false;
            show.bike = true;
            show.refitNew = true;

            break;

        case "Remote Bike Sizing":

            heading = "Pre-Bike Sizing Questionnaire";
            show.bikeSizing = true;

            break;

        case "Lab Test":

            heading = "Pre-Test Questionnaire";
            show.labTest = true;
            show.preparation = true;

            break;

        case "Gait Analysis":

            heading = "Pre-Gait Analysis Questionnaire";
            show.labTest = true;
            show.runningTime = true;
            show.preparation = true;

            break;

    }

    useEffect(() => {

        app.setTitle(heading);

    }, [heading])

    const text = QuestionnairesText(data);

    return useMemo(() => (
        <Panel
            heading={heading}
            actions={
                user ?
                    <Button
                        label={"GO BACK"}
                        path={"/questionnaires/" + data.id + "/view"}
                    /> : null
            }
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Seperator
                    bottom={"auto"}
                    heading={"Session Info"}
                    description={text.headingDescription}
                />

                <MetaTable
                    data={data}
                    columnA={[
                        {
                            label: "Athlete",
                            key: "athlete.fullName",
                        },
                    ]}
                    columnB={[
                        {
                            label: "Email Address",
                            key: "athlete.email",
                        },
                    ]}
                    columnC={[
                        {
                            label: "Type of Service",
                            key: "typeOfService",
                        },
                    ]}
                />

                {show.labTest &&
                    <>

                        <Seperator
                            top={"auto"}
                            bottom={"auto"}
                            heading={`${data.typeOfService == "Gait Analysis" ? "Athlete" : "Cyclist"} Info`}
                        />

                        <Row gutter={8} edge={true}>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldRadio
                                    button={true}
                                    form={form}
                                    required={true}
                                    label={"Gender"}
                                    name='cyclistInfo.gender'
                                    defaultValue={form.defaults.cyclistInfo.gender}
                                    options={[
                                        { value: "male", label: "Male" },
                                        { value: "female", label: "Female" }
                                    ]}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldTextDate
                                    form={form}
                                    required={true}
                                    label={"Date of Birth"}
                                    name={`cyclistInfo.dob`}
                                    defaultValue={form.defaults.cyclistInfo.dob}
                                    isDayBeforeMonth={true}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldNumber
                                    form={form}
                                    required={true}
                                    label={"Height"}
                                    name={`cyclistInfo.height`}
                                    defaultValue={form.defaults.cyclistInfo.height}
                                    suffix={" cm"}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldNumber
                                    form={form}
                                    required={true}
                                    label={"Weight"}
                                    name={`cyclistInfo.weight`}
                                    defaultValue={form.defaults.cyclistInfo.weight}
                                    suffix={" kg"}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    required={true}
                                    name="cyclistInfo.location"
                                    label="Location"
                                    defaultValue={form.defaults.cyclistInfo.location}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldSelect
                                    form={form}
                                    required={true}
                                    label={"Main Sport"}
                                    name='cyclistInfo.mainSport'
                                    defaultValue={form.defaults.cyclistInfo.mainSport}
                                    allowCustomOption={true}
                                    options={[
                                        { value: "Road Cycling", label: "Road Cycling" },
                                        { value: "Triathlon", label: "Triathlon" },
                                        { value: "MTB'ing", label: "MTB'ing" },
                                        { value: "Running", label: "Running" },
                                        { value: "Swimming", label: "Swimming" }
                                    ]}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    name="cyclistInfo.hadTested"
                                    label="Have you ever been tested before? if yes, when and where?"
                                    defaultValue={form.defaults.cyclistInfo.hadTested}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    name="cyclistInfo.referredBy"
                                    label="If a person referred you to us, please let us now who"
                                    defaultValue={form.defaults.cyclistInfo.referredBy}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>
                                <Line />
                            </Col>

                        </Row>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldRadio
                                    button={true}
                                    form={form}
                                    required={true}
                                    label={"Fitness Level"}
                                    name='cyclistInfo.fitnessLevel'
                                    defaultValue={form.defaults.cyclistInfo.fitnessLevel}
                                    options={[
                                        { value: "Untrained", label: "Untrained" },
                                        { value: "Low", label: "Low" },
                                        { value: "Moderate", label: "Moderate" },
                                        { value: "High", label: "High" },
                                        { value: "Very High", label: "Very High" }
                                    ]}
                                />

                                <Space />

                                {data.typeOfService !== "Gait Analysis" &&
                                    <FieldCheckbox
                                        form={form}
                                        required={true}
                                        label={"Reason For Training Cycling (check all that apply)"}
                                        name='cyclistInfo.reasonForCycling'
                                        defaultValue={form.defaults.cyclistInfo.reasonForCycling}
                                        options={[
                                            { value: "Fun and Recreation", label: "Fun and Recreation" },
                                            { value: "Fitness or Training", label: "Fitness or Training" },
                                            { value: "Racing and Competing", label: "Racing and Competing" },
                                        ]}
                                    />
                                }

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldNumber
                                    form={form}
                                    label={"Current Max Heart Rate (If Known)"}
                                    name={`cyclistInfo.maxHR`}
                                    defaultValue={form.defaults.cyclistInfo.maxHR}
                                    suffix=" bpm"
                                />

                            </Col>

                            {show.runningTime &&

                                <Col col={{ xs: 24 }}>
                                    <Seperator
                                        bottom={"auto"}
                                        heading={"Current Best Running Time"}
                                    />
                                </Col>

                            }

                            <Col col={{ md: 12, xs: 24 }}>

                                {!show.runningTime &&
                                    <Seperator heading="For Cycllists" />
                                }

                                <FieldNumber
                                    form={form}
                                    label={"Current FTP"}
                                    name={`cyclistInfo.currentFTP`}
                                    defaultValue={form.defaults.cyclistInfo.currentFTP}
                                    suffix={show.runningTime ? " min/km" : " W"}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                {!show.runningTime &&
                                    <Seperator heading="For Runner - Curent Best Time" />
                                }
                                <Row edge gutter={8}>

                                    <Col col={{ md: 12, xs: 24 }}>

                                        <FieldTextTime
                                            form={form}
                                            label={"5km"}
                                            name={`cyclistInfo.runningBest5km`}
                                            defaultValue={form.defaults.cyclistInfo.runningBest5km}
                                            placeholder="hh:mm:ss"
                                        />

                                    </Col>

                                    <Col col={{ md: 12, xs: 24 }}>

                                        <FieldTextTime
                                            form={form}
                                            label={"10km"}
                                            name={`cyclistInfo.runningBest10km`}
                                            defaultValue={form.defaults.cyclistInfo.runningBest10km}
                                            placeholder="hh:mm:ss"
                                        />

                                    </Col>

                                    <Col col={{ md: 12, xs: 24 }}>

                                        <FieldTextTime
                                            form={form}
                                            label={"Half Marathon"}
                                            name={`cyclistInfo.runningBestHalfMarathon`}
                                            defaultValue={form.defaults.cyclistInfo.runningBestHalfMarathon}
                                            placeholder="hh:mm:ss"
                                        />

                                    </Col>

                                    <Col col={{ md: 12, xs: 24 }}>

                                        <FieldTextTime
                                            form={form}
                                            label={"Full Marathon"}
                                            name={`cyclistInfo.runningBestFullMarathon`}
                                            defaultValue={form.defaults.cyclistInfo.runningBestFullMarathon}
                                            placeholder="hh:mm:ss"
                                        />

                                    </Col>

                                </Row>

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    required={true}
                                    label={"Years of Training"}
                                    name={`cyclistInfo.yearsOfTraining`}
                                    defaultValue={form.defaults.cyclistInfo.yearsOfTraining}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    required={true}
                                    label={"Weekly Training Volume(km/hrs)"}
                                    name='cyclistInfo.weeklyTrainingVolume'
                                    defaultValue={form.defaults.cyclistInfo.weeklyTrainingVolume}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldTextArea
                                    form={form}
                                    name={`cyclistInfo.performanceOrFitnessGoals`}
                                    label={"Performance or Fitness Goals"}
                                    defaultValue={form.defaults.cyclistInfo.performanceOrFitnessGoals}
                                    rows={4}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldTextArea
                                    form={form}
                                    name={`cyclistInfo.reasonForFit`}
                                    label={"Reason(s) For Wanting To Be Tested"}
                                    defaultValue={form.defaults.cyclistInfo.reasonForFit}
                                    rows={4}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldTextArea
                                    form={form}
                                    name={`cyclistInfo.discomfortOrPain`}
                                    label={"Do you currently have any injuries, discomfort or pain from training or racing?"}
                                    defaultValue={form.defaults.cyclistInfo.discomfortOrPain}
                                    rows={4}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>


                                <FieldTextArea
                                    form={form}
                                    name={`cyclistInfo.specificEventTraining`}
                                    label={"Is/Are there specific events you are training for? Please indicate below"}
                                    defaultValue={form.defaults.cyclistInfo.specificEventTraining}
                                    rows={4}
                                />

                            </Col>

                            {!show.runningTime &&
                                <>
                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"What is Your Usual Nutritional Practice?"}
                                            name='cyclistInfo.nutritionalPractice'
                                            defaultValue={form.defaults.cyclistInfo.nutritionalPractice}
                                            options={[
                                                { value: "Common (Animal and Plant-Based)", label: "Common (Animal and Plant-Based)" },
                                                { value: "Mostly Animal-Based", label: "Mostly Animal-Based" },
                                                { value: "Mostly-Plant-Based", label: "Mostly-Plant-Based" },
                                                { value: "Pure Vegan Based (No animal sources at all)", label: "Pure Vegan Based (No animal sources at all)" },
                                            ]}
                                            list
                                        />

                                    </Col>

                                    <Col col={{ xs: 12, md: 6 }}>

                                        <FieldRadio
                                            form={form}
                                            label={"On average, what is the amount of Carbohydrates you consume per day?"}
                                            name='cyclistInfo.carbIntake'
                                            defaultValue={form.defaults.cyclistInfo.carbIntake}
                                            options={[
                                                { value: "Very High (>400g)", label: "Very High (>400g)" },
                                                { value: "High (300-400g)", label: "High (300-400g)" },
                                                { value: "Moderate (200-300g)", label: "Moderate (200-300g)" },
                                                { value: "Low (100g-200g)", label: "Low (100g-200g)" },
                                                { value: "Very Low (<100g)", label: "Very Low (<100g)" },
                                            ]}
                                            list
                                        />

                                    </Col>

                                    <Col col={{ xs: 12, md: 6 }}>
                                        <div className={styles.guide}>
                                            <p className={styles.heading}> Guide:</p>
                                            <p>-1 Fruit drink is 30g</p>
                                            <p>-1 Slice of Bread is 15g</p>
                                            <p>-1 Cup of pasta is 40g</p>
                                            <p>-1 Cup of grapes is ~27g</p>
                                        </div>

                                    </Col>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldRadio
                                            form={form}
                                            label={`Are you accustomed to doing hard workouts (e.g. HR at/above threshold or near max efforts)?`}
                                            name='cyclistInfo.accustomedHardWorkout'
                                            defaultValue={form.defaults.cyclistInfo.accustomedHardWorkout}
                                            options={[
                                                { value: "Yes Frequently", label: "Yes Frequently" },
                                                { value: "Yes Sometimes", label: "Yes Sometimes" },
                                                { value: "Rarely", label: "Rarely" },
                                                { value: "Never", label: "Never" },
                                            ]}
                                            list
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <strong className={styles.altLabel}>
                                            Would you like to add any of the following services to your Test Session?
                                        </strong>

                                        <Row edge gutter={8}>

                                            <Col col={{ xs: 24, md: 12 }}>

                                                <FieldRadio
                                                    form={form}
                                                    label={`Sweat Analysis`}
                                                    name='cyclistInfo.addOnSweatTest'
                                                    defaultValue={form.defaults.cyclistInfo.addOnSweatTest}
                                                    options={[
                                                        { value: "Yes", label: "Yes" },
                                                        { value: "No", label: "No" },
                                                        { value: "Would Like More Info On It", label: "Would Like More Info On It" },
                                                    ]}
                                                    list
                                                />

                                            </Col>

                                            <Col col={{ xs: 24, md: 12 }}>

                                                <FieldRadio
                                                    form={form}
                                                    label={`Gait Analysis(Runners Only)`}
                                                    name='cyclistInfo.addOnGait'
                                                    defaultValue={form.defaults.cyclistInfo.addOnGait}
                                                    options={[
                                                        { value: "Yes", label: "Yes" },
                                                        { value: "No", label: "No" },
                                                        { value: "Would Like More Info On It", label: "Would Like More Info On It" },
                                                    ]}
                                                    list
                                                />

                                            </Col>

                                        </Row>

                                    </Col>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldRadio
                                            form={form}
                                            label={`Which discipline do you prefer to be tested on?`}
                                            name='cyclistInfo.discipline'
                                            defaultValue={form.defaults.cyclistInfo.discipline}
                                            options={[
                                                { value: "Bike (Cycling)", label: "Bike (Cycling)" },
                                                { value: "Treadmill (Running)", label: "Treadmill (Running)" },
                                            ]}
                                            list
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldRadio
                                            form={form}
                                            label={`If doing a bike test, choose one of the following`}
                                            name='cyclistInfo.bikeChoice'
                                            defaultValue={form.defaults.cyclistInfo.bikeChoice}
                                            options={[
                                                { value: "Use My Own Bike(Best If You Have A Powermeter", label: "Use My Own Bike(Best If You Have A Powermeter" },
                                                { value: "Use Our Test Bike", label: "Use Our Test Bike" },
                                                { value: "Not Sure", label: "Not Sure" },
                                            ]}
                                            list
                                        />

                                    </Col>

                                    <Col col={{ xs: 24, md: 12 }}>

                                        <FieldRadio
                                            form={form}
                                            label={`If you plan to be tested on the bike, what cleats do you currently have on your cycling shoes?`}
                                            name='cyclistInfo.cleatBrand'
                                            defaultValue={form.defaults.cyclistInfo.cleatBrand}
                                            options={[
                                                { value: "Shimano", label: "Shimano" },
                                                { value: "Look", label: "Look" },
                                                { value: "Platform", label: "Platform" },
                                                { value: "Other", label: "Other" },
                                            ]}
                                            list
                                        />

                                        {form.values.cyclistInfo.cleatBrand == "Other" &&

                                            <FieldText
                                                form={form}
                                                label={"Other"}
                                                name='cyclistInfo.cleatBrandOther'
                                                defaultValue={form.defaults.cyclistInfo.cleatBrandOther}
                                                required
                                            />

                                        }

                                    </Col>

                                </>
                            }

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldTextArea
                                    form={form}
                                    name={`cyclistInfo.otherInfo`}
                                    label={"Please share any other information you think we shouid know prior to your test session"}
                                    defaultValue={form.defaults.cyclistInfo.otherInfo}
                                    rows={4}
                                />

                            </Col>

                        </Row>

                    </>
                }

                {show.cyclist &&
                    <>

                        <Seperator
                            top={"auto"}
                            bottom={"auto"}
                            heading={"Cyclist Info"}
                        />

                        <Row gutter={8} edge={true}>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldRadio
                                    button={true}
                                    form={form}
                                    required={true}
                                    label={"Gender"}
                                    name='cyclistInfo.gender'
                                    defaultValue={form.defaults.cyclistInfo.gender}
                                    options={[
                                        { value: "male", label: "Male" },
                                        { value: "female", label: "Female" }
                                    ]}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldTextDate
                                    form={form}
                                    required={true}
                                    label={"Date of Birth"}
                                    name={`cyclistInfo.dob`}
                                    defaultValue={form.defaults.cyclistInfo.dob}
                                    isDayBeforeMonth={true}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldNumber
                                    form={form}
                                    required={true}
                                    label={"Height"}
                                    name={`cyclistInfo.height`}
                                    defaultValue={form.defaults.cyclistInfo.height}
                                    suffix={" cm"}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldNumber
                                    form={form}
                                    required={true}
                                    label={"Weight"}
                                    name={`cyclistInfo.weight`}
                                    defaultValue={form.defaults.cyclistInfo.weight}
                                    suffix={" kg"}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    required={true}
                                    name="cyclistInfo.location"
                                    label="Location"
                                    defaultValue={form.defaults.cyclistInfo.location}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldSelect
                                    form={form}
                                    required={true}
                                    label={"Main Sport"}
                                    name='cyclistInfo.mainSport'
                                    defaultValue={form.defaults.cyclistInfo.mainSport}
                                    allowCustomOption={true}
                                    options={[
                                        { value: "Road Cycling", label: "Road Cycling" },
                                        { value: "Triathlon", label: "Triathlon" },
                                        { value: "MTB'ing", label: "MTB'ing" },
                                        { value: "Running", label: "Running" },
                                        { value: "Swimming", label: "Swimming" }
                                    ]}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    name="cyclistInfo.hadABikeFit"
                                    label="Have you ever had a bike fit before? if yes, when and where?"
                                    defaultValue={form.defaults.cyclistInfo.hadABikeFit}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    name="cyclistInfo.referredBy"
                                    label="If a person referred you to us, please let us now who"
                                    defaultValue={form.defaults.cyclistInfo.referredBy}
                                />

                            </Col>

                            <Col col={{ xs: 24 }}>
                                <Line />
                            </Col>

                        </Row>

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldRadio
                                    button={true}
                                    form={form}
                                    required={true}
                                    label={"Cycling Level"}
                                    name='cyclistInfo.cyclingLevel'
                                    defaultValue={form.defaults.cyclistInfo.cyclingLevel}
                                    options={[
                                        { value: "Novice", label: "Novice" },
                                        { value: "Intermediate", label: "Intermediate" },
                                        { value: "Experienced", label: "Experienced" },
                                        { value: "Elite", label: "Elite" }
                                    ]}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldCheckbox
                                    form={form}
                                    required={true}
                                    label={"Reason For Cycling (check all that apply)"}
                                    name='cyclistInfo.reasonForCycling'
                                    defaultValue={form.defaults.cyclistInfo.reasonForCycling}
                                    options={[
                                        { value: "Fun and Recreation", label: "Fun and Recreation" },
                                        { value: "Fitness or Training", label: "Fitness or Training" },
                                        { value: "Racing and Competing", label: "Racing and Competing" },
                                    ]}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    required={true}
                                    label={"Years of Riding"}
                                    name={`cyclistInfo.yearsOfRiding`}
                                    defaultValue={form.defaults.cyclistInfo.yearsOfRiding}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldSelect
                                    form={form}
                                    required={true}
                                    label={"Weekly Riding Volume"}
                                    name='cyclistInfo.weeklyRidingVolume'
                                    defaultValue={form.defaults.cyclistInfo.weeklyRidingVolume}
                                    options={[
                                        { value: "None Currently", label: "None Currently" },
                                        { value: "Very-Low (<50KM)", label: "Very Low (<50KM)" },
                                        { value: "Low (50-100KM)", label: "Low (50-100KM)" },
                                        { value: "Moderate (101-200KM)", label: "Moderate (101-200KM)" },
                                        { value: "Moderately-High (201-300KM)", label: "Moderately-High (201-300KM)" },
                                        { value: "High (301-400KM)", label: "High (301-400KM)" },
                                        { value: "Very-High (401-500KM)", label: "Very High (401-500KM)" },
                                        { value: "Elite (>501KM)", label: "Elite (>501KM)" }
                                    ]}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldTextArea
                                    form={form}
                                    name={`cyclistInfo.performanceOrFitnessGoals`}
                                    label={"Performance or Fitness Goals"}
                                    defaultValue={form.defaults.cyclistInfo.performanceOrFitnessGoals}
                                    rows={4}
                                />

                            </Col>

                            <Col col={{ xs: 24, md: 12 }}>

                                <FieldTextArea
                                    form={form}
                                    name={`cyclistInfo.reasonForFit`}
                                    label={"Reason For Fit"}
                                    defaultValue={form.defaults.cyclistInfo.reasonForFit}
                                    rows={4}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldTextArea
                                    form={form}
                                    name={`cyclistInfo.discomfortOrPain`}
                                    label={"Do you currently have any discomfort or pain when riding?"}
                                    defaultValue={form.defaults.cyclistInfo.discomfortOrPain}
                                    rows={4}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>


                                <FieldTextArea
                                    form={form}
                                    name={`cyclistInfo.currentInjuries`}
                                    label={"Do you have any current Injuries that affect your cycling ability? "}
                                    defaultValue={form.defaults.cyclistInfo.currentInjuries}
                                    rows={4}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldTextArea
                                    form={form}
                                    name={`cyclistInfo.bikeDescriptionMore`}
                                    label={"If you would like to share anything else about you or your bike"}
                                    defaultValue={form.defaults.cyclistInfo.bikeDescriptionMore}
                                    rows={4}
                                />

                            </Col>

                        </Row>

                    </>
                }

                {show.bike &&
                    <>

                        <Seperator
                            top={"auto"}
                            bottom={"auto"}
                            heading={"Bike Details"}
                        />

                        <FieldRepeater
                            form={form}
                            name={`bikeDetails`}
                            defaultValues={""}
                            addButtonLabel={"Add Bike"}
                            required={true}
                            //@ts-ignore
                            min={1}
                        >

                            {(form.values.bikeDetails || []).map((row, n) => (

                                <React.Fragment key={form.key + "-" + n}>

                                    <Row gutter={8} edge={true}>

                                        <Col col={{ md: 24, xs: 24 }}>

                                            <FieldRadio
                                                button={true}
                                                form={form}
                                                required={true}
                                                label={"Type of Bike"}
                                                name={`bikeDetails[${n}][typeOfBike]`}
                                                defaultValue={row.typeOfBike}
                                                options={[
                                                    { value: "Road", label: "Road" },
                                                    { value: "Time Trial", label: "Time Trial" },
                                                    { value: "Gravel", label: "Gravel" },
                                                    { value: "Touring", label: "Touring" },
                                                    { value: "MTB", label: "Mountain Bike" },
                                                    { value: "Foldie", label: "Foldie" },
                                                ]}
                                            />

                                        </Col>

                                        <Col col={{ xs: 12, md: 6 }}>

                                            <FieldAutocomplete
                                                form={form}
                                                required={true}
                                                label={"Bike Brand"}
                                                name={`bikeDetails[${n}][bikeBrand]`}
                                                defaultValue={row.bikeBrand}
                                                group={"BikeBrand"}
                                                preload={true}
                                            />

                                        </Col>

                                        <Col col={{ xs: 12, md: 6 }}>

                                            <FieldAutocomplete
                                                key={row.bikeBrand}
                                                form={form}
                                                required={true}
                                                label={"Bike Model"}
                                                name={`bikeDetails[${n}][bikeModel]`}
                                                defaultValue={row.bikeModel}
                                                group={"BikeModel-" + row.bikeBrand}
                                                preload={true}
                                            />

                                        </Col>

                                        <Col col={{ xs: 12, md: 6 }}>

                                            <FieldText
                                                form={form}
                                                required={true}
                                                name={`bikeDetails[${n}][year]`}
                                                label="Year"
                                                defaultValue={row.year}
                                            />

                                        </Col>

                                        <Col col={{ xs: 12, md: 6 }}>

                                            <FieldText
                                                form={form}
                                                required={true}
                                                label={"Size"}
                                                name={`bikeDetails[${n}][size]`}
                                                defaultValue={row.size}
                                            />

                                        </Col>

                                        <Col col={{ xs: 12, md: 6 }}>

                                            <FieldSelect
                                                form={form}
                                                required={true}
                                                label={"Type of Pedals"}
                                                name={`bikeDetails[${n}][typeOfPedals]`}
                                                defaultValue={row.typeOfPedals}
                                                allowCustomOption={true}
                                                options={[
                                                    { value: "Shimano", label: "Shimano" },
                                                    { value: "Look", label: "Look" },
                                                    { value: "Time", label: "Time" },
                                                    { value: "Speedplay", label: "Speedplay" },
                                                    { value: "Garmin (e.g. Vector)", label: "Garmin (e.g. Vector)" },
                                                    { value: "Powertap", label: "Powertap" },
                                                    { value: "Exustar", label: "Exustar" },
                                                    { value: "Assioma", label: "Assioma" },
                                                    { value: "MTB SPD", label: "MTB SPD" },
                                                    { value: "Flat Platform", label: "Flat Platform" },
                                                    { value: "Toe Straps", label: "Toe Straps" },
                                                ]}
                                            />

                                        </Col>

                                        <Col col={{ md: 12, xs: 24 }}>

                                            <FieldRadio
                                                form={form}
                                                required={true}
                                                label={"Type of Brakes"}
                                                name={`bikeDetails[${n}][typeOfBrakes]`}
                                                defaultValue={row.typeOfBrakes}
                                                options={[
                                                    { value: "Rim", label: "Rim" },
                                                    { value: "Disc", label: "Disc" },
                                                    { value: "Not Sure", label: "Not Sure" }
                                                ]}
                                            />

                                        </Col>

                                    </Row>

                                </React.Fragment>

                            ))}

                        </FieldRepeater>

                    </>
                }

                {show.bikeSizing &&
                    <>

                        <Seperator
                            top={"auto"}
                            bottom={"auto"}
                            heading={"Bike Sizing"}
                        />

                        <Row gutter={8} edge={true}>

                            <Col col={{ md: 24, xs: 24 }}>

                                <FieldRadio
                                    button={true}
                                    form={form}
                                    required={true}
                                    label={"What type of Bike manufacturing are you considering? "}
                                    name='bikeSizing.bikeManufacturing'
                                    defaultValue={form.defaults.bikeSizing.bikeManufacturing}
                                    options={[
                                        { value: "Custom-made", label: "Custom-made" },
                                        { value: "Commercial Brand", label: "Commercial Brand" },
                                        { value: "Both (multiple choices)", label: "Both (multiple choices)" },
                                        { value: "Not Sure", label: "Not Sure" }
                                    ]}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldTextArea
                                    form={form}
                                    required={true}
                                    name={`bikeSizing.listOfBikes`}
                                    label={"List the Bike(s) you are thinking of getting?"}
                                    placeholder="(include the make and model of each bike)"
                                    defaultValue={form.defaults.bikeSizing.listOfBikes}
                                    rows={4}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldTextArea
                                    form={form}
                                    name={`bikeSizing.preferencesForNewBike`}
                                    label={"Do you have any specific preferences for your new bike?"}
                                    placeholder="(i.e. stem length, stem spacer height, seat post offset length, saddle type)"
                                    defaultValue={form.defaults.bikeSizing.preferencesForNewBike}
                                    rows={4}
                                />
                            </Col>

                        </Row>

                    </>
                }

                {show.refitSame &&
                    <>

                        <Seperator
                            top={"auto"}
                            bottom={"auto"}
                            heading={"ReFit Same Bike"}
                        />

                        <Row gutter={8} edge={true}>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    name="refitSame.reasonForReFitted"
                                    label="Why do you wish to be re-fitted again on your bike? "
                                    defaultValue={form.defaults.refitSame.reasonForReFitted}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    name="refitSame.madeNewBikeAdjustments"
                                    label="Have you made any changes to your bike since we last saw you?"
                                    defaultValue={form.defaults.refitSame.madeNewBikeAdjustments}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    name="refitSame.newFitnessOrPerformanceGoal"
                                    label="Do you have any new Performance or Fitness Goals? "
                                    defaultValue={form.defaults.refitSame.newFitnessOrPerformanceGoal}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    name="refitSame.newOrOngoingDiscomfortWhenRiding"
                                    label="Do you have any new or ongoing comfort issues when riding?"
                                    defaultValue={form.defaults.refitSame.newOrOngoingDiscomfortWhenRiding}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldSelect
                                    form={form}
                                    label={"Please indicate your current cycling volume"}
                                    name='refitSame.currentCyclingVolume'
                                    defaultValue={form.defaults.refitSame.currentCyclingVolume}
                                    options={[
                                        { value: "None Currently", label: "None Currently" },
                                        { value: "Very-Low (<50KM)", label: "Very Low (<50KM)" },
                                        { value: "Low (50-100KM)", label: "Low (50-100KM)" },
                                        { value: "Moderate (101-200KM)", label: "Moderate (101-200KM)" },
                                        { value: "Moderately-High (201-300KM)", label: "Moderately-High (201-300KM)" },
                                        { value: "High (301-400KM)", label: "High (301-400KM)" },
                                        { value: "Very-High (401-500KM)", label: "Very High (401-500KM)" },
                                        { value: "Elite (>501KM)", label: "Elite (>501KM)" }
                                    ]}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    name="refitSame.newInformation"
                                    label="Share any other information about your bike or yourself since your last session with us?"
                                    defaultValue={form.defaults.refitSame.newInformation}
                                />

                            </Col>

                        </Row>

                    </>
                }

                {show.refitNew &&
                    <>
                        <Seperator
                            top={"auto"}
                            bottom={"auto"}
                            heading={"ReFit New Bike"}
                        />

                        <Row gutter={8} edge={true}>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    name="refitNew.newFitnessOrPerformanceGoal"
                                    label="Do you have any new Performance or Fitness Goals you are pursing since last session?"
                                    defaultValue={form.defaults.refitNew.newFitnessOrPerformanceGoal}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    name="refitNew.newOrOngoingDiscomfortWhenRiding"
                                    label="Do you have any new or ongoing comfort issues when riding?"
                                    defaultValue={form.defaults.refitNew.newOrOngoingDiscomfortWhenRiding}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldSelect
                                    form={form}
                                    label={"Please indicate your current cycling volume"}
                                    name='refitNew.currentCyclingVolume'
                                    defaultValue={form.defaults.refitNew.currentCyclingVolume}
                                    options={[
                                        { value: "None Currently", label: "None Currently" },
                                        { value: "Very-Low (<50KM)", label: "Very Low (<50KM)" },
                                        { value: "Low (50-100KM)", label: "Low (50-100KM)" },
                                        { value: "Moderate (101-200KM)", label: "Moderate (101-200KM)" },
                                        { value: "Moderately-High (201-300KM)", label: "Moderately-High (201-300KM)" },
                                        { value: "High (301-400KM)", label: "High (301-400KM)" },
                                        { value: "Very-High (401-500KM)", label: "Very High (401-500KM)" },
                                        { value: "Elite (>501KM)", label: "Elite (>501KM)" }
                                    ]}
                                />

                            </Col>

                            <Col col={{ md: 12, xs: 24 }}>

                                <FieldText
                                    form={form}
                                    name="refitNew.newInformation"
                                    label="Share any other information about your bike or yourself since your last session with us?"
                                    defaultValue={form.defaults.refitNew.newInformation}
                                />

                            </Col>

                        </Row>

                    </>
                }

                {show.preparation &&
                    <Row edge gutter={8}>

                        <Col col={{ xs: 24 }}>

                            <Seperator
                                bottom={"auto"}
                                heading={text.preparationHeading}
                                description={text.preparationDescription}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldCheckbox
                                form={form}
                                label={"What To Bring"}
                                name='others.whatToBring'
                                defaultValue={form.defaults.others.whatToBring}
                                options={[
                                    show.runningTime && { value: "Your runnning attire (snug-fit), running shoes, socks", label: "Your runnning attire (snug-fit), running shoes, socks" },
                                    show.runningTime && { value: "Any othothics or special insoles you use", label: "Any othothics or special insoles you use" },
                                    !show.runningTime && { value: "Your running or cycling gear (shorts, top, bib, shoes, socks)", label: "Your running or cycling gear (shorts, top, bib, shoes, socks)" },
                                    { value: "Towel and water bottle", label: "Towel and water bottle" },
                                    !show.runningTime && { value: "If your pedals are NOT Shimano or Look. please bring them to the test session", label: "If your pedals are NOT Shimano or Look. please bring them to the test session" },
                                    !show.runningTime && { value: "A desire to try your best", label: "A desire to try your best" },
                                ].filter(Boolean)}
                                list
                            />

                        </Col>

                        {data.typeOfService !== "Gait Analysis" &&
                            <Col col={{ xs: 24 }}>

                                <FieldCheckbox
                                    form={form}
                                    label={"Ensure The Following"}
                                    name='others.ensureTheFollowing'
                                    defaultValue={form.defaults.others.ensureTheFollowing}
                                    options={[
                                        { value: "NO hard long-duration exercise the day prior to testing", label: "NO hard long-duration exercise the day prior to testing" },
                                        { value: "Do not eat a large or heavy meal within 4 hours of testing. Small to moderate is 0k", label: "Do not eat a large or heavy meal within 4 hours of testing. Small to moderate is 0k" },
                                        { value: "Avoid caffeine 6 hours prior to testing (e.g. coffee, black tea, caffeine gels)", label: "Avoid caffeine 6 hours prior to testing (e.g. coffee, black tea, caffeine gels)" },
                                        { value: "Dont smoke 24 hours prior to the test", label: "Dont smoke 24 hours prior to the test" },
                                        { value: "Avoid large amounts of alcohol 24 hours prior to testing", label: "Avoid large amounts of alcohol 24 hours prior to testing" },
                                        { value: "Get Sufficient sleep (7 hours +)", label: "Get Sufficient sleep (7 hours +)" },
                                    ]}
                                    list
                                />

                            </Col>
                        }

                        {data.typeOfService !== "Gait Analysis" &&

                            <Col col={{ xs: 24 }}>

                                <FieldCheckbox
                                    form={form}
                                    label={"Prior to a MET test"}
                                    edit={"In addition to the above, if you are coming in to do a MET test, you must do the following"}
                                    name='others.priorTest'
                                    labelElement={DualLabel}
                                    defaultValue={form.defaults.others.priorTest}
                                    options={[
                                        { value: "Fast for at least 10 to 12 hours before the test. Only water is allowed during this time. (Food, or beverage intake can elevate blood sugar levels prior to the test which will greatly affect the results).", label: "Fast for at least 10 to 12 hours before the test. Only water is allowed during this time. (Food, or beverage intake can elevate blood sugar levels prior to the test which will greatly affect the results)." },
                                        { value: "Avoid caffeine, caffeinated tea and alcohol for at least 12 hours before the test as they too can", label: "Avoid caffeine, caffeinated tea and alcohol for at least 12 hours before the test as they too can affect metabolic measurements." },
                                        { value: "Ensure you are well-hydrated but refrain from drinking large amounts of water just before the test.", label: "Ensure you are well-hydrated but refrain from drinking large amounts of water just before the test." },
                                    ]}
                                    list
                                />

                            </Col>

                        }

                        <Col col={{ xs: 24 }}>

                            <Seperator
                                bottom={"auto"}
                                heading={text.consentHeading}
                                description={text.consentDescription}
                            />

                        </Col>

                        {text.consentText()}

                    </Row>
                }

                <Line />

                <ButtonRow>

                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />



                </ButtonRow>

            </Form>

        </Panel>

    ), [props, show, text]);

}


const DualLabel: React.FC<any> = (props) => {

    let { label, edit } = props;

    return (

        <div className={'form-field-label ' + styles.labels}>

            <span className={styles.label}>{label}:</span>

            <Space height={6} />

            <span className={styles.desc}>{edit}:</span>

        </div>

    )

}
export default QuestionnairesFillForm;
