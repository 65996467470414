import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldPhone from '@dex/bubl-dash/src/lib/components/Fields/FieldPhone';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldTextDate from '@dex/bubl-dash/src/lib/components/Fields/FieldTextDate';
import { FieldAutocomplete, FieldRelationship } from '@dex/bubl-dash';
import { getAthleteAge } from '../../../functions/sharedGeneral';
import AthletesSingleFindDuplicate from './AthletesSingleFindDuplicate';

const AthletesCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, mode, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const age = getAthleteAge(form.values?.dob)

    return useMemo(() => (

        <Panel
            heading={"Athlete Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldRadio
                            button={true}
                            form={form}
                            label={"Type"}
                            name='type'
                            defaultValue={form.defaults.type}
                            options={[
                                { value: "individual", label: "Individual" },
                                { value: "team", label: "Team" },
                                { value: "both", label: "Both" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldRadio
                            button={true}
                            form={form}
                            label={"Gender"}
                            name='gender'
                            defaultValue={form.defaults.gender}
                            options={[
                                { value: "male", label: "Male" },
                                { value: "female", label: "Female" }
                            ]}
                        />

                    </Col>

                    {!form.values.type || form.values.type !== "individual" &&
                        <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                            <FieldRelationship
                                form={form}
                                name="teamsIds"
                                label="Teams"
                                preload={true}
                                isMulti={true}
                                endpoint="Teams"
                                defaultValue={form.defaults.teamsIds}
                            />

                        </Col>
                    }

                    <Col col={{ xs: 24 }}>
                        <Line />
                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldText
                            form={form}
                            name="firstName"
                            label="First Name"
                            required={true}
                            defaultValue={form.defaults.firstName}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldText
                            form={form}
                            name="lastName"
                            label="Last Name"
                            required={true}
                            defaultValue={form.defaults.lastName}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldText
                            form={form}
                            name="shortName"
                            label="Short Name"
                            defaultValue={form.defaults.shortName}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldText
                            form={form}
                            name="email"
                            label="Email"
                            defaultValue={form.defaults.email}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldPhone
                            form={form}
                            label={"Phone"}
                            name={'phone'}
                            defaultValue={form.defaults.phone}
                        />

                    </Col>

                    <Col col={{ xs: 18, sm: 8, lg: 6 }}>

                        <FieldTextDate
                            form={form}
                            label={"Date of Birth"}
                            name="dob"
                            defaultValue={form.defaults.dob}
                            isDayBeforeMonth={true}
                        />

                    </Col>

                    <Col col={{ xs: 6, sm: 4, lg: 2 }}>

                        <FieldText
                            form={form}
                            name="age"
                            label="Age"
                            defaultValue={age || 0}
                            key={form.values?.dob}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldText
                            form={form}
                            name="location"
                            label="Location"
                            defaultValue={form.defaults.location}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldText
                            form={form}
                            name="referredBy"
                            label="Referred By"
                            defaultValue={form.defaults.referredBy}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldUpload
                            form={form}
                            name='photo'
                            folder='admin-media'
                            label='Photo'
                            defaultValue={form.defaults.photo}
                            multiple={false}
                            accept='image/*'
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>
                        <Line />
                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldAutocomplete
                            form={form}
                            label={"Main Sport"}
                            name='mainSport'
                            defaultValue={form.defaults.mainSport}
                            group={"mainSport"}
                            options={[
                                { value: "Cycling", label: "Cycling" },
                                { value: "Triathlon", label: "Triathlon" },
                                { value: "Running", label: "Running" },
                                { value: "Swimming", label: "Swimming" },
                                { value: "Football", label: "Football" },
                                { value: "Rugby", label: "Rugby" },
                                { value: "Basketball", label: "Basketball" },
                                { value: "Cricket", label: "Cricket" },
                                { value: "Gymnastics", label: "Gymnastics" },
                            ]}
                            sort={false}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldAutocomplete
                            key={form.values.mainSport}
                            form={form}
                            name="discipline"
                            label="Discipline"
                            defaultValue={form.defaults.discipline}
                            group={"discipline." + form.values.mainSport}
                            preload={true}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldSelect
                            form={form}
                            name="level"
                            label="Level"
                            defaultValue={form.defaults.level}
                            options={[
                                { value: "national", label: "National" },
                                { value: "state", label: "State" },
                                { value: "junior", label: "Junior" },
                                { value: "amateur", label: "Amateur" },
                                { value: "club", label: "Club" },
                                { value: "novice", label: "Novice" },
                                { value: "age-grouper", label: "Age-Grouper" },
                                { value: "recreational", label: "Recreational" },
                                { value: "other", label: "Other" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldAutocomplete
                            key={form.values.mainSport}
                            form={form}
                            name="position"
                            label="Position"
                            defaultValue={form.defaults.position}
                            group={"position." + form.values.mainSport}
                            preload={true}
                        />
                    </Col>

                    <Col col={{ xs: 24, sm: 24, lg: 16 }}>

                        <FieldAutocomplete
                            form={form}
                            name="otherSports"
                            label="Other Sports"
                            defaultValue={form.defaults.otherSports}
                            isMulti={true}
                            group='otherSports'
                            options={[
                                { value: "Running", label: "Running" },
                                { value: "Swimming", label: "Swimming" },
                                { value: "Strenght/Gym", label: "Strenght/Gym" },
                                { value: "Stretch/Yoga", label: "Stretch/Yoga" },
                            ]}
                            sort={false}
                        />

                    </Col>

                </Row>

                {mode === "create" && form.values.firstName && form.values.lastName &&
                    <AthletesSingleFindDuplicate
                        firstName={form.values.firstName}
                        lastName={form.values.lastName}
                        email={form.values.email}
                        phone={form.values.phone}
                    />
                }

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />

                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default AthletesCreateEditForm;
