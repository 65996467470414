import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import SessionsSingleReport from './SessionsSingleReport';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Line } from '@dex/bubl-dash';
import AthletesSessionTabGeneral from '../../Athletes/Components/AthletesSingleViewGeneral';

const SessionsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Session Details"}
                    actions={() => (
                        <>
                            <Button
                                label="Edit Session"
                                type="faded"
                                size="small"
                                path={`/sessions/${data.id}/edit/info`}
                            />
                        </>
                    )}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Fit Type",
                                key: "fitType",
                                type: 'text',
                                format: "titleCase",
                            },
                            {
                                label: "Session Date",
                                key: "sessionDate",
                                format: 'date',
                            },
                            {
                                label: "Athlete",
                                key: "athlete",
                                format: 'modelLink',
                            },

                        ]}
                        columnB={[
                            {
                                label: "Cyclist Level",
                                key: "cyclistLevel",
                                type: 'text',
                                format: "titleCase",
                            },
                            {
                                label: "Cycling Mileage Per Week",
                                key: "cyclingMileagePerWeek",
                                type: 'text',
                                format: "titleCase",
                            },
                            {
                                label: "Competes",
                                key: "competes",
                                type: 'text',
                                format: "titleCase",
                            },
                        ]}
                        columnC={[
                            {
                                label: "Years Riding",
                                key: "yearsRiding",
                            },
                            data.fitType != "Aerodynamic" && {
                                label: "Fitted Else where",
                                key: "fittedElsewhere",
                                type: 'text',
                                format: "titleCase",
                            },
                            {
                                label: "Fitter",
                                key: "fitter",
                                format: 'modelLink',
                            },
                        ]}
                    />

                    {data.fitType != "Aerodynamic" &&
                        <>

                            <Line />

                            <MetaTable
                                data={data}
                                columnA={[
                                    {
                                        label: "Reason For Fit",
                                        key: "reasonForFit",
                                        type: 'text',
                                        format: "titleCase",
                                    },
                                ]}
                                columnB={[

                                    {
                                        label: "Performance Goals",
                                        key: "performanceGoals",
                                        type: 'text',
                                        format: "titleCase",
                                    },

                                ]}
                                columnC={[
                                    {
                                        label: "Comfort Issues When Riding",
                                        key: "comfortIssuesWhenRiding",
                                        type: 'text',
                                        format: "titleCase",
                                    },
                                ]}
                            />

                        </>
                    }
                </Panel>

                <Space />

                <Panel
                    heading={data.athlete.fullName}
                    actions={(
                        <ButtonRow>

                            <Button
                                label={"View Athlete"}
                                size="small"
                                type="faded"
                                path={`/athletes/${data.athlete.id}/view`}
                            />

                        </ButtonRow>
                    )}
                >

                    <AthletesSessionTabGeneral data={data.athlete} />

                </Panel>



            </Col>

            {data.fitType != "Aerodynamic" &&
                <Col className={'side'}>
                    <SessionsSingleReport data={data} />
                </Col>
            }

        </Row>

    ), [data]);

}

export default SessionsSingleView;
