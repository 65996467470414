import { apiGet, baseApi, baseApiReturn, options, response } from "@dex/bubl-fetch";

const endpoint = 'RatingScales';

const RatingScalesApi: baseApiReturn = baseApi(endpoint);

RatingScalesApi.getData = (options?: options): response => {

    return apiGet({
        url: endpoint + "/getData",
        params: {},
        ...options
    });
}

export default RatingScalesApi;