import React, { useEffect, useMemo, useRef } from 'react';
import AthletesApi from '../AthletesApi';
import { Alert, ModelLink, Space } from '@dex/bubl-dash';

const AthletesSingleFindDuplicate: React.FC<any> = (props: any) => {

    const { firstName, lastName, email, phone } = props;

    const matches = AthletesApi.get();

    const deffer: any = useRef();

    useEffect(() => {

        if (deffer.current) clearTimeout(deffer.current);

        deffer.current = setTimeout(() => {

            matches.cancel();

            const filters: any = [];

            // if (firstName && firstName.length > 3) filters.push({ firstName: firstName });
            // if (lastName && lastName.length > 3) filters.push({ lastName: lastName });
            if (firstName && firstName.length > 3) filters.push({ firstName: { like: firstName, options: "i" } });
            if (lastName && lastName.length > 3) filters.push({ lastName: { like: lastName, options: "i" } });
            if (email && email.length > 6) filters.push({ email: email });
            if (phone && phone.length > 6) filters.push({ phone: phone });

            if (!filters.length) return matches.reset();

            matches.run({
                params: {
                    where: { or: filters },
                }
            });

        }, 1000);

    }, [firstName, lastName, email, phone])

    return useMemo(() => (

        <>

            {matches.data && matches.data.data.length &&
                <>
                    <Space />

                    <Alert
                        type={"warning"}
                        message={matches.data.count.total + " Possible Duplicate Athlete(s) Found"}
                        description={<ModelLink data={matches.data.data} />}
                    />

                </>
            }

        </>

    ), [matches.data])

}

export default AthletesSingleFindDuplicate;
