import React, { useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';

const SessionsEditTabsBikePictures: React.FC<any> = (props: any) => {

    const { form, prefix, bike, data, setKey } = props;

    return useMemo(() => (

        <FieldRepeater
            form={form}
            name={`${prefix}.pictures`}
            addButtonLabel={"Add Picture"}
        >

            {(bike.pictures || []).map((row, n) => (
                <React.Fragment key={form.key + "-" + n}>

                    <Row gutter={16} edge={true} className='row-top-edge'>

                        <Col col={{ xs: 12 }}>

                            <FieldUpload
                                form={form}
                                name={`${prefix}.pictures[${n}].image`}
                                folder='admin-media'
                                label='Photo'
                                required={true}
                                defaultValue={row.image}
                                multiple={false}
                                accept='image/*'
                            />

                        </Col>

                        <Col col={{ xs: 12 }}>

                            <FieldTextArea
                                form={form}
                                name={`${prefix}.pictures[${n}].caption`}
                                label={"Caption"}
                                required={true}
                                defaultValue={row.caption}
                                rows={4}
                            />

                        </Col>

                    </Row>

                </React.Fragment>
            ))}

        </FieldRepeater>

    ), [form.hash, bike]);

}

export default SessionsEditTabsBikePictures;
