import { Line, Link, Panel, useApp, useMountEffect } from '@dex/bubl-dash';
import { get, hash, loop, titleCase } from '@dex/bubl-helpers';
import React, { useContext, useEffect, useState } from 'react';
import EventTargetsApi from '../../views/EventTargets/EventTargetsApi';
import { stepsMinMax } from '../RatingScale/RatingScaleElements';
import { FiEdit2 } from 'react-icons/fi';

import styles from "../RatingScale/RatingScaleCard.module.scss";
import { RatingScaleCardInner } from '../RatingScale/RatingScaleCard';
import ReportContext from '../../context/ReportContext';
import { getRandomInt } from '../../functions/sharedGeneral';

const EventTargetCard: React.FC<EventTargetCardProps> = (props) => {

    let { metric, activity, eventType, level, gender, values, altUnits } = props;

    gender = gender || "male";
    level = level || "all";

    const group = EventTargetsApi.getData();

    const [title, setTitle]: any = useState(metric);

    const [id, setId]: any = useState(null);

    const [data, setData]: any = useState({ metric: metric });

    const app = useApp();

    useEffect(() => {

        if (props.data) setData(props.data);

    }, [hash(props.data)]);

    useEffect(() => {

        if (!metric || !eventType) return;

        let act = activity.toLowerCase();

        if (activity === "Cycling") act = "bike";
        else if (activity === "Running") act = "run";
        else if (activity === "Swimming") act = "swim";

        let params: any = {
            metric: metric,
            act: act,
            eventType: eventType,
        };

        if (props.id) params.id = props.id;

        group.run({
            params: params,
            onComplete: (response) => {

                setTitle(response.title);
                setId(response.id);

                response.title = "Effort 1";
                response.split.title = "Effort 2";

                delete (response.id);
                delete (response.split.id);

                if (response) setData(response);

            }
        });

    }, [metric, props.id, activity, eventType]);

    const { steps, min, max }: any = stepsMinMax(data, level, gender);

    values = props.values == "rand" ? [getRandomInt(min, max), getRandomInt(min, max)] : values;

    const args: any = {
        value: values[0],
        demographic: level,
        showlevel: false,
        gender: gender,
        altUnit: null,
        split: {
            metric: metric,
            value: values[1],
            altUnit: null,
        },
        steps: steps,
        min: min,
        max: max,
    }

    if (altUnits) {

        args.altUnit = {
            ...altUnits,
            value: altUnits.values[0],
        }

        args.split.altUnit = {
            ...altUnits,
            value: altUnits.values[1],
        }

    }

    if (values.length <= 1) {

        delete (args.split);

    }

    const context = useContext(ReportContext);

    const getEventTarget = (level) => {

        let eventTarget;

        loop(steps, (step, index) => {

            if (step.chartLabel == titleCase(level)) {

                eventTarget = step
            }
        })

        return eventTarget?.min + " - " + eventTarget?.max
    }

    const getRange = (target, gender, activity, level) => {

        if (!target) return "";

        gender = gender || "male";

        const range = get(target, `steps[1].${gender}.${level}`);

        return `${range.min} - ${range.max} ${target.suffix || ""}`;

    };

    return (

        <>

            <Panel heading={false} className={[styles.panel, styles.panelEvent].join(" ")}>

                <div className={styles.heading}>
                    {app.userCan(["updateRatingScales"]) && id && !context.report &&
                        <Link
                            className={styles.edit}
                            target={"_blank"}
                            href={`/lab/event-targets/${id}/edit`}
                            title={"Edit Rating Scale"}
                        >
                            <FiEdit2 />
                        </Link>
                    }

                    {metric} - {titleCase(activity)}
                </div>

                <Line bottom={"small"} />

                {group.data &&
                    <RatingScaleCardInner
                        data={data}
                        {...args}
                    />
                }

                {group.data &&
                    <div className={styles.demographic}>
                        {titleCase(level)} Target Range: {getRange(group.data, gender, activity, level)}
                    </div>
                }

            </Panel>

        </>

    )

}

interface EventTargetCardProps {
    [key: string]: any,
}

export default EventTargetCard;