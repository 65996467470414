import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { FieldRadio, FieldRange, FieldTextArea, Heading, Line, Seperator, Space, useMountEffect } from '@dex/bubl-dash';
import { get } from '@dex/bubl-helpers';
import FieldRangeLabels from '../../../elements/FieldRangeLabels/FieldRangeLabels';
import { GaitsEditTabsRunSideViewIssue, HeadingTitleImage } from './GaitsEditTabsRunSideView';
import { ColoredHeader } from '../Report/GaitReportPage';

const GaitsEditTabsRunRearView: React.FC<any> = (props: any) => {

    const { form, prefix, run, data } = props;

    return useMemo(() => (

        <>

            <ColoredHeader heading="Foot Strike (Initial)" />

            <Seperator
                heading={
                    <HeadingTitleImage
                        title="Heel Strike Orientation"
                        image="HeelStrikeOrientation"
                        desc="(Optimal : Neutral To Slightly Supinated)"
                    />
                }
                top={"auto"}
                bottom={"auto"}
            />

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Left</Heading>

                    <FieldRange
                        form={form}
                        label={[<>Pronated<br />++</>, <>Neutral</>, <>Supinated<br />++</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.rear.heelStrikeOrientation.leftRating`}
                        defaultValue={get(run, `rear.heelStrikeOrientation.leftRating`)}
                        min={-5}
                        max={5}
                        step={0.5}
                        marks={{ "-5": "-5", "0": get(run, `rear.heelStrikeOrientation.leftRating`), "5": "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="heelStrikeOrientation"
                        keyName={"rear.heelStrikeOrientation.issueLeft"}
                        value={get(run, `rear.heelStrikeOrientation.leftRating`)}
                        label="Issue Left?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Right</Heading>

                    <FieldRange
                        form={form}
                        label={[<>Pronated<br />++</>, <>Neutral</>, <>Supinated<br />++</>]} labelElement={FieldRangeLabels}
                        name={`${prefix}.rear.heelStrikeOrientation.rightRating`}
                        defaultValue={get(run, `rear.heelStrikeOrientation.rightRating`)}
                        min={-5}
                        max={5}
                        step={0.5}
                        marks={{ "-5": "-5", "0": get(run, `rear.heelStrikeOrientation.rightRating`), "5": "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="heelStrikeOrientation"
                        keyName={"rear.heelStrikeOrientation.issueRight"}
                        value={get(run, `rear.heelStrikeOrientation.rightRating`)}
                        label="Issue Right?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <FieldTextArea
                        form={form}
                        name={`${prefix}.rear.heelStrikeOrientation.comment`}
                        defaultValue={get(run, `rear.heelStrikeOrientation.comment`)}
                        rows={6}
                        wrapClass={"manual-input"}
                    />

                </Col>

            </Row>

            <Seperator
                heading={
                    <HeadingTitleImage
                        title="Foot Pronation Rate"
                        image="FootPronationRate"
                        desc="(Optimal : Moderate)"
                    />
                }
                top={"auto"}
                bottom={"auto"}
            />

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Left</Heading>

                    <FieldRange
                        form={form}
                        label={[<>None</>, <>Moderate</>, <>Excessive</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.rear.footPronationRate.leftRating`}
                        defaultValue={get(run, `rear.footPronationRate.leftRating`)}
                        min={0}
                        max={5}
                        step={0.5}
                        marks={{ 0: "0", 2.5: get(run, `rear.footPronationRate.leftRating`), 5: "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="footPronationRate"
                        keyName={"rear.footPronationRate.issueLeft"}
                        value={get(run, `rear.footPronationRate.leftRating`)}
                        label="Issue Left?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Right</Heading>

                    <FieldRange
                        form={form}
                        label={[<>None</>, <>Moderate</>, <>Excessive</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.rear.footPronationRate.rightRating`}
                        defaultValue={get(run, `rear.footPronationRate.rightRating`)}
                        min={0}
                        max={5}
                        step={0.5}
                        marks={{ 0: "0", 2.5: get(run, `rear.footPronationRate.rightRating`), 5: "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="footPronationRate"
                        keyName={"rear.footPronationRate.issueRight"}
                        value={get(run, `rear.footPronationRate.rightRating`)}
                        label="Issue Right?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <FieldTextArea
                        form={form}
                        name={`${prefix}.rear.footPronationRate.comment`}
                        defaultValue={get(run, `rear.footPronationRate.comment`)}
                        rows={6}
                        wrapClass={"manual-input"}
                    />

                </Col>

            </Row>

            <Space />

            <ColoredHeader heading=" Mid-Stance" />

            <Seperator
                heading={
                    <HeadingTitleImage
                        title="Foot Direction"
                        image="FootDirection"
                        desc="(Optimal : Straight)"
                    />
                }
                top={"auto"}
                bottom={"auto"}
            />

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Left</Heading>

                    <FieldRange
                        form={form}
                        label={[<>Toed In <br /> ++</>, <>Straight</>, <>Toed Out<br />++</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.rear.footDirection.leftRating`}
                        defaultValue={get(run, `rear.footDirection.leftRating`)}
                        min={-5}
                        max={5}
                        step={0.5}
                        marks={{ "-5": "-5", "0": get(run, `rear.footDirection.leftRating`), "5": "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="footDirection"
                        keyName={"rear.footDirection.issueLeft"}
                        value={get(run, `rear.footDirection.leftRating`)}
                        label="Issue Left?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Right</Heading>

                    <FieldRange
                        form={form}
                        label={[<>Toed In <br /> ++</>, <>Straight</>, <>Toed Out<br />++</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.rear.footDirection.rightRating`}
                        defaultValue={get(run, `rear.footDirection.rightRating`)}
                        min={-5}
                        max={5}
                        step={0.5}
                        marks={{ "-5": "-5", "0": get(run, `rear.footDirection.rightRating`), "5": "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="footDirection"
                        keyName={"rear.footDirection.issueRight"}
                        value={get(run, `rear.footDirection.rightRating`)}
                        label="Issue Right?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <FieldTextArea
                        form={form}
                        name={`${prefix}.rear.footDirection.comment`}
                        defaultValue={get(run, `rear.footDirection.comment`)}
                        rows={6}
                        wrapClass={"manual-input"}
                    />

                </Col>

            </Row>

            {/* <Seperator
                heading={
                    <HeadingTitleImage
                        title="Leg Angle"
                        image="LegAngle"
                        desc="(Optimal : Straight)"
                    />
                }
                top={"auto"}
                bottom={"auto"}
            /> */}

            {/* <Row edge gutter={"auto"}>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Left</Heading>

                    <FieldRange
                        form={form}
                        label={[<>Knee In<br />++</>, <>Straight</>, <>Knee Out<br />++</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.rear.legAngle.leftRating`}
                        defaultValue={get(run, `rear.legAngle.leftRating`)}
                        min={-5}
                        max={5}
                        step={0.5}
                        marks={{ "-5": "-5", "0": get(run, `rear.heelStrikeOrientation.leftRating`), "5": "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="legAngle"
                        keyName={"rear.legAngle.issueLeft"}
                        value={get(run, `rear.legAngle.leftRating`)}
                        label="Issue Left?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Right</Heading>

                    <FieldRange
                        form={form}
                        label={[<>Knee In<br />++</>, <>Straight</>, <>Knee Out<br />++</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.rear.legAngle.rightRating`}
                        defaultValue={get(run, `rear.legAngle.rightRating`)}
                        min={-5}
                        max={5}
                        step={0.5}
                        marks={{ "-5": "-5", "0": get(run, `rear.heelStrikeOrientation.leftRating`), "5": "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="legAngle"
                        keyName={"rear.legAngle.issueRight"}
                        value={get(run, `rear.legAngle.rightRating`)}
                        label="Issue Right?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <FieldTextArea
                        form={form}
                        name={`${prefix}.rear.legAngle.comment`}
                        defaultValue={get(run, `rear.legAngle.comment`)}
                        rows={6}
                        wrapClass={"manual-input"}
                    />

                </Col>

            </Row> */}

            <Seperator
                heading={
                    <HeadingTitleImage
                        title="Knee Shifting"
                        image="KneeShifting"
                        desc="(Optimal : None)"
                    />
                }
                top={"auto"}
                bottom={"auto"}
            />

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Left</Heading>

                    <FieldRange
                        form={form}
                        label={[<>Knee In <br /> ++</>, <>None<br />(Stable)</>, <>Knee Out<br />++</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.rear.kneeShifting.leftRating`}
                        defaultValue={get(run, `rear.kneeShifting.leftRating`)}
                        min={-5}
                        max={5}
                        step={0.5}
                        marks={{ "-5": "-5", 0: get(run, `rear.kneeShifting.leftRating`), 5: "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="kneeShifting"
                        keyName={"rear.kneeShifting.issueLeft"}
                        value={get(run, `rear.kneeShifting.leftRating`)}
                        label="Issue Left?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Right</Heading>

                    <FieldRange
                        form={form}
                        label={[<>Knee In <br /> ++</>, <>None<br />(Stable)</>, <>Knee Out<br />++</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.rear.kneeShifting.rightRating`}
                        defaultValue={get(run, `rear.kneeShifting.rightRating`)}
                        min={-5}
                        max={5}
                        step={0.5}
                        marks={{ "-5": "-5", 0: get(run, `rear.kneeShifting.rightRating`), 5: "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="kneeShifting"
                        keyName={"rear.kneeShifting.issueRight"}
                        value={get(run, `rear.kneeShifting.rightRating`)}
                        label="Issue Right?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <FieldTextArea
                        form={form}
                        name={`${prefix}.rear.kneeShifting.comment`}
                        defaultValue={get(run, `rear.kneeShifting.comment`)}
                        rows={6}
                        wrapClass={"manual-input"}
                    />

                </Col>

            </Row>

            <Seperator
                heading={
                    <HeadingTitleImage
                        title="Pelvic Drop"
                        image="PelvicDrop"
                        desc="(Optimal : Moderate)"
                    />
                }
                top={"auto"}
                bottom={"auto"}
            />

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Left</Heading>

                    <FieldRange
                        form={form}
                        label={[<>None</>, <>Moderate</>, <>Excessive</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.rear.pelvicDrop.leftRating`}
                        defaultValue={get(run, `rear.pelvicDrop.leftRating`)}
                        min={0}
                        max={5}
                        step={0.5}
                        marks={{ 0: "0", 2.5: get(run, `rear.pelvicDrop.leftRating`), 5: "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="pelvicDrop"
                        keyName={"rear.pelvicDrop.issueLeft"}
                        value={get(run, `rear.pelvicDrop.leftRating`)}
                        label="Issue Left?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Right</Heading>

                    <FieldRange
                        form={form}
                        label={[<>None</>, <>Moderate</>, <>Excessive</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.rear.pelvicDrop.rightRating`}
                        defaultValue={get(run, `rear.pelvicDrop.rightRating`)}
                        min={0}
                        max={5}
                        step={0.5}
                        marks={{ 0: "0", 2.5: get(run, `rear.pelvicDrop.rightRating`), 5: "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="pelvicDrop"
                        keyName={"rear.pelvicDrop.issueRight"}
                        value={get(run, `rear.pelvicDrop.rightRating`)}
                        label="Issue Right?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <FieldTextArea
                        form={form}
                        name={`${prefix}.rear.pelvicDrop.comment`}
                        defaultValue={get(run, `rear.pelvicDrop.comment`)}
                        rows={6}
                        wrapClass={"manual-input"}
                    />

                </Col>

            </Row>

            <Seperator
                heading={
                    <HeadingTitleImage
                        title="Torso Rotation"
                        image="TorsoRotation"
                        desc="(Optimal : Moderate)"
                    />
                }
                top={"auto"}
                bottom={"auto"}
            />

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Left</Heading>

                    <FieldRange
                        form={form}
                        label={[<>None</>, <>Moderate</>, <>Excessive</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.rear.torsoRotation.leftRating`}
                        defaultValue={get(run, `rear.torsoRotation.leftRating`)}
                        min={0}
                        max={5}
                        step={0.5}
                        marks={{ 0: "0", 2.5: get(run, `rear.torsoRotation.leftRating`), 5: "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="torsoRotation"
                        keyName={"rear.torsoRotation.issueLeft"}
                        value={get(run, `rear.torsoRotation.leftRating`)}
                        label="Issue Left?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Right</Heading>

                    <FieldRange
                        form={form}
                        label={[<>None</>, <>Moderate</>, <>Excessive</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.rear.torsoRotation.rightRating`}
                        defaultValue={get(run, `rear.torsoRotation.rightRating`)}
                        min={0}
                        max={5}
                        step={0.5}
                        marks={{ 0: "0", 2.5: get(run, `rear.torsoRotation.rightRating`), 5: "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="torsoRotation"
                        keyName={"rear.torsoRotation.issueRight"}
                        value={get(run, `rear.torsoRotation.rightRating`)}
                        label="Issue Right?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <FieldTextArea
                        form={form}
                        name={`${prefix}.rear.torsoRotation.comment`}
                        defaultValue={get(run, `rear.torsoRotation.comment`)}
                        rows={6}
                        wrapClass={"manual-input"}
                    />

                </Col>

            </Row>

            <Seperator
                heading={
                    <HeadingTitleImage
                        title="Elbow Position"
                        image="ElbowPosition"
                        desc="(Optimal : Slightly Outwards)"
                    />
                }
                top={"auto"}
                bottom={"auto"}
            />

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Left</Heading>

                    <FieldRange
                        form={form}
                        label={[<>Inwards<br /> ++</>, <>Neutral</>, <>Outwards<br /> ++</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.rear.elbowPosition.leftRating`}
                        defaultValue={get(run, `rear.elbowPosition.leftRating`)}
                        min={-5}
                        max={5}
                        step={0.5}
                        marks={{ "-5": "-5", "0": get(run, `rear.elbowPosition.leftRating`), "5": "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="elbowPosition"
                        keyName={"rear.elbowPosition.issueLeft"}
                        value={get(run, `rear.elbowPosition.leftRating`)}
                        label="Issue Left?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Right</Heading>

                    <FieldRange
                        form={form}
                        label={[<>Inwards<br /> ++</>, <>Neutral</>, <>Outwards<br /> ++</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.rear.elbowPosition.rightRating`}
                        defaultValue={get(run, `rear.elbowPosition.rightRating`)}
                        min={-5}
                        max={5}
                        step={0.5}
                        marks={{ "-5": "-5", "0": get(run, `rear.elbowPosition.rightRating`), "5": "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="elbowPosition"
                        keyName={"rear.elbowPosition.issueRight"}
                        value={get(run, `rear.elbowPosition.rightRating`)}
                        label="Issue Right?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <FieldTextArea
                        form={form}
                        name={`${prefix}.rear.elbowPosition.comment`}
                        defaultValue={get(run, `rear.elbowPosition.comment`)}
                        rows={6}
                        wrapClass={"manual-input"}
                    />

                </Col>

            </Row>

            <Space />

            <ColoredHeader heading=" Swing" />

            <Seperator
                heading={
                    <HeadingTitleImage
                        title="Toe Off Area "
                        image="ToeOffArea"
                        desc="(Optimal : Mid To Big Toes)"
                    />
                }
                top={"auto"}
                bottom={"auto"}
            />

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Left</Heading>

                    <FieldRange
                        form={form}
                        label={[<>Big Toes</>, <>Mid-toes</>, <>Little Toes</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.rear.toeOffArea.leftRating`}
                        defaultValue={get(run, `rear.toeOffArea.leftRating`)}
                        min={0}
                        max={5}
                        step={0.5}
                        marks={{ "0": "0", "2.5": get(run, `rear.toeOffArea.leftRating`), "5": "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="toeOffArea"
                        keyName={"rear.toeOffArea.issueLeft"}
                        value={get(run, `rear.toeOffArea.leftRating`)}
                        label="Issue Left?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Right</Heading>

                    <FieldRange
                        form={form}
                        label={[<>Big Toes</>, <>Mid-toes</>, <>Little Toes</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.rear.toeOffArea.rightRating`}
                        defaultValue={get(run, `rear.toeOffArea.rightRating`)}
                        min={0}
                        max={5}
                        step={0.5}
                        marks={{ "0": "0", "2.5": get(run, `rear.toeOffArea.rightRating`), "5": "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="toeOffArea"
                        keyName={"rear.toeOffArea.issueRight"}
                        value={get(run, `rear.toeOffArea.rightRating`)}
                        label="Issue Right?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <FieldTextArea
                        form={form}
                        name={`${prefix}.rear.toeOffArea.comment`}
                        defaultValue={get(run, `rear.toeOffArea.comment`)}
                        rows={6}
                        wrapClass={"manual-input"}
                    />

                </Col>

            </Row>

            <Seperator
                heading={
                    <HeadingTitleImage
                        title="Heel Whip ( Swing Mid- Stance)"
                        image="HeelWhip"
                        desc="(Optimal : Straight)"
                    />
                }
                top={"auto"}
                bottom={"auto"}
            />

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Left</Heading>

                    <FieldRange
                        form={form}
                        label={[<>Toed In<br /> ++</>, <>Neutral</>, <>Toed Out<br /> ++</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.rear.heelWhip.leftRating`}
                        defaultValue={get(run, `rear.heelWhip.leftRating`)}
                        min={-5}
                        max={5}
                        step={0.5}
                        marks={{ "-5": "-5", "0": get(run, `rear.heelWhip.leftRating`), "5": "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="heelWhip"
                        keyName={"rear.heelWhip.issueLeft"}
                        value={get(run, `rear.heelWhip.leftRating`)}
                        label="Issue Left?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Right</Heading>

                    <FieldRange
                        form={form}
                        label={[<>Toed In<br /> ++</>, <>Neutral</>, <>Toed Out<br /> ++</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.rear.heelWhip.rightRating`}
                        defaultValue={get(run, `rear.heelWhip.rightRating`)}
                        min={-5}
                        max={5}
                        step={0.5}
                        marks={{ "-5": "-5", "0": get(run, `rear.heelWhip.rightRating`), "5": "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="heelWhip"
                        keyName={"rear.heelWhip.issueRight"}
                        value={get(run, `rear.heelWhip.rightRating`)}
                        label="Issue Right?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <FieldTextArea
                        form={form}
                        name={`${prefix}.rear.heelWhip.comment`}
                        defaultValue={get(run, `rear.heelWhip.comment`)}
                        rows={6}
                        wrapClass={"manual-input"}
                    />

                </Col>

            </Row>

            <Seperator
                heading={
                    <HeadingTitleImage
                        title="Leg Cross On Foot Strike"
                        image="LegCross"
                        desc="(Optimal :  No Crossover)"
                    />
                }
                top={"auto"}
                bottom={"auto"}
            />

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Left</Heading>

                    <FieldRange
                        form={form}
                        label={[<>Crosses In<br /> ++</>, <>Midline</>, <>Crosses Out<br /> ++</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.rear.legCross.leftRating`}
                        defaultValue={get(run, `rear.legCross.leftRating`)}
                        min={-5}
                        max={5}
                        step={0.5}
                        marks={{ "-5": "-5", "0": get(run, `rear.legCross.leftRating`), "5": "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="legCross"
                        keyName={"rear.legCross.issueLeft"}
                        value={get(run, `rear.legCross.leftRating`)}
                        label="Issue Left?"
                    />
                </Col>

                <Col col={{ xs: 8 }}>

                    <Heading size={4} space={null}>Right</Heading>

                    <FieldRange
                        form={form}
                        label={[<>Crosses In<br /> ++</>, <>Midline</>, <>Crosses Out<br /> ++</>]}
                        labelElement={FieldRangeLabels}
                        name={`${prefix}.rear.legCross.rightRating`}
                        defaultValue={get(run, `rear.legCross.rightRating`)}
                        min={-5}
                        max={5}
                        step={0.5}
                        marks={{ "-5": "-5", "0": get(run, `rear.legCross.rightRating`), "5": "5" }}
                    />

                    <GaitsEditTabsRunSideViewIssue
                        form={form}
                        prefix={prefix}
                        run={run}
                        type="legCross"
                        keyName={"rear.legCross.issueRight"}
                        value={get(run, `rear.legCross.rightRating`)}
                        label="Issue Right?"
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <FieldTextArea
                        form={form}
                        name={`${prefix}.rear.legCross.comment`}
                        defaultValue={get(run, `rear.legCross.comment`)}
                        rows={6}
                        wrapClass={"manual-input"}
                    />

                </Col>

            </Row>

        </>

    ), [form.hash, run]);

}

export default GaitsEditTabsRunRearView;
