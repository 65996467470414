import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldAutocomplete, FieldNumber, FieldPhone, FieldRadio, FieldSelect, FieldText, FieldUpload } from '@dex/bubl-dash';

const TeamsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Team Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, sm: 24, lg: 8 }}>

                        <FieldText
                            form={form}
                            name="name"
                            label="Team Name"
                            required={true}
                            defaultValue={form.defaults.name}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldRadio
                            button={true}
                            form={form}
                            label={"Gender"}
                            name='gender'
                            defaultValue={form.defaults.gender}
                            options={[
                                { value: "male", label: "Male" },
                                { value: "female", label: "Female" },
                                { value: "mixed", label: "Mixed" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 3 }}>

                        <FieldNumber
                            form={form}
                            name="year"
                            label="Year"
                            defaultValue={form.defaults.year}
                            thousandSeparator={false}
                            max={9999}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldAutocomplete
                            form={form}
                            label={"Main Sport"}
                            name='mainSport'
                            defaultValue={form.defaults.mainSport}
                            group={"mainSport"}
                            options={[
                                { value: "Cycling", label: "Cycling" },
                                { value: "Triathlon", label: "Triathlon" },
                                { value: "Running", label: "Running" },
                                { value: "Swimming", label: "Swimming" },
                                { value: "Football", label: "Football" },
                                { value: "Rugby", label: "Rugby" },
                                { value: "Basketball", label: "Basketball" },
                                { value: "Cricket", label: "Cricket" },
                                { value: "Gymnastics", label: "Gymnastics" },
                            ]}
                            sort={false}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldAutocomplete
                            key={form.values.mainSport}
                            form={form}
                            name="discipline"
                            label="Discipline"
                            defaultValue={form.defaults.discipline}
                            group={"discipline." + form.values.mainSport}
                            preload={true}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldSelect
                            form={form}
                            name="level"
                            label="Level"
                            defaultValue={form.defaults.level}
                            options={[
                                { value: "national", label: "National" },
                                { value: "state", label: "State" },
                                { value: "junior", label: "Junior" },
                                { value: "amateur", label: "Amateur" },
                                { value: "club", label: "Club" },
                                { value: "novice", label: "Novice" },
                                { value: "age-grouper", label: "Age-Grouper" },
                                { value: "recreational", label: "Recreational" },
                                { value: "other", label: "Other" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldText
                            form={form}
                            name="trainingVenue"
                            label="Training Venue"
                            defaultValue={form.defaults.trainingVenue}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldUpload
                            form={form}
                            name='photo'
                            folder='admin-media'
                            label='Photo'
                            defaultValue={form.defaults.photo}
                            multiple={false}
                            accept='image/*'
                        />

                    </Col>

                </Row>

                <Line />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldText
                            form={form}
                            name="coachName"
                            label="Coach Name"
                            defaultValue={form.defaults.coachName}
                        />

                        <FieldText
                            form={form}
                            name="coachEmail"
                            label="Coach Email"
                            defaultValue={form.defaults.coachEmail}
                        />

                        <FieldPhone
                            form={form}
                            name={'coachPhone'}
                            label={"Coach Phone"}
                            defaultValue={form.defaults.coachPhone}
                        />

                    </Col>

                    <Col col={{ xs: 24, sm: 12, lg: 8 }}>

                        <FieldText
                            form={form}
                            name="managerName"
                            label="Manager Name"
                            defaultValue={form.defaults.managerName}
                        />

                        <FieldText
                            form={form}
                            name="managerEmail"
                            label="Manager Email"
                            defaultValue={form.defaults.managerEmail}
                        />

                        <FieldPhone
                            form={form}
                            label={"Manager Phone"}
                            name={'managerPhone'}
                            defaultValue={form.defaults.managerPhone}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Save"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel >


    ), [props]);

}

export default TeamsCreateEditForm;
