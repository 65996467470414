import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import { Button, FieldCheckbox, FieldHidden, FieldNumber, FieldSelect, FieldText, FieldUpload, Heading, index, InputTable, InputTableCell, InputTableHead, InputTableRow, MediaImage, Panel, Space, useMountEffect, useUpdateEffect } from '@dex/bubl-dash';
import { fixedNum, loop, lowerCase } from '@dex/bubl-helpers';
import { airDensityCalculation, airPressureCalculation, gravityCalculation } from '../../../functions/sharedAerodynamic';
import styles from "./BikeAerodynamic.module.scss";
import { bikeTypeOptions, calculateAirSpeed, calculateBikeSpeed, calculateCoefficientDrag, calculateDragForce, calculatePowerWithDrive, calculateRollingResistanceCoefficient, calculateRollingResistanceDrag, calculateTotalResistance, clothingOptions, gravititionalForce, helmetOptions, pressureOptions, qualityOptions, ridingPositionOptions, roadSurfaceOptions, roadTypeOptions, widthOptions } from '../../../functions/sharedAerodynamicBike';

const SessionsEditTabsBikeParameters: React.FC<any> = (props: any) => {

    const { form, prefix, bike, data, setKey } = props;
    useEffect(() => {

        //env section
        if (!bike.parameter?.gravitationalAcceleration) form.handleChange({ reset: true, name: `${prefix}.parameter.gravitationalAcceleration`, value: gravititionalForce });
        if (!bike.parameter?.relativeHumidity) form.handleChange({ reset: true, name: `${prefix}.parameter.relativeHumidity`, value: 50 });
        if (!bike.parameter?.airTemperature) form.handleChange({ reset: true, name: `${prefix}.parameter.airTemperature`, value: 30 });

        //bike section
        if (!bike.parameter?.driveChangePowerLoss) form.handleChange({ reset: true, name: `${prefix}.parameter.driveChangePowerLoss`, value: 2 });

    }, [props]);

    useUpdateEffect(() => {

        form.handleChange({ reset: true, name: `${prefix}.parameter.roadTypeCrr`, value: roadTypeOptions.find(x => x.label === bike.parameter?.roadType)?.value });

    }, [bike.parameter?.roadType]);

    useUpdateEffect(() => {

        form.handleChange({ reset: true, name: `${prefix}.parameter.roadSurfaceCrr`, value: roadSurfaceOptions.find(x => x.label === bike.parameter?.roadSurface)?.value });

    }, [bike.parameter?.roadSurface]);

    const [airDensity, setAirDensity] = useState<any>();

    useEffect(() => {
        const calcAirDensity = airDensityCalculation(bike.parameter?.relativeHumidity, bike.parameter?.airTemperature, bike.parameter?.altitude)
        setAirDensity(calcAirDensity);
    }, [bike.parameter?.relativeHumidity, bike.parameter?.airTemperature, bike.parameter?.altitude]);

    const fetchBioValue = () => {

        const bikeSpeed = form.values.bio.targetSpeed;
        const power = form.values.bio.targetPower;

        form.handleChange({ reset: true, name: `${prefix}.parameter.bikeSpeed`, value: bikeSpeed });
        form.handleChange({ reset: true, name: `${prefix}.parameter.power`, value: power });

    };
    useEffect(() => {

        const airSpeed = calculateAirSpeed(bike.parameter?.bikeSpeed, bike.parameter?.headwind);

        form.handleChange({ reset: true, name: `${prefix}.parameter.airSpeed`, value: fixedNum(airSpeed, 2) });

    }, [bike.parameter?.bikeSpeed, bike.parameter?.headwind]);

    const [roadSurfaceRating, setRoadSurfaceRating] = useState("");
    useEffect(() => {

        let output;
        output = roadSurfaceOptions.find(x => x.label === bike.parameter?.roadSurface)?.rating;
        output = lowerCase(output);
        setRoadSurfaceRating(output);

    }, [bike.parameter?.roadSurface]);

    const [gravity, setGravity] = useState<number>();
    const [rollingResistanceCoefficient, setRollingResistanceCoefficient] = useState<number>();
    const [rollingResistanceDrag, setRollingResistanceDrag] = useState<number>();
    useEffect(() => {

        const calcGravity = gravityCalculation(bike.parameter?.percentGrade, data.bio?.weight, bike.parameter?.bikeWeight)
        setGravity(calcGravity)

        const calcRollingResistanceCoefficient = calculateRollingResistanceCoefficient(roadSurfaceRating, bike.parameter?.roadTypeCrr, bike.parameter?.roadSurfaceCrr, bike.parameter?.pressureCrr, bike.parameter?.qualityCrr, bike.parameter?.widthCrr)
        setRollingResistanceCoefficient(calcRollingResistanceCoefficient)

        const calcRollingResistanceDrag = calculateRollingResistanceDrag(bike.parameter?.percentGrade, data.bio?.weight, bike.parameter?.bikeWeight, calcRollingResistanceCoefficient)
        setRollingResistanceDrag(calcRollingResistanceDrag)

    }, [form.hash]);

    return useMemo(() => (

        <Row gutter={8} edge={true}>

            <Col col={{ xs: 24 }}>

                <Heading>
                    <div style={{ display: "flex", alignItems: "flex-end", justifyContent: 'space-between' }}>
                        Target Power or Speed
                        <Button
                            onClick={fetchBioValue}
                            type="faded"
                            size="small"
                            label="Fetch Bio Value"
                        />
                    </div>
                    <Line />
                </Heading>

            </Col>

            <Col col={{ xs: 24, md: 11 }}>

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 19 }}>

                        <FieldCheckbox
                            form={form}
                            label="use"
                            name={`${prefix}.parameter.useTarget`}
                            defaultValue={bike.parameter?.useTarget}
                            wrapClass={"inline-label"}
                            singular
                            options={[
                                { label: "", value: "speed" }
                            ]}
                            key={bike.parameter?.useTarget}
                        />

                        <FieldNumber
                            form={form}
                            label="Bike Speed"
                            name={`${prefix}.parameter.bikeSpeed`}
                            defaultValue={bike.parameter?.bikeSpeed}
                            wrapClass={"inline-label manual-input"}
                            disabled={bike.parameter?.useTarget == "power"}
                            dynamicValue
                            suffix=" kph"
                        />

                        <FieldNumber
                            form={form}
                            label="Air Speed"
                            name={`${prefix}.parameter.airSpeed`}
                            defaultValue={bike.parameter?.airSpeed}
                            wrapClass={"inline-label "}
                            disabled
                            dynamicValue
                            suffix=" kph"
                        />

                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 24, md: 2 }} align='center' justify='center'>
                or
            </Col>

            <Col col={{ xs: 24, md: 11 }}>

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 19 }}>

                        <FieldCheckbox
                            form={form}
                            label="use"
                            name={`${prefix}.parameter.useTarget`}
                            defaultValue={bike.parameter?.useTarget}
                            wrapClass={"inline-label"}
                            singular
                            options={[
                                { label: "", value: "power" }
                            ]}
                            key={bike.parameter?.useTarget}
                        />

                        <FieldNumber
                            form={form}
                            label="Power"
                            name={`${prefix}.parameter.power`}
                            defaultValue={bike.parameter?.power}
                            wrapClass={"inline-label manual-input"}
                            disabled={bike.parameter?.useTarget == "speed"}
                            dynamicValue
                            suffix=" W"
                        />

                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 24, md: 12 }}>

                <EnvironmentSection
                    form={form}
                    prefix={prefix}
                    bike={bike}
                    data={data}
                    airDensity={airDensity}
                    gravity={gravity}
                />

            </Col >

            <Col col={{ xs: 24, md: 12 }}>

                <BikeSection
                    form={form}
                    prefix={prefix}
                    bike={bike}
                    data={data}
                    rollingResistanceCoefficient={rollingResistanceCoefficient}
                    roadSurfaceRating={roadSurfaceRating}
                    rollingResistanceDrag={rollingResistanceDrag}
                />

            </Col>

            <Col col={{ xs: 6 }} align='center'>

                <Space height={8} />

                <span className={styles.labelText}>

                    Air Pressure = {airPressureCalculation(bike.parameter?.altitude, bike.parameter?.airTemperature, bike.parameter?.airPressureUnit)}

                    <FieldHidden
                        form={form}
                        name={`${prefix}.parameter.airPressure`}
                        value={airPressureCalculation(bike.parameter?.altitude, bike.parameter?.airTemperature, bike.parameter?.airPressureUnit)}
                    />

                </span>

                <br />

                <span className={styles.labelText}>
                    Air Density = {fixedNum(airDensity, 2)} kg/m3

                    <FieldHidden
                        form={form}
                        name={`${prefix}.parameter.airDensity`}
                        value={airDensity}
                    />

                </span>


            </Col>

            <Col col={{ xs: 6 }}>

                <FieldSelect
                    form={form}
                    label=""
                    name={`${prefix}.parameter.airPressureUnit`}
                    defaultValue={bike.parameter?.airPressureUnit}
                    options={[
                        { label: "atm", value: "atm" },
                        { label: "mmHg", value: "mmHg" },
                        { label: "inHg", value: "inHg" },
                        { label: "kPa", value: "kPa" },
                        { label: "Pa", value: "Pa" },
                        { label: "psi", value: "psi" },
                    ]}
                />

            </Col>

            <Col col={{ xs: 6 }} align='center'>

                <Space height={8} />

                <span className={styles.labelText}>C<sub>rr</sub> :</span>
                <span className={styles.labelText}> {rollingResistanceCoefficient}</span>

                <br />

                <span className={styles.labelText}>F<sub>rr</sub> :</span>
                <span className={styles.labelText}> {rollingResistanceDrag} N</span>

            </Col>

            <Col col={{ xs: 6 }} align='center'>

                <Space height={8} />

                <span className={styles.labelText}>
                    F<sub>G</sub> = {gravityCalculation(bike.parameter?.percentGrade, data.bio?.weight, bike.parameter?.bikeWeight)} N

                    <FieldHidden
                        form={form}
                        name={`${prefix}.parameter.forceGravity`}
                        value={gravityCalculation(bike.parameter?.percentGrade, data.bio?.weight, bike.parameter?.bikeWeight)}
                    />
                </span>
            </Col>

            <Col col={{ xs: 24 }}>

                <PositionSection
                    form={form}
                    data={data}
                    prefix={prefix}
                    bike={bike}
                    airDensity={airDensity}
                    gravity={gravity}
                    rollingResistanceDrag={rollingResistanceDrag}
                    rollingResistanceCoefficient={rollingResistanceCoefficient}
                />

            </Col>

        </Row >


    ), [props, form.hash]);

}

const EnvironmentSection: React.FC<any> = (props: any) => {

    const { form, prefix, bike, data, airDensity } = props;

    return (
        <>
            <Heading>
                Environment
                <Line />
            </Heading>

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 19 }}>

                    <Space />

                    <FieldNumber
                        form={form}
                        label="Air Temperature"
                        name={`${prefix}.parameter.airTemperature`}
                        defaultValue={bike.parameter?.airTemperature}
                        wrapClass="manual-input inline-label"
                        suffix=" C"
                        dynamicValue
                    />

                </Col>

                <Col col={{ xs: 19 }}>

                    <FieldNumber
                        form={form}
                        label="Relative Humidity"
                        name={`${prefix}.parameter.relativeHumidity`}
                        defaultValue={bike.parameter?.relativeHumidity}
                        wrapClass="manual-input inline-label"
                        suffix=" %"
                        dynamicValue
                    />

                </Col>

                <Col col={{ xs: 19 }}>

                    <FieldNumber
                        form={form}
                        label="Altitude"
                        name={`${prefix}.parameter.altitude`}
                        defaultValue={bike.parameter?.altitude}
                        wrapClass="manual-input inline-label"
                        suffix=" m"
                    />

                </Col>

                <Col col={{ xs: 19 }}>

                    <FieldNumber
                        form={form}
                        label="% Grade"
                        name={`${prefix}.parameter.percentGrade`}
                        defaultValue={bike.parameter?.percentGrade}
                        wrapClass="manual-input inline-label"
                        suffix=" %"
                    />

                </Col>

                <Col col={{ xs: 19 }}>

                    <FieldNumber
                        form={form}
                        label="Headwind"
                        name={`${prefix}.parameter.headwind`}
                        defaultValue={bike.parameter?.headwind}
                        wrapClass="manual-input inline-label"
                        suffix=" kph"
                    />

                </Col>

                <Col col={{ xs: 19 }}>

                    <FieldNumber
                        form={form}
                        label="Yaw Angle"
                        name={`${prefix}.parameter.yawAngle`}
                        defaultValue={bike.parameter?.yawAngle}
                        wrapClass="manual-input inline-label"
                        suffix=" deg"
                    />

                </Col>

                <Col col={{ xs: 19 }}>

                    <Space />

                    <FieldSelect
                        form={form}
                        label="Road Type"
                        name={`${prefix}.parameter.roadType`}
                        defaultValue={bike.parameter?.roadType}
                        wrapClass="manual-input inline-label"
                        options={[
                            { label: "Track", value: "Track" },
                            { label: "Road", value: "Road" },
                            { label: "Off-Road", value: "Off-Road" },
                        ]}
                    />

                </Col>

                <Col col={{ xs: 5 }}>

                    <FieldText
                        form={form}
                        //@ts-ignore
                        label={<span>C<sub>rr</sub> Adjustment</span>}
                        name={`${prefix}.parameter.roadTypeCrr`}
                        defaultValue={bike.parameter?.roadTypeCrr}
                        wrapClass={"green-input"}
                        dynamicValue
                    />

                </Col>

                <Col col={{ xs: 19 }}>

                    <FieldSelect
                        form={form}
                        label="Road Surface"
                        name={`${prefix}.parameter.roadSurface`}
                        defaultValue={bike.parameter?.roadSurface}
                        wrapClass="manual-input inline-label"
                        options={[
                            bike.parameter?.roadType == "Track" ? { label: "Wood", value: "Wood" } : null,
                            bike.parameter?.roadType == "Track" || bike.parameter?.roadType == "Road" ? { label: "Smooth Concreate", value: "Smooth Concreate" } : null,
                            bike.parameter?.roadType == "Track" ? { label: "Smooth Asphalt", value: "Smooth Asphalt" } : null,
                            bike.parameter?.roadType == "Off-Road" || bike.parameter?.roadType == "Road" ? { label: "Rough Asphalt", value: "Rough Asphalt" } : null,
                            bike.parameter?.roadType == "Off-Road" || bike.parameter?.roadType == "Road" ? { label: "Mixed", value: "Mixed" } : null,
                            bike.parameter?.roadType == "Off-Road" || bike.parameter?.roadType == "Road" ? { label: "Gravel", value: "Gravel" } : null,
                            bike.parameter?.roadType == "Off-Road" || bike.parameter?.roadType == "Road" ? { label: "Cobblestones/Brick", value: "Cobblestones/Brick" } : null,
                            bike.parameter?.roadType == "Off-Road" || bike.parameter?.roadType == "Road" ? { label: "Uneven Dirt", value: "Uneven Dirt" } : null,
                        ].filter((option): option is { label: string; value: string } => option !== null)}
                    />

                </Col>

                <Col col={{ xs: 5 }}>

                    <FieldText
                        form={form}
                        label={""}
                        name={`${prefix}.parameter.roadSurfaceCrr`}
                        defaultValue={bike.parameter?.roadSurfaceCrr}
                        wrapClass={"green-input"}
                        dynamicValue
                    />

                </Col>

                <Col col={{ xs: 19 }}>

                    <FieldNumber
                        form={form}
                        label="Gravitational Acceleration"
                        name={`${prefix}.parameter.gravitationalAcceleration`}
                        defaultValue={bike.parameter?.gravitationalAcceleration}
                        wrapClass=" inline-label"
                        disabled
                        suffix=" m/sec²"
                        dynamicValue
                    />

                </Col>

            </Row>
        </>
    )
};

const BikeSection: React.FC<any> = (props: any) => {

    const { form, prefix, bike, data, rollingResistanceCoefficient, roadSurfaceRating, rollingResistanceDrag } = props;

    useUpdateEffect(() => {
        form.handleChange({ reset: true, name: `${prefix}.parameter.bikeType`, value: bike.type });
        form.handleChange({ reset: true, name: `${prefix}.parameter.bikeTypeCd`, value: bikeTypeOptions.find(x => x.label === bike.type)?.value });
    }, [bike.type]);

    useUpdateEffect(() => {
        form.handleChange({ reset: true, name: `${prefix}.parameter.bikeWeight`, value: fixedNum(bike.parameter?.bikeAndRiderWeight - form.values.bio?.weight, 2) });
    }, [bike.parameter?.bikeAndRiderWeight]);

    useUpdateEffect(() => {
        form.handleChange({ reset: true, name: `${prefix}.parameter.helmetCd`, value: helmetOptions.find(x => x.label === bike.parameter?.helmet)?.value });
    }, [bike.parameter?.helmet]);

    useUpdateEffect(() => {
        form.handleChange({ reset: true, name: `${prefix}.parameter.clothingCd`, value: clothingOptions.find(x => x.label === bike.parameter?.clothing)?.value });
    }, [bike.parameter?.clothing]);

    useUpdateEffect(() => {

        const pressureValue = roadSurfaceRating ? pressureOptions.find(x => x.label === bike.parameter?.pressure)?.[roadSurfaceRating] : "";

        form.handleChange({ reset: true, name: `${prefix}.parameter.pressureCrr`, value: pressureValue });

    }, [bike.parameter?.pressure, bike.parameter?.roadSurface]);

    useUpdateEffect(() => {
        form.handleChange({ reset: true, name: `${prefix}.parameter.qualityCrr`, value: qualityOptions.find(x => x.label === bike.parameter?.quality)?.value });
    }, [bike.parameter?.quality]);

    useUpdateEffect(() => {

        const widthValue = roadSurfaceRating ? widthOptions.find(x => x.label === bike.parameter?.width)?.[roadSurfaceRating] : "";

        form.handleChange({ reset: true, name: `${prefix}.parameter.widthCrr`, value: widthValue });

    }, [bike.parameter?.width, bike.parameter?.roadSurface]);

    return (
        <>
            <Heading>
                Bike
                <Line />
            </Heading>

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 19 }}>

                    <Space height={23} />

                    <FieldText
                        form={form}
                        label="Bike Type"
                        name={`${prefix}.parameter.bikeType`}
                        defaultValue={bike.parameter?.bikeType}
                        wrapClass="manual-input inline-label"
                        dynamicValue
                        disabled
                    />

                </Col>

                <Col col={{ xs: 5 }}>

                    <FieldText
                        form={form}
                        //@ts-ignore
                        label={<span>C<sub>rr</sub> Adjustment</span>}
                        name={`${prefix}.parameter.bikeTypeCd`}
                        defaultValue={bike.parameter?.bikeTypeCd}
                        wrapClass={"green-input"}
                        dynamicValue
                    />

                </Col>

                <Col col={{ xs: 6 }} align='end' justify='center'>
                    <span style={{ fontWeight: 600 }}>Bike & Rider kg:</span>
                </Col>

                <Col col={{ xs: 4 }}>

                    <FieldNumber
                        form={form}
                        label=""
                        name={`${prefix}.parameter.bikeAndRiderWeight`}
                        defaultValue={bike.parameter?.bikeAndRiderWeight}
                        wrapClass="manual-input inline-label w-100"
                        dynamicValue
                        suffix=" kg"
                    />

                </Col>

                <Col col={{ xs: 4 }} align='end' justify='center'>
                    <span style={{ fontWeight: 600 }}>Bike Only:</span>
                </Col>

                <Col col={{ xs: 4 }}>

                    <FieldNumber
                        form={form}
                        label=""
                        name={`${prefix}.parameter.bikeWeight`}
                        defaultValue={bike.parameter?.bikeWeight}
                        wrapClass="manual-input inline-label w-100"
                        dynamicValue
                        disabled
                        suffix=" kg"
                    />

                </Col>

                <Col col={{ xs: 19 }}>

                    <FieldSelect
                        form={form}
                        label="Helmet"
                        name={`${prefix}.parameter.helmet`}
                        defaultValue={bike.parameter?.helmet}
                        wrapClass="manual-input inline-label"
                        dynamicValue
                        options={[
                            { label: "Regular with Vents", value: "Regular with Vents" },
                            { label: "Regular few vents", value: "Regular few vents" },
                            { label: "Aero Some Vents", value: "Aero Some Vents" },
                            { label: "Aero No Vents", value: "Aero No Vents" },
                            { label: "Pro Aero", value: "Pro Aero" },
                            { label: "No Selection", value: "No Selection" },
                        ]}
                    />

                </Col>

                <Col col={{ xs: 5 }}>

                    <FieldText
                        form={form}
                        label={""}
                        name={`${prefix}.parameter.helmetCd`}
                        defaultValue={bike.parameter?.helmetCd}
                        wrapClass={"green-input"}
                        dynamicValue
                    />

                </Col>

                <Col col={{ xs: 19 }}>

                    <FieldSelect
                        form={form}
                        label="Clothing"
                        name={`${prefix}.parameter.clothing`}
                        defaultValue={bike.parameter?.clothing}
                        wrapClass="manual-input inline-label"
                        dynamicValue
                        options={[
                            { label: "Street Clothing", value: "Street Clothing" },
                            { label: "Loose Cycling Kit", value: "Loose Cycling Kit" },
                            { label: "Snug Cycling Bib", value: "Snug Cycling Bib" },
                            { label: "TT Bib", value: "TT Bib" },
                            { label: "Skin Suit", value: "Skin Suit" },
                            { label: "No Selection", value: "No Selection" },
                        ]}
                    />

                </Col>

                <Col col={{ xs: 5 }}>

                    <FieldText
                        form={form}
                        label={""}
                        name={`${prefix}.parameter.clothingCd`}
                        defaultValue={bike.parameter?.clothingCd}
                        wrapClass={"green-input"}
                        dynamicValue
                    />

                </Col>

                <Col col={{ xs: 24 }}>

                    <Heading>
                        Tires
                        <Line />
                    </Heading>
                </Col>

                <Col col={{ xs: 19 }}>

                    <Space height={23} />

                    <FieldSelect
                        form={form}
                        label="Pressure"
                        name={`${prefix}.parameter.pressure`}
                        defaultValue={bike.parameter?.pressure}
                        wrapClass="manual-input inline-label"
                        dynamicValue
                        options={[
                            { label: "Very High (>120psi)", value: "Very High (>120psi)", },
                            { label: "High (101-120psi)", value: "High (101-120psi)", },
                            { label: "Moderate (81-100psi)", value: "Moderate (81-100psi)", },
                            { label: "Low (40-80psi)", value: "Low (40-80psi)", },
                            { label: "Very Low (<40psi)", value: "Very Low (<40psi)", },
                        ]}
                    />

                </Col>

                <Col col={{ xs: 5 }}>

                    <FieldText
                        form={form}
                        //@ts-ignore
                        label={<span>C<sub>rr</sub> Adjustment</span>}
                        name={`${prefix}.parameter.pressureCrr`}
                        defaultValue={bike.parameter?.pressureCrr}
                        wrapClass={"green-input"}
                        dynamicValue
                    />

                </Col>

                <Col col={{ xs: 19 }}>

                    <FieldSelect
                        form={form}
                        label="Quality"
                        name={`${prefix}.parameter.quality`}
                        defaultValue={bike.parameter?.quality}
                        wrapClass="manual-input inline-label"
                        dynamicValue
                        options={[
                            { label: "Very High", value: "Very High" },
                            { label: "High", value: "High" },
                            { label: "Moderate", value: "Moderate" },
                            { label: "Low", value: "Low" },
                            { label: "Very Low", value: "Very Low" },
                        ]}
                    />

                </Col>

                <Col col={{ xs: 5 }}>

                    <FieldText
                        form={form}
                        label={""}
                        name={`${prefix}.parameter.qualityCrr`}
                        defaultValue={bike.parameter?.qualityCrr}
                        wrapClass={"green-input"}
                        dynamicValue
                    />

                </Col>

                <Col col={{ xs: 19 }}>

                    <FieldSelect
                        form={form}
                        label="Width"
                        name={`${prefix}.parameter.width`}
                        defaultValue={bike.parameter?.width}
                        wrapClass="manual-input inline-label"
                        dynamicValue
                        options={[
                            { label: "<23mm", value: "<23mm" },
                            { label: "23-24mm", value: "23-24mm" },
                            { label: "25-27mm", value: "25-27mm" },
                            { label: "28-30mm", value: "28-30mm" },
                            { label: "31-34mm", value: "31-34mm" },
                            { label: ">34mm", value: ">34mm" },
                        ]}
                    />

                </Col>

                <Col col={{ xs: 5 }}>

                    <FieldText
                        form={form}
                        label={""}
                        name={`${prefix}.parameter.widthCrr`}
                        defaultValue={bike.parameter?.widthCrr}
                        wrapClass={"green-input"}
                        dynamicValue
                    />

                </Col>

                <Col col={{ xs: 19 }}>

                    <FieldNumber
                        form={form}
                        label={"Drive Change Power Loss"}
                        name={`${prefix}.parameter.driveChangePowerLoss`}
                        defaultValue={bike.parameter?.driveChangePowerLoss}
                        wrapClass={"manual-input inline-label"}
                        dynamicValue
                        suffix=" %"
                    />

                </Col>

            </Row>

        </>
    )
};

const PositionSection: React.FC<any> = (props: any) => {

    const { form, data, prefix, bike, airDensity, gravity, rollingResistanceDrag, rollingResistanceCoefficient } = props;

    const getRidingPositionCd = (val) => {

        form.handleChange({ reset: true, name: val.name + "Cd", value: ridingPositionOptions.find(x => x.label === val.value)?.value })

    };

    const [dragCoefficient, setDragCoefficient] = useState<number[]>([]);
    const [dragForce, setDragForce] = useState<number[]>([]);
    const [totalDragResistance, setTotalDragResistance] = useState<number[]>([]);
    const [powerDrive, setPowerDrive] = useState<number[]>([]);
    const [bikeSpeed, setBikeSpeed] = useState<number[]>([]);

    useEffect(() => {

        const newDragCoefficients: number[] = [];
        const newDragForces: number[] = [];
        const newTotalDragResistances: number[] = [];
        const newPowerDrive: number[] = [];
        const newBikeSpeed: number[] = [];

        loop(bike.positions, (position, index) => {

            const coefficientDrag = calculateCoefficientDrag(position.ridingPositionCd, bike.parameter?.helmetCd, bike.parameter?.clothingCd, bike.parameter?.bikeTypeCd);
            const forceDrag = calculateDragForce(bike.parameter?.bikeSpeed, bike.parameter?.headwind, airDensity, position.FSA, coefficientDrag);
            const totalResistance = calculateTotalResistance([gravity, rollingResistanceDrag, forceDrag]);

            newDragCoefficients.push(coefficientDrag);
            newDragForces.push(forceDrag);
            newTotalDragResistances.push(totalResistance);

            const powerDrive = calculatePowerWithDrive(bike.parameter?.bikeSpeed, bike.parameter?.headwind, totalDragResistance[index], bike.parameter?.driveChangePowerLoss);
            const bikeSpeed = calculateBikeSpeed(bike.parameter?.power, (position?.FSA * dragCoefficient[index]), airDensity, bike.parameter?.headwind, bike.parameter?.bikeAndRiderWeight, bike.parameter?.driveChangePowerLoss, bike.parameter?.percentGrade, rollingResistanceCoefficient);

            if (bike.parameter?.useTarget == "power") {
                newPowerDrive.push(bike.parameter?.power);
                newBikeSpeed.push(bikeSpeed);
            } else {
                newPowerDrive.push(powerDrive);
                newBikeSpeed.push(bike.parameter?.bikeSpeed);
            }

        })

        setDragCoefficient(newDragCoefficients);
        setDragForce(newDragForces);
        setTotalDragResistance(newTotalDragResistances);

        setPowerDrive(newPowerDrive);
        setBikeSpeed(newBikeSpeed);

    }, [form.hash]);

    useEffect(() => {
        loop(bike.positions, (position, index) => {
            const output = position.FSA * dragCoefficient[index];

            form.handleChange({ reset: true, name: `${prefix}.positions[${index}].cdA`, value: fixedNum(output, 3) });
        })
    }, [dragCoefficient]);

    return (
        <>
            <Heading space={null}>
                Position
            </Heading>

            <InputTable >

                <InputTableRow>
                    {bike.positions?.map((position: any, index: number) => {
                        return (
                            <React.Fragment key={index}>
                                <InputTableCell align="center" className={`${index >= 1 ? "table-bdr-left-gray" : ""} table-bdr-top-none table-bdr-btm-none`}>

                                    <Heading space="small">Position {index + 1}</Heading>
                                    <Heading space="small">{position.photoCaption ? position?.photoCaption : <>&nbsp;</>}</Heading>

                                    <MediaImage
                                        id={position.photo?.fileId}
                                        filters={{ width: 150, height: 225, resizeMode: "contain", fallback: { background: "#272D60", text: position.photoCaption } }}
                                        style={{ borderRadius: 0, }}
                                        className={styles.positionImage}
                                        imgProps={{
                                            crossOrigin: "anonymous"
                                        }}
                                    />
                                </InputTableCell>

                            </React.Fragment >)
                    })}
                </InputTableRow>

                <InputTableRow>
                    {bike.positions?.map((position: any, index: number) => {
                        return (
                            <React.Fragment key={index}>
                                <InputTableCell align="center" className={`${index >= 1 ? "table-bdr-left-gray" : ""} table-bdr-top-none table-bdr-btm-none`}>

                                    <Row edge gutter={8}>

                                        <Col col={{ xs: 18 }}>

                                            <Space height={22} />

                                            <FieldSelect
                                                form={form}
                                                label=""
                                                name={`${prefix}.positions[${index}].ridingPosition`}
                                                defaultValue={position.ridingPosition}
                                                options={[
                                                    { label: "Standing, Hands on Hoods", value: "Standing, Hands on Hoods" },
                                                    { label: "Standing, Hands on  Drops", value: "Standing, Hands on  Drops" },
                                                    { label: "Hands on Hoods straight arms", value: "Hands on Hoods straight arms" },
                                                    { label: "Hands on Hoods Arms slightly bent", value: "Hands on Hoods Arms slightly bent" },
                                                    { label: "Hands on Hoods Aero", value: "Hands on Hoods Aero" },
                                                    { label: "Hands on Drops,  Straight Arms", value: "Hands on Drops,  Straight Arms" },
                                                    { label: "Hands on Drops, Arms Slightly Bent", value: "Hands on Drops, Arms Slightly Bent" },
                                                    { label: "Hands on Drops Aero", value: "Hands on Drops Aero" },
                                                    { label: "With Aerobars Relaxed", value: "With Aerobars Relaxed" },
                                                    { label: "With Aerobars Moderate", value: "With Aerobars Moderate" },
                                                    { label: "With Aerobars Aggressive", value: "With Aerobars Aggressive" },
                                                    { label: "Sprint Tuck on Top Tube", value: "Sprint Tuck on Top Tube" },
                                                    { label: "TT Aero - Hands on Basebar", value: "TT Aero - Hands on Basebar" },
                                                    { label: "TT Aero - Relaxed", value: "TT Aero - Relaxed" },
                                                    { label: "TT Aero - Moderate", value: "TT Aero - Moderate" },
                                                    { label: "TT Aero - Aggressive", value: "TT Aero - Aggressive" },
                                                    { label: "TT Aero - Pro*", value: "TT Aero - Pro*" },
                                                ]}
                                                wrapClass="manual-input"
                                                onChange={getRidingPositionCd}
                                            />
                                        </Col>

                                        <Col col={{ xs: 6 }}>
                                            <FieldText
                                                form={form}
                                                //@ts-ignore
                                                label={<span>C<sub>d</sub> Estimate</span>}
                                                name={`${prefix}.positions[${index}].ridingPositionCd`}
                                                defaultValue={position.ridingPositionCd}
                                                wrapClass={"green-input"}
                                                dynamicValue
                                            />
                                        </Col>

                                    </Row>

                                </InputTableCell>

                            </React.Fragment>)
                    })}
                </InputTableRow>

                <InputTableRow>
                    <InputTableCell colSpan={3} >
                        <Heading space={null}>
                            Cycling Metric
                        </Heading>
                    </InputTableCell>
                </InputTableRow>

                <InputTableRow>

                    {bike.positions?.length >= 0 && bike.positions?.map((position: any, index: number) => {

                        return (
                            <React.Fragment key={index}>
                                <InputTableCell align="center" className={`${index >= 1 ? "table-bdr-left-gray" : ""} table-bdr-top-none table-bdr-btm-none`}>

                                    <Row edge gutter={8}>

                                        <Col col={{ xs: 12 }}>
                                            <span>C<sub>D</sub>= </span>
                                        </Col>
                                        <Col col={{ xs: 12 }} align='start'>
                                            {dragCoefficient[index]}
                                            <FieldHidden
                                                form={form}
                                                name={`${prefix}.positions[${index}].coefficientOfDrag`}
                                                value={dragCoefficient[index]}
                                            />
                                        </Col>

                                        <Col col={{ xs: 12 }}>
                                            <span>F<sub>D</sub>= </span>
                                        </Col>
                                        <Col col={{ xs: 12 }} align='start'>
                                            {dragForce[index]} N
                                            <FieldHidden
                                                form={form}
                                                name={`${prefix}.positions[${index}].forceDrag`}
                                                value={dragForce[index]}
                                            />
                                        </Col>

                                        <Col col={{ xs: 12 }}>
                                            <span>F<sub>Total</sub>= </span>
                                        </Col>
                                        <Col col={{ xs: 12 }} align='start'>
                                            {totalDragResistance[index]} N
                                            <FieldHidden
                                                form={form}
                                                name={`${prefix}.positions[${index}].totalDragResistance`}
                                                value={totalDragResistance[index]}
                                            />
                                        </Col>

                                        <Col col={{ xs: 12 }} justify='center'>
                                            <span>CdA= </span>
                                        </Col>
                                        <Col col={{ xs: 12 }} >
                                            <FieldNumber
                                                form={form}
                                                name={`${prefix}.positions[${index}].cdA`}
                                                defaultValue={position.cdA}
                                                wrapClass={"green-input"}
                                                dynamicValue
                                                suffix=" m²"
                                            />
                                        </Col>

                                        <Col col={{ xs: 12 }}>
                                            <p className='text-secondary'>Power= </p>
                                        </Col>
                                        <Col col={{ xs: 12 }} align='start'>
                                            <p className='text-secondary'>  {powerDrive[index]} W</p>
                                        </Col>

                                        <FieldHidden
                                            form={form}
                                            name={`${prefix}.positions[${index}].power`}
                                            value={powerDrive[index]}
                                        />

                                        <Col col={{ xs: 12 }} >
                                            <p className='text-light-blue'>Speed= </p>
                                        </Col>

                                        <Col col={{ xs: 12 }} align='start'>
                                            <p className='text-light-blue'>{bikeSpeed[index]} kph</p>
                                        </Col>

                                        <FieldHidden
                                            form={form}
                                            name={`${prefix}.positions[${index}].speed`}
                                            value={bikeSpeed[index]}
                                        />
                                    </Row>

                                </InputTableCell >

                            </React.Fragment>)

                    })}

                </InputTableRow>

            </InputTable >

        </>
    )
};

export default SessionsEditTabsBikeParameters;
