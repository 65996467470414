import React from "react";
import { FieldSelect } from "@dex/bubl-dash";
import { getArchLengthDifference, getFootLengthDifference } from "../../functions/sharedBikeFit";

let AthletesTrackingRows = (dataType, dataSet, form) => {

    let rows: any = [];

    if (dataType === "labTest") {

        if (dataSet === "bio") {

            rows = [
                {
                    label: "Weight",
                    key: "bio.weight",
                    suffix: " kg",
                    format: "number"
                },
                {
                    label: "Height",
                    key: "bio.height",
                    suffix: " cm",
                    format: "number"
                },
                {
                    label: "Inseam",
                    key: "bio.inseam",
                    suffix: " cm",
                    format: "number"
                },
                {
                    label: "Manual Percentage Bf",
                    key: "bio.manualPercentageBf",
                    suffix: " %",
                    format: "number"
                },
                {
                    label: "Girths Biceps-Relaxed - Actual",
                    key: "bio.actualGirthsRelaxedBiceps",
                    suffix: " cm",
                    format: "number"
                },
                {
                    label: "Girths Biceps-Relaxed - Corrected",
                    key: "bio.correctedGirthsRelaxedBiceps",
                    suffix: " cm",
                    format: "number"
                },
                {
                    label: "Girths Upper-Forearm - Actual",
                    key: "bio.actualGirthsUpperForearm",
                    suffix: " cm",
                    format: "number"
                },
                {
                    label: "Girths Upper-Forearm - Corrected",
                    key: "bio.correctedGirthsUpperForearm",
                    suffix: " cm",
                    format: "number"
                },
                {
                    label: "Girths Mid-Thigh - Actual",
                    key: "bio.actualGirthsMidThigh",
                    suffix: " cm",
                    format: "number"
                },
                {
                    label: "Girths Mid-Thigh- Corrected",
                    key: "bio.correctedGirthsMidThigh",
                    suffix: " cm",
                    format: "number"
                },
                {
                    label: "Girths Calf - Actual",
                    key: "bio.actualGirthsCalf",
                    suffix: " cm",
                    format: "number"
                },
                {
                    label: "Girths Calf - Corrected",
                    key: "bio.correctedGirthsCalf",
                    suffix: " cm",
                    format: "number"
                },
                {
                    label: "Girths Ankle - Actual",
                    key: "bio.actualGirthsAnkle",
                    suffix: " cm",
                    format: "number"
                },
                {
                    label: "Girths Ankle - Corrected",
                    key: "bio.correctedGirthsAnkle",
                    suffix: " cm",
                    format: "number"
                },
                {
                    label: "Skinfolds Triceps",
                    key: "bio.skinFoldsAttr.tricepsAverage",
                    suffix: " mm",
                    format: "number"
                },
                {
                    label: "Skinfolds Subscapular",
                    key: "bio.skinFoldsAttr.subscapularAverage",
                    suffix: " mm",
                    format: "number"
                },
                {
                    label: "Skinfolds Biceps",
                    key: "bio.skinFoldsAttr.bicepsAverage",
                    suffix: " mm",
                    format: "number"
                },
                {
                    label: "Skinfolds Suprailium",
                    key: "bio.skinFoldsAttr.suprailiumAverage",
                    suffix: " mm",
                    format: "number"
                },
                {
                    label: "Skinfolds Abdomen",
                    key: "bio.skinFoldsAttr.tricepsAverage",
                    suffix: " mm",
                    format: "number"
                },
                {
                    label: "Skinfolds Mid Thigh",
                    key: "bio.skinFoldsAttr.midThighAverage",
                    suffix: " mm",
                    format: "number"
                },
                {
                    label: "Skinfolds Calf",
                    key: "bio.skinFoldsAttr.calfAverage",
                    suffix: " mm",
                    format: "number"
                },
                {
                    label: "Skinfolds Total",
                    key: "bio.skinfoldsSosTotal",
                    suffix: " mm",
                    format: "number"
                },
                {
                    label: "Lean Body Weight",
                    key: "bio.leanBodyWeight",
                    suffix: " kg",
                    format: "number"
                }, {
                    label: "Muscle Mass",
                    key: "bio.muscleMass",
                    suffix: " kg",
                    format: "number"
                }, {
                    label: "Upper Body Muscle Mass",
                    key: "bio.upperBodyMuscleMass",
                    suffix: " kg",
                    format: "number"
                }, {
                    label: "Lower Body Muscle Mass",
                    key: "bio.lowerBodyMuscleMass",
                    suffix: " kg",
                    format: "number"
                },

            ];

        } else if (dataSet == "health") {
            rows = [
                {
                    label: "PARQ",
                    key: "health.parq",
                },
                {
                    label: "Health Issues/Injuries",
                    key: "health.healthIssusesInjuries",
                    format: "nl2br"
                },
                {
                    label: "Medication",
                    key: "health.medication",
                    format: "nl2br"
                },
                {
                    label: "Resting HR",
                    key: "health.restingHr",
                    format: "number",
                    suffix: " BPM"
                },
                {
                    label: "Resting Bp Systolic",
                    key: "health.restingBpSystolic",
                    format: "number",
                    suffix: " mmHg"
                },
                {
                    label: "Resting Bp Diastolic",
                    key: "health.restingBpDiastolic",
                    format: "number",
                    suffix: " mmHg"
                },
                {
                    label: "Resting Blood Glucose",
                    key: "health.restingBloodGlucose",
                    format: "number",
                    suffix: " mmol/L"
                },
                {
                    label: "Resting Fev1",
                    key: "health.restingFev1",
                    format: "number",
                    suffix: " L"
                },
                {
                    label: "Resting Fvc",
                    key: "health.restingFvc",
                    format: "number",
                    suffix: " L"
                },
                {
                    label: "Fev1 Fvc Percentage",
                    key: "health.fev1FvcPercentage",
                    format: "number",
                    suffix: " %"
                }
            ]
        } else if (dataSet == "vo2") {
            rows = [
                {
                    label: "Relative [ml/kg*min] - Max",
                    key: "test.vo2.relativeMax",
                    format: "number"
                },
                {
                    label: "Relative [ml/kg*min] - Min",
                    key: "test.vo2.relativeMin",
                    format: "number"
                },
                {
                    label: "Relative [ml/kg*min] - Avg",
                    key: "test.vo2.relativeAvg",
                    format: "number"
                },
                {
                    label: "Relative [ml/kg*min] - At Rest",
                    key: "test.vo2.relativeAtRest",
                    format: "number"
                },
                {
                    label: "Relative [ml/kg*min] - Max (Peak Test)",
                    key: "test.vo2.relativeMaxPeak",
                    format: "number"
                },
                {
                    label: "Absolute [L/ min] - Max",
                    key: "test.vo2.absoluteMax",
                    format: "number"
                },
                {
                    label: "Absolute [L/ min] - Min",
                    key: "test.vo2.absoluteMin",
                    format: "number"
                },
                {
                    label: "Absolute [L/ min] - Avg",
                    key: "test.vo2.absoluteAvg",
                    format: "number"
                },
                {
                    label: "Absolute [L/ min] - At Rest",
                    key: "test.vo2.absoluteAtRest",
                    format: "number"
                },
                {
                    label: "Absolute [L/ min] - Max (Peak Test)",
                    key: "test.vo2.absoluteMaxPeak",
                    format: "number"
                }
            ]
        } else if (dataSet == "hr") {
            rows = [
                {
                    label: "Heart Rate [bpm] - Max",
                    key: "test.hr.max",
                    format: "number"
                },
                {
                    label: "Heart Rate [bpm] - Min",
                    key: "test.hr.min",
                    format: "number"
                },
                {
                    label: "Heart Rate [bpm] - Avg",
                    key: "test.hr.avg",
                    format: "number"
                },
                {
                    label: "Heart Rate [bpm] - Max Predicted",
                    key: "test.hr.maxEst",
                    format: "number"
                },
                {
                    label: "Heart Rate [bpm] - Max Training",
                    key: "test.hr.maxTraining",
                    format: "number"
                },
                {
                    label: "Heart Rate [bpm] - Max (Peak Test)",
                    key: "test.hr.max",
                    format: "number"
                },
                {
                    label: "VO2-HR [ml/kg*min/Beat] - Max",
                    key: "test.hr.vo2hrmax",
                    format: "number"
                },
                {
                    label: "VO2-HR [ml/kg*min/Beat] - Min",
                    key: "test.hr.vo2hrmin",
                    format: "number"
                },
                {
                    label: "VO2-HR [ml/kg*min/Beat] - Avg",
                    key: "test.hr.vo2hravg",
                    format: "number"
                },
                {
                    label: "VO2-HR - HR Limitation",
                    key: "test.hr.limitation",
                },
                {
                    label: "VO2-HR [ml/kg*min/Beat] - Max (Peak Test)",
                    key: "test.hr.vo2hrMaxPeak",
                    format: "number"
                }
            ]
        } else if (dataSet == "breathing") {
            rows = [
                {
                    label: "Breathing Rate [bpm] - Max",
                    key: "test.breathing.brMax",
                    format: "number"
                },
                {
                    label: "Breathing Rate [bpm] - Min",
                    key: "test.breathing.brMin",
                    format: "number"
                },
                {
                    label: "Breathing Rate [bpm] - Avg",
                    key: "test.breathing.brAvg",
                    format: "number"
                },
                {
                    label: "Tidal Volume [L/breath] - Max",
                    key: "test.breathing.tvMax",
                    format: "number"
                },
                {
                    label: "Tidal Volume [L/breath] - Min",
                    key: "test.breathing.tvMin",
                    format: "number"
                },
                {
                    label: "Tidal Volume [L/breath]- Avg",
                    key: "test.breathing.tvAvg",
                    format: "number"
                },
                {
                    label: "Rate [L/min] - Max",
                    key: "test.breathing.vrMax",
                    format: "number"
                },
                {
                    label: "Rate [L/min] - Min",
                    key: "test.breathing.vrMin",
                    format: "number"
                },
                {
                    label: "Rate [L/min] - Avg",
                    key: "test.breathing.vrAvg",
                    format: "number"
                },
                {
                    label: "Rf/FEv1 [%] - Max",
                    key: "test.breathing.beMax",
                    format: "number"
                },
                {
                    label: "Rf/FEv1 [%] - Min",
                    key: "test.breathing.beMin",
                    format: "number"
                },
                {
                    label: "Rf/FEv1 [%] - Avg",
                    key: "test.breathing.beAvg",
                    format: "number"
                },
                {
                    label: "Rf/FEv1 [%] - % Time > 60%",
                    key: "test.breathing.beZoneTime",
                    format: "number"
                },
                {
                    label: "VO2/BF [ml/breath] - Max",
                    key: "test.breathing.beVo2Max",
                    format: "number"
                },
                {
                    label: "VO2/BF [ml/breath] - Min",
                    key: "test.breathing.beVo2Min",
                    format: "number"
                },
                {
                    label: "VO2/BF [ml/breath] - Avg",
                    key: "test.breathing.beVo2Avg",
                    format: "number"
                },
                {
                    label: "VO2/BF - Trend",
                    key: "test.breathing.beVo2Trend",
                }

            ]
        } else if (dataSet == "muscle") {
            rows = [
                {
                    label: "Moxy Location A",
                    key: "test.equipment.moxyLocationA",
                },
                {
                    label: "Moxy Sensor A",
                    key: "test.equipment.moxySensorA",
                },
                {
                    label: "SMO2 % - Min",
                    key: "test.muscle.a.smo2Min",
                    format: "number",
                },
                {
                    label: "SMO2 % - Avg",
                    key: "test.muscle.a.smo2Avg",
                    format: "number",
                },
                {
                    label: "SMO2 % - Max",
                    key: "test.muscle.a.smo2Max",
                    format: "number",
                },
                {
                    label: "SMO2 % - Drop",
                    key: "test.muscle.a.smo2Drop",
                    format: "number",
                },
                {
                    label: "THb mg/dl - Min",
                    key: "test.muscle.a.thbMin",
                    format: "number",
                },
                {
                    label: "THb mg/dl - Avg",
                    key: "test.muscle.a.thbAvg",
                    format: "number",
                },
                {
                    label: "THb mg/dl - Max",
                    key: "test.muscle.a.thbMax",
                    format: "number",
                },
                {
                    label: "THb mg/dl - Trend",
                    key: "test.muscle.a.thbTrend",
                },
                {
                    label: "Zones- Endurance",
                    key: "test.muscle.a.zonesEndurance",
                    format: "number",
                },
                {
                    label: "Zones- Threshold",
                    key: "test.muscle.a.zonesThreshold",
                    format: "number",
                },
                {
                    label: "Zones- Anaerobic",
                    key: "test.muscle.a.zonesAnaerobic",
                    format: "number",
                },
                {
                    label: "Moxy Location B",
                    key: "test.equipment.moxyLocationB",
                },
                {
                    label: "Moxy Sensor B",
                    key: "test.equipment.moxySensorB",
                },
                {
                    label: "SMO2 % - Min",
                    key: "test.muscle.b.smo2Min",
                    format: "number",
                },
                {
                    label: "SMO2 % - Avg",
                    key: "test.muscle.b.smo2Avg",
                    format: "number",
                },
                {
                    label: "SMO2 % - Max",
                    key: "test.muscle.b.smo2Max",
                    format: "number",
                },
                {
                    label: "SMO2 % - Drop",
                    key: "test.muscle.b.smo2Drop",
                    format: "number",
                },
                {
                    label: "THb mg/dl - Min",
                    key: "test.muscle.b.thbMin",
                    format: "number",
                },
                {
                    label: "THb mg/dl - Avg",
                    key: "test.muscle.b.thbAvg",
                    format: "number",
                },
                {
                    label: "THb mg/dl - Max",
                    key: "test.muscle.b.thbMax",
                    format: "number",
                },
                {
                    label: "THb mg/dl - Trend",
                    key: "test.muscle.b.thbTrend",
                },
                {
                    label: "Zones- Endurance",
                    key: "test.muscle.b.zonesEndurance",
                    format: "number",
                },
                {
                    label: "Zones- Threshold",
                    key: "test.muscle.b.zonesThreshold",
                    format: "number",
                },
                {
                    label: "Zones- Anaerobic",
                    key: "test.muscle.b.zonesAnaerobic",
                    format: "number",
                }
            ]
        } else if (dataSet == "lactate") {
            rows = [

            ]
        }

    } else if (dataType === "fitSize") {

        if (dataSet === "bio") {

            rows = [
                {
                    label: "Weight",
                    key: "bio.weight",
                    suffix: " kg",
                    format: "number"
                },
                {
                    label: "Height",
                    key: "bio.height",
                    suffix: " cm",
                    format: "number"
                },
                {
                    label: "Shoulder Width",
                    key: "bio.shoulderWidth",
                    suffix: " cm",
                    format: "number"
                },
                {
                    label: "Arm Length",
                    key: "bio.armLength",
                    suffix: " cm",
                    format: "number"
                },
                {
                    label: "Inseam",
                    key: "bio.inseam",
                    suffix: " cm",
                    format: "number"
                },
                {
                    label: "Arm Span",
                    key: "bio.armSpan",
                    suffix: " cm",
                    format: "number"
                },
                {
                    label: "Shoulder Height",
                    key: "bio.shoulderHeight",
                    suffix: " cm",
                    format: "number"
                },
                {
                    label: "Thigh Length",
                    key: "bio.thighLength",
                    suffix: " cm",
                    format: "number"
                },
                {
                    label: "Shin Length",
                    key: "bio.shinLength",
                    suffix: " cm",
                    format: "number"
                },
                {
                    label: "Torso Length",
                    key: "bio.torsoLength",
                    suffix: " cm",
                    format: "number"
                },
                {
                    label: "Arm Ratio",
                    key: "bio.armRatio",
                    format: "number"
                },
                {
                    label: "Leg Ratio",
                    key: "bio.legRatio",
                    format: "number"
                },
                {
                    label: "Shin-Thigh Ratio",
                    key: "bio.thighShinRatio",
                    format: "number"
                },
                {
                    label: "Arm Proportion",
                    key: "bio.armProportion",

                },
                {
                    label: "Leg Proportion",
                    key: "bio.legProportion",
                },
                {
                    label: "Shin-Thigh Proportion",
                    key: "bio.thighShinProportion",
                }
            ];

        } else if (dataSet === "injury") {

            rows = [
                {
                    label: "Front Left",
                    key: "injury.frontLeft",

                },
                {
                    label: "Front Right",
                    key: "injury.frontRight",
                },
                {
                    label: "Back & Neck",
                    key: "injury.back",
                },
                {
                    label: "Rear Left",
                    key: "injury.backLeft",
                },
                {
                    label: "Rear Right",
                    key: "injury.backLeft",
                },
            ];
        } else if (dataSet === "posture") {

            rows = [
                {
                    label: "Normal Posture",
                    key: "posture.normalPosture",
                },
                {
                    label: "Head",
                    key: "posture.head",
                },
                {
                    label: "Upper Extremity",
                    key: "posture.upperExtremity",
                },
                {
                    label: "Torso/Spine",
                    key: "posture.spine",
                },
                {
                    label: "Lower Extremity",
                    key: "posture.lowerExtremity",
                },
                {
                    label: "Pedal Stance",
                    key: "posture.pedalStance",
                },
                {
                    label: "Thigh Gap",
                    key: "posture.thighGap",
                },
            ]

        } else if (dataSet === "mobility") {

            rows = [
                {
                    label: "Toe Touch",
                    key: "mobility.toeTouch",

                },
                {
                    label: "Pelvic Angle",
                    key: "mobility.pelvicAngle",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Quads",
                    key: "mobility.quads",
                },
                {
                    label: "Quads Details",
                    key: "mobility.quadsDetails",
                },
                {
                    label: "Hamstrings",
                    key: "mobility.hamstrings",
                },
                {
                    label: "Hamstrings Details",
                    key: "mobility.hamstringsDetails",
                },
                {
                    label: "Hip Flexion",
                    key: "mobility.hipFlexion",
                },
                {
                    label: "Hip Flexion Details",
                    key: "mobility.hipFlexionDetails",
                },
                {
                    label: "Hip Flexors",
                    key: "mobility.hipFlexors",
                },
                {
                    label: "Hip Flexors Details",
                    key: "mobility.hipFlexorsDetails",
                },
                {
                    label: "Hip Internal Rotation",
                    key: "mobility.hipInternalRotation",
                },
                {
                    label: "Hip Internal Rotation Details",
                    key: "mobility.hipInternalRotationDetails",
                },
                {
                    label: "Trunk Rotation",
                    key: "mobility.trunkRotation",
                },
                {
                    label: "Trunk Rotation Details",
                    key: "mobility.trunkRotationDetails",
                },
                {
                    label: "Plantar Flexion",
                    key: "mobility.plantarFlexion",
                },
                {
                    label: "Plantar Flexion Details",
                    key: "mobility.plantarFlexionDetails",
                },
                {
                    label: "Dorsi Flexion",
                    key: "mobility.dorsiFlexion",
                },
                {
                    label: "Dorsi Flexion Details",
                    key: "mobility.dorsiFlexionDetails",
                },
                {
                    label: "Shoulders",
                    key: "mobility.shoulders",
                },
                {
                    label: "Shoulders Details",
                    key: "mobility.shouldersDetails",
                },
                {
                    label: "IT Band",
                    key: "mobility.itBand",
                },
                {
                    label: "IT Band Details",
                    key: "mobility.itBandDetails",
                },
                {
                    label: "Upper-Mid Back",
                    key: "mobility.upperMidBack",
                },
                {
                    label: "Upper-Mid Back Details",
                    key: "mobility.upperMidBackDetails",
                },
                {
                    label: "Lower Back",
                    key: "mobility.lowerBack",
                },
                {
                    label: "Rating",
                    key: "mobility.rating",
                    format: "number",
                    suffix: "/10"
                },

            ]

        } else if (dataSet === "symmetry") {

            rows = [
                {
                    label: "Hip Level Standing",
                    key: "symmetry.hipLevelStanding",
                },
                {
                    label: "Hip Rotation Lying",
                    key: "symmetry.hipLevelLying"
                },
                {
                    label: "Hip Rotation Standing",
                    key: "symmetry.hipRotationStanding",
                },
                {
                    label: "Leg Length",
                    key: "symmetry.legLength",
                },
                {
                    label: "Leg Length Details",
                    key: "symmetry.legLengthDetails",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Foot Walk Left",
                    key: "symmetry.footWalkLeft",
                },
                {
                    label: "Foot Walk Right",
                    key: "symmetry.footWalkRight",
                },
                {
                    label: "Hip/Leg Asymmetry",
                    key: "symmetry.asymmetry",
                },
                {
                    label: "Reactive Eye",
                    key: "symmetry.reactiveEye",
                },
                {
                    label: "Improved",
                    key: "symmetry.improved",
                },
                {
                    label: "With",
                    key: "symmetry.with",
                },
                {
                    label: "Rating",
                    key: "symmetry.rating",
                    format: "number",
                    suffix: "/10"
                }
            ]

        } else if (dataSet === "stability") {

            rows = [
                {
                    label: "Single Leg Squat (Left)",
                    key: "stability.legSquatLeft",
                },
                {
                    label: "Single Leg Squat (Left) - Details",
                    key: "stability.legSquatLeftDetails",
                },
                {
                    label: "Single Leg Squat (Right)",
                    key: "stability.legSquatRight",
                },
                {
                    label: "Single Leg Squat (Right) - Details",
                    key: "stability.legSquatRightDetails",
                },
                {
                    label: "Core Stability",
                    key: "stability.coreStability",
                },
                {
                    label: "Rating",
                    key: "stability.rating",
                    format: "number",
                    suffix: "/10"
                }
            ]

        } else if (dataSet === "foot") {

            rows = [
                {
                    label: "Foot Length Left",
                    key: "foot.footLengthLeft",
                    format: "number",
                    suffix: " EUR"
                },
                {
                    label: "Width (Left)",
                    key: "foot.widthLeft",
                },
                {
                    label: "Arch Length (Left)",
                    key: "foot.archLengthLeft",
                    format: "number",
                },
                {
                    label: "Arch Height (Left)",
                    key: "foot.archHeightLeft",
                },
                {
                    label: "Forefoot (Left)",
                    key: "foot.forefootLeft",
                },
                {
                    label: "Heel (Left)",
                    key: "foot.heelLeft",
                },
                {
                    label: "Foot Inversion Left (Unloaded)",
                    key: "foot.footInversionLeft",
                },
                {
                    label: "Foot Length Right",
                    key: "foot.footLengthRight",
                    format: "number",
                    suffix: " EUR"
                },
                {
                    label: "Width (Right)",
                    key: "foot.widthRight",
                },
                {
                    label: "Arch Length (Right)",
                    key: "foot.archLengthRight",
                    format: "number",
                },
                {
                    label: "Arch Height (Right)",
                    key: "foot.archHeightRight",
                },
                {
                    label: "Forefoot (Right)",
                    key: "foot.forefootRight",
                },
                {
                    label: "Heel (Right)",
                    key: "foot.heelRight",
                },
                {
                    label: "Foot Inversion Right (Unloaded)",
                    key: "foot.footInversionRight",
                },
                {
                    label: "Foot Length Difference",
                    key: "foot.footLengthDifference",
                    render: (value, row) => {

                        return getFootLengthDifference(row?.foot?.footLengthLeft, row?.foot?.footLengthRight)

                    }
                },
                {
                    label: "Arch Length Difference",
                    key: "foot.archLengthDifference",
                    render: (value, row) => {

                        return getArchLengthDifference(row?.foot?.archLengthLeft, row?.foot?.archLengthRight)

                    }
                }
            ]

        } else if (dataSet === "cleat") {

            rows = [
                {
                    label: "Shoe Brand",
                    key: "cleat.shoeBrand",
                },
                {
                    label: "Shoe Size",
                    key: "cleat.shoeSize",
                    format: "number",
                    suffix: " EUR"
                },
                {
                    label: "Foot Length (Left)",
                    key: "cleat.footLengthLeft",
                    format: "number",
                    suffix: " EUR"
                },
                {
                    label: "Foot Length (Right)",
                    key: "cleat.footLengthRight",
                    format: "number",
                    suffix: " EUR"
                },
                {
                    label: "Cleat Brand",
                    key: "cleat.cleatBrand",
                },
                {
                    label: "Float",
                    key: "cleat.float",
                },
                {
                    label: "Insoles",
                    key: "cleat.insoles",
                },
                {
                    label: "Fore/Aft - Left",
                    key: "cleat.foreLeft",
                },
                {
                    label: "Fore/Aft - Right",
                    key: "cleat.foreRight",
                },
                {
                    label: "Float (Rotation) - Left",
                    key: "cleat.floatLeft",
                },
                {
                    label: "Float (Rotation) - Right",
                    key: "cleat.floatRight",
                },
                {
                    label: "Side-to-Side - Left",
                    key: "cleat.sideLeft",
                },
                {
                    label: "Side-to-Side - Right",
                    key: "cleat.sideRight",
                },
                {
                    label: "Big Toe Joint from Pedal Center - Left",
                    key: "cleat.leftBigToe",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Heel In - / Out + - Left",
                    key: "cleat.leftHeelInOut",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Shims - Left",
                    key: "cleat.leftShims",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Heel Wedges - Left",
                    key: "cleat.leftHeelWedges",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Heel Wedges Details - Left",
                    key: "cleat.leftHeelWedgesDetails",
                },
                {
                    label: "Cleat Wedges - Left",
                    key: "cleat.leftCleatWedges",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Cleat Wedges Details - Left",
                    key: "cleat.leftCleatWedgesDetails",
                },
                {
                    label: "Big Toe Joint from Pedal Center - Right",
                    key: "cleat.rightBigToe",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Heel In - / Out + - Right",
                    key: "cleat.rightHeelInOut",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Shims - Right",
                    key: "cleat.rightShims",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Heel Wedges - Right",
                    key: "cleat.rightHeelWedges",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Heel Wedges Details - Right",
                    key: "cleat.rightHeelWedgesDetails",
                },
                {
                    label: "Cleat Wedges - Right",
                    key: "cleat.rightCleatWedges",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Cleat Wedges Details - Right",
                    key: "cleat.rightCleatWedgesDetails",
                },

            ];

        } else if (dataSet === "bikeSizing") {

            rows = [
                {
                    label: "Bike Brand",
                    key: "bike.brand",
                },
                {
                    label: "Bike Model",
                    key: "bike.model",
                },
                {
                    label: "Bike Year",
                    key: "bike.year",
                },
                {
                    label: "Bike Size",
                    key: "bike.setupBikeSize",
                },
                {
                    label: "Bike Type",
                    key: "bike.type",
                },
                {
                    label: "Handlebar Type",
                    key: "bike.handlebarType",
                },
                {
                    label: "Stem Size",
                    key: "bike.stemSize",
                },
                {
                    label: "Spacer",
                    key: "bike.spacers",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Bar Reach",
                    key: "bike.barReach",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Seatpost Offset",
                    key: "bike.seatpostOffset",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Crank Length",
                    key: "bike.crankLength",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Shoulder Shifting",
                    key: "bike.shoulderShifting",
                },
                {
                    label: "Shoulder Level",
                    key: "bike.shoulderLevel",
                },
                {
                    label: "Elbow Posture",
                    key: "bike.elbowPosture",
                },
                {
                    label: "Torso Posture",
                    key: "bike.torsoPosture",
                },
                {
                    label: "Torso Balance",
                    key: "bike.torsoBalance",
                },
                {
                    label: "Hip Shifting",
                    key: "bike.hipShifting",
                },
                {
                    label: "Hip Rotation",
                    key: "bike.hipRotation",
                },
                {
                    label: "Hip Drop",
                    key: "bike.hipDrop",
                },
                {
                    label: "Knee Tracking - Left",
                    key: "bike.kneeTrackingLeft",
                },
                {
                    label: "Knee Tracking - Right",
                    key: "bike.kneeTrackingRight",
                },
                {
                    label: "Foot Ankling - Left",
                    key: "bike.footAnklingLeft",
                },
                {
                    label: "Foot Ankling - Right",
                    key: "bike.footAnklingRight",
                },
                {
                    label: "Foot Direction - Left",
                    key: "bike.footDirectionLeft",
                },
                {
                    label: "Foot Direction - Right",
                    key: "bike.footDirectionRight",
                },
                {
                    label: "Heel Tilt - Left",
                    key: "bike.heelTiltLeft",
                },
                {
                    label: "Heel Tilt - Right",
                    key: "bike.heelTiltRight",
                },
                {
                    label: "Shoulder Angle - Left",
                    key: "bike.shoulderAngleLeft",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Shoulder Angle - Right",
                    key: "bike.shoulderAngleRight",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Trunk Angle - Left",
                    key: "bike.trunkAngleLeft",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Trunk Angle - Right",
                    key: "bike.trunkAngleRight",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Elbow Angle - Left",
                    key: "bike.elbowAngleLeft",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Elbow Angle - Right",
                    key: "bike.elbowAngleRight",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Knee Angle Max - Left",
                    key: "bike.kneeAngleLeftMax",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Knee Angle Max - Right",
                    key: "bike.kneeAngleRightMax",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Knee Angle Min - Left",
                    key: "bike.kneeAngleLeftMin",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Knee Angle Min - Right",
                    key: "bike.kneeAngleRightMin",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Ankle Angle Max - Left",
                    key: "bike.ankleAngleLeftMax",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Ankle Angle Max - Right",
                    key: "bike.ankleAngleRightMax",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Ankle Angle Min - Left",
                    key: "bike.ankleAngleLeftMin",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Ankle Angle Min - Right",
                    key: "bike.ankleAngleRightMin",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Knee Over Pedal - Left",
                    key: "bike.kneeOverPedalLeft",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Knee Over Pedal - Right",
                    key: "bike.kneeOverPedalRight",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Knee Travel - Left",
                    key: "bike.kneeTravelLeft",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Knee Travel - Right",
                    key: "bike.kneeTravelRight",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Foot Rotation - Left",
                    key: "bike.footRotationLeft",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Foot Rotation - Right",
                    key: "bike.footRotationRight",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Knee Tracking Tilt - Left",
                    key: "bike.kneeTrackingTiltLeft",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Knee Tracking Tilt - Right",
                    key: "bike.kneeTrackingTiltRight",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Average Cadence - Left",
                    key: "bike.averageCadenceLeft",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Average Cadence - Right",
                    key: "bike.averageCadenceRight",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Thigh Length - Left",
                    key: "bike.thighLengthLeft",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Thigh Length - Right",
                    key: "bike.thighLengthRight",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Shin Length - Left",
                    key: "bike.shinLengthLeft",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Shin Length - Right",
                    key: "bike.shinLengthRight",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Frame Stack",
                    key: "bike.frameStack",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Frame Reach",
                    key: "bike.frameReach",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Seat Tube Angle (Eff.)",
                    key: "bike.effSeatTubeAngle",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Saddle Height",
                    key: "bike.saddleSetback",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Saddle Setback",
                    key: "bike.shinLengthRight",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Saddle Tilt",
                    key: "bike.saddleAngle",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Handlebar Reach",
                    key: "bike.handlebarsGripReach",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Handlebar Drop",
                    key: "bike.handlebarsDrop",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Grip Width",
                    key: "bike.handlebarsGripWidth",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Grip Drop",
                    key: "bike.handlebarsGripDrop",
                    format: "number",
                    suffix: " mm"
                },
            ];

        } else if (dataSet === "bike") {

            rows = [
                {
                    label: "Bike Brand",
                    key: "bike.brand",
                },
                {
                    label: "Bike Model",
                    key: "bike.model",
                },
                {
                    label: "Bike Year",
                    key: "bike.year",
                },
                {
                    label: "Bike Size",
                    key: "bike.setupBikeSize",
                },
                {
                    label: "Bike Type",
                    key: "bike.type",
                },
                {
                    label: "Handlebar Type",
                    key: "bike.handlebarType",
                },
                {
                    label: "Top Tube - Pre",
                    key: "bike.setupTopTubePre",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Top Tube - Post",
                    key: "bike.setupTopTubePost",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Seat Tube - Pre",
                    key: "bike.setupSeatTubePre",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Seat Tube - Post",
                    key: "bike.setupSeatTubePost",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Crank Length - Pre",
                    key: "bike.setupCrankLengthPre",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Crank Length - Post",
                    key: "bike.setupCrankLengthPost",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Pedal Brand - Pre",
                    key: "bike.setupPedalBrandPre",
                },
                {
                    label: "Pedal Brand - Post",
                    key: "bike.setupPedalBrandPost",
                },
                {
                    label: "Stem Length - Pre",
                    key: "bike.stemLengthPre",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Stem Length - Post",
                    key: "bike.stemLengthPost",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Stem Rise - Pre",
                    key: "bike.stemRisePre",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Stem Rise - Post",
                    key: "bike.stemRisePost",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Stem Spacers - Pre",
                    key: "bike.stemSpacerPre",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Stem Spacers - Post",
                    key: "bike.stemSpacerPost",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Stem Cap - Pre",
                    key: "bike.stemCapPre",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Stem Cap - Post",
                    key: "bike.stemCapPost",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Spacer Total - Pre",
                    key: "bike.stemSpacerTotalPre",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Spacer Total - Post",
                    key: "bike.stemSpacerTotalPost",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Saddle  Brand - Pre",
                    key: "bike.saddleBrandPre",
                },
                {
                    label: "Saddle  Brand - Post",
                    key: "bike.saddleBrandPost",
                },
                {
                    label: "Saddle Length - Pre",
                    key: "bike.saddleLengthPre",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Saddle Length - Post",
                    key: "bike.saddleLengthPost",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Saddle Width - Pre",
                    key: "bike.saddleWidthPre",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Saddle Width - Post",
                    key: "bike.saddleWidthPost",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Saddle Tilt - Pre",
                    key: "bike.saddleTiltPre",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Saddle Tilt - Post",
                    key: "bike.saddleTiltPost",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Saddle Seat Post - Pre",
                    key: "bike.saddleSeatPostPre",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Saddle Seat Post - Pre",
                    key: "bike.saddleSeatPostPost",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Saddle Rail Exposed (Rear) - Post",
                    key: "bike.saddleRailExposedPre",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Saddle Rail Exposed (Rear) - Pre",
                    key: "bike.saddleRailExposedPost",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Aerobar Brand - Pre",
                    key: "bike.aerobarBrandPre",
                },
                {
                    label: "Aerobar Brand - Post",
                    key: "bike.aerobarBrandPost",
                },
                {
                    label: "Aeropad Tilt - Pre",
                    key: "bike.aeropadTiltPre",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Aeropad Tilt - Post",
                    key: "bike.aeropadTiltPre",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Spacer - Pre",
                    key: "bike.stemSpacerTotalPost",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Aeropad Spacer - Post",
                    key: "bike.stemSpacerTotalPost",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Aeropad Spacer - Pre",
                    key: "bike.aeropadSpacersPre",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Spacer - Post",
                    key: "bike.aeropadSpacersPost",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Extension Type - Pre",
                    key: "bike.extensionTypePre",
                },
                {
                    label: "Extension Type - Post",
                    key: "bike.extensionTypePost",
                },
                {
                    label: "Shoulder Shifting",
                    key: "bike.shoulderShifting",
                },
                {
                    label: "Shoulder Level",
                    key: "bike.shoulderLevel",
                },
                {
                    label: "Elbow Posture",
                    key: "bike.elbowPosture",
                },
                {
                    label: "Torso Posture",
                    key: "bike.torsoPosture",
                },
                {
                    label: "Torso Balance",
                    key: "bike.torsoBalance",
                },
                {
                    label: "Aero Frontal Tuck Position",
                    key: "bike.aeroPositionFront",
                },
                {
                    label: "Aero View - Hand Position",
                    key: "bike.aeroPositionHandSlide",
                },
                {
                    label: "Aero View - Elbow Position",
                    key: "bike.aeroPositionElbowSide",
                },
                {
                    label: "Aero View - Rear Position",
                    key: "bike.aeroPositionRear",
                },
                {
                    label: "Hip Shifting",
                    key: "bike.hipShifting",
                },
                {
                    label: "Hip Rotation",
                    key: "bike.hipRotation",
                },
                {
                    label: "Hip Drop",
                    key: "bike.hipDrop",
                },
                {
                    label: "Knee Tracking (Left)",
                    key: "bike.kneeTrackingLeft",
                },
                {
                    label: "Knee Tracking (Right)",
                    key: "bike.kneeTrackingRight",
                },
                {
                    label: "Foot Ankling (Left)",
                    key: "bike.footAnklingLeft",
                },
                {
                    label: "Foot Ankling (Right)",
                    key: "bike.footAnklingRight",
                },
                {
                    label: "Foot Direction (Left)",
                    key: "bike.footDirectionLeft",
                },
                {
                    label: "Foot Direction (Right)",
                    key: "bike.footDirectionRight",
                },
                {
                    label: "Heel Tilt (Left)",
                    key: "bike.heelTiltLeft",
                },
                {
                    label: "Heel Tilt (Right)",
                    key: "bike.heelTiltRight",
                },
                {
                    label: "Adjusment - Saddle Height",
                    key: "bike.adjustmentsSaddleHeight",
                },
                {
                    label: "Adjusment - Details",
                    key: "bike.adjustmentsSaddleHeightDetails",
                },
                {
                    label: "Adjusment - Saddle Fore/Aft",
                    key: "bike.adjustmentsSaddleForeAlt",
                },
                {
                    label: "Adjusment - Details",
                    key: "bike.adjustmentsSaddleForeAltDetails",
                },
                {
                    label: "Adjusment - Saddle Tilt",
                    key: "bike.adjustmentsSaddleTilt",
                },
                {
                    label: "Adjusment - Details",
                    key: "bike.adjustmentsSaddleTiltDetails",
                },
                {
                    label: "Adjusment - Aero Pad Height",
                    key: "bike.adjustmentsAerobarsPadHeight",
                },
                {
                    label: "Adjusment - Details",
                    key: "bike.adjustmentsAerobarsPadHeightDetails",
                },
                {
                    label: "Adjusment - Aero Pad Fore-Aft",
                    key: "bike.adjustmentsAerobarsPadForeAft",
                },
                {
                    label: "Adjusment - Details",
                    key: "bike.adjustmentsAerobarsPadForeAftDetails",
                },
                {
                    label: "Adjusment - Aero Pad Tilt",
                    key: "bike.adjustmentsAerobarsPadTilt",
                },
                {
                    label: "Adjusment - Details",
                    key: "bike.adjustmentsAerobarsPadTiltDetails",
                },
                {
                    label: "Adjusment - Extension Fore-Aft",
                    key: "bike.adjustmentsExtensionPadForeAft",
                },
                {
                    label: "Adjusment - Details",
                    key: "bike.adjustmentsExtensionPadForeAftDetails",
                },
                {
                    label: "Adjusment - Extension Rotation",
                    key: "bike.adjustmentsExtensionRotation",
                },
                {
                    label: "Adjusment - Details",
                    key: "bike.adjustmentsExtensionRotationDetails",
                },
                {
                    label: "Adjusment - Stem Length",
                    key: "bike.adjustmentsStemLength",
                },
                {
                    label: "Adjusment - Details",
                    key: "bike.adjustmentsStemLengthDetails",
                },
                {
                    label: "Adjusment - Stem Rise",
                    key: "bike.adjustmentsStemRise",
                },
                {
                    label: "Adjusment - Details",
                    key: "bike.adjustmentsStemRiseDetails",
                },
                {
                    label: "RETUL - Shoulder Angle - Left",
                    key: "bike.shoulderAngleLeft",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Shoulder Angle - Right",
                    key: "bike.shoulderAngleRight",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Trunk Angle - Left",
                    key: "bike.trunkAngleLeft",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Trunk Angle - Right",
                    key: "bike.trunkAngleRight",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Forearm Angle - Left",
                    key: "bike.forearmAngleLeft",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Forearm Angle - Right",
                    key: "bike.forearmAngleRight",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Elbow Angle - Left",
                    key: "bike.elbowAngleLeft",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Elbow Angle - Right",
                    key: "bike.elbowAngleRight",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Knee Angle Max - Left",
                    key: "bike.kneeAngleLeftMax",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Knee Angle Max - Right",
                    key: "bike.kneeAngleRightMax",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Knee Angle Min - Left",
                    key: "bike.kneeAngleLeftMin",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Knee Angle Min - Right",
                    key: "bike.kneeAngleRightMin",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Ankle Angle Max - Left",
                    key: "bike.ankleAngleLeftMax",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Ankle Angle Max - Right",
                    key: "bike.ankleAngleRightMax",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Ankle Angle Min - Left",
                    key: "bike.ankleAngleLeftMin",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Ankle Angle Min - Right",
                    key: "bike.ankleAngleRightMin",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Hip Angle Closed - Left",
                    key: "bike.aeropadTiltPre",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Hip Angle Closed - Right",
                    key: "bike.aeropadTiltPre",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Knee Over Pedal - Left",
                    key: "bike.kneeTravelLeft",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "RETUL - Knee Over Pedal - Right",
                    key: "bike.kneeTravelRight",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "RETUL - Knee Travel - Left",
                    key: "bike.aeropadTiltPre",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "RETUL - Knee Travel - Right",
                    key: "bike.aeropadTiltPre",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "RETUL - Foot Rotation - Left",
                    key: "bike.footRotationLeft",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Foot Rotation - Right",
                    key: "bike.footRotationRight",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Knee Tracking Tilt - Left",
                    key: "bike.kneeTrackingTiltLeft",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Knee Tracking Tilt - Right",
                    key: "bike.kneeTrackingTiltRight",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Average Cadence - Left",
                    key: "bike.averageCadenceLeft",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Average Cadence - Right",
                    key: "bike.averageCadenceRight",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Thigh Length - Left",
                    key: "bike.thighLengthLeft",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "RETUL - Thigh Length - Right",
                    key: "bike.thighLengthRight",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "RETUL - Shin Length - Left",
                    key: "bike.shinLengthLeft",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "RETUL - Shin Length - Right",
                    key: "bike.shinLengthRight",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "RETUL - Frame Stack",
                    key: "bike.frameStack",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "RETUL - Frame Reach",
                    key: "bike.frameReach",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "RETUL - Seat Tube Angle (Eff.)",
                    key: "bike.effSeatTubeAngle",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Saddle Height",
                    key: "bike.saddleHeight",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "RETUL - Saddle Setback",
                    key: "bike.saddleSetback",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "RETUL - Saddle Tilt",
                    key: "bike.saddleAngle",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "RETUL - Arm Pad Reach",
                    key: "bike.armPadReach",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "RETUL - Arm Pad Drop",
                    key: "bike.armPadDrop",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "RETUL - Arm Pad Width",
                    key: "bike.armPadWidth",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "RETUL - Arm Pad Stack BB",
                    key: "bike.armPadStackBb",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "RETUL - Arm Pad Reach BB",
                    key: "bike.armPadReachBb",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "RETUL - Extension Length",
                    key: "bike.extensionLength",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "RETUL - Grip Drop",
                    key: "bike.gripDrop",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "RETUL - Grip Width",
                    key: "bike.gripWidth",
                    format: "number",
                    suffix: " mm"
                },
                {
                    label: "Pressure - Maximum Load - Pre",
                    key: "bike.preFitMaximumLoad",
                },
                {
                    label: "Pressure - Maximum Load - Post",
                    key: "bike.postFitMaximumLoad",
                },
                {
                    label: "Pressure - Fore-Aft - Pre",
                    key: "bike.preFitForeAft",
                },
                {
                    label: "Pressure - Fore-Aft - Post",
                    key: "bike.postFitForeAft",
                },
                {
                    label: "Pressure - Side-to-Side - Pre",
                    key: "bike.preFitSideToSide",
                },
                {
                    label: "Pressure - Side-to-Side - Post",
                    key: "bike.postFitSideToSide",
                },
                {
                    label: "Pressure - Fit Stability - Pre",
                    key: "bike.preFitStability",
                },
                {
                    label: "Pressure - Fit Stability - Post",
                    key: "bike.postFitStability",
                },
                {
                    label: "Pressure - Overall Rating - Pre",
                    key: "bike.preFitOverallRating",
                },
                {
                    label: "Pressure - Overall Rating - Post",
                    key: "bike.postFitOverallRating",
                },
                {
                    label: "LEOMO - Torso Angle - Pre",
                    key: "bike.leomoTorsoAnglePre",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "LEOMO - Torso Angle - Post",
                    key: "bike.leomoTorsoAnglePost",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "LEOMO - Hip Angle - Pre",
                    key: "bike.leomoHipAnglePre",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "LEOMO - Hip Angle - Post",
                    key: "bike.leomoHipAnglePost",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "LEOMO - Hip Rotation - Pre",
                    key: "bike.leomoHipRotationPre",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "LEOMO - Hip Rotation - Post",
                    key: "bike.leomoHipRotationPost",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "LEOMO - Hip Rocking - Pre",
                    key: "bike.leomoHipRockingPre",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "LEOMO - Hip Rocking - Post",
                    key: "bike.leomoHipRockingPost",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "LEOMO - FEET DDS Left - Pre",
                    key: "bike.leomoFeetDdsLeftPre",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "LEOMO - FEET DDS Left - Post",
                    key: "bike.leomoFeetDdsLeftPost",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "LEOMO - FEET DDS Right - Pre",
                    key: "bike.leomoFeetDdsRightPre",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "LEOMO - FEET DDS Right - Post",
                    key: "bike.leomoFeetDdsRightPost",
                    format: "number",
                    suffix: "°"
                },
                {
                    label: "Frame Suitability",
                    key: "bike.frameSuitability",
                },
                {
                    label: "Crank Suitability",
                    key: "bike.crankSuitability",
                },
                {
                    label: "Bar Suitability",
                    key: "bike.barSuitability",
                },
            ];

        } else if (dataSet === "exercises") {

            rows = [
                {
                    label: "Flexibilityasdasd",
                    key: "exercises.Flexibility",
                },
                {
                    label: "Strengthasds",
                    key: "exercises.Strength",
                },
            ]
        }

    }

    if (dataSet === "summary") {

        rows = [
            {
                label: "Comments",
                key: "summary.comments",
                format: "nl2br"
            },
        ];

    }


    return rows;

}

export default AthletesTrackingRows