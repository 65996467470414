import React from 'react';
import { Panel } from '@dex/bubl-dash';
import styles from "./SessionReportPage.module.scss";
import moment from 'moment';

const SessionReportPage: React.FC<any> = (props: any) => {

    const { data, index, heading, name, date, forwardedRef } = props;

    let formattedDateRange = "";

    if (date?.weekStartDate && date?.weekEndDate) {

        const startDate = moment(date.weekStartDate);
        const endDate = moment(date.weekEndDate);

        const formattedStartDate = startDate.format('MMM DD');
        const formattedEndDate = endDate.format('MMM DD YYYY');

        formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;

    }

    return (

        <>

            <Panel
                id={index}
                full={true}
                heading={false}
                forwardedRef={forwardedRef}
                className={styles.page + " report-page"}
            >


                {heading &&
                    <div className={styles.heading}>
                        <h1>{heading}</h1>

                        <div>
                            <p>{name}</p>
                            <p>{formattedDateRange}</p>
                        </div>

                    </div>
                }

                <div className={styles.body}>
                    {props.children}
                </div>

            </Panel>

        </>

    );

}

export default SessionReportPage;
