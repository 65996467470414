import React, { useEffect, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import InputTable, { InputTableCell, InputTableRow } from '@dex/bubl-dash/src/lib/components/InputTable/InputTable';
import Heading from '@dex/bubl-dash/src/lib/components/Heading/Heading';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import { getSpacerTotal } from '../../../functions/sharedBikeFit';

const SessionsEditTabsBikeSetup: React.FC<any> = (props: any) => {

    const { form, prefix, bike, data, setKey } = props;

    useEffect(() => {

        const spacerTotalPre = getSpacerTotal(bike.stemSpacerPre, bike.stemCapPre)
        const spacerTotalPost = getSpacerTotal(bike.stemSpacerPost, bike.stemCapPost)

        form.handleChange({ reset: true, silent: true, name: `${prefix}.stemSpacerTotalPre`, value: spacerTotalPre });
        form.handleChange({ reset: true, silent: true, name: `${prefix}.stemSpacerTotalPost`, value: spacerTotalPost });

    }, [bike.stemSpacerPre, bike.stemCapPre, bike.stemSpacerPost, bike.stemCapPost]);

    return useMemo(() => (

        <Row gutter={8} edge={true}>

            <Col col={{ xs: 24 }}>

                <InputTable
                    isTableContext={false}
                    columns={[
                        {
                            key: "prop",
                            label: "Property / Description",
                        },
                        {
                            key: "pre",
                            label: "Pre",
                        },
                        {
                            key: "prost",
                            label: "Post",
                        },
                    ]}
                >

                    <InputTableRow>

                        <InputTableCell colSpan={3} style={{ paddingLeft: 12 }}>
                            <Heading space={null}>Frame</Heading>
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                            Top Tube:
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.setupTopTubePre`}
                                defaultValue={bike.setupTopTubePre}
                                suffix={" mm"}
                                allowNegative
                            />
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.setupTopTubePost`}
                                defaultValue={bike.setupTopTubePost}
                                suffix={" mm"}
                                allowNegative
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                            Seat Tube:
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.setupSeatTubePre`}
                                defaultValue={bike.setupSeatTubePre}
                                suffix={" mm"}
                                allowNegative
                            />
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.setupSeatTubePost`}
                                defaultValue={bike.setupSeatTubePost}
                                suffix={" mm"}
                                allowNegative
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell colSpan={3} style={{ paddingLeft: 12 }}>
                            <Heading space={null}>Crank &amp; Pedals</Heading>
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                            Crank Length:
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldSelect
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.setupCrankLengthPre`}
                                defaultValue={bike.setupCrankLengthPre}
                                allowCustomOption
                                options={[
                                    { value: "150", label: "150mm" },
                                    { value: "155", label: "155mm" },
                                    { value: "160", label: "160mm" },
                                    { value: "165", label: "165mm" },
                                    { value: "167.5", label: "167.5mm" },
                                    { value: "170", label: "170mm" },
                                    { value: "172.5", label: "172.5mm" },
                                    { value: "175", label: "175mm" },
                                    { value: "177.5", label: "177.5mm" },
                                    { value: "180", label: "180mm" },
                                    { value: "182.5", label: "182.5mm" },
                                    { value: "185", label: "185mm" },
                                ]}
                            />
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldSelect
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.setupCrankLengthPost`}
                                defaultValue={bike.setupCrankLengthPost}
                                allowCustomOption
                                options={[
                                    { value: "150", label: "150mm" },
                                    { value: "155", label: "155mm" },
                                    { value: "160", label: "160mm" },
                                    { value: "165", label: "165mm" },
                                    { value: "167.5", label: "167.5mm" },
                                    { value: "170", label: "170mm" },
                                    { value: "172.5", label: "172.5mm" },
                                    { value: "175", label: "175mm" },
                                    { value: "177.5", label: "177.5mm" },
                                    { value: "180", label: "180mm" },
                                    { value: "182.5", label: "182.5mm" },
                                    { value: "185", label: "185mm" },
                                ]}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                            Pedal Brand:
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldSelect
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.setupPedalBrandPre`}
                                defaultValue={bike.setupPedalBrandPre}
                                allowCustomOption={true}
                                options={[
                                    { value: "Shimano", label: "Shimano" },
                                    { value: "Look", label: "Look" },
                                    { value: "Speedplay", label: "Speedplay" },
                                    { value: "Exustar", label: "Exustar" },
                                    { value: "Assioma", label: "Assioma" },
                                    { value: "Garmin", label: "Garmin" },
                                    { value: "Time", label: "Time" },
                                    { value: "Platform", label: "Platform" },
                                    { value: "MTB", label: "MTB" },
                                    { value: "Powertap", label: "Powertap" },
                                    { value: "Crank Brothers", label: "Crank Brothers" }
                                ]}
                            />
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldSelect
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.setupPedalBrandPost`}
                                defaultValue={bike.setupPedalBrandPost}
                                allowCustomOption={true}
                                options={[
                                    { value: "Shimano", label: "Shimano" },
                                    { value: "Look", label: "Look" },
                                    { value: "Speedplay", label: "Speedplay" },
                                    { value: "Exustar", label: "Exustar" },
                                    { value: "Assioma", label: "Assioma" },
                                    { value: "Garmin", label: "Garmin" },
                                    { value: "Time", label: "Time" },
                                    { value: "Platform", label: "Platform" },
                                    { value: "MTB", label: "MTB" },
                                    { value: "Powertap", label: "Powertap" },
                                    { value: "Crank Brothers", label: "Crank Brothers" }
                                ]}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell colSpan={3} style={{ paddingLeft: 12 }}>
                            <Heading space={null}>Stem</Heading>
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                            Stem Length:
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.stemLengthPre`}
                                defaultValue={bike.stemLengthPre}
                                suffix={" mm"}
                                allowNegative={true}
                            />
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.stemLengthPost`}
                                defaultValue={bike.stemLengthPost}
                                suffix={" mm"}
                                allowNegative={true}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                            Stem Rise:
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.stemRisePre`}
                                defaultValue={bike.stemRisePre}
                                suffix={" °"}
                                allowNegative={true}
                            />
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.stemRisePost`}
                                defaultValue={bike.stemRisePost}
                                suffix={" °"}
                                allowNegative={true}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                            Stem Spacers:
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.stemSpacerPre`}
                                defaultValue={bike.stemSpacerPre}
                                suffix={" mm"}
                            />
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.stemSpacerPost`}
                                defaultValue={bike.stemSpacerPost}
                                suffix={" mm"}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                            Stem Cap:
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.stemCapPre`}
                                defaultValue={bike.stemCapPre}
                                suffix={" mm"}
                            />
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.stemCapPost`}
                                defaultValue={bike.stemCapPost}
                                suffix={" mm"}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                            Spacer Total:
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                dynamicKey
                                form={form}
                                disabled
                                hidden
                                wrapClass={"w-200"}
                                name={`${prefix}.stemSpacerTotalPre`}
                                suffix={" mm"}
                                defaultValue={bike.stemSpacerTotalPre}
                            />
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                dynamicKey
                                form={form}
                                disabled
                                hidden
                                wrapClass={"w-200"}
                                name={`${prefix}.stemSpacerTotalPost`}
                                defaultValue={bike.stemSpacerTotalPost}
                                suffix={" mm"}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    {(bike.type === 'MTB' || bike.type === 'Gravel') &&
                        <>
                            <InputTableRow>

                                <InputTableCell colSpan={3} style={{ paddingLeft: 12 }}>
                                    <Heading space={null}>Shocks</Heading>
                                </InputTableCell>

                            </InputTableRow>

                            <InputTableRow>

                                <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                                    Front Shock:
                                </InputTableCell>

                                <InputTableCell colSpan={2}>
                                    <FieldSwitch
                                        key={'Front Shock Pre'}
                                        form={form}
                                        name={`${prefix}.frontShockPre`}
                                        defaultValue={bike.frontShockPre}
                                    />
                                </InputTableCell>

                            </InputTableRow>

                            {bike.frontShockPre &&
                                <>
                                    <InputTableRow>

                                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                                            Front Shock Brand:
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>
                                            <FieldText
                                                form={form}
                                                wrapClass={"w-200"}
                                                name={`${prefix}.frontShocksBrandPre`}
                                                defaultValue={bike.frontShocksBrandPre}
                                            />
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>
                                            <FieldText
                                                form={form}
                                                wrapClass={"w-200"}
                                                name={`${prefix}.frontShocksBrandPost`}
                                                defaultValue={bike.frontShocksBrandPost}
                                            />
                                        </InputTableCell>

                                    </InputTableRow>

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                                            Front Shock Travel:
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>
                                            <FieldSelect
                                                form={form}
                                                wrapClass={"w-200"}
                                                name={`${prefix}.frontShocksTravelPre`}
                                                defaultValue={bike.frontShocksTravelPre}
                                                allowCustomOption
                                                options={[
                                                    { value: "0", label: "No Travel" },
                                                    { value: "80", label: "80mm" },
                                                    { value: "90", label: "90mm" },
                                                    { value: "100", label: "100mm" },
                                                    { value: "110", label: "110mm" },
                                                    { value: "120", label: "120mm" },
                                                    { value: "130", label: "130mm" },
                                                    { value: "140", label: "140mm" },
                                                    { value: "150", label: "150mm" },
                                                    { value: "160", label: "160mm" },
                                                    { value: "170", label: "170mm" },
                                                    { value: "180", label: "180mm" },
                                                    { value: "190", label: "190mm" },
                                                    { value: "200", label: "200mm" },
                                                ]}
                                            />
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>
                                            <FieldSelect
                                                form={form}
                                                wrapClass={"w-200"}
                                                name={`${prefix}.frontShocksTravelPost`}
                                                defaultValue={bike.frontShocksTravelPost}
                                                allowCustomOption
                                                options={[
                                                    { value: "0", label: "No Travel" },
                                                    { value: "80", label: "80mm" },
                                                    { value: "90", label: "90mm" },
                                                    { value: "100", label: "100mm" },
                                                    { value: "110", label: "110mm" },
                                                    { value: "120", label: "120mm" },
                                                    { value: "130", label: "130mm" },
                                                    { value: "140", label: "140mm" },
                                                    { value: "150", label: "150mm" },
                                                    { value: "160", label: "160mm" },
                                                    { value: "170", label: "170mm" },
                                                    { value: "180", label: "180mm" },
                                                    { value: "190", label: "190mm" },
                                                    { value: "200", label: "200mm" },
                                                ]}
                                            />
                                        </InputTableCell>

                                    </InputTableRow>
                                </>
                            }

                            <InputTableRow>

                                <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                                    Rear Shock:
                                </InputTableCell>

                                <InputTableCell colSpan={2}>
                                    <FieldSwitch
                                        key={'Rear Shock Pre'}
                                        form={form}
                                        name={`${prefix}.rearShockPre`}
                                        defaultValue={bike.rearShockPre}
                                    />
                                </InputTableCell>

                            </InputTableRow>

                            {bike.rearShockPre &&


                                <>
                                    <InputTableRow>

                                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                                            Rear Shock Brand:
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>
                                            <FieldText
                                                form={form}
                                                wrapClass={"w-200"}
                                                name={`${prefix}.rearShocksBrandPre`}
                                                defaultValue={bike.rearShocksBrandPre}
                                            />
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>
                                            <FieldText
                                                form={form}
                                                wrapClass={"w-200"}
                                                name={`${prefix}.rearShocksBrandPost`}
                                                defaultValue={bike.rearShocksBrandPost}
                                            />
                                        </InputTableCell>

                                    </InputTableRow>

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                                            Rear Shock Sag:
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>
                                            <FieldText
                                                form={form}
                                                wrapClass={"w-200"}
                                                name={`${prefix}.rearShocksSagPre`}
                                                defaultValue={bike.rearShocksSagPre}
                                            // allowCustomOption
                                            // options={[
                                            //     { value: "0", label: "No Sag" },
                                            //     { value: "80", label: "80mm" },
                                            //     { value: "90", label: "90mm" },
                                            //     { value: "100", label: "100mm" },
                                            //     { value: "110", label: "110mm" },
                                            //     { value: "120", label: "120mm" },
                                            //     { value: "130", label: "130mm" },
                                            //     { value: "140", label: "140mm" },
                                            //     { value: "150", label: "150mm" },
                                            //     { value: "160", label: "160mm" },
                                            //     { value: "170", label: "170mm" },
                                            //     { value: "180", label: "180mm" },
                                            //     { value: "190", label: "190mm" },
                                            //     { value: "200", label: "200mm" },
                                            // ]}
                                            />
                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>
                                            <FieldText
                                                form={form}
                                                wrapClass={"w-200"}
                                                name={`${prefix}.rearShocksSagPost`}
                                                defaultValue={bike.rearShocksSagPost}
                                            // allowCustomOption
                                            // options={[
                                            //     { value: "0", label: "No Sag" },
                                            //     { value: "80", label: "80mm" },
                                            //     { value: "90", label: "90mm" },
                                            //     { value: "100", label: "100mm" },
                                            //     { value: "110", label: "110mm" },
                                            //     { value: "120", label: "120mm" },
                                            //     { value: "130", label: "130mm" },
                                            //     { value: "140", label: "140mm" },
                                            //     { value: "150", label: "150mm" },
                                            //     { value: "160", label: "160mm" },
                                            //     { value: "170", label: "170mm" },
                                            //     { value: "180", label: "180mm" },
                                            //     { value: "190", label: "190mm" },
                                            //     { value: "200", label: "200mm" },
                                            // ]}
                                            />
                                        </InputTableCell>

                                    </InputTableRow>
                                </>

                            }



                        </>

                    }

                    {bike.type === 'Gravel' &&
                        <>
                            <InputTableRow>

                                <InputTableCell colSpan={3} style={{ paddingLeft: 12 }}>
                                    <Heading space={null}>Shocks</Heading>
                                </InputTableCell>

                            </InputTableRow>





                            <InputTableRow>

                                <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                                    Rear Shock Sag:
                                </InputTableCell>


                                <InputTableCell style={{ width: 220 }}>
                                    <FieldNumber
                                        form={form}
                                        wrapClass={"w-200"}
                                        name={`${prefix}.rearShockSagPre`}
                                        defaultValue={bike.rearShockSagPre}
                                        suffix={" mm"}
                                    />
                                </InputTableCell>

                                <InputTableCell style={{ width: 220 }}>
                                    <FieldNumber
                                        form={form}
                                        wrapClass={"w-200"}
                                        name={`${prefix}.rearShockSagPre`}
                                        defaultValue={bike.rearShockSagPre}
                                        suffix={" mm"}
                                    />
                                </InputTableCell>

                            </InputTableRow>

                        </>

                    }

                    <InputTableRow>

                        <InputTableCell colSpan={3} style={{ paddingLeft: 12 }}>
                            <Heading space={null}>Saddle:</Heading>
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                            Saddle Brand:
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldText
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.saddleBrandPre`}
                                defaultValue={bike.saddleBrandPre}
                            />
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldText
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.saddleBrandPost`}
                                defaultValue={bike.saddleBrandPost}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                            Saddle Length:
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.saddleLengthPre`}
                                defaultValue={bike.saddleLengthPre}
                                suffix={" mm"}
                            />
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.saddleLengthPost`}
                                defaultValue={bike.saddleLengthPost}
                                suffix={" mm"}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                            Saddle Width:
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.saddleWidthPre`}
                                defaultValue={bike.saddleWidthPre}
                                suffix={" mm"}
                            />
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.saddleWidthPost`}
                                defaultValue={bike.saddleWidthPost}
                                suffix={" mm"}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                            Saddle Tilt:
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.saddleTiltPre`}
                                defaultValue={bike.saddleTiltPre}
                                suffix={" °"}
                                allowNegative={true}
                            />
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.saddleTiltPost`}
                                defaultValue={bike.saddleTiltPost}
                                suffix={" °"}
                                allowNegative={true}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                            Saddle Seat Post:
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.saddleSeatPostPre`}
                                defaultValue={bike.saddleSeatPostPre}
                                suffix={" mm"}
                                allowNegative={true}
                            />
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.saddleSeatPostPost`}
                                defaultValue={bike.saddleSeatPostPost}
                                suffix={" mm"}
                                allowNegative={true}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    <InputTableRow>

                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>
                            Saddle Rail Exposed &#40;Rear&#41;:
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.saddleRailExposedPre`}
                                defaultValue={bike.saddleRailExposedPre}
                                suffix={" mm"}
                                allowNegative={true}
                            />
                        </InputTableCell>

                        <InputTableCell style={{ width: 220 }}>
                            <FieldNumber
                                form={form}
                                wrapClass={"w-200"}
                                name={`${prefix}.saddleRailExposedPost`}
                                defaultValue={bike.saddleRailExposedPost}
                                suffix={" mm"}
                                allowNegative={true}
                            />
                        </InputTableCell>

                    </InputTableRow>

                    {(bike.handlebarType !== 'None' && (bike.handlebarType === 'Dropbars' || bike.handlebarType?.indexOf('MTB') === 0)) &&
                        <>
                            <InputTableRow>

                                <InputTableCell colSpan={3} style={{ paddingLeft: 12 }}>

                                    <Heading space={null}>Handlebars:</Heading>

                                </InputTableCell>

                            </InputTableRow>

                            <InputTableRow>

                                <InputTableCell style={{ width: 660, paddingLeft: 12 }}>

                                    Handlebar Brand:

                                </InputTableCell>

                                <InputTableCell style={{ width: 220 }}>

                                    <FieldText
                                        form={form}
                                        wrapClass={"w-200"}
                                        name={`${prefix}.handlebarsBrandPre`}
                                        defaultValue={bike.handlebarsBrandPre}

                                    />

                                </InputTableCell>

                                <InputTableCell style={{ width: 220 }}>

                                    <FieldText
                                        form={form}
                                        wrapClass={"w-200"}
                                        name={`${prefix}.handlebarsBrandPost`}
                                        defaultValue={bike.handlebarsBrandPost}
                                    />

                                </InputTableCell>

                            </InputTableRow>

                            {bike.handlebarType?.indexOf('MTB') === -1 &&
                                <>
                                    <InputTableRow>

                                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>

                                            Handlebars Width-Hoods:

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>

                                            <FieldNumber
                                                form={form}
                                                wrapClass={"w-200"}
                                                name={`${prefix}.handlebarsWidthHoodsPre`}
                                                defaultValue={bike.handlebarsWidthHoodsPre}
                                                suffix={" mm"}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>

                                            <FieldNumber
                                                form={form}
                                                wrapClass={"w-200"}
                                                name={`${prefix}.handlebarsWidthHoodsPost`}
                                                defaultValue={bike.handlebarsWidthHoodsPost}
                                                suffix={" mm"}
                                            />

                                        </InputTableCell>

                                    </InputTableRow>

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>

                                            Handlebars Width-Drops:

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>

                                            <FieldNumber
                                                form={form}
                                                wrapClass={"w-200"}
                                                name={`${prefix}.handlebarsWidthDropsPre`}
                                                defaultValue={bike.handlebarsWidthDropsPre}
                                                suffix={" mm"}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>

                                            <FieldNumber
                                                form={form}
                                                wrapClass={"w-200"}
                                                name={`${prefix}.handlebarsWidthDropsPost`}
                                                defaultValue={bike.handlebarsWidthDropsPost}
                                                suffix={" mm"}
                                            />

                                        </InputTableCell>

                                    </InputTableRow>

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>

                                            Handlebar Bar Reach:

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>

                                            <FieldNumber
                                                form={form}
                                                wrapClass={"w-200"}
                                                name={`${prefix}.handlebarsBarReachPre`}
                                                defaultValue={bike.handlebarsBarReachPre}
                                                suffix={" mm"}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>

                                            <FieldNumber
                                                form={form}
                                                wrapClass={"w-200"}
                                                name={`${prefix}.handlebarsBarReachPost`}
                                                defaultValue={bike.handlebarsBarReachPost}
                                                suffix={" mm"}
                                            />

                                        </InputTableCell>

                                    </InputTableRow>
                                </>
                            }

                            {bike.handlebarType?.indexOf('MTB') === 0 &&

                                <>
                                    <InputTableRow>

                                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>

                                            Handlebar Width-Tip-to-Tip:

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>

                                            <FieldNumber
                                                form={form}
                                                wrapClass={"w-200"}
                                                name={`${prefix}.handlebarsWidthMidGripPre`}
                                                defaultValue={bike.handlebarsWidthMidGripPre}
                                                suffix={" mm"}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>

                                            <FieldNumber
                                                form={form}
                                                wrapClass={"w-200"}
                                                name={`${prefix}.handlebarsWidthMidGripPost`}
                                                defaultValue={bike.handlebarsWidthMidGripPost}
                                                suffix={" mm"}
                                            />

                                        </InputTableCell>

                                    </InputTableRow>

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>

                                            Handlebar Bar Rise:
                                            {/* Renamed to Rise from Setback: */}

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>

                                            <FieldNumber
                                                form={form}
                                                wrapClass={"w-200"}
                                                name={`${prefix}.handlebarsBarSetbackPre`}
                                                defaultValue={bike.handlebarsBarSetbackPre}
                                                suffix={" mm"}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>

                                            <FieldNumber
                                                form={form}
                                                wrapClass={"w-200"}
                                                name={`${prefix}.handlebarsBarSetbackPost`}
                                                defaultValue={bike.handlebarsBarSetbackPost}
                                                suffix={" mm"}
                                            />

                                        </InputTableCell>

                                    </InputTableRow>

                                    <InputTableRow>

                                        <InputTableCell style={{ width: 660, paddingLeft: 12 }}>

                                            Handlebar Bar Backsweep:

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>

                                            <FieldNumber
                                                form={form}
                                                wrapClass={"w-200"}
                                                name={`${prefix}.handlebarsBarBacksweepPre`}
                                                defaultValue={bike.handlebarsBarBacksweepPre}
                                                suffix={" mm"}
                                            />

                                        </InputTableCell>

                                        <InputTableCell style={{ width: 220 }}>

                                            <FieldNumber
                                                form={form}
                                                wrapClass={"w-200"}
                                                name={`${prefix}.handlebarsBarBacksweepPost`}
                                                defaultValue={bike.handlebarsBarBacksweepPost}
                                                suffix={" mm"}
                                            />

                                        </InputTableCell>

                                    </InputTableRow>
                                </>
                            }


                            <InputTableRow>

                                <InputTableCell style={{ width: 660, paddingLeft: 12 }}>

                                    Handlebars Mid-Saddle to Grip:

                                </InputTableCell>

                                <InputTableCell style={{ width: 220 }}>

                                    <FieldNumber
                                        form={form}
                                        wrapClass={"w-200"}
                                        name={`${prefix}.handlebarsMidSaddleToGripPre`}
                                        defaultValue={bike.handlebarsMidSaddleToGripPre}
                                        suffix={" mm"}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ width: 220 }}>

                                    <FieldNumber
                                        form={form}
                                        wrapClass={"w-200"}
                                        name={`${prefix}.handlebarsMidSaddleToGripPost`}
                                        defaultValue={bike.handlebarsMidSaddleToGripPost}
                                        suffix={" mm"}
                                    />

                                </InputTableCell>

                            </InputTableRow>

                            <InputTableRow>

                                <InputTableCell style={{ width: 660, paddingLeft: 12 }}>

                                    Grip Orientation:

                                </InputTableCell>

                                <InputTableCell style={{ width: 220 }}>


                                    <FieldSelect
                                        button={true}
                                        form={form}
                                        wrapClass={"w-200"}
                                        name={`${prefix}.gripOrientationPre`}
                                        required={false}
                                        defaultValue={bike.gripOrientationPre}
                                        allowCustomOption={true}
                                        options={[
                                            { value: "Left Inwards", label: "Left Inwards" },
                                            { value: "Right Inwards", label: "Right Inwards" },
                                            { value: "Left Outwards", label: "Left Outwards" },
                                            { value: "Right Outwards", label: "Right Outwards" },
                                            { value: "Both Straight", label: "Both Straight" },
                                            { value: "Both Inwards", label: "Both Inwards" },
                                            { value: "Both Outwards", label: "Both Outwards" },
                                            { value: "Left Inwards, Right Outwards", label: "Left Inwards, Right Outwards" },
                                            { value: "Left Outwards, Right Inwards", label: "Left Outwards, Right Inwards" },
                                        ]}
                                        menuPlacement={"top"}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ width: 220 }}>

                                    <FieldSelect
                                        button={true}
                                        form={form}
                                        wrapClass={"w-200"}
                                        name={`${prefix}.gripOrientationPost`}
                                        required={false}
                                        defaultValue={bike.gripOrientationPost}
                                        allowCustomOption={true}
                                        options={[
                                            { value: "Left Inwards", label: "Left Inwards" },
                                            { value: "Right Inwards", label: "Right Inwards" },
                                            { value: "Left Outwards", label: "Left Outwards" },
                                            { value: "Right Outwards", label: "Right Outwards" },
                                            { value: "Both Straight", label: "Both Straight" },
                                            { value: "Both Inwards", label: "Both Inwards" },
                                            { value: "Both Outwards", label: "Both Outwards" },
                                            { value: "Left Inwards, Right Outwards", label: "Left Inwards, Right Outwards" },
                                            { value: "Left Outwards, Right Inwards", label: "Left Outwards, Right Inwards" },
                                        ]}
                                        menuPlacement={"top"}
                                    />

                                </InputTableCell>

                            </InputTableRow>

                        </>
                    }

                    {bike.handlebarType === 'Aerobars' &&
                        <>
                            <InputTableRow>

                                <InputTableCell colSpan={3} style={{ paddingLeft: 12 }}>

                                    <Heading space={null}>Aerobars:</Heading>

                                </InputTableCell>

                            </InputTableRow>

                            <InputTableRow>

                                <InputTableCell style={{ width: 660, paddingLeft: 12 }}>

                                    Aerobar Brand:

                                </InputTableCell>

                                <InputTableCell style={{ width: 220 }}>

                                    <FieldText
                                        form={form}
                                        wrapClass={"w-200"}
                                        name={`${prefix}.aerobarBrandPre`}
                                        defaultValue={bike.aerobarBrandPre}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ width: 220 }}>

                                    <FieldText
                                        form={form}
                                        wrapClass={"w-200"}
                                        name={`${prefix}.aerobarBrandPost`}
                                        defaultValue={bike.aerobarBrandPost}
                                    />

                                </InputTableCell>

                            </InputTableRow>

                            <InputTableRow>

                                <InputTableCell style={{ width: 660, paddingLeft: 12 }}>

                                    Aeropad Tilt:

                                </InputTableCell>

                                <InputTableCell style={{ width: 220 }}>
                                    <FieldNumber
                                        form={form}
                                        wrapClass={"w-200"}
                                        name={`${prefix}.aeropadTiltPre`}
                                        defaultValue={bike.aeropadTiltPre}
                                        suffix={" °"}
                                        allowNegative={true}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ width: 220 }}>

                                    <FieldNumber
                                        form={form}
                                        wrapClass={"w-200"}
                                        name={`${prefix}.aeropadTiltPost`}
                                        defaultValue={bike.aeropadTiltPost}
                                        suffix={" °"}
                                        allowNegative={true}
                                    />

                                </InputTableCell>

                            </InputTableRow>

                            <InputTableRow>

                                <InputTableCell style={{ width: 660, paddingLeft: 12 }}>

                                    Aeropad Spacers:

                                </InputTableCell>

                                <InputTableCell style={{ width: 220 }}>

                                    <FieldNumber
                                        form={form}
                                        wrapClass={"w-200"}
                                        name={`${prefix}.aeropadSpacersPre`}
                                        defaultValue={bike.aeropadSpacersPre}
                                        suffix={" mm"}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ width: 220 }}>

                                    <FieldNumber
                                        form={form}
                                        wrapClass={"w-200"}
                                        name={`${prefix}.aeropadSpacersPost`}
                                        defaultValue={bike.aeropadSpacersPost}
                                        suffix={" mm"}
                                    />

                                </InputTableCell>

                            </InputTableRow>

                            <InputTableRow>

                                <InputTableCell style={{ width: 660, paddingLeft: 12 }}>

                                    Basebar to Aeropad Height:

                                </InputTableCell>

                                <InputTableCell style={{ width: 220 }}>

                                    <FieldNumber
                                        form={form}
                                        wrapClass={"w-200"}
                                        name={`${prefix}.basebarToAeropadHeightPre`}
                                        defaultValue={bike.basebarToAeropadHeightPre}
                                        suffix={" mm"}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ width: 220 }}>

                                    <FieldNumber
                                        form={form}
                                        wrapClass={"w-200"}
                                        name={`${prefix}.basebarToAeropadHeightPost`}
                                        defaultValue={bike.basebarToAeropadHeightPost}
                                        suffix={" mm"}
                                    />

                                </InputTableCell>

                            </InputTableRow>

                            <InputTableRow>

                                <InputTableCell style={{ width: 660, paddingLeft: 12 }}>

                                    Extension Type:

                                </InputTableCell>

                                <InputTableCell style={{ width: 220 }}>

                                    <FieldSelect
                                        button={true}
                                        form={form}
                                        wrapClass={"w-200"}
                                        name={`${prefix}.extensionTypePre`}
                                        required={false}
                                        defaultValue={bike.extensionTypePre}
                                        allowCustomOption={true}
                                        options={[
                                            { value: "J (Ski) Bend", label: "J (Ski) Bend" },
                                            { value: "J (Ski) Bend Closed", label: "J (Ski) Bend Closed" },
                                            { value: "S Bend", label: "S Bend" },
                                            { value: "Straight", label: "Straight" },
                                            { value: "Mini", label: "Mini" },
                                        ]}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ width: 220 }}>

                                    <FieldSelect
                                        button={true}
                                        form={form}
                                        wrapClass={"w-200"}
                                        name={`${prefix}.extensionTypePost`}
                                        required={false}
                                        defaultValue={bike.extensionTypePost}
                                        allowCustomOption={true}
                                        options={[
                                            { value: "J (Ski) Bend", label: "J (Ski) Bend" },
                                            { value: "J (Ski) Bend Closed", label: "J (Ski) Bend Closed" },
                                            { value: "S Bend", label: "S Bend" },
                                            { value: "Straight", label: "Straight" },
                                            { value: "Mini", label: "Mini" },
                                        ]}
                                    />

                                </InputTableCell>

                            </InputTableRow>

                        </>
                    }

                </InputTable>

            </Col>

            <Line />

        </Row >


    ), [props]);

}

export default SessionsEditTabsBikeSetup;
