import React, { useEffect } from 'react';
import { Bar, BarChart, Cell, Label, LabelList, Legend, ReferenceLine, Text } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { fixedNum, loop } from '@dex/bubl-helpers';
import { getClockTime, getDecimalTime, getDuration } from '../../functions/sharedTests';
import { fancyHourFormat, fancyTimeFormat, nearestMaxHundered, nearestMaxTen } from '../../functions/sharedChart';

const ChartPerformanceTimeTrial: React.FC<ChartPerformanceTimeTrialProps> = (props) => {

    let { data, type, compareTo, parameter, setTimeTrialDistance } = props;

    useEffect(() => {
        setTimeTrialDistance(type)
    }, [type]);

    const Axis = new ChartAxis();

    Axis.x = {
        key: "photoCaption",
        label: "Position",
        index: 6,
        format: undefined
    }

    Axis.x.props = {
        interval: 0,
        type: "category",
        ticks: data?.map(item => item?.photoCaption),
    }

    const totalSecondsToHMSTime = (totalSeconds) => {

        const isNegative = totalSeconds < 0;
        totalSeconds = Math.abs(totalSeconds);

        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = Math.floor(totalSeconds % 60);

        const formattedTime = `${isNegative ? '-' : ''}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

        return formattedTime;
    };

    const calculateTotalTIme = (distance, speed) => {

        if (!speed) return 0;
        if (!distance) distance = 10;

        const sec: number = (distance / speed) * 3600;

        const clockTime = totalSecondsToHMSTime(sec)

        return sec
    };

    const calculateDiffAndPercentage = (currentValue, previousValue) => {
        const diff = currentValue - previousValue;
        if (previousValue !== 0) {
            const percentageDiff = (diff / previousValue) * 100;
            return { diff, percent: fixedNum(percentageDiff, 0) };
        } else {
            return { diff, percent: 0 };
        }
    };

    loop(data, (item, index) => {

        item.trialTime = calculateTotalTIme(type, item.speed);
        const trialTimePrevious = data[(compareTo - 1)]?.trialTime || 0;
        const trialTimeResults = calculateDiffAndPercentage(item.trialTime, trialTimePrevious);
        item.timeDiff = trialTimeResults.diff;
        item.timeDiffPercent = trialTimeResults.percent;

        const speedPrevious = data[(compareTo - 1)]?.speed || 0;
        const speedResults = calculateDiffAndPercentage(item.speed, speedPrevious);
        item.speedDiff = fixedNum(speedResults.diff, 2);
        item.speedDiffPercent = speedResults.percent;

        const powerPrevious = data[(compareTo - 1)]?.power || 0;
        const powerResults = calculateDiffAndPercentage(item.power, powerPrevious);
        item.powerDiff = fixedNum(powerResults.diff, 2);
        item.powerDiffPercent = powerResults.percent;
    });

    if (parameter === "Total Time") {
        Axis.y.push({
            axis: true,
            // bar: true,
            pos: "left",
            index: 4,
            key: "trialTime",
            axisLabel: "Total Time - hh:mm:ss",
            label: "Total Time",
            props: {
                tickFormatter: fancyTimeFormat,
            }
        });
    } else if (parameter === "Avg Speed") {
        Axis.y.push({
            axis: true,
            // bar: true,
            pos: "left",
            index: 1,
            key: "speed",
            axisLabel: "Avg Speed",
            label: "Avg Speed",
            props: {
                domain: [0, nearestMaxTen],
            }
        });
    } else if (parameter === "Avg Power") {
        Axis.y.push({
            axis: true,
            // bar: true,
            pos: "left",
            index: 0,
            key: "power",
            axisLabel: "Avg Power",
            label: "Avg Power",
            props: {
                domain: [0, nearestMaxHundered],
            }
        });
    }

    Axis.legend = false;

    let diff = "time";

    switch (parameter) {
        case "Total Time":
            diff = "time";
            break;
        case "Avg Speed":
            diff = "speed";
            break;
        case "Avg Power":
            diff = "power";
            break;
        default:
            break;
    };

    return (

        <Chart>

            <BarChart
                {...ChartProps.BarChart()}
                data={data}
            >

                {Axis.render()}

                {Axis.y.length >= 1 &&
                    <Bar
                        dataKey={Axis.y[0].key || ""}
                        yAxisId={"left-axis"}
                        barSize={80}
                        fill={ChartProps.indexColor(Axis.y[0].index)}
                    >
                        <>
                            <LabelList position="insideBottom" offset={20} fill={ChartProps.indexColor(7)} formatter={diff == "time" ? totalSecondsToHMSTime : undefined} dataKey={`${diff}Diff`} style={{ fontSize: 16 }} />
                            <LabelList position="insideBottom" fill={ChartProps.indexColor(7)} formatter={(val) => `(${val} %)`} dataKey={`${diff}DiffPercent`} style={{ fontSize: 16 }} />
                            <LabelList position="top" fill={ChartProps.indexColor(Axis.y[0].index)} formatter={diff == "time" ? totalSecondsToHMSTime : undefined} dataKey={Axis.y[0].key} />
                        </>
                    </Bar>
                }

            </BarChart>

        </Chart>


    )

}

interface ChartPerformanceTimeTrialProps {
    [key: string]: any,
}

export default ChartPerformanceTimeTrial;