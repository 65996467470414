import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldList from '@dex/bubl-dash/src/lib/components/Fields/FieldList';
import FieldPopup from '@dex/bubl-dash/src/lib/components/Fields/FieldPopup';
import SessionsEditTabsPanel from './SessionsEditTabsPanel';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import FieldLabel from '@dex/bubl-dash/src/lib/components/Fields/FieldLabel';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import FieldUpload from '@dex/bubl-dash/src/lib/components/Fields/FieldUpload';
import EditableContext from '../../../context/EditableContext';
import { Button, Space, useApp } from '@dex/bubl-dash';
import { set, get } from '@dex/bubl-helpers';

const SessionsEditTabsCleat: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, data } = props;

    return useMemo(() => (

        <SessionsEditTabsPanel
            heading={"Cleats"}
            {...props}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldList
                        form={form}
                        name={`cleats`}
                        label={"Cleats"}
                        addButtonLabel={"Add Cleat"}
                        defaultValues={{}}
                    >

                        {(form.values.cleats || []).map((cleat, i) => (

                            <SessionsEditTabsCleatForm
                                key={i}
                                i={i}
                                cleat={cleat}
                                form={form}
                                data={data}
                            />

                        ))}

                    </FieldList>

                </Col>

            </Row>

        </SessionsEditTabsPanel>


    ), [props]);

}

const SessionsEditTabsCleatForm: React.FC<any> = (props: any) => {

    const { i, cleat, form, data } = props;

    const prefix = `cleats[${i}]]`;

    const [tab, setTab] = useState("details");

    const [key, setKey] = useState(0);

    const editable = useContext(EditableContext);

    const app = useApp();

    const [settings] = app.store("appSettings");
    const useDefaultText = useCallback((defaultName, name: string) => {

        let text = get(settings.fit, defaultName);

        const values = form.values;

        set(values, name, text);

        setKey(key + 1);

    }, [props, form.hash]);

    return (

        <FieldPopup
            buttonLabel={"Cleat " + (i + 1) + (cleat.shoeBrand ? ` - ${cleat.shoeBrand}` : "") + (cleat.shoeSize ? ` Size ${cleat.shoeSize}` : "")}
            heading={"Cleat " + (i + 1) + (cleat.shoeBrand ? ` - ${cleat.shoeBrand}` : "") + (cleat.shoeSize ? ` Size ${cleat.shoeSize}` : "")}
            form={form}
            name={prefix}
            modalProps={{
                className: 'modal-top'
            }}
            panelProps={{
                tabs: [
                    { label: "Details", name: "details" },
                    { label: "Position", name: "position" },
                    { label: "Left Cleat", name: "leftCleat" },
                    { label: "Right Cleat", name: "rightCleat" },
                ],
                setTab: setTab,
                activeTab: tab
            }}
        >

            <div className={!editable ? 'form-not-editable' : ''}>

                <div className={tab === "details" ? "" : "hidden"}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldText
                                form={form}
                                label={"Shoe Brand"}
                                name={`${prefix}.shoeBrand`}
                                defaultValue={cleat.shoeBrand}
                            />

                        </Col>

                        <Col col={{ xs: 12, md: 8 }}>

                            <FieldNumber
                                form={form}
                                label={"Shoe Size"}
                                name={`${prefix}.shoeSize`}
                                defaultValue={cleat.shoeSize}
                                suffix={" EUR"}
                            />

                        </Col>

                        <Col col={{ xs: 6, md: 4 }}>

                            <FieldNumber
                                form={form}
                                label={"Measured Shoe Size (Left)"}
                                name={`${prefix}.footLengthLeft`}
                                defaultValue={data.foot.measuredShoeLeft}
                                suffix={" EUR"}
                                disabled={true}
                            />

                        </Col>

                        <Col col={{ xs: 6, md: 4 }}>

                            <FieldNumber
                                form={form}
                                label={"Measured Shoe Size (Right)"}
                                name={`${prefix}.footLengthRight`}
                                defaultValue={data.foot.measuredShoeRight}
                                suffix={" EUR"}
                                disabled={true}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <Line />

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldSelect
                                form={form}
                                label={"Cleat Brand"}
                                name={`${prefix}.cleatBrand`}
                                defaultValue={cleat.cleatBrand}
                                allowCustomOption={true}
                                options={[
                                    { value: "Shimano", label: "Shimano" },
                                    { value: "Look", label: "Look" },
                                    { value: "Shimano-Imitation", label: "Shimano-Imitation" },
                                    { value: "Look-Imitation", label: "Look-Imitation" },
                                    { value: "Speedplay", label: "Speedplay" },
                                    { value: "Exustar", label: "Exustar" },
                                    { value: "Assioma", label: "Assioma" },
                                    { value: "Garmin", label: "Garmin" },
                                    { value: "Time", label: "Time" },
                                    { value: "Platform", label: "Platform" },
                                    { value: "MTB", label: "MTB" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldSelect
                                form={form}
                                label={"Float"}
                                name={`${prefix}.float`}
                                defaultValue={cleat.float}
                                options={[
                                    { value: "A Lot", label: "A Lot" },
                                    { value: "Moderate", label: "Moderate" },
                                    { value: "Minimal", label: "Minimal" },
                                    { value: "Fixed", label: "Fixed" },
                                    { value: "Uncertain", label: "Uncertain" },
                                    { value: "Not Evaluated", label: "Not Evaluated" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 8 }}>

                            <FieldSelect
                                form={form}
                                label={"Insoles"}
                                name={`${prefix}.insoles`}
                                defaultValue={cleat.insoles}
                                allowCustomOption={true}
                                options={[
                                    { value: "Shoe Brand", label: "Shoe Brand" },
                                    { value: "G8 - Level 1", label: "G8 - Level 1" },
                                    { value: "G8 - Level 2", label: "G8 - Level 2" },
                                    { value: "G8 - Level 3", label: "G8 - Level 3" },
                                    { value: "G8 - Level 4", label: "G8 - Level 4" },
                                    { value: "G8 - Level 5", label: "G8 - Level 5" },
                                    { value: "Push GebioMized - Low Arch", label: "Push GebioMized - Low Arch" },
                                    { value: "Push GebioMized - Med Arch", label: "Push GebioMized - Med Arch" },
                                    { value: "Push GebioMized - High Arch", label: "Push GebioMized - High Arch" },
                                    { value: "Specialized - Low Arch", label: "Specialized - Low Arch" },
                                    { value: "Specialized - Med Arch", label: "Specialized - Med Arch" },
                                    { value: "Specialized - High Arch", label: "Specialized - High Arch" },
                                    { value: "Solestar - Low Arch", label: "Solestar - Low Arch" },
                                    { value: "Solestar - Med Arch", label: "Solestar - Med Arch" },
                                    { value: "Solestar - High Arch", label: "Solestar - High Arch" }
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>
                            <Line />
                        </Col>

                        <Col col={{ xs: 24 }}>

                            <FieldUpload
                                form={form}
                                folder='admin-media'
                                label={"Cleat Photo"}
                                name={`${prefix}.photo`}
                                defaultValue={cleat.photo}
                                accept='image/*'
                            />

                        </Col>

                        <Col col={{ xs: 24 }}>
                            <Line />
                        </Col>

                        <Row edge={true} gutter={8}>

                            <Col col={{ xs: 24, lg: 20 }}>

                                <FieldTextArea
                                    form={form}
                                    label={"Cleat Comments"}
                                    name={`${prefix}.comments`}
                                    defaultValue={cleat.comments}
                                    rows={4}
                                    maxLength={500}
                                />

                            </Col>

                            <Col col={{ xs: 24, lg: 4 }}>

                                <Space />

                                <Button
                                    label="Use Default Text"
                                    type='faded'
                                    size='small'
                                    confirm={cleat.comments ? { title: 'Are you sure?', message: 'Are you sure you want to overwrite the current comment' } : undefined}
                                    onClick={useDefaultText.bind(null, "cleatComment", `${prefix}.comments`)}
                                />

                            </Col>

                        </Row>

                    </Row>

                </div>

                <div className={tab === "position" ? "" : "hidden"}>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 10 }} align='center' className='text-center'>

                                    <FieldLabel
                                        label="Fore/Aft"
                                    />

                                    <br />

                                    <img src={require('../../../assets/images/ForeAft.png')}
                                        alt="Fore/Aft"
                                        className="cleatImage"
                                    />

                                </Col>

                                <Col col={{ xs: 12, md: 7 }}>

                                    <FieldSelect
                                        form={form}
                                        name={`${prefix}.foreLeft`}
                                        label="Left"
                                        defaultValue={cleat.foreLeft}
                                        allowCustomOption={true}
                                        options={[
                                            { value: "Shifted Cleats Rearwards By", label: "Shifted Cleats Rearwards By" },
                                            { value: "Shifted Cleats Forwards By", label: "Shifted Cleats Forwards By" },
                                            { value: "Unchanged", label: "Unchanged" },
                                        ]}
                                    />

                                    {(cleat.foreLeft && cleat.foreLeft !== "Unchanged") &&
                                        <FieldText
                                            form={form}
                                            name={`${prefix}.foreLeftDetails`}
                                            label="Left Details"
                                            defaultValue={cleat.foreLeftDetails}
                                        />
                                    }

                                </Col>

                                <Col col={{ xs: 12, md: 7 }}>

                                    <FieldSelect
                                        form={form}
                                        name={`${prefix}.foreRight`}
                                        label="Right"
                                        defaultValue={cleat.foreRight}
                                        allowCustomOption={true}
                                        options={[
                                            { value: "Shifted Cleats Rearwards By", label: "Shifted Cleats Rearwards By" },
                                            { value: "Shifted Cleats Forwards By", label: "Shifted Cleats Forwards By" },
                                            { value: "Unchanged", label: "Unchanged" },
                                        ]}
                                    />

                                    {(cleat.foreRight && cleat.foreRight !== "Unchanged") &&
                                        <FieldText
                                            form={form}
                                            name={`${prefix}.foreRightDetails`}
                                            label="Right Details"
                                            defaultValue={cleat.foreRightDetails}
                                        />
                                    }

                                </Col>

                            </Row>

                        </Col>

                        <Col col={{ xs: 24 }}>
                            <Line />
                        </Col>

                        <Col col={{ xs: 24 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 10 }} align='center' className='text-center'>

                                    <FieldLabel
                                        label="Float (Rotation)"
                                    />

                                    <br />

                                    <img src={require('../../../assets/images/FloatRotation.png')}
                                        alt="Float Rotation"
                                        className="cleatImage"
                                    />

                                </Col>

                                <Col col={{ xs: 12, md: 7 }}>

                                    <FieldSelect
                                        form={form}
                                        name={`${prefix}.floatLeft`}
                                        label="Left"
                                        defaultValue={cleat.floatLeft}
                                        allowCustomOption={true}
                                        options={[
                                            { value: "Rotated Heel Inwards By", label: "Rotated Heel Inwards By" },
                                            { value: "Rotated Heel Outwards By", label: "Rotated Heel Outwards By" },
                                            { value: "Unchanged", label: "Unchanged" },
                                        ]}
                                    />

                                    {(cleat.floatLeft && cleat.floatLeft !== "Unchanged") &&
                                        <FieldText
                                            form={form}
                                            name={`${prefix}.floatLeftDetails`}
                                            label="Left Details"
                                            defaultValue={cleat.floatLeftDetails}
                                        />
                                    }

                                </Col>

                                <Col col={{ xs: 12, md: 7 }}>

                                    <FieldSelect
                                        form={form}
                                        name={`${prefix}.floatRight`}
                                        label="Right"
                                        defaultValue={cleat.floatRight}
                                        allowCustomOption={true}
                                        options={[
                                            { value: "Rotated Heel Inwards By", label: "Rotated Heel Inwards By" },
                                            { value: "Rotated Heel Outwards By", label: "Rotated Heel Outwards By" },
                                            { value: "Unchanged", label: "Unchanged" },
                                        ]}
                                    />

                                    {(cleat.floatRight && cleat.floatRight !== "Unchanged") &&
                                        <FieldText
                                            form={form}
                                            name={`${prefix}.floatRightDetails`}
                                            label="Right Details"
                                            defaultValue={cleat.floatRightDetails}
                                        />
                                    }

                                </Col>

                            </Row>

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <Line />

                        </Col>

                        <Col col={{ xs: 24 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 10 }} align='center' className='text-center'>

                                    <FieldLabel
                                        label="Side-to-Side"
                                    />

                                    <br />

                                    <img src={require('../../../assets/images/SideTravel.png')}
                                        alt="Side-to-Side"
                                        className="cleatImage"
                                    />

                                </Col>

                                <Col col={{ xs: 12, md: 7 }}>

                                    <FieldSelect
                                        form={form}
                                        name={`${prefix}.sideLeft`}
                                        label="Left"
                                        defaultValue={cleat.sideLeft}
                                        allowCustomOption={true}
                                        options={[
                                            { value: "Shifted Towards Cranks", label: "Shifted Towards Cranks" },
                                            { value: "Shifted Away From Cranks", label: "Shifted Away From Cranks" },
                                            { value: "Centered", label: "Centered" },
                                            { value: "Unchanged", label: "Unchanged" },
                                        ]}
                                    />

                                    {(cleat.sideLeft && cleat.sideLeft !== "Unchanged" && cleat.sideLeft !== "Centered") &&
                                        <FieldText
                                            form={form}
                                            name={`${prefix}.sideLeftDetails`}
                                            label="Left Details"
                                            defaultValue={cleat.sideLeftDetails}
                                        />
                                    }

                                </Col>

                                <Col col={{ xs: 12, md: 7 }}>

                                    <FieldSelect
                                        form={form}
                                        name={`${prefix}.sideRight`}
                                        label="Right"
                                        defaultValue={cleat.sideRight}
                                        allowCustomOption={true}
                                        options={[
                                            { value: "Shifted Towards Cranks", label: "Shifted Towards Cranks" },
                                            { value: "Shifted Away From Cranks", label: "Shifted Away From Cranks" },
                                            { value: "Centered", label: "Centered" },
                                            { value: "Unchanged", label: "Unchanged" },
                                        ]}
                                    />

                                    {(cleat.sideRight && cleat.sideRight !== "Unchanged" && cleat.sideRight !== "Centered") &&
                                        <FieldText
                                            form={form}
                                            name={`${prefix}.sideRightDetails`}
                                            label="Right Details"
                                            defaultValue={cleat.sideRightDetails}
                                        />
                                    }

                                </Col>

                            </Row>

                        </Col>

                    </Row>

                </div>

                <div className={tab === "leftCleat" ? "" : "hidden"}>

                    <Row gutter={16} edge={true}>

                        <Col col={{ xs: 24, md: 8 }} align='center' justify='center'>

                            <img src={require('../../../assets/images/Cleat/Cleat_Left.png')}
                                alt="Left Cleat"
                                className="cleatSideImage"
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 16 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Big Toe Joint from Pedal Center"}
                                        name={`${prefix}.leftBigToe`}
                                        defaultValue={cleat.leftBigToe}
                                        suffix={" mm"}
                                        allowNegative={true}
                                    />

                                    <FieldNumber
                                        form={form}
                                        label={"Heel In - / Out +"}
                                        name={`${prefix}.leftHeelInOut`}
                                        defaultValue={cleat.leftHeelInOut}
                                        suffix={" mm"}
                                        allowNegative={true}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>
                                    <Line />
                                </Col>

                            </Row>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Shims"}
                                        name={`${prefix}.leftShims`}
                                        defaultValue={cleat.leftShims}
                                        suffix={" mm"}
                                    />

                                </Col>

                            </Row>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Heel Wedges"}
                                        name={`${prefix}.leftHeelWedges`}
                                        defaultValue={cleat.leftHeelWedges}
                                        suffix={"°"}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldSelect
                                        form={form}
                                        label={"Heel Wedges Details"}
                                        name={`${prefix}.leftHeelWedgesDetails`}
                                        defaultValue={cleat.leftHeelWedgesDetails}
                                        options={[
                                            { value: "Medial", label: "Medial" },
                                            { value: "Lateral", label: "Lateral" },
                                            { value: "None", label: "None" },
                                        ]}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Cleat Wedges"}
                                        name={`${prefix}.leftCleatWedges`}
                                        defaultValue={cleat.leftCleatWedges}
                                        suffix={"°"}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldSelect
                                        form={form}
                                        label={"Cleat Wedges Details"}
                                        name={`${prefix}.leftCleatWedgesDetails`}
                                        defaultValue={cleat.leftCleatWedgesDetails}
                                        options={[
                                            { value: "Medial", label: "Medial" },
                                            { value: "Lateral", label: "Lateral" },
                                            { value: "None", label: "None" },
                                        ]}
                                    />

                                </Col>

                            </Row>

                        </Col>

                    </Row>

                </div>

                <div className={tab === "rightCleat" ? "" : "hidden"}>

                    <Row gutter={16} edge={true} >

                        <Col col={{ xs: 24, md: 8 }} align='center' justify='center'>

                            <img src={require('../../../assets/images/Cleat/Cleat_Right.png')}
                                alt="Right Cleat"
                                className="cleatSideImage"
                            />

                        </Col>

                        <Col col={{ xs: 24, md: 16 }}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Big Toe Joint from Pedal Center"}
                                        name={`${prefix}.rightBigToe`}
                                        defaultValue={cleat.rightBigToe}
                                        suffix={" mm"}
                                        allowNegative={true}
                                    />

                                    <FieldNumber
                                        form={form}
                                        label={"Heel In - / Out +"}
                                        name={`${prefix}.rightHeelInOut`}
                                        defaultValue={cleat.rightHeelInOut}
                                        suffix={" mm"}
                                        allowNegative={true}
                                    />

                                </Col>

                                <Col col={{ xs: 24 }}>

                                    <Line />

                                </Col>

                            </Row>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Shims"}
                                        name={`${prefix}.rightShims`}
                                        defaultValue={cleat.rightShims}
                                        suffix={" mm"}
                                    />

                                </Col>

                            </Row>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Heel Wedges"}
                                        name={`${prefix}.rightHeelWedges`}
                                        defaultValue={cleat.rightHeelWedges}
                                        suffix={"°"}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldSelect
                                        form={form}
                                        label={"Heel Wedges Details"}
                                        name={`${prefix}.rightHeelWedgesDetails`}
                                        defaultValue={cleat.rightHeelWedgesDetails}
                                        options={[
                                            { value: "Medial", label: "Medial" },
                                            { value: "Lateral", label: "Lateral" },
                                            { value: "None", label: "None" },
                                        ]}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldNumber
                                        form={form}
                                        label={"Cleat Wedges"}
                                        name={`${prefix}.rightCleatWedges`}
                                        defaultValue={cleat.rightCleatWedges}
                                        suffix={"°"}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldSelect
                                        form={form}
                                        label={"Cleat Wedges Details"}
                                        name={`${prefix}.rightCleatWedgesDetails`}
                                        defaultValue={cleat.rightCleatWedgesDetails}
                                        options={[
                                            { value: "Medial", label: "Medial" },
                                            { value: "Lateral", label: "Lateral" },
                                            { value: "None", label: "None" },
                                        ]}
                                    />

                                </Col>

                            </Row>

                        </Col>

                    </Row>

                </div>

            </div>

        </FieldPopup>

    )

}

export default SessionsEditTabsCleat;
