import React, { useEffect, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import AthletesApi from '../AthletesApi';
import { FieldList, FieldRadio, FieldSelect, Form } from '@dex/bubl-dash';

const AthletesSingleTrackingForm: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const sessions = AthletesApi.getTrackingSessionsList(data.id);

    useEffect(() => {

        sessions.run({
            params: {},
            onComplete: (sessions) => {

                const preselected = sessions.map((session: any, index) => {
                    return index < 3 ? session.value : null
                }).filter(Boolean);

                form.handleChange({ name: "sessions", value: preselected, silent: true });

            }
        });

    }, []);

    const dataSetOptions = useMemo(() => {

        let options: any = [];

        if (form.values.dataType === "labTest") {

            options = [
                { label: "Bio", value: "bio" },
                { label: "Health", value: "health" },
                { label: "VO2", value: "vo2" },
                { label: "HR", value: "hr" },
                { label: "Breathing", value: "breathing" },
                { label: "Muscle", value: "muscle" },
                { label: "Lactate", value: "lactate" },
                { label: "Summary", value: "summary" },
            ];

        } else if (form.values.dataType === "fitSize") {

            options = [
                { label: "Bio", value: "bio" },
                { label: "Injury", value: "injury" },
                { label: "Posture", value: "posture" },
                { label: "Mobility", value: "mobility" },
                { label: "Symmetry", value: "symmetry" },
                { label: "Stability", value: "stability" },
                { label: "Foot", value: "foot" },
                { label: "Cleat", value: "cleat" },
                { label: "Bike", value: "bike" },
                { label: "Bike Sizing", value: "bikeSizing" },
                { label: "Exercises", value: "exercises" },
                { label: "Summary", value: "summary" },
            ];

        }

        if (options[0]) form.handleChange({ name: "dataSet", value: options[0].value, silent: true });

        return options;

    }, [form.values.dataType])

    return useMemo(() => (

        <>

            {sessions.data && sessions.data.length > 0 &&

                <Form
                    form={form}
                >

                    <Row
                        gutter={'auto'}
                        edge={true}
                    >

                        <Col col={{ xs: 24, sm: 12, md: 12 }}>

                            <FieldList
                                form={form}
                                name={'sessions'}
                                label={"Sessions"}
                                required={true}
                                addButtonLabel={"Add Session"}
                            >

                                {form.values.sessions.map((session, n) => (

                                    <React.Fragment key={form.key + "-" + n}>

                                        <FieldSelect
                                            form={form}
                                            name={`sessions[${n}]`}
                                            required={false}
                                            defaultValue={session}
                                            options={sessions.data}
                                            isClearable={false}
                                        />

                                    </React.Fragment>

                                ))}

                            </FieldList>

                        </Col>

                        <Col col={{ xs: 24, sm: 12, md: 6 }}>

                            <FieldRadio
                                form={form}
                                required={true}
                                name={`dataType`}
                                defaultValue={form.defaults.dataType}
                                label={"Data Type"}
                                list={true}
                                options={[
                                    { label: "Fit / Size", value: "fitSize" },
                                    { label: "Lab Test", value: "labTest" },
                                ]}
                            />

                        </Col>

                        <Col col={{ xs: 24, sm: 12, md: 6 }}>

                            <FieldRadio
                                key={form.values.dataType}
                                required={true}
                                form={form}
                                name={`dataSet`}
                                defaultValue={form.values.dataSet}
                                label={"Data Set"}
                                list={true}
                                options={dataSetOptions}
                            />

                        </Col>

                    </Row>

                </Form>
            }

        </>

    ), [data, form.hash, sessions]);

}

export default AthletesSingleTrackingForm;
