import { FieldHidden, useUpdateEffect } from '@dex/bubl-dash';
import { get, loop } from '@dex/bubl-helpers';
import React from 'react';

import styles from "./TabBody.module.scss";

const TabBody: React.FC<TabBodyProps> = (props) => {

    let { form, children, active, fields } = props;

    useUpdateEffect(() => {

        if (active) return;

        loop(fields, (name) => {

            const value = get(form.values, name);

            const event = {
                name: name,
                value: value,
                silent: true,
                reset: true,
            }

            // this doesnt make sense.
            // So i deisabled it
            // why are we running handleChange with the same value?
            // field hidden should already handle this

            //ok maybe not. It is needed so default values stay changed when tab is not active
            form.handleChange(event);

        });

    }, [active])

    return (

        <>

            {active &&
                children
            }

            {!active && fields.length > 0 &&
                <>

                    {fields.map((name, index) => (
                        <FieldHidden
                            key={index}
                            form={form}
                            name={name}
                            value={get(form.values, name)}
                        />
                    ))}

                </>
            }

        </>

    )

}

interface TabBodyProps {
    [key: string]: any,
}

export default TabBody;