import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import SessionsApi from './SessionsApi';

let settings;

export default settings = {
    key: 'sessions',
    zone: 'fit',
    path: '/sessions',
    title: "Fit / Size Sessions",
    endpoint: SessionsApi.endpoint,
    modelName: 'Sessions',
    idKey: 'id',
    primaryKey: 'id',
    actions: ['trash', 'clone'],
    indexCaps: ["viewSessions"],
    viewCaps: ["viewSessions"],
    createCaps: ["createSessions"],
    updateCaps: ["updateSessions"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: SessionsApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = {
            where: {},
            include: [{ relation: 'athlete', preset: "link" }, { relation: 'fitter', preset: "link" }],
            order: ['created.date DESC'],
            fields: [
                "id", "modelName",
                "athlete", "athleteId", "fullName",
                "fitter", "fitterId", "name",
                "sessionDate", "fitType", "mainBikeLabel",
                "created", "updated",
            ],
            limit: 10,
            skip: 0
        };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['fitType', 'mainBikeLabel', 'athleteName', 'id'];

        return fields;

    },
    indexExportFormats: (routeName, params, user) => {

        const formats = [
            { value: "all", label: "All" },
            { value: "male", label: "Male" },
            { value: "female", label: "Female" },
        ];

        if (!user || !user.caps.includes("exportSessions")) return [];

        return formats;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "ID",
                key: "id",
                type: "text",
            },
            {
                label: "Athlete",
                key: "athleteId",
                type: "relationship",
                model: "Athletes",
            },
            {
                label: "Session Date",
                key: "sessionDate",
                type: "date",
            },
            {
                label: "Fitter",
                key: "fitterId",
                type: "relationship",
                model: "Fitters",
            },
            {
                label: "Main Bike",
                key: "mainBikeLabel",
                type: "text",
            },
            {
                label: "Fit Type",
                key: "fitType",
                type: "choice",
                options: [
                    { value: "Comprehensive Bike Fit", label: "Comprehensive Bike Fit" },
                    { value: "Basic Bike Fit", label: "Basic Bike Fit" },
                    { value: "Bike Sizing", label: "Bike Sizing" },
                    { value: "Size and Fit", label: "Size and Fit" },
                    { value: "Remote Sizing", label: "Remote Sizing" },
                    { value: "Refit Same Bike ", label: "Refit Same Bike " },
                    { value: "Refit New Bike", label: "Refit New Bike" },
                    { value: "Cleat Alignment ", label: "Cleat Alignment" },
                    { value: "Aerodynamic ", label: "Aerodynamic" },
                    { value: "Other Services", label: "Other Services" },
                ],
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "ID",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Athlete",
                key: "athlete",
                format: "ModelLink",
            },
            {
                label: "Fit Type",
                key: "fitType",
                type: 'text',
                format: "titleCase",
            },
            {
                label: "Session Date",
                key: "sessionDate",
                format: 'date',
            },
            {
                label: "Fitter",
                key: "fitter",
                format: "ModelLink",
            },
            {
                label: "Main Bike",
                key: "mainBikeLabel",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: SessionsApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: SessionsApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: ['athlete', 'fitter'] };

        return filters;

    },

    reportFetch: SessionsApi.getReport,
    reportFetchParams: (routeName, params, users, fetch) => {

        const filters = { key: params?.reportKey };

        return filters;

    },

    singleTitle: (routeName, params, users, fetch, app) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) {

            title = [data.athlete.fullName, app.formatValue("dateDay", {}, data.sessionDate)].join(" - "); //data.fitType,

        }
        else if (complete && !data) title = "Not Found";

        return title;

    },

    singleButtons: (routeName, params, users, fetch) => {

        const data = get(fetch, 'data');

        if (!data) return [];

        return [
            {
                label: "Athlete Profile",
                path: `/athletes/${data.athleteId}/view`,
                caps: settings.createCaps
            },
        ]

    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        let tabs: any = [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Bio Data",
                path: path + "/edit/bio",
                caps: settings.updateCaps
            },

        ]

        const fitType = get(fetch, 'data.fitType');

        if (fitType !== "Aerodynamic") {
            tabs = [
                ...tabs,
                {
                    label: "Injury",
                    path: path + "/edit/injury",
                    caps: settings.updateCaps
                },
            ]
        }

        if (fitType === 'Bike Sizing' || fitType === 'Remote Sizing') {
            tabs = [
                ...tabs,
                {
                    label: "Toe Touch",
                    path: path + "/edit/toeTouch",
                    caps: settings.updateCaps
                },
                {
                    label: "Foot",
                    path: path + "/edit/foot",
                    caps: settings.updateCaps
                },
                {
                    label: "Bike Sizing",
                    path: path + "/edit/sizing",
                    caps: settings.updateCaps
                },
                {
                    label: "Summary",
                    path: path + "/edit/summary",
                    caps: settings.updateCaps
                },
            ]
        } else if (fitType === 'Basic Bike Fit') {
            tabs = [
                ...tabs,
                {
                    label: "Mobility",
                    path: path + "/edit/mobility",
                    caps: settings.updateCaps
                },
                {
                    label: "Foot",
                    path: path + "/edit/foot",
                    caps: settings.updateCaps
                },
                {
                    label: "Cleat",
                    path: path + "/edit/cleat",
                    caps: settings.updateCaps
                },
                {
                    label: "Bike",
                    path: path + "/edit/bike",
                    caps: settings.updateCaps
                },
                {
                    label: "Exercises",
                    path: path + "/edit/exercises",
                    caps: settings.updateCaps
                },
                {
                    label: "Summary",
                    path: path + "/edit/summary",
                    caps: settings.updateCaps
                },
            ]
        } else if (fitType != "Aerodynamic") {
            tabs = [
                ...tabs,
                {
                    label: "Posture",
                    path: path + "/edit/posture",
                    caps: settings.updateCaps
                },
                {
                    label: "Mobility",
                    path: path + "/edit/mobility",
                    caps: settings.updateCaps
                },
                {
                    label: "Symmetry",
                    path: path + "/edit/symmetry",
                    caps: settings.updateCaps
                },
                {
                    label: "Stability",
                    path: path + "/edit/stability",
                    caps: settings.updateCaps
                },
                {
                    label: "Foot",
                    path: path + "/edit/foot",
                    caps: settings.updateCaps
                },
                {
                    label: "Cleat",
                    path: path + "/edit/cleat",
                    caps: settings.updateCaps
                },
                {
                    label: "Bike",
                    path: path + "/edit/bike",
                    caps: settings.updateCaps
                },
                {
                    label: "Exercises",
                    path: path + "/edit/exercises",
                    caps: settings.updateCaps
                },
                {
                    label: "Summary",
                    path: path + "/edit/summary",
                    caps: settings.updateCaps
                },
            ]
        } else if (fitType == "Aerodynamic") {
            tabs = [
                ...tabs,
                {
                    label: "Bike",
                    path: path + "/edit/bike",
                    caps: settings.updateCaps
                },
                {
                    label: "Report",
                    path: path + "/edit/report",
                    caps: settings.updateCaps
                },
            ]
        }

        return tabs;

    },
};