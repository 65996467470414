import React, { useEffect, useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { DataTableCell, FieldAutocomplete, FieldRange, FieldSelect, Heading, InputTable, InputTableCell, InputTableRow, Space, useMountEffect } from '@dex/bubl-dash';
import { get, hashCode, loop } from '@dex/bubl-helpers';
import FieldRangeLabels from '../../../elements/FieldRangeLabels/FieldRangeLabels';
import { BsQuestion } from "react-icons/bs";
import { GoDash } from 'react-icons/go';
import { HiArrowDown, HiArrowUp } from "react-icons/hi";
import styles from "./GaitsEditTabs.module.scss";

const GaitsEditTabsRunChanges: React.FC<any> = (props: any) => {

    const { form, prefix, run, data } = props;

    const columns = [
        ...(run?.metrics || []).map((metric, i) => (
            {
                label: `Trial ${i + 1}`,
                style: { minWidth: 200 }
            }
        ))
    ];

    const options = [
        { value: 'No Change', label: <GoDash className={styles.dashSmall} /> },
        { value: 'Improved a lot', label: <><HiArrowUp className={styles.upSmall} /><HiArrowUp className={styles.upSmall} /><HiArrowUp className={styles.upSmall} /></> },
        { value: 'Improved Moderately', label: <><HiArrowUp className={styles.upSmall} /><HiArrowUp className={styles.upSmall} /></> },
        { value: 'Improved a bit', label: <><HiArrowUp className={styles.upSmall} /></> },
        { value: 'Worsened a lot', label: <><HiArrowDown className={styles.downSmall} /><HiArrowDown className={styles.downSmall} /><HiArrowDown className={styles.downSmall} /></> },
        { value: 'Worsened Moderately', label: <><HiArrowDown className={styles.downSmall} /><HiArrowDown className={styles.downSmall} /></> },
        { value: 'Worsened a bit', label: <><HiArrowDown className={styles.downSmall} /></> },
        { value: "Can't tell/Uncertain", label: <BsQuestion className={styles.dashSmall} /> },
    ];

    useEffect(() => {

        const average = (left, right, inverted = false) => {
            const total = left + right;
            const result = total / 2;

            const valueMap = {
                5: 0,
                4: 1,
                3: 2,
                2: 3,
                1: 4,
                0: 5,
                4.5: 0.5,
                3.5: 2.5,
                2.5: 2.5,
                1.5: 3.5,
                0.5: 4.5,
            };

            if (result in valueMap && inverted) {
                return valueMap[result];
            } else {
                return result;
            }

        }

        const footPronation = (ratio) => {
            if (ratio >= 2 && ratio <= 3) {
                return 5;
            } else if ((ratio >= 1 && ratio <= 1.5) || (ratio >= 3.5 && ratio <= 4)) {
                return 2.5;
            } else if (ratio <= 1 || ratio >= 4) {
                return 0;
            }
        };

        const leanRating = (ratio) => {
            if (ratio >= 1 && ratio <= 2) {
                return 5;
            } else if ((ratio >= -1 && ratio <= 0.5) || (ratio >= 2.5 && ratio <= 4)) {
                return 2.5;
            } else if (ratio < -1 || ratio > 4) {
                return 0;
            }
        };


        const runningCadence = (cadence) => {
            if (cadence > 190) {
                return 2.5;
            } else if (cadence >= 170 && cadence <= 190) {
                return 5;
            } else if (cadence >= 160 && cadence <= 169.9) {
                return 2.5;
            } else if (cadence >= 150 && cadence <= 159.9) {
                return 1.5;
            } else {
                return 0;
            }
        };

        const runningVerticalRatio = (ratio) => {
            if (ratio >= 8.2 && ratio <= 9.9) {
                return 5;
            } else if ((ratio >= 6.3 && ratio <= 8.1) || (ratio >= 10 && ratio <= 11.8)) {
                return 2.5;
            } else if (ratio < 6.3 || ratio > 11.8) {
                return 0;
            }
        };

        const runningGctBalance = (ratio) => {
            if (ratio >= 49.5 && ratio <= 50.5) {
                return 5;
            } else if ((ratio >= 48.5 && ratio <= 49.4) || (ratio >= 50.6 && ratio <= 51.5)) {
                return 2.5;
            } else if (ratio < 48.5 || ratio > 51.5) {
                return 0;
            }
        };

        if (run?.side?.footInclination?.leftRating && run?.side?.footInclination?.rightRating && !get(run, `changes[0].footStrike`)) form.handleChange({ reset: true, name: `${prefix}.changes[0].footStrike`, value: average(run?.side?.footInclination?.leftRating, run?.side?.footInclination?.rightRating, true) })

        if (run?.rear?.kneeShifting?.leftRating && run?.rear?.kneeShifting?.rightRating && !get(run, `changes[0].kneeStability`)) form.handleChange({ reset: true, name: `${prefix}.changes[0].kneeStability`, value: average(run?.rear?.kneeShifting?.leftRating, run?.rear?.kneeShifting?.rightRating, true) })

        if (run?.side?.footStrikeAudio?.rating && run?.side?.footStrikeAudio?.rating && !get(run, `changes[0].footNoise`)) form.handleChange({ reset: true, name: `${prefix}.changes[0].footNoise`, value: average(run?.side?.footStrikeAudio?.rating, run?.side?.footStrikeAudio?.rating) })

        if (run?.rear?.footPronationRate?.leftRating && run?.rear?.footPronationRate?.rightRating && !get(run, `changes[0].footControl`)) form.handleChange({ reset: true, name: `${prefix}.changes[0].footControl`, value: footPronation(average(run?.rear?.footPronationRate?.leftRating, run?.rear?.footPronationRate?.rightRating)) })

        if (run?.rear?.pelvicDrop?.leftRating && run?.rear?.pelvicDrop?.rightRating && !get(run, `changes[0].hipStability`)) form.handleChange({ reset: true, name: `${prefix}.changes[0].hipStability`, value: footPronation(average(run?.rear?.pelvicDrop?.leftRating, run?.rear?.pelvicDrop?.rightRating)) })

        if (run?.side?.armSwingStyle?.leftRating && run?.side?.armSwingStyle?.rightRating && !get(run, `changes[0].armSwing`)) form.handleChange({ reset: true, name: `${prefix}.changes[0].armSwing`, value: footPronation(average(run?.side?.armSwingStyle?.leftRating, run?.side?.armSwingStyle?.rightRating)) })

        if (run?.side?.torsoLean?.rating && !get(run, `changes[0].torsoPosture`)) form.handleChange({ reset: true, name: `${prefix}.changes[0].torsoPosture`, value: leanRating(run?.side?.torsoLean?.rating) })

        if (run?.metrics[0]?.cadence && !get(run, `changes[0].cadence`)) form.handleChange({ reset: true, name: `${prefix}.changes[0].cadence`, value: runningCadence(run?.metrics[0]?.cadence) })

        if (run?.metrics[0]?.verticalRatio && !get(run, `changes[0].verticalHip`)) form.handleChange({ reset: true, name: `${prefix}.changes[0].verticalHip`, value: runningVerticalRatio(run?.metrics[0]?.verticalRatio) })

        if (run?.metrics[0]?.gtcLeft && run?.metrics[0]?.gtcRight && !get(run, `changes[0].gtcBalance`)) form.handleChange({ reset: true, name: `${prefix}.changes[0].gtcBalance`, value: runningGctBalance(average(run?.metrics[0]?.gtcLeft, run?.metrics[0]?.gtcRight)) })

    }, [form.hash]);

    useEffect(() => {

        const metrics = get(form.values, `${prefix}.metrics`, []);
        const changes = get(form.values, `${prefix}.changes`, []);

        loop(get(form.values, `${prefix}.metrics`, []), (metric, i) => {

            form.handleChange({ reset: true, name: `${prefix}.changes[${i}].shoe`, value: metric.shoe })
            form.handleChange({ reset: true, name: `${prefix}.changes[${i}].condition`, value: metric.condition })

            if (i >= 1) {
                if (!get(run, `changes[${i}].footStrike`)) form.handleChange({ reset: true, name: `${prefix}.changes[${i}].footStrike`, value: 'No Change' })
                if (!get(run, `changes[${i}].footControl`)) form.handleChange({ reset: true, name: `${prefix}.changes[${i}].footControl`, value: 'No Change' })
                if (!get(run, `changes[${i}].kneeStability`)) form.handleChange({ reset: true, name: `${prefix}.changes[${i}].kneeStability`, value: 'No Change' })
                if (!get(run, `changes[${i}].hipStability`)) form.handleChange({ reset: true, name: `${prefix}.changes[${i}].hipStability`, value: 'No Change' })
                if (!get(run, `changes[${i}].torsoPosture`)) form.handleChange({ reset: true, name: `${prefix}.changes[${i}].torsoPosture`, value: 'No Change' })
                if (!get(run, `changes[${i}].armSwing`)) form.handleChange({ reset: true, name: `${prefix}.changes[${i}].armSwing`, value: 'No Change' })
                if (!get(run, `changes[${i}].footNoise`)) form.handleChange({ reset: true, name: `${prefix}.changes[${i}].footNoise`, value: 'No Change' })
                if (!get(run, `changes[${i}].cadence`)) form.handleChange({ reset: true, name: `${prefix}.changes[${i}].cadence`, value: 'No Change' })
                if (!get(run, `changes[${i}].verticalHip`)) form.handleChange({ reset: true, name: `${prefix}.changes[${i}].verticalHip`, value: 'No Change' })
                if (!get(run, `changes[${i}].gtcBalance`)) form.handleChange({ reset: true, name: `${prefix}.changes[${i}].gtcBalance`, value: 'No Change' })
            }

        })

        if (metrics.length < changes.length) {
            const updatedChanges = changes.filter((change, i) => i < metrics.length);
            form.handleChange({ reset: true, name: `${prefix}.changes`, value: updatedChanges });
        }

    }, [hashCode(get(form.values, `${prefix}.metrics`, []))]);

    return useMemo(() => (
        <>

            <Row edge gutter={8}>
                <Col col={{ xs: 24 }} align='center'>
                    <Heading>Ratings And Changes Across Trials</Heading>
                </Col>
            </Row>

            <InputTable
                className={""}
                columns={columns}
            >

                <InputTableRow >

                    {get(form.values, `${prefix}.metrics`, []).map((column, i) => (

                        <>
                            <InputTableCell style={{ width: 200 }}>

                                <FieldAutocomplete
                                    key={get(run, `changes[${i}].condition`)}
                                    form={form}
                                    label="Condition"
                                    name={`${prefix}.changes[${i}].condition`}
                                    defaultValue={get(run, `changes[${i}].condition`)}
                                    // options={[
                                    //     { value: "Initial Assessment", label: "Initial Assessment" },
                                    //     { value: "Different Shoes", label: "Different Shoes" },
                                    //     { value: "Outdoor Running", label: "Outdoor Running" },
                                    //     { value: "Faster Run Speed", label: "Faster Run Speed" },
                                    //     { value: "Slower Run Speed", label: "Slower Run Speed" },
                                    //     { value: "Barefoot Running", label: "Barefoot Running" },
                                    //     { value: "G8 Insoles", label: "G8 Insoles" },
                                    //     { value: "Push Insoles", label: "Push Insoles" },
                                    //     { value: "Corrective Insoles", label: "Corrective Insoles" },
                                    //     { value: "In-Shoe Wedges", label: "In-Shoe Wedges" },
                                    // ]}
                                    wrapClass={'manual-input'}
                                    group={"condition"}
                                    preload
                                    isMulti
                                />

                            </InputTableCell>

                        </>

                    ))}

                </InputTableRow>

                <InputTableRow >

                    {get(form.values, `${prefix}.metrics`, []).map((column, i) => (

                        <>
                            <InputTableCell style={{ width: 200 }}>

                                <FieldSelect
                                    form={form}
                                    label="Shoe"
                                    name={`${prefix}.changes[${i}].shoe`}
                                    defaultValue={get(run, `changes[${i}].shoe`)}
                                    options={form.values.shoes.map((shoe: any) => ({ value: shoe.shoeBrand, label: shoe.shoeBrand }))}
                                    wrapClass={'manual-input'}
                                />

                            </InputTableCell>

                        </>

                    ))}

                </InputTableRow>

                <InputTableRow >

                    {get(form.values, `${prefix}.metrics`, []).map((column, i) => (

                        <>
                            <InputTableCell style={{ width: 200 }}>
                                {i == 0 ?
                                    <>
                                        <strong>Foot Strike</strong>

                                        <Space height={8} />

                                        <FieldRange
                                            form={form}
                                            label={[<>Poor</>, <>Fair</>, <>Good</>]}
                                            labelElement={FieldRangeLabels}
                                            name={`${prefix}.changes[${i}].footStrike`}
                                            defaultValue={get(run, `changes[${i}].footStrike`)}
                                            key={get(run, `changes[${i}].footStrike`)}
                                            min={0}
                                            max={5}
                                            step={0.5}
                                        />
                                    </>
                                    :
                                    <FieldSelect
                                        form={form}
                                        name={`${prefix}.changes[${i}].footStrike`}
                                        defaultValue={get(run, `changes[${i}].footStrike`)}
                                        options={options}
                                        wrapClass={'manual-input'}
                                    />
                                }

                            </InputTableCell>

                        </>

                    ))}

                </InputTableRow>

                <InputTableRow >



                    {get(form.values, `${prefix}.metrics`, []).map((column, i) => (

                        <>
                            <InputTableCell style={{ width: 200 }}>
                                {i == 0 ?
                                    <>
                                        <strong>Foot Control/Stability</strong>

                                        <Space height={8} />

                                        <FieldRange
                                            form={form}
                                            label={[<>Poor</>, <>Fair</>, <>Good</>]}
                                            labelElement={FieldRangeLabels}
                                            name={`${prefix}.changes[${i}].footControl`}
                                            defaultValue={get(run, `changes[${i}].footControl`)}
                                            key={get(run, `changes[${i}].footControl`)}
                                            min={0}
                                            max={5}
                                            step={0.5}
                                        />
                                    </>
                                    :
                                    <FieldSelect
                                        form={form}
                                        name={`${prefix}.changes[${i}].footControl`}
                                        defaultValue={get(run, `changes[${i}].footControl`)}
                                        options={options}
                                        wrapClass={'manual-input'}
                                    />
                                }

                            </InputTableCell>

                        </>

                    ))}

                </InputTableRow>

                <InputTableRow >

                    {get(form.values, `${prefix}.metrics`, []).map((column, i) => (

                        <>
                            <InputTableCell style={{ width: 200 }}>
                                {i == 0 ?
                                    <>
                                        <strong>Knee Stability</strong>

                                        <Space height={8} />

                                        <FieldRange
                                            form={form}
                                            label={[<>Poor</>, <>Fair</>, <>Good</>]}
                                            labelElement={FieldRangeLabels}
                                            name={`${prefix}.changes[${i}].kneeStability`}
                                            defaultValue={get(run, `changes[${i}].kneeStability`)}
                                            key={get(run, `changes[${i}].kneeStability`)}
                                            min={0}
                                            max={5}
                                            step={0.5}
                                        />
                                    </>
                                    :
                                    <FieldSelect
                                        form={form}
                                        name={`${prefix}.changes[${i}].kneeStability`}
                                        defaultValue={get(run, `changes[${i}].kneeStability`)}
                                        options={options}
                                        wrapClass={'manual-input'}
                                    />
                                }

                            </InputTableCell>

                        </>

                    ))}

                </InputTableRow>

                <InputTableRow >

                    {get(form.values, `${prefix}.metrics`, []).map((column, i) => (

                        <>
                            <InputTableCell style={{ width: 200 }}>
                                {i == 0 ?
                                    <>
                                        <strong>Hip Stability</strong>

                                        <Space height={8} />

                                        <FieldRange
                                            form={form}
                                            label={[<>Poor</>, <>Fair</>, <>Good</>]}
                                            labelElement={FieldRangeLabels}
                                            name={`${prefix}.changes[${i}].hipStability`}
                                            defaultValue={get(run, `changes[${i}].hipStability`)}
                                            key={get(run, `changes[${i}].hipStability`)}
                                            min={0}
                                            max={5}
                                            step={0.5}
                                        />
                                    </>
                                    :
                                    <FieldSelect
                                        form={form}
                                        name={`${prefix}.changes[${i}].hipStability`}
                                        defaultValue={get(run, `changes[${i}].hipStability`)}
                                        options={options}
                                        wrapClass={'manual-input'}
                                    />
                                }

                            </InputTableCell>

                        </>

                    ))}

                </InputTableRow>

                <InputTableRow >

                    {get(form.values, `${prefix}.metrics`, []).map((column, i) => (

                        <>
                            <InputTableCell style={{ width: 200 }}>
                                {i == 0 ?
                                    <>
                                        <strong>Torso Posture</strong>

                                        <Space height={8} />

                                        <FieldRange
                                            form={form}
                                            label={[<>Poor</>, <>Fair</>, <>Good</>]}
                                            labelElement={FieldRangeLabels}
                                            name={`${prefix}.changes[${i}].torsoPosture`}
                                            defaultValue={get(run, `changes[${i}].torsoPosture`)}
                                            key={get(run, `changes[${i}].torsoPosture`)}
                                            min={0}
                                            max={5}
                                            step={0.5}
                                        />
                                    </>
                                    :
                                    <FieldSelect
                                        form={form}
                                        name={`${prefix}.changes[${i}].torsoPosture`}
                                        defaultValue={get(run, `changes[${i}].torsoPosture`)}
                                        options={options}
                                        wrapClass={'manual-input'}
                                    />
                                }

                            </InputTableCell>

                        </>

                    ))}

                </InputTableRow>

                <InputTableRow >

                    {get(form.values, `${prefix}.metrics`, []).map((column, i) => (

                        <>
                            <InputTableCell style={{ width: 200 }}>
                                {i == 0 ?
                                    <>
                                        <strong>Arm Swing</strong>

                                        <Space height={8} />

                                        <FieldRange
                                            form={form}
                                            label={[<>Poor</>, <>Fair</>, <>Good</>]}
                                            labelElement={FieldRangeLabels}
                                            name={`${prefix}.changes[${i}].armSwing`}
                                            defaultValue={get(run, `changes[${i}].armSwing`)}
                                            key={get(run, `changes[${i}].armSwing`)}
                                            min={0}
                                            max={5}
                                            step={0.5}
                                        />
                                    </>
                                    :
                                    <FieldSelect
                                        form={form}
                                        name={`${prefix}.changes[${i}].armSwing`}
                                        defaultValue={get(run, `changes[${i}].armSwing`)}
                                        options={options}
                                        wrapClass={'manual-input'}
                                    />
                                }

                            </InputTableCell>

                        </>

                    ))}

                </InputTableRow>

                <InputTableRow >

                    {get(form.values, `${prefix}.metrics`, []).map((column, i) => (

                        <>
                            <InputTableCell style={{ width: 200 }}>
                                {i == 0 ?
                                    <>
                                        <strong>Foot Noise</strong>

                                        <Space height={8} />

                                        <FieldRange
                                            form={form}
                                            label={[<>Poor</>, <>Fair</>, <>Good</>]}
                                            labelElement={FieldRangeLabels}
                                            name={`${prefix}.changes[${i}].footNoise`}
                                            defaultValue={get(run, `changes[${i}].footNoise`)}
                                            key={get(run, `changes[${i}].footNoise`)}
                                            min={0}
                                            max={5}
                                            step={0.5}
                                        />
                                    </>
                                    :
                                    <FieldSelect
                                        form={form}
                                        name={`${prefix}.changes[${i}].footNoise`}
                                        defaultValue={get(run, `changes[${i}].footNoise`)}
                                        options={options}
                                        wrapClass={'manual-input'}
                                    />
                                }

                            </InputTableCell>

                        </>

                    ))}

                </InputTableRow>

                <InputTableRow >

                    {get(form.values, `${prefix}.metrics`, []).map((column, i) => (

                        <>
                            <InputTableCell style={{ width: 200 }}>
                                {i == 0 ?
                                    <>
                                        <strong>Cadence</strong>

                                        <Space height={8} />

                                        <FieldRange
                                            form={form}
                                            label={[<>Poor</>, <>Fair</>, <>Good</>]}
                                            labelElement={FieldRangeLabels}
                                            name={`${prefix}.changes[${i}].cadence`}
                                            defaultValue={get(run, `changes[${i}].cadence`)}
                                            key={get(run, `changes[${i}].cadence`)}
                                            min={0}
                                            max={5}
                                            step={0.5}
                                        />
                                    </>
                                    :
                                    <FieldSelect
                                        form={form}
                                        name={`${prefix}.changes[${i}].cadence`}
                                        defaultValue={get(run, `changes[${i}].cadence`)}
                                        options={options}
                                        wrapClass={'manual-input'}
                                    />
                                }

                            </InputTableCell>

                        </>

                    ))}

                </InputTableRow>

                <InputTableRow >

                    {get(form.values, `${prefix}.metrics`, []).map((column, i) => (

                        <>
                            <InputTableCell style={{ width: 200 }}>
                                {i == 0 ?
                                    <>
                                        <strong>Vertical Hip Motion</strong>

                                        <Space height={8} />

                                        <FieldRange
                                            form={form}
                                            label={[<>Poor</>, <>Fair</>, <>Good</>]}
                                            labelElement={FieldRangeLabels}
                                            name={`${prefix}.changes[${i}].verticalHip`}
                                            defaultValue={get(run, `changes[${i}].verticalHip`)}
                                            key={get(run, `changes[${i}].verticalHip`)}
                                            min={0}
                                            max={5}
                                            step={0.5}
                                        />
                                    </>
                                    :
                                    <FieldSelect
                                        form={form}
                                        name={`${prefix}.changes[${i}].verticalHip`}
                                        defaultValue={get(run, `changes[${i}].verticalHip`)}
                                        options={options}
                                        wrapClass={'manual-input'}
                                    />
                                }

                            </InputTableCell>

                        </>

                    ))}

                </InputTableRow>

                <InputTableRow >

                    {get(form.values, `${prefix}.metrics`, []).map((column, i) => (

                        <>
                            <InputTableCell style={{ width: 200 }}>
                                {i == 0 ?
                                    <>
                                        <strong>Ground Contact Time Balance (Left vs Right)</strong>

                                        <Space height={8} />

                                        <FieldRange
                                            form={form}
                                            label={[<>Poor</>, <>Fair</>, <>Good</>]}
                                            labelElement={FieldRangeLabels}
                                            name={`${prefix}.changes[${i}].gtcBalance`}
                                            defaultValue={get(run, `changes[${i}].gtcBalance`)}
                                            key={get(run, `changes[${i}].gtcBalance`)}
                                            min={0}
                                            max={5}
                                            step={0.5}
                                        />
                                    </>
                                    :
                                    <FieldSelect
                                        form={form}
                                        name={`${prefix}.changes[${i}].gtcBalance`}
                                        defaultValue={get(run, `changes[${i}].gtcBalance`)}
                                        options={options}
                                        wrapClass={'manual-input'}
                                        menuPlacement={"top"}
                                    />
                                }

                            </InputTableCell>

                        </>

                    ))}

                </InputTableRow>

            </InputTable>

        </>

    ), [form.hash, run, hashCode(get(run, "changes"))]);

}

export default GaitsEditTabsRunChanges;
