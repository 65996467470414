
export const fancyTimeFormat = (duration) => {

    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;

}
fancyTimeFormat.isFancyTime = true;

export const fancyTimeFormatNoZero = (duration) => {

    const ret = fancyTimeFormat(duration);

    if (ret == "0:00" || ret == "00:00" || ret == "00:00:00") return "";

    return ret;

}


export const fancyHourFormat = (duration) => {

    // Hours, minutes
    var hours = ~~((duration) / 60);
    var mins = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    ret += "" + hours + ":" + (mins < 10 ? "0" : "");
    ret += "" + mins;
    return ret;

}
fancyHourFormat.isFancyHour = true;

export const fancyHourFormatNoZero = (duration) => {

    const ret = fancyHourFormat(duration);

    if (ret == "0:00" || ret == "00:00" || ret == "00:00:00") return "";

    return ret;

}

export const nearest = (value, nearestTo, direction) => {

    if (!value && direction === "max") value = nearestTo;
    else if (!value && direction === "min") value = 0;

    let rounded = value / nearestTo;

    if (direction == 'max') {
        rounded = Math.ceil(rounded);
    } else {
        rounded = Math.floor(rounded);
    }

    if (nearestTo == 60) return nearest(rounded, 10, direction) * nearestTo;

    return rounded * nearestTo;

}

export const nearestMaxOne = (value) => {

    return nearest(value, 1, 'max');

}

export const nearestMaxTen = (value) => {

    return nearest(value, 10, 'max');

}

export const nearestMaxHour = (value) => {

    return nearest(value, 60, 'max');

}

export const nearestMaxHundered = (value) => {

    return nearest(value, 100, 'max');

}

export const nearestMaxThousand = (value) => {

    return nearest(value, 1000, 'max');

}

export const nearestMaxTenThousand = (value) => {

    return nearest(value, 10000, 'max');

}

export const nearestMaxAuto = (value) => {

    if (!value) return nearestMaxTen(1);

    if (value < 100) return nearestMaxTen(value);

    if (value < 1000) return nearestMaxHundered(value);

    if (value < 10000) return nearestMaxThousand(value);

    return nearestMaxTenThousand(value);

}


export const nearestMinOne = (value) => {

    return nearest(value, 1, 'min');

}

export const nearestMinTen = (value) => {

    return nearest(value, 10, 'min');

}

export const nearestMinHour = (value) => {

    return nearest(value, 60, 'min');

}

export const nearestMinHundered = (value) => {

    return nearest(value, 100, 'min');

}

export const nearestMinThousand = (value) => {

    return nearest(value, 1000, 'min');

}

export const nearestMinTenThousand = (value) => {

    return nearest(value, 10000, 'min');

}

export const nearestMinAuto = (value) => {

    if (!value) return nearestMinTen(0);

    if (value < 100) return nearestMinTen(value);

    if (value < 1000) return nearestMinHundered(value);

    if (value < 10000) return nearestMinThousand(value);

    return nearestMinTenThousand(value);

}