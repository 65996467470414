import { fixedNum } from "@dex/bubl-helpers";
import { calculateArcSin, calculateArcTan, gravititionalForce } from "./sharedAerodynamicBike";

export const meterSquareCalculation = (distance, pixel) => {

    if (distance == null || pixel == null) return;

    const output = (distance / pixel) ** 2;

    return fixedNum(output, 9);
};

export const FSACalculation = (imagePixel, meterSquare) => {

    if (imagePixel == null || meterSquare == null) return;

    const output = imagePixel * meterSquare;

    return fixedNum(output, 3);
};

export const kelvinCalculation = (temperature) => {

    let output = 0

    if (temperature) {
        output = temperature + 273.15;
    }

    return output;

};

export const pressureAtAtltitudeCalculation = (altitude, temperature) => {

    const pressureAtSeaLevel = 1013.25;
    const kelvin = kelvinCalculation(temperature);

    const molarMassOfAir = 0.0289644;
    const gas = 8.31432;

    const exponantialPart = -gravititionalForce * molarMassOfAir * altitude / (gas * kelvin);
    const pressureAtAtltitude = pressureAtSeaLevel * Math.exp(exponantialPart);

    return { pressureAtAtltitude, pressureAtSeaLevel };

}
export const airPressureCalculation = (altitude, temperature, unit = "Pa") => {

    if (altitude == null || temperature == null || unit == null) return;
    let output = 0;

    const { pressureAtAtltitude, pressureAtSeaLevel } = pressureAtAtltitudeCalculation(altitude, temperature);

    switch (unit) {
        case "atm":
            output = fixedNum(pressureAtAtltitude * 1 / pressureAtSeaLevel, 2);
            break;

        case "mmHg":
            output = fixedNum(pressureAtAtltitude * 760 / pressureAtSeaLevel, 0);
            break;

        case "inHg":
            output = fixedNum(pressureAtAtltitude * 30.0058 / pressureAtSeaLevel, 2);
            break;

        case "kPa":
            output = fixedNum(pressureAtAtltitude * 101.325 / pressureAtSeaLevel, 3);
            break;

        case "Pa":
            output = fixedNum(pressureAtAtltitude * 101325 / pressureAtSeaLevel, 0);
            break;

        case "psi":
            output = fixedNum(pressureAtAtltitude * 14.6960 / pressureAtSeaLevel, 1);
            break;

        default:

            break;
    }

    return output + " " + unit;
};

export const airDensityCalculation = (humidity, temperature, altitude) => {

    if (humidity == null || temperature == null) return 0;

    let output: number = 0;

    humidity = humidity / 100;
    const { pressureAtAtltitude } = pressureAtAtltitudeCalculation(altitude, temperature);

    const raisedToPower = (7.5 * temperature / (temperature + 237.3));
    const airPressureAtTemperature = 6.1078 * Math.pow(10, raisedToPower);
    const airPressureMoisture = airPressureAtTemperature * humidity;
    const convertMoistureToPa = airPressureMoisture * 100;

    const airPressureDry = pressureAtAtltitude - airPressureMoisture;
    const convertDryToPa = airPressureDry * 100;

    const kelvin = kelvinCalculation(temperature);

    output = (convertDryToPa / (287.058 * kelvin)) + (convertMoistureToPa / (kelvin * 461.495));

    return output;

};

export const gravityCalculation = (percentGrade, riderWeight, BikeWeight) => {

    let output;
    const GravitationalForce = 9.8067;
    const BikeAndRiderWeight = parseFloat(BikeWeight) + parseFloat(riderWeight);

    const ArcTan = calculateArcTan(percentGrade);
    const ArcSin = calculateArcSin(ArcTan);

    output = GravitationalForce * ArcSin * BikeAndRiderWeight;

    return fixedNum(output, 2);
};