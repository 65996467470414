import { FieldSelect, Form } from '@dex/bubl-dash';
import React, { useEffect, useMemo } from 'react';
import AthletesApi from '../AthletesApi';


const AthletesSessionsSelectForm: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const sessions = AthletesApi.getSessions(data.id);

    useEffect(() => {

        sessions.run({
            onComplete: (response: any) => {

                if (response && response[0]) form.reset({ sessionId: response[0].value });

            }
        });

    }, []);

    return useMemo(() => (

        <Form
            form={form}
            onSubmit={() => { }}
            loading={sessions.loading}
            autoDiscardChanges={true}
        >

            {sessions.data &&
                <div style={{ width: 300, maxWidth: "100%", margin: "-8px 0 -16px" }}>

                    <FieldSelect
                        form={form}
                        name="sessionId"
                        placeholder="Select Session"
                        options={sessions.data}
                        defaultValue={form.defaults.sessionId}
                    />

                </div>
            }

        </Form>


    ), [data, sessions, form.values]);

}

export default AthletesSessionsSelectForm;
