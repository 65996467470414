import { Box, Col, Row, Space } from '@dex/bubl-dash';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import React, { useMemo } from 'react';
import DashboardQuestionaires from './DashboardQuestionaires';
import DashboardSessions from './DashboardSessions';
import DashboardCalculator from './DashboardCalculator';

const DashboardView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <>

            <Box gutter={'auto'}>

                {/* <DashboardCalculator /> */}

                <DashboardSessions />

                <Space />

                <DashboardQuestionaires />

            </Box>
        </>


    ), []);

}

export default DashboardView;
