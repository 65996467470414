import { apiGet, baseApi, baseApiReturn, options, response } from "@dex/bubl-fetch";

const endpoint = 'Teams';

const TeamsApi: baseApiReturn = baseApi(endpoint);

TeamsApi.getTeam = (options?: options): response => {

    return apiGet({
        url: endpoint + "/getTeam",
        params: {},
        ...options
    });
}

export default TeamsApi;