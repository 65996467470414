import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import AthletesSingleQuestionnaires from './AthletesSingleQuestionnaires';
import AthletesApi from '../AthletesApi';
import { useForm } from '@dex/bubl-dash';
import AthletesSingleViewGeneral from './AthletesSingleViewGeneral';
import AthletesSingleSessions from './AthletesSingleSessions';
import AthletesSingleTestSessions from './AthletesSingleTestSessions';
import GaitsSingle from '../../Gaits/GaitsSingle';
import GaitsSingleSessions from '../../Gaits/Components/GaitsSingleSessions';

const AthletesSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const form = useForm({ sessionId: "" });

    const pathDefaults = useMemo(() => {

        const defaults = { athleteId: data.id };

        return encodeURIComponent(JSON.stringify(defaults));

    }, [data]);

    const session = AthletesApi.getSession(data.id);

    useEffect(() => {

        if (!form.values.sessionId) return;

        session.run({
            params: { sessionId: form.values.sessionId }
        });

    }, [form.values.sessionId]);

    const [tab, setTab] = useState("general");

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Athlete Details"}
                >

                    <AthletesSingleViewGeneral
                        data={data}
                    />

                </Panel>

            </Col>

            <Col
                className={'side'}
            >
                <Panel
                    heading={"Actions"}
                >

                    <Button
                        label={"Prepare Questionnaire"}
                        size="small"
                        path={`/questionnaires/create/?defaults=${pathDefaults}`}
                        block={true}
                    />

                    <Space height={"small"} />

                    <Button
                        label={"New Fit / Size Session"}
                        size="small"
                        path={`/sessions/create/?defaults=${pathDefaults}`}
                        block={true}
                    />

                    <Space height={"small"} />

                    <Button
                        label={"New Lab Test Session"}
                        size="small"
                        path={`/lab/test-sessions/create/?defaults=${pathDefaults}`}
                        block={true}
                    />

                    <Space height={"small"} />

                    <Button
                        label={"New Training Week"}
                        size="small"
                        path={`/coaching/create/?defaults=${pathDefaults}`}
                        block={true}
                    />

                    <Space height={"small"} />

                    <Button
                        label={"New Gait Analysis"}
                        size="small"
                        path={`/gait/create/?defaults=${pathDefaults}`}
                        block={true}
                    />

                </Panel>

            </Col>

            <Col col={{ xs: 24 }}>

                <AthletesSingleSessions data={data} />

                <Space />

                <AthletesSingleTestSessions data={data} />

                <Space />

                <AthletesSingleQuestionnaires data={data} />

                <Space />

                <GaitsSingleSessions data={data} />

            </Col>

        </Row>

    ), [data, tab, session]);

}

export default AthletesSingleView;
