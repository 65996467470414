import { Col, FieldCheckbox, FieldDate, FieldRadio, FieldSelect, Heading, Row, Space, WhiteSpace, useForm, useMountEffect } from '@dex/bubl-dash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import ChartCoachingHeartrate from '../../../elements/ChartCoaching/ChartCoachingHeartrate';
import ChartCoachingVolumeIntensity from '../../../elements/ChartCoaching/ChartCoachingVolumeIntensity';
import ChartCoachingWorkout from '../../../elements/ChartCoaching/ChartCoachingWorkout';
import ChartCoachingSelfRating from '../../../elements/ChartCoaching/ChartCoachingSelfRating';
import ChartCoachingFitness from '../../../elements/ChartCoaching/ChartCoachingFitness';
import ChartCoachingHydrationFueling from '../../../elements/ChartCoaching/ChartCoachingHydrationFueling';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import CoachingReportHeading from '../../../elements/CoachingReportHeading/CoachingReportHeading';
import { GiCheckMark } from "react-icons/gi";
import { HiArrowDown, HiArrowUp } from "react-icons/hi";
import style from './CoachingReportTracking.module.scss';
import CoachingApi from '../CoachingApi';
import { fixedNum, unslug } from '@dex/bubl-helpers';
import moment from 'moment';
import ChartProps from '../../../elements/Chart/Chart.Props';
import CoachingReportWeeklyAverage from '../../../elements/CoachingReportWeeklyAverage/CoachingReportWeeklyAverage';

const CoachingReportTracking: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const activity = data.activity;
    const tracking = data?.reportTracking || [];

    const filter = useForm({ period: "last-3-months" });

    const [trackingData, setTrackingData]: any = useState([]);
    const fetch = CoachingApi.getReportData(data.id);

    useEffect(() => {

        fetch.run({
            params: { filters: filter.values, type: "training-over-time" },
            onComplete: (response: any) => {

                setTrackingData(response);

            }

        })

    }, [filter.values.to, filter.values.from]);

    const trackingAvg = trackingData?.averages;

    const volumeMiniTable = {
        average: [
            { label: "Duration:", value: trackingAvg?.volumeTotalTime, unit: "hh:mm" },
            { label: "Mileage:", value: fixedNum(trackingAvg?.volumeTotalDistance, 0), unit: "km" },
            { label: "Intensity:", value: trackingAvg?.intensityTotalHrTime, unit: "hh:mm" },
            { label: "T. Stimulus:", value: trackingAvg?.totalStimulusAverage, unit: "%" },
            { label: "TSS Score:", value: fixedNum(trackingAvg?.tssScore, 0) },

        ],

    };

    const workoutMiniTable = [
        { value: trackingAvg?.workoutsSwim, type: "Swims" },
        { value: trackingAvg?.workoutsBrick, type: "Bricks" },
        { value: trackingAvg?.workoutsBike, type: "Rides" },
        { value: trackingAvg?.workoutsEvent, type: "Event/Test" },
        { value: trackingAvg?.workoutsRun, type: "Runs" },
        { value: trackingAvg?.workoutsOther, type: "Other" },
        { value: trackingAvg?.workoutsGym, type: "Gym" },
    ];

    const heartrateMiniTable = [
        {
            label: "All",
            hour: trackingAvg?.intensityTotalHrTime,
            percent: trackingAvg?.hrTotalTimePercent,
            hr: trackingAvg?.hrPeakAll,
            className: "all"
        },
        {
            label: "Bike",
            hour: trackingAvg?.hrBikeTime,
            percent: trackingAvg?.hrBikeTimePercent,
            hr: trackingAvg?.hrPeakBikeTime,
            className: "bike"
        },
        {
            label: "Run",
            hour: trackingAvg?.hrRunTime,
            percent: trackingAvg?.hrRunTimePercent,
            hr: trackingAvg?.hrPeakRunTime,
            className: "run"
        },
    ];
    const fitnessMiniTable = [
        { label: "Fitness", value: data.week?.other?.fitness, target: "100 - 150 / Increasing", className: "blue" },
        { label: "Fatigue", value: data.week?.other?.fatigue, target: "<100 / Decreasing", className: "pink" },
        { label: "Form", value: data.week?.other?.form, target: ">10 / Increasing", className: 'yellow' },
        { label: "Body Stress", value: trackingData.weeks ? trackingData?.weeks[0]?.bodyStress + " %" : "", target: "<100% / Decreasing", className: "red" },
        { label: "Stimulus", value: trackingData.weeks ? trackingData?.weeks[0]?.totalStimulusAverage + " %" : "", target: "<100% / Decreasing", className: "green" },
    ];

    const hydrationMiniTable = [
        {
            label: "All",
            fluid: trackingData?.enduranceAverage?.all?.fluidLossRate,
            rehydrationPercent: trackingData?.enduranceAverage?.all?.rehydPercent,
            rehydration: trackingData?.enduranceAverage?.all?.fluidIntakeRate,
            carb: trackingData?.enduranceAverage?.all?.carbIntakeRate,
            className: 'yellow'
        },
        {
            label: "Bike",
            fluid: trackingData?.enduranceAverage?.bike?.fluidLossRate,
            rehydrationPercent: trackingData?.enduranceAverage?.bike?.rehydPercent,
            rehydration: trackingData?.enduranceAverage?.bike?.fluidIntakeRate,
            carb: trackingData?.enduranceAverage?.bike?.carbIntakeRate,
            className: 'yellow'
        },
        {
            label: "Run",
            fluid: trackingData?.enduranceAverage?.run?.fluidLossRate,
            rehydrationPercent: trackingData?.enduranceAverage?.run?.rehydPercent,
            rehydration: trackingData?.enduranceAverage?.run?.fluidIntakeRate,
            carb: trackingData?.enduranceAverage?.run?.carbIntakeRate,
            className: 'yellow'
        },
    ];

    return useMemo(() => (

        <Row edge gutter={"auto"}>

            <PeriodFilter data={data} filter={filter} />

            {tracking.includes("volume") &&
                <>
                    <Col col={{ xs: 24 }}>

                        <CoachingReportHeading
                            text="Volume And Intensity by Week"
                        />

                        <PanelChart
                            heading={"Volume And Intensity By Week"}
                            renderForm={(form, heading) => (

                                <Row edge gutter={8}>

                                    <Col>

                                        <FieldRadio
                                            form={form}
                                            name="trackingVolume"
                                            defaultValue={form.defaults?.trackingVolume}
                                            label='Volume (Bars)'
                                            required
                                            options={[
                                                { label: "Hours", value: "Hours", color: ChartProps.indexColor(0) },
                                                { label: "KM", value: "KM", color: ChartProps.indexColor(1) },
                                            ]}
                                        />

                                    </Col>

                                    <Col col={{ xs: 14 }}>
                                        <FieldCheckbox
                                            form={form}
                                            name="trackingIntensity"
                                            defaultValue={form.defaults?.trackingIntensity}
                                            label='Intensity (Lines)'
                                            required
                                            options={[
                                                { label: "Hours Above Zone 2", value: "Hours Above Zone 2", color: ChartProps.indexColor(5) },
                                                { label: "TSS Score", value: "TSS Score", color: ChartProps.indexColor(2) },
                                                { label: "Training Stimulus", value: "Training Stimulus", color: ChartProps.indexColor(4) },
                                            ]}
                                        />
                                    </Col>

                                </Row>

                            )}
                            addZero={false}
                            data={trackingData?.weeks}
                            formDefaults={{ trackingVolume: "Hours" }}
                            renderChart={(chartType, chartData, values) => (

                                <ChartCoachingVolumeIntensity
                                    data={trackingData?.weeks}
                                    type={chartType}
                                    activity={activity}
                                    intensity={data.intensity}
                                    filters={values}
                                />
                            )}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <CoachingReportHeading
                            text="Your Weekly Training Volume and Intensity"
                        />

                        <ReportDesc
                            className="no-box"
                            value={`This chart allows you to view your training mileage and intensity over time for the date period selected. Choose a date period first, then select which measure (km, hours, TSS etc.) you wish to view. How well you met your training volume targets are are also given as a percentage of the total weeks within the training period. A Training Stimulus over 100% means your training load was above your usual average [greater stimulus], while under 100% means your training load was less than average.`}
                        />

                    </Col>

                    <Col col={{ xs: 12 }} className='table-bdr-left-gray'>

                        <CoachingReportWeeklyAverage
                            title="Weekly Averages"
                            columnA={[
                                { label: "Duration:", value: trackingAvg?.volumeTotalTime, unit: "hh:mm" },
                                { label: "Mileage:", value: fixedNum(trackingAvg?.volumeTotalDistance, 0), unit: "km" },
                                { label: "Intensity:", value: trackingAvg?.intensityTotalHrTime, unit: "hh:mm" },
                                { label: "T. Stimulus:", value: trackingAvg?.totalStimulusAverage, unit: "%" },
                                { label: "TSS Score:", value: fixedNum(trackingAvg?.tssScore, 0) },
                            ]}
                            percentageNote="*The % of all weeks over time period."
                            metTarget={trackingAvg?.volumeTargetMet}
                            aboveTarget={trackingAvg?.volumeTargetAbove}
                            belowTarget={trackingAvg?.volumeTargetBelow}
                        />

                    </Col>

                </>
            }

            {
                tracking.includes("workouts") &&
                <>
                    <Col col={{ xs: 24 }}>

                        <CoachingReportHeading
                            classes="seperator"
                            text="Workouts By Week"
                        />

                        <PanelChart
                            heading={"Workouts By Week"}
                            renderForm={(form, heading) => (

                                <Row edge gutter={8}>

                                    <Col>
                                        <FieldCheckbox
                                            form={form}
                                            name="trackingWorkouts"
                                            defaultValue={form.defaults?.trackingWorkouts}
                                            options={[
                                                { label: "Swim", value: "Swim", color: ChartProps.indexColor(1) },
                                                { label: "Bike", value: "Bike", color: ChartProps.indexColor(0) },
                                                { label: "Run", value: "Run", color: ChartProps.indexColor(4) },
                                                { label: "Brick", value: "Brick", color: ChartProps.indexColor(3) },
                                                { label: "Gym", value: "Gym", color: ChartProps.indexColor(2) },
                                                { label: "Other", value: "Other", color: ChartProps.indexColor(8) },
                                                { label: "Event/Test", value: "Event/Test", color: ChartProps.indexColor(5) },
                                            ]}
                                        />

                                    </Col>

                                </Row>

                            )}
                            addZero={false}
                            formDefaults={{ trackingWorkouts: ["Swim", "Bike", "Run", "Event/Test"] }}
                            renderChart={(chartType, chartData, values) => (

                                <ChartCoachingWorkout
                                    data={trackingData?.weeks}
                                    type={chartType}
                                    activity={activity}
                                    intensity={data.intensity}
                                    filters={values}
                                />
                            )}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <CoachingReportHeading
                            text="Your Workouts"
                        />

                        <ReportDesc
                            className="no-box"
                            value={`This chart allows you to view all your workouts performed each week over time. You can view all or just one at a time by choosing the appropriate checkbox.`}
                        />

                    </Col>

                    <Col col={{ xs: 12 }} className='table-bdr-left-gray'>

                        <CoachingReportHeading
                            text="Weekly Averages - "
                            desc={unslug(filter.values?.period)}
                        />

                        <Row edge gutter={8}>

                            <Col col={{ xs: 12 }} align='center'>
                                <Heading size={2} space={false} className={"text-center"}>
                                    <strong>
                                        ALL
                                    </strong>
                                </Heading>
                                <Heading size={2} space={false} className={"text-center"} >
                                    <strong>
                                        {trackingAvg?.workoutsAll}
                                    </strong>
                                </Heading>
                                <strong className={style.subText}># Workout Per Week</strong>
                            </Col>

                            <Col col={{ xs: 12 }} >

                                <Row edge gutter={8} >

                                    {workoutMiniTable?.map((item, index) => (

                                        <Col col={{ xs: 12 }} key={index}>

                                            <p><strong>{fixedNum(item?.value, 1)} - {item.type}</strong></p>

                                        </Col>

                                    ))}

                                </Row>

                            </Col>

                        </Row>

                    </Col>

                </>
            }

            {
                tracking.includes("heartrate") &&
                <>
                    <Col col={{ xs: 24 }}>

                        <CoachingReportHeading
                            classes="seperator"
                            text="Heartrate By Week"
                        />

                        <PanelChart
                            heading={"Heartrate By Week"}
                            renderForm={(form, heading) => (

                                <Row edge gutter={8} >

                                    <Col >
                                        <FieldRadio
                                            form={form}
                                            name="trackingHeartrateZone2"
                                            defaultValue={form.defaults?.trackingHeartrateZone2}
                                            label='Hours Above Zone 2 (Bars)'
                                            required
                                            options={[
                                                { label: "All", value: "All", color: ChartProps.indexColor(5) },
                                                { label: "Bike", value: "Bike", color: ChartProps.indexColor(0) },
                                                { label: "Run", value: "Run", color: ChartProps.indexColor(4) },
                                            ]}
                                        />
                                    </Col>

                                    <Col >
                                        <FieldCheckbox
                                            form={form}
                                            name="trackingHeartratePeakHR"
                                            defaultValue={form.defaults?.trackingHeartratePeakHR}
                                            label='Peak HR (Lines)'
                                            options={[
                                                { label: "Bike", value: "Bike", color: ChartProps.indexColor(0) },
                                                { label: "Run", value: "Run", color: ChartProps.indexColor(4) },
                                            ]}
                                        />
                                    </Col>

                                </Row>

                            )}
                            formDefaults={{ trackingHeartrateZone2: "All", trackingHeartratePeakHR: "All" }}
                            addZero={false}
                            renderChart={(chartType, chartData, values) => (

                                <ChartCoachingHeartrate
                                    data={trackingData?.weeks}
                                    type={chartType}
                                    activity={activity}
                                    intensity={data.intensity}
                                    filters={values}
                                />
                            )}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <CoachingReportHeading
                            text="Your Heartrate"
                        />

                        <ReportDesc
                            className="no-box"
                            value={`This chart shows your Peak HR as well as how many hours you spent above your Zone 2 HR over time. The information allows you to visualize the varying intensity or physiological stress your body goes through during a training period.`}
                        />

                    </Col>

                    <Col col={{ xs: 12 }} className='table-bdr-left-gray'>

                        <CoachingReportHeading
                            text="Weekly Averages - "
                            desc={unslug(filter.values?.period)}
                        />

                        <Row edge gutter={8}>

                            <Col col={{ xs: 4 }} >

                            </Col>

                            <Col col={{ xs: 12 }} >

                                <p>
                                    <strong >Hours Above Zone 2</strong>
                                </p>
                                <p>
                                    <strong className={style.subText}>hh:mm (%TotaI hrs)</strong>
                                </p>

                            </Col>

                            <Col col={{ xs: 8 }}>
                                <p>
                                    <strong>Peak HR</strong>
                                </p>
                                <p>
                                    <strong className={style.subText}>(bpm)</strong>
                                </p>

                            </Col>

                            {heartrateMiniTable?.map((item, index) => (

                                <React.Fragment key={index}>

                                    <Col col={{ xs: 4 }} >

                                        <p>
                                            <strong className={style[item.className]}>
                                                {item.label}
                                            </strong>
                                        </p>

                                    </Col>

                                    <Col col={{ xs: 12 }} >

                                        <p>
                                            <strong className={style[item.className]}>
                                                {item.hour}
                                                <span>
                                                    <WhiteSpace /> hh:mm  <WhiteSpace />
                                                </span>
                                                / {item.percent}%
                                            </strong>
                                        </p>

                                    </Col>

                                    <Col col={{ xs: 8 }}>

                                        <p>
                                            <strong className={style[item.className]}>
                                                {item.hr}
                                                <span> bpm</span>
                                            </strong>
                                        </p>

                                    </Col>

                                </React.Fragment>

                            ))}

                        </Row>

                    </Col>

                </>
            }

            {tracking.includes("self-ratings") &&
                <>
                    <Col col={{ xs: 24 }}>

                        <CoachingReportHeading
                            classes="seperator"
                            text="Self-Ratings By Week"
                        />

                        <PanelChart
                            heading={"Self-Ratings By Week"}
                            renderForm={(form, heading) => (

                                <Row edge gutter={8}>

                                    <Col >
                                        <FieldCheckbox
                                            form={form}
                                            name="trackingDistribution"
                                            defaultValue={form.defaults?.trackingDistribution}
                                            label='% Felt Distribution (Bars)'
                                            required
                                            options={[
                                                { label: "% Felt Strong", value: "% Felt Strong", color: ChartProps.trackingColor("% Felt Strong") },
                                                { label: "% Felt Weak", value: "% Felt Weak", color: ChartProps.trackingColor("% Felt Weak") },
                                                { label: "% No Rating", value: "% No Rating", color: ChartProps.trackingColor("% No Rating") },
                                            ]}
                                        />
                                    </Col>

                                    <Col >
                                        <FieldCheckbox
                                            form={form}
                                            name="trackingWorkoutRating"
                                            defaultValue={form.defaults?.trackingWorkoutRating}
                                            label='Workout Ratings (Lines)'
                                            options={[
                                                { label: "Avg RPE", value: "Avg RPE", color: ChartProps.trackingColor("Avg RPE") },
                                                { label: "Avg Felt", value: "Avg Felt", color: ChartProps.trackingColor("Avg Felt") },
                                            ]}
                                        />
                                    </Col>

                                </Row>

                            )}
                            addZero={false}
                            formDefaults={{ trackingDistribution: ["% Felt Strong", "% Felt Weak", "% No Rating"], trackingWorkoutRating: ["Avg RPE", "Avg Felt"] }}
                            renderChart={(chartType, chartData, values) => (

                                <ChartCoachingSelfRating
                                    data={trackingData?.weeks}
                                    type={chartType}
                                    activity={activity}
                                    intensity={data.intensity}
                                    filters={values}
                                />
                            )}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <CoachingReportHeading
                            text="Your Self-Ratings"
                        />

                        <ReportDesc
                            value={`This chart tracks your average ratings across workouts on effort and how you felt while doing them. The percentage of workouts when you Felt strong vs weak are also given. Ideally, you would like to have felt strong for most- Many consecutive weeks of feeling weak or finding workouts hard may be a sign of fatigue, high stress or overtraining.`}
                            className="no-box"
                        />

                    </Col>

                    <Col col={{ xs: 12 }} className='table-bdr-left-gray'>

                        <CoachingReportHeading
                            text="Weekly Averages - "
                            desc={unslug(filter.values?.period)}
                        />

                        <Row edge gutter={8} >

                            <Col col={{ xs: 16 }} align='start'>

                                <p className={style.blue}><strong>AVG Felt Rating - {trackingAvg?.avgFelt}</strong></p>
                                <strong>(Felt Scale: 1 Felt Strong to 5 Felt Weak)</strong>

                                <Space height={8} />

                                <p className={style.yellow}><strong>AVG RPE Rating - {trackingAvg?.avgRpe}</strong></p>
                                <strong>(RPE Scale: 1-Very Easy to 10-Maximal)</strong>

                            </Col>

                            <Col col={{ xs: 8 }} align='center'>
                                <p className={style.blue}><strong>% Felt Strong - {trackingAvg?.feltStrongPercent} %</strong></p>

                                <p className={style.green}><strong>% Felt Weak - {trackingAvg?.feltWeakPercent} %</strong></p>

                                <p className={style.noRating}><strong>% No Rating - {trackingAvg?.feltMissingPercent} %</strong></p>
                            </Col>

                        </Row>

                    </Col>

                </>
            }

            {
                tracking.includes("fitness") &&
                <>
                    <Col col={{ xs: 24 }}>

                        <CoachingReportHeading
                            classes="seperator"
                            text="Fitness By Week"
                        />

                        <PanelChart
                            heading={"Fitness By Week"}
                            renderForm={(form, heading) => (

                                <Row edge gutter={8}>

                                    <Col >
                                        <FieldRadio
                                            form={form}
                                            name="trackingFitness"
                                            defaultValue={form.defaults?.trackingFitness}
                                            required
                                            options={[
                                                { label: "Training Stimulus", value: "Training Stimulus", color: ChartProps.indexColor(0) },
                                            ]}
                                        />
                                    </Col>

                                    <Col >
                                        <FieldCheckbox
                                            form={form}
                                            name="trackingFitnessReadiness"
                                            defaultValue={form.defaults?.trackingFitnessReadiness}
                                            options={[
                                                { label: "Fitness", value: "Fitness", color: ChartProps.trackingColor("Fitness") },
                                                { label: "Fatigue", value: "Fatigue", color: ChartProps.trackingColor("Fatigue") },
                                                { label: "Form", value: "Form", color: ChartProps.trackingColor("Form") },
                                                { label: "Body Stress", value: "Body Stress", color: ChartProps.trackingColor("Body Stress") },
                                            ]}
                                        />
                                    </Col>

                                </Row>

                            )}
                            addZero={false}
                            formDefaults={{ trackingFitness: "Training Stimulus" }}
                            renderChart={(chartType, chartData, values) => (

                                <ChartCoachingFitness
                                    data={trackingData?.weeks}
                                    type={chartType}
                                    activity={activity}
                                    intensity={data.intensity}
                                    filters={values}
                                />
                            )}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <CoachingReportHeading
                            text="Your Fitness Progression"
                        />

                        <ReportDesc
                            className="no-box"
                            value={`This chart tracks your levels of Fitness, Fatigue, Form (fitness + freshness), Body Stress and Training Stimuli (volume + intensity) over time Ideally as you approach a main event, you want your Fitness and Form to be as high as possible, your fatigue and body stress to be lower than average or decreasing and your training stimulus to be maintained or dropping so you can perform your best on event day. As reference, ideal values for these measures just prior to a race are given below although they can vary considerably depending on your training exposure at the time.`}
                        />

                    </Col>

                    <Col col={{ xs: 12 }} className='table-bdr-left-gray'>

                        <CoachingReportHeading
                            text="This Week's Score"
                            classes="around"
                            altText="Race Day Target/Trend"
                        />

                        <Row edge gutter={8}>

                            {fitnessMiniTable.map((metric, index) => (

                                <React.Fragment key={index}>

                                    <Col col={{ xs: 6 }} align='end'>

                                        <p className={style[metric.className]}><strong>{metric.label}:</strong></p>

                                    </Col>

                                    <Col col={{ xs: 6 }}>

                                        <p className={style[metric.className]}><strong>{metric.value}</strong></p>

                                    </Col>

                                    <Col col={{ xs: 12 }}>

                                        <p className={style[metric.className]}><strong>{metric.target}</strong></p>

                                    </Col>

                                </React.Fragment>

                            ))}

                        </Row>

                    </Col>

                </>
            }

            {
                tracking.includes("hydration") &&
                <>
                    <Col col={{ xs: 24 }}>

                        <CoachingReportHeading
                            classes="seperator"
                            text="Hydration And Fueling"
                        />

                        <PanelChart
                            heading={"Hydration And Fueling"}
                            renderForm={(form, heading) => (

                                <Row edge gutter={8}>

                                    <Col >
                                        <FieldCheckbox
                                            form={form}
                                            name="trackingHydration"
                                            defaultValue={form.defaults?.trackingHydration}
                                            required
                                            options={[
                                                { label: "Fluid Loss", value: "Fluid Loss", color: ChartProps.trackingColor("Fluid Loss") },
                                                { label: "Fluid Intake", value: "Fluid Intake", color: ChartProps.trackingColor("Fluid Intake") },
                                                { label: "Carb Intake", value: "Carb Intake", color: ChartProps.trackingColor("Carb Intake") },
                                            ]}
                                        />
                                    </Col>

                                    <Col >
                                        <FieldRadio
                                            form={form}
                                            name="trackingHydrationType"
                                            defaultValue={form.defaults?.trackingHydrationType}
                                            options={[
                                                { label: "All", value: "All" },
                                                { label: "Bike Only", value: "bike" },
                                                { label: "Run Only", value: "run" },
                                            ]}
                                        />
                                    </Col>

                                    <Col >
                                        <FieldRadio
                                            form={form}
                                            name="trackingHydrationVariation"
                                            defaultValue={form.defaults?.trackingHydrationVariation}
                                            options={[
                                                { label: "Total", value: "Total" },
                                                { label: "Rate", value: "Rate" },
                                            ]}
                                        />
                                    </Col>

                                </Row>

                            )}
                            addZero={false}
                            formDefaults={{ trackingHydration: ["Fluid Loss"], trackingHydrationType: "All", trackingHydrationVariation: "Total" }}
                            renderChart={(chartType, chartData, values) => (
                                <ChartCoachingHydrationFueling
                                    data={trackingData?.endurance}
                                    type={chartType}
                                    activity={activity}
                                    intensity={data.intensity}
                                    filters={values}
                                />
                            )}
                        />

                    </Col>

                    <Col col={{ xs: 12 }}>

                        <CoachingReportHeading
                            text="Your Hydration And Fueling"
                        />

                        <ReportDesc
                            className="no-box"
                            value={`This chart shows your fluid loss rate, rehydration percentage (how much of what you lost did you consume back) and carb refueling experienced during those endurance workouts when it was measured. Select the measure or measures you wish to view for the date period given. Averages across the date period are also given below.`}
                        />

                    </Col>

                    <Col col={{ xs: 12 }} className='table-bdr-left-gray'>

                        <CoachingReportHeading
                            text="Overall Average - "
                            desc={unslug(filter.values?.period)}
                        />

                        <Row edge gutter={8}>

                            <Col col={{ xs: 6 }} >

                            </Col>

                            <Col col={{ xs: 6 }}>

                                <strong className={style.yellow}>Fluid Loss</strong>

                            </Col>

                            <Col col={{ xs: 6 }}>

                                <strong className={style.green}>Rehydration</strong>

                            </Col>

                            <Col col={{ xs: 6 }}>

                                <strong className={style.pink}>Carb Refueling</strong>

                            </Col>

                            {hydrationMiniTable.map((metric, index) => (

                                <React.Fragment key={index}>

                                    <Col col={{ xs: 6 }} >

                                        <strong>
                                            {metric.label}
                                        </strong>

                                    </Col>

                                    <Col col={{ xs: 6 }}>

                                        <strong className={style.yellow}>
                                            {metric.fluid} <span> ml/hr</span>
                                        </strong>

                                    </Col>

                                    <Col col={{ xs: 6 }}>

                                        <strong className={style.green}>
                                            {metric.rehydration} <span> ml/hr</span> /{metric.rehydrationPercent} %
                                        </strong>

                                    </Col>

                                    <Col col={{ xs: 6 }}>

                                        <strong className={style.pink}>
                                            {metric.carb} <span>g/hr</span>
                                        </strong>

                                    </Col>

                                </React.Fragment>

                            ))}

                        </Row>

                    </Col>

                </>
            }

        </Row >

    ), [data, filter.hash, fetch, trackingData]);

}

export default CoachingReportTracking;

export const PeriodFilter: React.FC<any> = (props: any) => {

    const { data, filter } = props;

    const [key, setKey] = useState(0);

    useMountEffect(() => {

        if (!filter.values.from && !filter.values.to) handlePeriodChange({ name: 'period', value: filter.values.period });

    });

    const handlePeriodChange = useCallback(({ name, value }) => {

        if (value === "custom-date") return;

        const weekEndDate = data?.week?.weekEndDate;

        let fromDate = moment(weekEndDate).subtract(1, "month").add(1, "day");
        let toDate = moment(weekEndDate);

        if (value === "last-3-months") {

            fromDate = moment(weekEndDate).subtract(3, "month").add(1, "day");

        } else if (value === "last-6-months") {

            fromDate = moment(weekEndDate).subtract(6, "month").add(1, "day");

        } else if (value === "last-year") {

            fromDate = moment(weekEndDate).subtract(1, "year").add(1, "day");

        }

        filter.handleChange({ name: "from", value: fromDate.format("YYYY-MM-DD"), reset: true });
        filter.handleChange({ name: "to", value: toDate.format("YYYY-MM-DD"), reset: true });

        setKey((key) => key + 1);

    }, [filter.hash])

    return (
        <Col col={{ xs: 24 }}>

            <Row edge gutter={"auto"}>

                <Col col={{ xs: 8 }}>

                    <FieldSelect
                        button
                        form={filter}
                        name="period"
                        defaultValue={filter.defaults.period}
                        onChange={handlePeriodChange}
                        options={[
                            { label: "Last Month", value: "last-month" },
                            { label: "Last 3 Months", value: "last-3-months" },
                            { label: "Last 6 Months", value: "last-6-months" },
                            { label: "Last Year", value: "last-year" },
                            { label: "Custom Date", value: "custom-date" },
                        ]}
                        label="Period"
                        required
                        wrapClass={'manual-input'}
                    />

                </Col>

                <Col col={{ xs: 8 }}>

                    <FieldDate
                        key={key}
                        form={filter}
                        name='from'
                        defaultValue={filter.defaults.from}
                        label='From'
                        wrapClass={filter.values.period !== "custom-date" ? '' : "manual-input"}
                        disabled={filter.values.period !== "custom-date"}
                    />

                </Col>

                <Col col={{ xs: 8 }}
                >

                    <FieldDate
                        key={key}
                        form={filter}
                        name='to'
                        defaultValue={filter.defaults.to}
                        label='To'
                        wrapClass={filter.values.period !== "custom-date" ? '' : "manual-input"}
                        disabled={filter.values.period !== "custom-date"}
                    />

                </Col>

            </Row>

        </Col>

    )
};

export const WeeklyAveragePercentage: React.FC<any> = (props: any) => {

    const { metTarget, belowTarget, aboveTarget } = props;

    return (
        <>
            <div className={style.met}>

                <GiCheckMark className={style.icon} />

                <p className={style.label}>Met Target Volume</p>

                <p className={style.dash}>-</p>

                <p className={style.value}> {metTarget}%</p>

            </div>

            <div className={style.above}>

                <HiArrowUp className={style.icon} />

                <p className={style.label}>Above Target Volume</p>

                <p className={style.dash}>-</p>

                <p className={style.value}> {belowTarget}%</p>

            </div>

            <div className={style.below}>

                <HiArrowDown className={style.icon} />

                <p className={style.label}>Below Target Volume</p>

                <p className={style.dash}>-</p>

                <p className={style.value}> {aboveTarget}%</p>

            </div>
        </>
    )
};