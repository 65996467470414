import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import { Button } from '@dex/bubl-dash';

const SessionsEditTabsBikeAdjustments: React.FC<any> = (props: any) => {

    const { form, prefix, bike, data, useDefaultText } = props;

    return useMemo(() => (

        <Row gutter={16} edge={true} className='row-top-edge'>

            <Col col={{ xs: 24 }}>

                <Seperator
                    bottom="auto"
                    heading={"Saddle"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12, lg: 8 }}>

                        <FieldSelect
                            form={form}
                            label={"Saddle Height"}
                            name={`${prefix}.adjustmentsSaddleHeight`}
                            defaultValue={bike.adjustmentsSaddleHeight}
                            options={[
                                { value: "Raised By", label: "Raised By" },
                                { value: "Lowered By", label: "Lowered By" },
                                { value: "Unchanged", label: "Unchanged" }
                            ]}
                        />

                    </Col>

                    {(bike.adjustmentsSaddleHeight && bike.adjustmentsSaddleHeight !== "Unchanged") &&
                        <Col col={{ xs: 24, md: 12, lg: 8 }}>

                            <FieldText
                                form={form}
                                label={"Details"}
                                name={`${prefix}.adjustmentsSaddleHeightDetails`}
                                defaultValue={bike.adjustmentsSaddleHeightDetails}
                            />

                        </Col>
                    }

                </Row>

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12, lg: 8 }}>

                        <FieldSelect
                            form={form}
                            label={"Saddle Fore/Aft"}
                            name={`${prefix}.adjustmentsSaddleForeAlt`}
                            defaultValue={bike.adjustmentsSaddleForeAlt}
                            options={[
                                { value: "Shifted Saddle Forwards By", label: "Shifted Saddle Forwards By" },
                                { value: "Shifted Saddle Rearwards By", label: "Shifted Saddle Rearwards By" },
                                { value: "Unchanged", label: "Unchanged" },
                            ]}
                        />

                    </Col>

                    {(bike.adjustmentsSaddleForeAlt && bike.adjustmentsSaddleForeAlt !== "Unchanged") &&
                        <Col col={{ xs: 24, md: 12, lg: 8 }}>

                            <FieldText
                                form={form}
                                label={"Details"}
                                name={`${prefix}.adjustmentsSaddleForeAltDetails`}
                                defaultValue={bike.adjustmentsSaddleForeAltDetails}
                            />

                        </Col>
                    }

                </Row>

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12, lg: 8 }}>

                        <FieldSelect
                            form={form}
                            label={"Saddle Tilt"}
                            name={`${prefix}.adjustmentsSaddleTilt`}
                            defaultValue={bike.adjustmentsSaddleTilt}
                            options={[
                                { value: "Titled Nose Up", label: "Titled Nose Up" },
                                { value: "Titled Nose Down", label: "Titled Nose Down" },
                                { value: "Unchanged", label: "Unchanged" },
                            ]}
                        />

                    </Col>

                    {(bike.adjustmentsSaddleTilt && bike.adjustmentsSaddleTilt !== "Unchanged") &&
                        <Col col={{ xs: 24, md: 12, lg: 8 }}>

                            <FieldText
                                form={form}
                                label={"Details (From __ To __)"}
                                name={`${prefix}.adjustmentsSaddleTiltDetails`}
                                defaultValue={bike.adjustmentsSaddleTiltDetails}
                            />

                        </Col>
                    }

                </Row>

            </Col>

            {(bike.handlebarType === 'Dropbars' || bike.handlebarType?.indexOf('MTB') === 0) &&

                <Col col={{ xs: 24 }}>

                    <Seperator
                        bottom="auto"
                        heading={"Handlebars"}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12, lg: 8 }}>

                            <FieldSelect
                                form={form}
                                label={"Handlebar Height"}
                                name={`${prefix}.adjustmentsHandlebarsHeight`}
                                defaultValue={bike.adjustmentsHandlebarsHeight}
                                options={[
                                    { value: "Raised By", label: "Raised By" },
                                    { value: "Lowered By", label: "Lowered By" },
                                    { value: "Unchanged", label: "Unchanged" }
                                ]}
                            />

                        </Col>

                        {(bike.adjustmentsHandlebarsHeight && bike.adjustmentsHandlebarsHeight !== "Unchanged") &&
                            <Col col={{ xs: 24, md: 12, lg: 8 }}>

                                <FieldText
                                    form={form}
                                    label={"Details"}
                                    name={`${prefix}.adjustmentsHandlebarsHeightDetails`}
                                    defaultValue={bike.adjustmentsHandlebarsHeightDetails}
                                />

                            </Col>
                        }

                    </Row>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12, lg: 8 }}>

                            <FieldSelect
                                form={form}
                                label={"Handlebar Rotation"}
                                name={`${prefix}.adjustmentsHandlebarsRotation`}
                                defaultValue={bike.adjustmentsHandlebarsRotation}
                                options={[
                                    { value: "Rotated up by", label: "Rotated up by" },
                                    { value: "Rotated Down by", label: "Rotated Down by" },
                                    { value: "Unchanged", label: "Unchanged" }
                                ]}
                            />

                        </Col>

                        {(bike.adjustmentsHandlebarsRotation && bike.adjustmentsHandlebarsRotation !== "Unchanged") &&
                            <Col col={{ xs: 24, md: 12, lg: 8 }}>

                                <FieldText
                                    form={form}
                                    label={"Details"}
                                    name={`${prefix}.adjustmentsHandlebarsRotationDetails`}
                                    defaultValue={bike.adjustmentsHandlebarsRotationDetails}
                                />

                            </Col>
                        }

                    </Row>

                    {bike.handlebarType?.indexOf('MTB') === -1 &&

                        <Row gutter={8} edge={true}>

                            <Col col={{ xs: 24, md: 12, lg: 8 }}>

                                <FieldSelect
                                    form={form}
                                    label={"Handlebar Brake Hoods"}
                                    name={`${prefix}.adjustmentsHandlebarsBrakeHoods`}
                                    defaultValue={bike.adjustmentsHandlebarsBrakeHoods}
                                    options={[
                                        { value: "Unchanged", label: "Unchanged" },
                                        { value: "Raised Both", label: "Raised Both" },
                                        { value: "Raised Left", label: "Raised Left" },
                                        { value: "Raised Right", label: "Raised Right" },
                                        { value: "Lowered Both", label: "Lowered Both" },
                                        { value: "Lowered Left", label: "Lowered Left" },
                                        { value: "Lowered Right", label: "Lowered Right" },
                                        { value: "Both Turned In", label: "Both Turned In" },
                                        { value: "Left Turned In", label: "Left Turned In" },
                                        { value: "Right Turned In", label: "Right Turned In" },
                                        { value: "Both Turned Out", label: "Both Turned Out" },
                                        { value: "Left Turned Out", label: "Left Turned Out" },
                                        { value: "Right Turned Out", label: "Right Turned Out" },
                                    ]}
                                />

                            </Col>

                            {(bike.adjustmentsHandlebarsBrakeHoods && bike.adjustmentsHandlebarsBrakeHoods !== "Unchanged") &&
                                <Col col={{ xs: 24, md: 12, lg: 8 }}>

                                    <FieldText
                                        form={form}
                                        label={"Details"}
                                        name={`${prefix}.adjustmentsHandlebarsBrakeHoodsRaisedDetails`}
                                        defaultValue={bike.adjustmentsHandlebarsBrakeHoodsRaisedDetails}
                                    />

                                </Col>
                            }

                        </Row>
                    }

                </Col>
            }

            {bike.handlebarType === 'Aerobars' &&

                <Col col={{ xs: 24 }}>

                    <Seperator
                        bottom="auto"
                        heading={"Aerobars"}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12, lg: 8 }}>

                            <FieldSelect
                                form={form}
                                label={"Aero Pad Height"}
                                name={`${prefix}.adjustmentsAerobarsPadHeight`}
                                defaultValue={bike.adjustmentsAerobarsPadHeight}
                                options={[
                                    { value: "Raised By", label: "Raised By" },
                                    { value: "Lowered By", label: "Lowered By" },
                                    { value: "Unchanged", label: "Unchanged" }
                                ]}
                            />

                        </Col>

                        {(bike.adjustmentsAerobarsPadHeight && bike.adjustmentsAerobarsPadHeight !== "Unchanged") &&
                            <Col col={{ xs: 24, md: 12, lg: 8 }}>

                                <FieldText
                                    form={form}
                                    label={"Details"}
                                    name={`${prefix}.adjustmentsAerobarsPadHeightDetails`}
                                    defaultValue={bike.adjustmentsAerobarsPadHeightDetails}
                                />

                            </Col>
                        }

                    </Row>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12, lg: 8 }}>

                            <FieldSelect
                                form={form}
                                label={"Aero Pad Fore-Aft"}
                                name={`${prefix}.adjustmentsAerobarsPadForeAft`}
                                defaultValue={bike.adjustmentsAerobarsPadForeAft}
                                options={[
                                    { value: "Shifted Forwards By", label: "Shifted Forwards By" },
                                    { value: "Shifted Rearwards By", label: "Shifted Rearwards By" },
                                    { value: "Unchanged", label: "Unchanged" }
                                ]}
                            />

                        </Col>

                        {(bike.adjustmentsAerobarsPadForeAft && bike.adjustmentsAerobarsPadForeAft !== "Unchanged") &&
                            <Col col={{ xs: 24, md: 12, lg: 8 }}>

                                <FieldText
                                    form={form}
                                    label={"Details"}
                                    name={`${prefix}.adjustmentsAerobarsPadForeAftDetails`}
                                    defaultValue={bike.adjustmentsAerobarsPadForeAftDetails}
                                />

                            </Col>
                        }

                    </Row>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12, lg: 8 }}>

                            <FieldSelect
                                form={form}
                                label={"Aero Pad Tilt"}
                                name={`${prefix}.adjustmentsAerobarsPadTilt`}
                                defaultValue={bike.adjustmentsAerobarsPadTilt}
                                options={[
                                    { value: "Rotated Up From", label: "Rotated Up From" },
                                    { value: "Rotated Down From", label: "Rotated Down From" },
                                    { value: "Unchanged", label: "Unchanged" }
                                ]}
                            />

                        </Col>

                        {(bike.adjustmentsAerobarsPadTilt && bike.adjustmentsAerobarsPadTilt !== "Unchanged") &&
                            <Col col={{ xs: 24, md: 12, lg: 8 }}>

                                <FieldText
                                    form={form}
                                    label={"Details"}
                                    name={`${prefix}.adjustmentsAerobarsPadTiltDetails`}
                                    defaultValue={bike.adjustmentsAerobarsPadTiltDetails}
                                />

                            </Col>
                        }

                    </Row>

                    <Space />

                    <Seperator
                        bottom="auto"
                        heading={"Extensions"}
                    />

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12, lg: 8 }}>

                            <FieldSelect
                                form={form}
                                label={"Extension Fore-Aft"}
                                name={`${prefix}.adjustmentsExtensionPadForeAft`}
                                defaultValue={bike.adjustmentsExtensionPadForeAft}
                                options={[
                                    { value: "Shifted Forwards By", label: "Shifted Forwards By" },
                                    { value: "Shifted Rearwards By", label: "Shifted Rearwards By" },
                                    { value: "Unchanged", label: "Unchanged" }
                                ]}
                            />

                        </Col>

                        {(bike.adjustmentsExtensionPadForeAft && bike.adjustmentsExtensionPadForeAft !== "Unchanged") &&
                            <Col col={{ xs: 24, md: 12, lg: 8 }}>

                                <FieldText
                                    form={form}
                                    label={"Details"}
                                    name={`${prefix}.adjustmentsExtensionPadForeAftDetails`}
                                    defaultValue={bike.adjustmentsExtensionPadForeAftDetails}
                                />

                            </Col>
                        }

                    </Row>

                    <Row gutter={8} edge={true}>

                        <Col col={{ xs: 24, md: 12, lg: 8 }}>

                            <FieldSelect
                                form={form}
                                label={"Extension Rotation"}
                                name={`${prefix}.adjustmentsExtensionRotation`}
                                defaultValue={bike.adjustmentsExtensionRotation}
                                options={[
                                    { value: "Set Neutral", label: "Set Neutral" },
                                    { value: "Turned Inwards", label: "Turned Inwards" },
                                    { value: "Turned Outwards", label: "Turned Outwards" },
                                    { value: "Unchanged", label: "Unchanged" }
                                ]}
                            />

                        </Col>

                        {(bike.adjustmentsExtensionRotation && bike.adjustmentsExtensionRotation !== "Set Neutral") &&
                            <Col col={{ xs: 24, md: 12, lg: 8 }}>

                                <FieldText
                                    form={form}
                                    label={"Details"}
                                    name={`${prefix}.adjustmentsExtensionRotationDetails`}
                                    defaultValue={bike.adjustmentsExtensionRotationDetails}
                                />

                            </Col>
                        }

                    </Row>

                </Col>

            }

            <Col col={{ xs: 24 }}>

                <Seperator
                    bottom="auto"
                    heading={"Stem"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12, lg: 8 }}>

                        <FieldSelect
                            form={form}
                            label={"Stem Length"}
                            name={`${prefix}.adjustmentsStemLength`}
                            defaultValue={bike.adjustmentsStemLength}
                            options={[
                                { value: "Changed Length", label: "Changed Length" },
                                { value: "Unchanged", label: "Unchanged" },
                            ]}
                        />

                    </Col>

                    {(bike.adjustmentsStemLength && bike.adjustmentsStemLength !== "Unchanged") &&
                        <Col col={{ xs: 24, md: 12, lg: 8 }}>

                            <FieldText
                                form={form}
                                label={"Details (From __ To __)"}
                                name={`${prefix}.adjustmentsStemLengthDetails`}
                                defaultValue={bike.adjustmentsStemLengthDetails}
                            />

                        </Col>
                    }

                </Row>


                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12, lg: 8 }}>

                        <FieldSelect
                            form={form}
                            label={"Stem Rise"}
                            name={`${prefix}.adjustmentsStemRise`}
                            defaultValue={bike.adjustmentsStemRise}
                            options={[
                                { value: "Changed Rise", label: "Changed Rise" },
                                { value: "Unchanged", label: "Unchanged" },
                            ]}
                        />

                    </Col>

                    {(bike.adjustmentsStemRise && bike.adjustmentsStemRise !== "Unchanged") &&
                        <Col col={{ xs: 24, md: 12, lg: 8 }}>

                            <FieldText
                                form={form}
                                label={"Details (From __ To __)"}
                                name={`${prefix}.adjustmentsStemRiseDetails`}
                                defaultValue={bike.adjustmentsStemRiseDetails}
                            />

                        </Col>
                    }

                </Row>

            </Col>


            <Col col={{ xs: 24 }}>

                <Seperator
                    bottom="auto"
                    heading={"Other Adjustments"}
                />

            </Col>

            <Row edge={true} gutter={8}>

                <Col col={{ xs: 24, lg: 20 }}>

                    <FieldTextArea
                        form={form}
                        label={"Comments"}
                        name={`${prefix}.adjustmentsComments`}
                        defaultValue={bike.adjustmentsComments}
                        rows={4}
                        maxLength={960}
                    />

                </Col>

                <Col col={{ xs: 24, lg: 4 }}>

                    <Space />

                    <Button
                        label="Use Default Text"
                        type='faded'
                        size='small'
                        confirm={bike.adjustmentsComments ? { title: 'Are you sure?', message: 'Are you sure you want to overwrite the current comment' } : undefined}
                        onClick={useDefaultText.bind(null, "adjustmentComment", `${prefix}.adjustmentsComments`)}
                    />

                </Col>

            </Row>

        </Row>

    ), [props]);

}

export default SessionsEditTabsBikeAdjustments;
