import { FieldSelect, Panel, Space } from '@dex/bubl-dash';
import { formatNum, get, parseNum, titleCase } from '@dex/bubl-helpers';
import React, { useEffect, useState } from 'react';
import { getRandomInt } from '../../functions/sharedGeneral';
import RatingScalesApi from '../../views/RatingScales/RatingScalesApi';
import { stepsMinMax, stepLabels, ratingLabel, arrowPosition, Scale, Steps, DisplayAs } from './RatingScaleElements';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import styles from "./RatingScaleTable.module.scss";
import { changeDemographic } from '../../functions/sharedTests';

const RatingScaleTable: React.FC<RatingScaleTableProps> = (props) => {

    let { title, form, rows, name, demographic, gender } = props;

    if (form && name) demographic = get(form.values, name) || demographic;

    if (!gender && form) get(form.values, "gender");
    if (!gender) gender = "male";
    if (!demographic) demographic = "all";

    const [show, setShow]: any = useState(props.show ? true : false);

    return (

        <>

            <Panel heading={false} className={styles.panel}>

                <div className={styles.inner}>

                    <div className={styles.head}>

                        <div className={styles.title}>
                            {title}
                        </div>

                        <a
                            className={styles.more}
                            onClick={setShow.bind(null, !show)}>
                            {!show ? <>Show Ranges <FiEye /></> : <>Hide Ranges <FiEyeOff /></>}
                        </a>

                    </div>

                    <div className={styles.rows}>

                        {rows.map((row, index) => (

                            <RatingScaleTableRow
                                key={index}
                                demographic={demographic}
                                gender={gender}
                                metric={row.metric}
                                value={row.value}
                                altUnit={row.altUnit}
                                show={show}
                                renderAfter={row.renderAfter}
                                renderHeading={row.renderHeading}
                            />

                        ))}

                    </div>

                    {props.demographic && (!name || !form) &&
                        <div className={styles.demographic}>
                            Demographic: {titleCase(changeDemographic(props.demographic))}
                        </div>
                    }

                </div>

                {form && name &&

                    <FieldSelect
                        form={form}
                        name={name}
                        wrapClass={styles.fieldWrap}
                        defaultValue={get(form.defaults, name, demographic)}
                        options={[
                            { label: "Recreational", value: "untrained" },
                            { label: "Competitive", value: "trained" },
                            { label: "Pro", value: "elite" },
                            { label: "All", value: "all" },
                        ]}
                    />

                }

            </Panel>

        </>

    )

}

const RatingScaleTableRow: React.FC<RatingScaleTableProps> = (props) => {

    let { metric, value, altUnit, demographic, gender, show, renderAfter, renderHeading } = props;

    const group = RatingScalesApi.getData();

    const [data, setData]: any = useState({ metric: metric, demographic: "Scale Not Found" });

    useEffect(() => {

        group.run({
            params: {
                metric: metric,
            },
            onComplete: (response) => {

                if (response) setData(response);

            }
        });

    }, []);

    const { steps, min, max }: any = stepsMinMax(data, demographic, gender);

    value = (props.value === "rand") ? getRandomInt(min, max) : parseFloat(value);

    const labels = stepLabels(steps);

    const rating = ratingLabel(value, steps, min, max);

    const left = arrowPosition(value, min, max);

    const decimalPlace = -Math.abs(parseNum(typeof data.decimalPlaces === "number" ? data.decimalPlaces : 1));

    return (

        <>
            {data &&

                <div className={styles.row + " row"}>

                    <div className={styles.cols}>

                        <div className={styles.metric}>

                            {renderHeading && renderHeading(data.title ? data.title : data.metric)}
                            {!renderHeading && <>{data.title ? data.title : data.metric}: </>}

                            <div className={styles.rating}>
                                {rating}
                            </div>

                        </div>

                        <div className={styles.measurement}>
                            {value ? formatNum(value, decimalPlace) : "-"}<span className={styles.unit}> {data.suffix}</span>

                            {altUnit &&
                                <> <span className={styles.altSep}>/</span> {formatNum(altUnit.value, altUnit.decimalPlace || decimalPlace)}<span className={styles.unit}> {altUnit.suffix}</span></>
                            }
                        </div>



                    </div>

                    <div className={show ? styles.displayShow : styles.displayHide}>

                        <DisplayAs
                            left={left}
                            labels={labels}
                            steps={steps}
                            data={data}
                            min={min}
                            max={max}
                            demographic={demographic}
                            gender={gender}
                        />

                        {renderAfter &&
                            <>
                                <Space />
                                {renderAfter()}
                            </>
                        }
                    </div>

                </div>

            }

        </>

    )

};

interface RatingScaleTableProps {
    [key: string]: any,
}

export default RatingScaleTable;